// Lib
import React from 'react'
import { Chip } from '@mui/material'

// Include in project
import { Props, ThemeColor } from './config'

//Images
import { HighlightOffRounded } from '@mui/icons-material'

const ChipDeleteComponent: React.FC<Props> = (props: Props) => {
  return (
    <Chip
      icon={props.startIcon && props.startIcon}
      onDelete={() => (props.onDelete ? props.onDelete() : '')}
      onClick={props.onClick && props.onClick}
      sx={ThemeColor}
      label={props.label}
      disabled={props.disabled}
      deleteIcon={<HighlightOffRounded />}
    />
  )
}

export default ChipDeleteComponent
