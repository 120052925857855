// Lib
import React, { useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import _ from 'lodash'

// Include in project
import styles from './index.module.scss'
import { Button, Modal, Table, AutoComplete } from 'component'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { ICategory } from 'pages/Category'
import { RootState } from 'states/store'
import { IOption } from 'utils/interface'

interface Props {
  open: boolean
  handleClose: () => void
  handleArchiveProduct: (productID: string) => void
  handleAddProduct: () => void
}

const ModalAddProductToCategory: React.FC<Props> = ({ open, handleClose, handleArchiveProduct, handleAddProduct }) => {
  const { values, setFieldValue } = useFormikContext<ICategory>()
  const rawProductOptionList = useSelector((state: RootState) => state.product.productOptionList)
  const [optionProduct, setOptionProduct] = useState<IOption[]>([])

  useEffect(() => {
    const productIDList = values.productList?.map((ele) => ({
      value: ele?.productID,
      label: ele?.name,
    }))

    const productOptionList = _.differenceWith(rawProductOptionList, productIDList as any, _.isEqual)

    setOptionProduct(productOptionList)
  }, [rawProductOptionList, values.productList])

  const dataTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.ProductManagementList,
    dataList: values.productList,
    handleArchive: (data) => handleArchiveProduct(data),
  })

  return (
    <Modal
      open={open}
      handleClose={() => {
        handleClose()
        setFieldValue('searchProducts', [])
      }}
      width="70%"
      height="90%"
      title="Add Product to Category"
      hiddenButton
    >
      <div className={styles.container}>
        <div className={styles.wrapperInput}>
          <h6>Choose Product</h6>
          <section className={styles.box}>
            <AutoComplete name="searchProducts" placeholder="Search Product Name" option={optionProduct} />
            <Button type="info" name="Add" typeButton="button" functionOnClick={handleAddProduct} />
          </section>
        </div>

        <Table
          objectKey={{
            imageHTML: 'Image',
            productNameHTML: 'Name',
            description: 'Description',
            brandName: 'Brand',
            actionCategory: '',
          }}
          data={dataTable}
        />
      </div>
    </Modal>
  )
}

export default ModalAddProductToCategory
