// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Include in project
import styles from './index.module.scss'
import { Chip, Divider } from 'component'
import { IProductInfo } from 'pages/ProductInfo'
import type { RootState } from 'states/store'

const TagsDetailsSection: React.FC = () => {
  const navigate = useNavigate()
  const { values } = useFormikContext<IProductInfo>()
  const tagsOptionList = useSelector((state: RootState) => state.tags.tagsOptionList)

  return (
    <div className={styles.container}>
      <h6>Tags</h6>
      <Divider />
      <div className={styles.boxText}>
        <p className={`fontBold ${styles.text}`}>Tags</p>
        <div className={styles.wrapperChip}>
          {values.productInfo.tagID &&
            values.productInfo.tagID?.map((item, index: number) => (
              <Chip
                key={index}
                label={tagsOptionList?.find((ele) => ele.value === item)?.label || ''}
                color="infoSecond"
                onClick={() => navigate(`/product-management/tags?tagID=${item}`)}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default TagsDetailsSection
