// Lib
import React from 'react'
import Switch from '@mui/material/Switch'
import { useFormikContext } from 'formik'

// Include in project
import { Props, SwitchSx } from './config'

const SwitchComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const { handleChange } = useFormikContext()

  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <p>{props.labelDetail?.false && props.labelDetail?.false}</p>
      <Switch
        checked={props.checked}
        name={props.name}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
        onChange={props.onChange || handleChange}
        disabled={props.disabled}
        sx={SwitchSx}
      />
      <p>{props.labelDetail?.true && props.labelDetail.true}</p>

      {/* <p style={{ padding: '0px 5px' }}>{props.label && props.label}</p> */}
    </div>
  )
}

export default SwitchComponent
