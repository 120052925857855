// Lib
import React from 'react'
import { getIn, useFormikContext } from 'formik'

//Images
import AddIcon from '@mui/icons-material/Add'

// Include in project
import styles from './index.module.scss'
import { SelectSingle, Modal, TextField } from 'component'
import { IProductInfo } from 'pages/ProductInfo'
import { optionList } from 'utils/other'
import { E_Pricing_Model } from 'utils/generatedNonAuth'
import { TableVolumnPrice } from 'utils/generated'

interface Props {
  open: boolean
  handleClose: () => void
  selectPricingSKUIndex: number
}

const ModalUpdateProductPricing: React.FC<Props> = ({ open, handleClose, selectPricingSKUIndex }) => {
  const { values, setFieldValue } = useFormikContext<IProductInfo>()

  const productSKU = values.formInputAddSKU.productSKU[selectPricingSKUIndex]
  const pricingKey = `formInputAddSKU.productSKU[${selectPricingSKUIndex}].pricing[0]`
  const getInKey = () => {
    if (productSKU?.pricing?.[0]?.pricingModel === E_Pricing_Model.StandardPricing)
      return `${pricingKey}.priceModelStandard`
    else if (productSKU?.pricing?.[0]?.pricingModel === E_Pricing_Model.VolumePricing)
      return `${pricingKey}.priceModelVolumn`
  }

  // Update endUnit +10 of last tier
  // And push New Tier
  const handleAddNewTier = () => {
    const lastIndex = getIn(values, `${getInKey()}.tableVolumnPrice`).length - 1
    const lastTier = getIn(values, `${getInKey()}.tableVolumnPrice`)[lastIndex]

    const clone = JSON.parse(JSON.stringify(getIn(values, `${getInKey()}.tableVolumnPrice`)))

    clone[lastIndex].endUnit = lastTier.startUnit + 10
    const newTier = {
      startUnit: lastTier.startUnit + 11,
      endUnit: null,
      salePrice: 0,
    }
    clone.push(newTier)

    setFieldValue(`${getInKey()}.tableVolumnPrice`, clone)
  }

  const handleChangeEndUnit = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = parseInt(e.target.value) || 0
    setFieldValue(`${getInKey()}.tableVolumnPrice[${index}].endUnit`, value)
    setFieldValue(`${getInKey()}.tableVolumnPrice[${index + 1}].startUnit`, value + 1)
  }

  const RenderPricingModel = () => {
    // Model Standard
    if (productSKU?.pricing?.[0]?.pricingModel === E_Pricing_Model.StandardPricing) {
      return (
        <>
          <TextField name={`${getInKey()}.defaultPrice`} type="number" label="Default Price (Max price)" />
          <TextField name={`${getInKey()}.salePrice`} type="number" label="Sale Price" require />
        </>
      )
    }
    // Model Volumn
    else if (productSKU?.pricing?.[0]?.pricingModel === E_Pricing_Model.VolumePricing) {
      return (
        <>
          <div className="row justify-space-between">
            <h6>Tiers</h6>

            <div className={`row ${styles.groupAdd}`} onClick={() => handleAddNewTier()}>
              <AddIcon />
              <p>Add another tier</p>
            </div>
          </div>

          <table className={styles.table}>
            <thead>
              <tr>
                <th style={{ minWidth: '80px' }}></th>
                <th style={{ minWidth: '80px' }}>First Unit</th>
                <th>Last Unit</th>
                <th>Price Per Unit</th>
              </tr>
            </thead>
            <tbody>
              {getIn(values, `${getInKey()}.tableVolumnPrice`).map((price: TableVolumnPrice, index: number) => (
                <tr key={index}>
                  <th>Total units</th>
                  <td>{price.startUnit}</td>
                  <td>
                    {getIn(values, `${getInKey()}.tableVolumnPrice`).length !== index + 1 ? (
                      <TextField
                        name={`${getInKey()}.tableVolumnPrice[${index}].endUnit`}
                        type="number"
                        onChange={(e) => handleChangeEndUnit(e, index)}
                      />
                    ) : (
                      '∞'
                    )}
                  </td>
                  <td>
                    <TextField name={`${getInKey()}.tableVolumnPrice[${index}].salePrice`} type="number" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )
    }
  }

  return (
    <Modal open={open} handleClose={handleClose} width="30%" height="auto" title="Price information" hiddenButton>
      <div className={styles.container}>
        <h5>Pricing details</h5>
        <SelectSingle
          name={`formInputAddSKU.productSKU[${selectPricingSKUIndex}].pricing[0].pricingModel`}
          option={optionList.pricingModelOption}
          label="Pricing model"
        />
        <SelectSingle name={`${getInKey()}.paymentType`} option={optionList.paymenyTypeOption} label="Payment Type" />

        {RenderPricingModel()}

        <TextField name={`${pricingKey}.cost`} type="number" label="Cost" />
      </div>
    </Modal>
  )
}

export default ModalUpdateProductPricing
