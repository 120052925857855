// Lib
import { NodeModel } from '@minoru/react-dnd-treeview'
import _ from 'lodash'

// Include in project
import { ITags } from 'pages/Tags'
import { IBrand } from 'pages/Brand'
import { IUserInfo } from 'pages/UserInfo'
import { ICMS } from 'pages/CMS'
import { ISetting } from 'pages/Setting'
import { ICustomerManagement } from 'pages/CustomerManagement'
import { IStaffManagement } from 'pages/StaffManagement'
import { IPermissionManagement } from 'pages/PermissionManagement'
import { IIventoryManagement } from 'pages/InventoryManagement'
import {
  ProductOptionGroup,
  E_Is_Active,
  ProductOption,
  ProductOptionVariant,
  Product,
  Category,
} from 'utils/generated'
import { packDataImage, packDataFile } from 'utils/handleFile'
import { IUpdateOption } from 'pages/UpdateOption'
import { IProductManagement } from 'pages/ProductManagement'
import { IProductInfo } from 'pages/ProductInfo'
import { IOrderManagement } from 'pages/OrderManagement'

export enum ETransformationDataToServer {
  UpdateTag = 'UPDATE_TAG',
  UpdateBrand = 'UPDATE_BRAND',
  UpdateUserInfo = 'UPDATE_USER_INFO',
  UpdateSetting = 'UPDATE_SETTING',
  UpdateCMS = 'UPDATE_CMS',
  ArchiveOption = 'ARCHIVE_OPTION',
  UpdateOption = 'UPDATE_OPTION',
  UpdateTagsAndIsActiveCustomer = 'UPDATE_TAGS_AND_ISACTIVE_CUSTOMER',
  CreateStaff = 'CREATE_STAFF',
  UpdateStaff = 'UPDATE_STAFF',
  UpdateRoleCompany = 'UPDATE_ROLE_COMPANY',
  CreateTransection = 'CREATE_TRANSECTION',
  ArchiveProduct = 'ARCHIVE_PRODUCT',
  UpdateProduct = 'UPDATE_PRODUCT',
  UpdateSKU = 'UPDATE_SKU',
  UpdateCategory = 'UPDATE_CATEGORY',
  UpdateOrderStatus = 'UPDATE_ORDER_STATUS',
  UpdateStripePaymentStatus = 'UPDATE_STRIPE_PAYMENT_STATUS',
}

export const transformationDataToServer = async ({
  values,
  type,
}: {
  values: any
  type: ETransformationDataToServer
}) => {
  switch (type) {
    case ETransformationDataToServer.UpdateTag:
      const formInputTag = (values as ITags).formInput
      return {
        input: {
          tagID: formInputTag.tagID,
          tagName: formInputTag.tagName,
          color: formInputTag.color,
          isActive: formInputTag.isActive,
        },
      }

    case ETransformationDataToServer.UpdateBrand:
      const formInputBrand = (values as IBrand).formInput

      const imgBrandURL = await packDataImage(
        formInputBrand.brandImgURL as string[],
        formInputBrand.brandImgURLShow,
        `Brand/${formInputBrand.brandID || formInputBrand.name}`,
      )

      return {
        input: {
          brandID: formInputBrand.brandID,
          name: formInputBrand.name,
          description: formInputBrand.description,
          brandImgURL: imgBrandURL,
          customAttribute: formInputBrand.customAttribute,
          isActive: formInputBrand.isActive,
        },
      }

    case ETransformationDataToServer.UpdateUserInfo:
      const formInputUser = (values as IUserInfo).formInput

      const imgURL = await packDataImage(
        formInputUser.profileImgURL as string[],
        formInputUser.profileImgURLShow,
        `Profile/${formInputUser.userID}`,
      )

      return {
        input: {
          profileImgURL: imgURL?.[0] || '',
          idCard: formInputUser.idCard,
          isActive: formInputUser.isActive,
          firstname: formInputUser.firstname,
          surname: formInputUser.surname,
          birthday: formInputUser.birthday,
        },
      }

    case ETransformationDataToServer.UpdateSetting:
      const formInputSetting = (values as ISetting).formInput

      return {
        input: {
          domainName: formInputSetting.domainName,
          stripeSuccessPathURL: formInputSetting.stripeSuccessPathURL,
          stripeCancelPathURL: formInputSetting.stripeCancelPathURL,
          stripeSecretKeyProd: formInputSetting.stripeSecretKeyProd,
          stripeSecretKeyTest: formInputSetting.stripeSecretKeyTest,
          senderMail: formInputSetting.senderMail,
          senderPassword: formInputSetting.senderPassword,
          lineNotifyToken: formInputSetting.lineNotifyToken,
          tax: formInputSetting.tax,
          isClose: formInputSetting.isClose,
          timeoutCart: formInputSetting.timeoutCart,
          timeoutPaid: formInputSetting.timeoutPaid,
          freightModel: formInputSetting.freightModel,
          freightModelFix: {
            freight: formInputSetting.freightModelFix?.freight || 0,
          },
          freightModelProvince: formInputSetting.freightModelProvince?.map((province) => ({
            province: province?.province,
            tableFreightProvince: province?.tableFreightProvince?.map((frightProvince) => ({
              startPrice: frightProvince?.startPrice,
              endPrice: frightProvince?.endPrice,
              freight: frightProvince?.freight,
            })),
            isFreightOnDelivery: province?.isFreightOnDelivery,
          })),
        },
      }

    case ETransformationDataToServer.UpdateCMS:
      const formInputCms = (values as ICMS).formInput

      const banner1 = await packDataImage(formInputCms.poster.banner1, formInputCms.poster.banner1Show, `CMS/banner1`)
      const banner2 = await packDataImage(formInputCms.poster.banner2, formInputCms.poster.banner2Show, `CMS/banner2`)
      const banner3 = await packDataImage(formInputCms.poster.banner3, formInputCms.poster.banner3Show, `CMS/banner3`)
      const banner4 = await packDataImage(formInputCms.poster.banner4, formInputCms.poster.banner4Show, `CMS/banner4`)
      const promotionBanner = await packDataImage(
        formInputCms.poster.promotionBanner,
        formInputCms.poster.promotionBannerShow,
        `CMS/promotionBanner`,
      )
      const bestSellerBanner = await packDataImage(
        formInputCms.poster.bestSellerBanner,
        formInputCms.poster.bestSellerBannerShow,
        `CMS/bestSellerBanner`,
      )
      const recommendedBanner = await packDataImage(
        formInputCms.poster.recommendedBanner,
        formInputCms.poster.recommendedBannerShow,
        `CMS/recommendedBanner`,
      )
      const announcement = await packDataImage(
        formInputCms.announcement.announcement,
        formInputCms.announcement.announcementShow,
        `CMS/announcement`,
      )

      return {
        input: {
          customAttribute: null,
          poster: {
            banner1: banner1,
            banner2: banner2,
            banner3: banner3,
            banner4: banner4,
            promotionBanner: promotionBanner,
            bestSellerBanner: bestSellerBanner,
            recommendedBanner: recommendedBanner,
          },
          announcement: {
            announcement: announcement,
          },
        },
      }

    case ETransformationDataToServer.ArchiveOption:
      const formInputArchiveOption = values as ProductOptionGroup
      return {
        input: {
          productOptionGroupID: formInputArchiveOption.productOptionGroupID,
          isActive: formInputArchiveOption.isActive === E_Is_Active.True ? E_Is_Active.False : E_Is_Active.True,
        },
      }

    case ETransformationDataToServer.UpdateOption:
      const formInputUpdateOption = (values as IUpdateOption).formInput

      const optionRemoveIDGenerate = (formInputUpdateOption.option as ProductOption[]).map((data) => {
        return {
          productOptionID: data.productOptionID?.includes('GENERATE') ? null : data.productOptionID,
          index: data.index,
          name: data.name,
          description: data.description,
          customAttribute: data.customAttribute,
          isOption: data.isOption,
          isFilter: data.isFilter,
          isActive: data.isActive,
          type: data.type,
          variant: (data.variant as ProductOptionVariant[]).map((eleVariant) => {
            return {
              productOptionVariantID: eleVariant.productOptionVariantID?.includes('GENERATE')
                ? null
                : eleVariant.productOptionVariantID,
              index: eleVariant.index,
              name: eleVariant.name,
              description: eleVariant.description,
              remark: eleVariant.remark,
              customAttribute: eleVariant.customAttribute,
              isActive: eleVariant.isActive,
              isDefault: eleVariant.isDefault,
            }
          }),
        }
      })

      return {
        input: {
          productOptionGroupID: formInputUpdateOption.productOptionGroupID,
          name: formInputUpdateOption.name,
          description: formInputUpdateOption.description,
          customAttribute: formInputUpdateOption.customAttribute,
          isActive: formInputUpdateOption.isActive,
          option: optionRemoveIDGenerate,
        },
      }

    case ETransformationDataToServer.UpdateTagsAndIsActiveCustomer:
      const formInputUserRole = (values as ICustomerManagement).formInput

      return {
        input: {
          isActive: formInputUserRole.isActive,
          userID: formInputUserRole.userID,
          tagID: formInputUserRole.tagID?.map((tag) => tag.value),
        },
      }

    case ETransformationDataToServer.CreateStaff:
      const formInputStaff = (values as IStaffManagement).formInput

      const formInputStaffImgURL = await packDataImage(
        formInputStaff.profileImgURL as string[],
        formInputStaff.profileImgURLShow,
        `Profile/Staff/${formInputStaff.firstname}`,
      )

      return {
        input: {
          firstname: formInputStaff.firstname,
          surname: formInputStaff.surname,
          idCard: formInputStaff.idCard,
          email: formInputStaff.email,
          phoneNumber: formInputStaff.phoneNumber,
          userRoleCompanyID: formInputStaff.userRoleCompanyID,
          profileImgURL: formInputStaffImgURL,
          password: formInputStaff.password,
          tagID: formInputStaff.tagID?.map((tag) => tag.value),
        },
      }

    case ETransformationDataToServer.UpdateStaff:
      const formInputUpdateStaff = (values as IStaffManagement).formInput

      const formInputUpdateStaffImgURL = await packDataImage(
        formInputUpdateStaff.profileImgURL as string[],
        formInputUpdateStaff.profileImgURLShow,
        `Profile/Staff/${formInputUpdateStaff.firstname}`,
      )

      return {
        input: {
          userID: formInputUpdateStaff.userID,
          firstname: formInputUpdateStaff.firstname,
          surname: formInputUpdateStaff.surname,
          idCard: formInputUpdateStaff.idCard,
          email: formInputUpdateStaff.email,
          phoneNumber: formInputUpdateStaff.phoneNumber,
          userRoleCompanyID: formInputUpdateStaff.userRoleCompanyID,
          profileImgURL: formInputUpdateStaffImgURL,
          tagID: formInputUpdateStaff.tagID?.map((tag) => tag.value),
          isActive: formInputUpdateStaff.isActive,
        },
      }

    case ETransformationDataToServer.UpdateRoleCompany:
      const formInputRoleCompany = (values as IPermissionManagement).formInput

      return {
        input: {
          isActive: formInputRoleCompany.isActive,
          userRoleCompanyID: formInputRoleCompany.userRoleCompanyID,
          userRoleCompanyName: formInputRoleCompany.userRoleCompanyName,
          userLevel: formInputRoleCompany.userLevel,
          permission: formInputRoleCompany.permission,
        },
      }

    case ETransformationDataToServer.CreateTransection:
      const formInputTransection = (values as IIventoryManagement).formInput

      return {
        input: {
          productSKUID: formInputTransection.productSKUID,
          productID: formInputTransection.productID,
          triggerType: formInputTransection.triggerType,
          qty: formInputTransection.qty,
          note: formInputTransection.note,
        },
      }

    case ETransformationDataToServer.ArchiveProduct:
      const formInputArchiveProduct = values as Product

      return {
        input: {
          productID: formInputArchiveProduct.productID,
          isActive: formInputArchiveProduct.isActive === E_Is_Active.True ? E_Is_Active.False : E_Is_Active.True,
          brandID: formInputArchiveProduct.brandID,
          productOptionGroupID: formInputArchiveProduct.productOptionGroupID,
        },
      }

    case ETransformationDataToServer.UpdateProduct:
      const formInputUpdateProduct = (values as IProductManagement).formInput

      const imgProductURL = await packDataImage(
        formInputUpdateProduct.productImgURL as string[],
        formInputUpdateProduct.productImgURLShow,
        `Product/img/${formInputUpdateProduct.productID || formInputUpdateProduct.name}`,
      )

      const fileProductURL = await packDataFile(
        formInputUpdateProduct.productFileURL as string[],
        formInputUpdateProduct.productFileURLShow,
        `Product/file/${formInputUpdateProduct.productID || formInputUpdateProduct.name}`,
      )

      return {
        input: {
          productID: formInputUpdateProduct.productID,
          brandID: formInputUpdateProduct.brandID,
          name: formInputUpdateProduct.name,
          description: formInputUpdateProduct.description,
          unitLabel: formInputUpdateProduct.unitLabel,
          productOptionGroupID: formInputUpdateProduct.productOptionGroupID,
          productImgURL: imgProductURL,
          productFileURL: fileProductURL,
          isVisible: formInputUpdateProduct.isVisible,
          isActive: formInputUpdateProduct.isActive,
          tagID: formInputUpdateProduct.tagID?.map((tag) => tag.value),
          customAttribute: formInputUpdateProduct.customAttribute,
          specAttribute: formInputUpdateProduct.specAttribute,
          minimumStock: formInputUpdateProduct.minimumStock,
          prefix: formInputUpdateProduct.prefix,
          remark: formInputUpdateProduct.remark,
        },
      }

    case ETransformationDataToServer.UpdateSKU:
      const formInputSku = (values as IProductInfo).formInputAddSKU.productSKU

      return {
        input: formInputSku.map((data) => {
          Object.keys(data.optionAttribute).map((optionKey) => {
            if (data.optionAttribute[optionKey] !== '-') return

            // Remove Option when variant is `-`
            delete data.optionAttribute[optionKey]
          })
          return {
            productID: data.productID,
            productSKUID: data.productSKUID?.includes('GENERATE') ? null : data.productSKUID,
            productSKU: data.productSKU,
            productSKUName: data.productSKUName,
            customAttribute: data.customAttribute,
            optionAttribute: data.optionAttribute,
            isActive: data.isActive,
            isVisible: data.isVisible,
            tagID: data.tagID,
            remark: data.remark,
            pricing: {
              pricingID: data.pricing?.[0]?.pricingID,
              pricingModel: data.pricing?.[0]?.pricingModel,
              priceModelStandard: {
                paymentType: data.pricing?.[0]?.priceModelStandard?.paymentType,
                defaultPrice: data.pricing?.[0]?.priceModelStandard?.defaultPrice,
                salePrice: data.pricing?.[0]?.priceModelStandard?.salePrice,
              },
              priceModelVolumn: {
                paymentType: data.pricing?.[0]?.priceModelVolumn?.paymentType,
                tableVolumnPrice: data.pricing?.[0]?.priceModelVolumn?.tableVolumnPrice?.map((price) => ({
                  startUnit: price?.startUnit,
                  endUnit: price?.endUnit,
                  salePrice: price?.salePrice,
                })),
              },
              cost: data.pricing?.[0]?.cost,
            },
          }
        }),
      }

    case ETransformationDataToServer.UpdateCategory:
      const promiseFormInputCategory = (
        values as NodeModel<Category & { pdfUrlShow: (string & File)[]; imageUrlShow: (string & File)[] }>[]
      ).map(async (data) => {
        const fileCategoryURL = await packDataFile(
          (data.data?.pdfUrl as string[]) || [],
          data.data?.pdfUrlShow || [],
          `Category/file/${data.data?.categoryID || data.data?.categoryName}`,
        )

        const imgCategoryURL = await packDataImage(
          data.data?.imageUrl as string[],
          data.data?.imageUrlShow || [],
          `Category/image/${data.data?.categoryID || data.data?.categoryName}`,
        )

        return {
          ...data,
          data: {
            categoryID: data.data?.categoryID,
            categoryName: data.data?.categoryName,
            productOptionGroupID: data.data?.productOptionGroupID,
            joinItemWithCategoryID: data.data?.joinItemWithCategoryID,
            description: data.data?.description,
            isActive: data.data?.isActive,
            index: data.data?.index,
            customAttribute: data.data?.customAttribute,
            features: data.data?.features,
            pdfUrl: fileCategoryURL,
            imageUrl: imgCategoryURL,
            items: [],
          },
        }
      })

      const formInputCategory = await Promise.all(promiseFormInputCategory)

      const findUniqueParentID: any = {}
      formInputCategory.forEach((ele) => {
        findUniqueParentID[ele.parent] = [...(findUniqueParentID[ele.parent] || []), ele.data]
      })

      // Dimention 1
      const intitialCategory: Category[] = JSON.parse(JSON.stringify(findUniqueParentID.ROOT))
      delete findUniqueParentID.ROOT

      intitialCategory.forEach((eleSecond: Category, idxSecond: number) => {
        // Dimention 2
        if (!_.isEmpty(findUniqueParentID)) {
          Object.keys(findUniqueParentID).map((key) => {
            if (eleSecond.categoryID === key) {
              intitialCategory[idxSecond].items = findUniqueParentID[key]
              delete findUniqueParentID[key]
            }
          })

          // Dimention 3
          if (!_.isEmpty(findUniqueParentID)) {
            eleSecond.items?.forEach((eleThird, idxThird) => {
              Object.keys(findUniqueParentID).map((key) => {
                if (eleThird?.categoryID === key) {
                  ;(intitialCategory[idxSecond].items as Category[])[idxThird].items = findUniqueParentID[key]
                  delete findUniqueParentID[key]
                }
              })

              // Demention 4
              if (!_.isEmpty(findUniqueParentID)) {
                eleThird?.items?.forEach((eleFourth, idxFourth) => {
                  Object.keys(findUniqueParentID).map((key) => {
                    if (eleFourth?.categoryID === key) {
                      ;((intitialCategory[idxSecond].items as Category[])[idxThird].items as Category[])[
                        idxFourth
                      ].items = findUniqueParentID[key]
                      delete findUniqueParentID[key]
                    }
                  })

                  // Demention 5
                  if (!_.isEmpty(findUniqueParentID)) {
                    eleFourth?.items?.forEach((eleFifth, idxFifth) => {
                      Object.keys(findUniqueParentID).map((key) => {
                        if (eleFifth?.categoryID === key) {
                          ;(
                            ((intitialCategory[idxSecond].items as Category[])[idxThird].items as Category[])[idxFourth]
                              .items as Category[]
                          )[idxFifth].items = findUniqueParentID[key]
                          delete findUniqueParentID[key]
                        }
                      })
                    })
                  }
                })
              }
            })
          }
        }
      })

      return {
        input: {
          items: intitialCategory,
        },
      }

    case ETransformationDataToServer.UpdateOrderStatus:
      const formInputOrder = (values as IOrderManagement).formInput
      return {
        input: {
          orderID: formInputOrder.orderID,
          orderStatus: formInputOrder.orderStatus,
          deliveryStatus: formInputOrder.deliveryStatus,
          shippingCompany: formInputOrder.shippingCompany,
          shippingTrackingID: formInputOrder.shippingTrackingID,
          note: formInputOrder.note,
        },
      }

    case ETransformationDataToServer.UpdateStripePaymentStatus:
      const formInputOrderStripe = (values as IOrderManagement).formInput
      return {
        input: {
          orderID: formInputOrderStripe.orderID,
          sessionID: formInputOrderStripe.sessionID,
        },
      }

    default:
      break
  }
}
