import * as Yup from 'yup'

const yupBrand = Yup.object().shape({
  formInput: Yup.object().shape({
    name: Yup.string().required('Plese Enter'),
    // brandImgURLShow: Yup.array().min(1, 'Please Upload Image'),
  }),
})

export default yupBrand
