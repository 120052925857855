// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Include in project
import styles from './index.module.scss'
import { convertDateLL } from 'utils/convertDate'
import { Button, Divider, Notification, SelectSingle } from 'component'
import { MutationUpdateProductOptionGroupArgs } from 'utils/generated'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { EResCode, optionList } from 'utils/other'
import { FormInput } from 'container'
import { IRenderInput } from 'utils/interface'
import { IUpdateOption } from 'pages/UpdateOption'
import type { RootState } from 'states/store'
import { ProductOption } from 'utils/generatedNonAuth'

interface Props {
  updateOption: ({ variables }: { variables: MutationUpdateProductOptionGroupArgs }) => Promise<any>
}

const formInputEditSection: React.FC<Props> = ({ updateOption }) => {
  const { values, errors, setFieldValue } = useFormikContext<IUpdateOption>()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const optionGroupReducer = useSelector((state: RootState) => state.optionGroup)

  const handleUpdateOption = async () => {
    if (!_.isEmpty(errors)) return

    const packData = await transformationDataToServer({
      values: values,
      type: ETransformationDataToServer.UpdateOption,
    })

    updateOption({ variables: packData as MutationUpdateProductOptionGroupArgs }).then((res) => {
      const resUpdate = res.data.updateProductOptionGroup
      if (resUpdate.res_code !== EResCode.Success) return

      optionGroupReducer.refetch?.()
      enqueueSnackbar(<Notification title="Success" description="Update Option information." />, { variant: 'success' })
      navigate('/product-management/option')
    })
  }

  const fieldInputs: IRenderInput[] = [
    {
      heading: 'Name',
      type: 'TEXT_FIELD',
      name: 'formInput.name',
      disabled: false,
      require: true,
    },
    {
      heading: 'Description',
      type: 'TEXT_FIELD',
      name: 'formInput.description',
      disabled: false,
    },
    {
      heading: 'Created At',
      type: 'TEXT',
      words: convertDateLL(values.formInput?.createdAt),
    },
    {
      heading: 'Updated At',
      type: 'TEXT',
      words: convertDateLL(values.formInput?.updatedAt),
    },
    {
      heading: 'Status',
      type: 'SELEC_SINGLE',
      name: 'formInput.isActive',
      disabled: false,
      option: optionList.isActiveOption,
    },
  ]

  const handleCloneOption = () => {
    if (!values.cloneProductOptionGroupID) return

    const optionGroup = optionGroupReducer.optionGroupList.find(
      (ele) => ele.productOptionGroupID === values.cloneProductOptionGroupID,
    )
    if (optionGroup?.option?.length === 0) return

    const startIndex = values.formInput.option?.length ? (values.formInput.option?.length as number) : 0

    const newOption =
      optionGroup?.option?.map((option, index) => {
        const productOptionIDGenerate = `GENERATE${Math.floor(1000 + Math.random() * 9000)}`

        return {
          customAttribute: option?.customAttribute,
          description: option?.description,
          index: startIndex + index,
          isActive: option?.isActive,
          isFilter: option?.isFilter,
          isOption: option?.isOption,
          name: option?.name,
          productOptionGroupID: values.formInput.productOptionGroupID,
          productOptionID: productOptionIDGenerate,
          type: option?.type,
          variant: option?.variant?.map((variant, indexVaraint) => {
            const productOptionVariantIDGenerate = `GENERATE${Math.floor(1000 + Math.random() * 9000)}`

            return {
              customAttribute: variant?.customAttribute,
              description: variant?.description,
              index: indexVaraint,
              isActive: variant?.isActive,
              isDefault: variant?.isDefault,
              name: variant?.name,
              productOptionID: productOptionIDGenerate,
              productOptionVariantID: productOptionVariantIDGenerate,
              remark: variant?.remark,
            }
          }),
        }
      }) || []

    setFieldValue(
      'formInput.option',
      [...(values.formInput.option as ProductOption[]), ...(newOption as ProductOption[])] || [],
    )
    setFieldValue('cloneProductOptionGroupID', '')
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapperBox}>
        <h5>{values.formInput?.name}</h5>
      </div>

      <div className={styles.wrapperBox}>
        <h6>Details</h6>
        <Button typeButton="submit" type="info" name="Save" size="small" functionOnClick={handleUpdateOption} />
      </div>

      <Divider />

      <FormInput title="" fieldInputs={fieldInputs} />

      <div className={styles.rowCloneOption}>
        <p className="fontBold">Copy Option</p>

        <div className={styles.wrapper}>
          <SelectSingle name="cloneProductOptionGroupID" option={optionGroupReducer.optionGroupOptionList} />
          <Button
            typeButton="button"
            type="info"
            name="Clone Options"
            size="small"
            functionOnClick={handleCloneOption}
            disabled={!Boolean(values.cloneProductOptionGroupID)}
          />
          <Button
            typeButton="button"
            type="infoOutline"
            name="View Options"
            size="small"
            disabled={!Boolean(values.cloneProductOptionGroupID)}
            functionOnClick={() => window.open(`/product-management/option-info/${values.cloneProductOptionGroupID}`)}
          />
        </div>
      </div>
    </div>
  )
}

export default formInputEditSection
