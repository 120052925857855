import Color from 'abstracts/export.module.scss'

export interface Props {
  name: string
  value: string
  label?: string
  checked?: boolean
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export const ColorTheme = {
  color: `${Color.grey200}`,

  '&.Mui-checked': {
    color: `${Color.infoMain}`,
  },
  '&.Mui-disabled': {
    color: `${Color.grey500}`,
  },
}
