// Lib
import React from 'react'

// Images
import bgSVG from 'images/Home/bg.svg'

// Include in project
import styles from './index.module.scss'
import { NavTopBar } from 'container'
import { E_Permission } from 'utils/generated'

interface Props {
  children?: JSX.Element | JSX.Element[]
  hiddenNavigateMenu?: boolean
  hiddenHeaderSection?: boolean
  headerSectionName?: string
  headerSectionManu?: { section: string; path: string; permission: E_Permission }[]
}

const Layout: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={styles.gridLayout}
      style={{
        backgroundImage: `url(${bgSVG})`,
        backgroundSize: '400px',
        backgroundAttachment: 'fixed',
      }}
    >
      <div className={styles.gridNavTop}>
        <NavTopBar />
      </div>

      <div className={styles.gridData}>
        <div className={styles.children}>
          <>{props.children}</>
        </div>
      </div>
    </div>
  )
}

export default Layout
