// Lib
import React from 'react'

// Include in project
import Layout from 'layouts'
import { menuHeaderInventory } from 'utils/menu'

const MainInventoryManagement: React.FC = () => {
  return <Layout headerSectionName="Inventory Management" headerSectionManu={menuHeaderInventory}></Layout>
}

export default MainInventoryManagement
