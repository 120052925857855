import { gql } from '@apollo/client'

export const TAG_LIST = gql`
  query tagList($filter: TagFilter!, $pageSize: Int, $nextToken: String) {
    tagList(filter: $filter, pageSize: $pageSize, nextToken: $nextToken) {
      res_code
      res_desc
      nextToken
      items {
        companyID
        tagID
        tagName
        color
        createdAt
        updatedAt
        isActive
      }
    }
  }
`

export const CREATE_TAG = gql`
  mutation createTag($input: CreateTag!) {
    createTag(input: $input) {
      res_code
      res_desc
      companyID
      tagID
    }
  }
`

export const UPDATE_TAG = gql`
  mutation updateTag($input: UpdateTag!) {
    updateTag(input: $input) {
      res_code
      res_desc
      companyID
      tagID
    }
  }
`
