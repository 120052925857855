// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'

// Include in project
import styles from './index.module.scss'
import { Modal, Notification } from 'component'
import { ProductInformation, ProductOptionInformation } from 'container'
import { MutationUpdateProductArgs } from 'utils/generated'
import { IProductManagement } from 'pages/ProductManagement'
import { transformationDataToServer, ETransformationDataToServer } from 'utils/transformationDataToServer'
import { EResCode } from 'utils/other'
import { setOpenLoading } from 'states/slice/loading'
import type { RootState } from 'states/store'

interface Props {
  open: boolean
  handleClose: () => void
  refetch: () => void
  updateProduct: ({ variables }: { variables: MutationUpdateProductArgs }) => Promise<any>
}

const ModalAddProduct: React.FC<Props> = ({ open, handleClose, updateProduct, refetch }: Props): JSX.Element => {
  const { values, errors } = useFormikContext<IProductManagement>()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const productReducer = useSelector((state: RootState) => state.product)

  const handleSave = async () => {
    if (!_.isEmpty(errors.formInput)) return

    dispatch(setOpenLoading())

    const packData = await transformationDataToServer({
      values: values,
      type: ETransformationDataToServer.UpdateProduct,
    })

    updateProduct({ variables: packData as MutationUpdateProductArgs }).then((res) => {
      const resUpdate = res.data.updateProduct
      if (resUpdate.res_code !== EResCode.Success) return

      refetch()
      productReducer?.refetch?.()
      enqueueSnackbar(<Notification title="Success" description="Update Product information." />, {
        variant: 'success',
      })
      handleClose()
    })
  }

  return (
    <Modal
      open={open}
      width="80%"
      height="90%"
      handleClose={handleClose}
      title="Add Product"
      buttonText="Save Product"
      handleSave={handleSave}
    >
      <div className={styles.main}>
        <div className={styles.container}>
          <ProductInformation />
          <ProductOptionInformation />
        </div>
      </div>
    </Modal>
  )
}
export default ModalAddProduct
