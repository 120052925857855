// Lib
import React from 'react'

// Include in project
import styles from './index.module.scss'
import { otpTimer } from 'hooks'

interface Props {
  resendOTP?: () => void
}

const OTPTimer: React.FC<Props> = ({ resendOTP }): JSX.Element => {
  const { minutes, seconds, resetTimer } = otpTimer(1)

  const disableResendOTP = seconds === 0 && minutes === 0

  return (
    <div className={styles.container}>
      {seconds > 0 || minutes > 0 ? (
        <p className={styles.textNumber}>
          {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </p>
      ) : (
        ''
      )}
      <p
        onClick={() => {
          if (!disableResendOTP) return

          resetTimer()
          resendOTP?.()
        }}
        className={styles.text}
        aria-disabled={disableResendOTP}
      >
        ส่งรหัสอีกครั้ง
      </p>
    </div>
  )
}

export default OTPTimer
