// Lib
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { withFormik, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

// Include in project
import styles from './index.module.scss'
import Layout from 'layouts'
import {
  HeadOfProductInfo,
  OptionDetailsSection,
  ProductInfoDetailsSection,
  ProductSKUSection,
  SpecDetailsSection,
  TagsDetailsSection,
  ModalAddProduct,
} from 'container'
import { E_Is_Active, Product, ProductSku } from 'utils/generatedNonAuth'
import { useProductInfo, useProductMutation, useLoadingControl, useProductSKUMutation, useModal } from 'hooks'
import yupSKU from 'validations/yupSKU.validate'
import { IOption } from 'utils/interface'
import type { RootState } from 'states/store'
import _ from 'lodash'

export interface IProductInfo {
  productInfo: {
    productImgURLShow: (string & File)[]
    productFileURLShow: (string & File)[]
  } & Product
  formInputAddSKU: {
    productSKU: ProductSku[]
  }
  formInput: {
    productImgURLShow: (string & File)[]
    productFileURLShow: (string & File)[]
    tagID: IOption[]
  } & Product
  filter: {
    isActive: E_Is_Active
  }
}

const intitialValue: IProductInfo = {
  productInfo: {
    name: '',
    brandID: '',
    description: '',
    unitLabel: '',
    productImgURL: [],
    productImgURLShow: [],
    productFileURL: [],
    productFileURLShow: [],
    isActive: E_Is_Active.True,
    isVisible: E_Is_Active.True,
    prefix: '',
    remark: '',
    productOptionGroupID: '',
    tagID: [],
    specAttribute: {},
    productSKU: [],
  },
  formInputAddSKU: {
    productSKU: [],
  },
  formInput: {
    name: '',
    brandID: '',
    description: '',
    unitLabel: '',
    productImgURL: [],
    productImgURLShow: [],
    productFileURL: [],
    productFileURLShow: [],
    isActive: E_Is_Active.True,
    isVisible: E_Is_Active.True,
    prefix: '',
    remark: '',
    productOptionGroupID: '',
    tagID: [],
    specAttribute: {},
  },
  filter: {
    isActive: E_Is_Active.True,
  },
}

const ProductInfo: React.FC = () => {
  const { productID } = useParams()
  const { setFieldValue } = useFormikContext<IProductInfo>()
  const { isModalOpen, handleClose, handleOpen: handleOpenModal } = useModal(intitialValue.formInput)
  const decodeTokenReducer = useSelector((state: RootState) => state.decodeToken)

  const {
    data: productInfo,
    isLoading,
    refetch,
  } = useProductInfo({
    companyID: decodeTokenReducer.companyID,
    productID: productID as string,
  })
  const { updateProduct, isLoading: isLoadingAchieve } = useProductMutation()
  const { updateSKU, isLoading: isLoadingUpdate } = useProductSKUMutation()

  useLoadingControl(isLoading || isLoadingAchieve || isLoadingUpdate)

  useEffect(() => {
    if (!productInfo) return

    const productSKUs = productInfo?.productSKU?.map((data) => {
      const newOptionAttribute: Record<string, string> = {}
      productInfo.productOptionGroup?.option?.map((option) => {
        const optionID = option?.productOptionID as string
        newOptionAttribute[optionID] = data?.optionAttribute[optionID] || '-'
      })
      return { ...data, optionAttribute: newOptionAttribute }
    })

    const productSKUsAfterSort = _.sortBy(productSKUs, 'productSKU')

    setFieldValue('productInfo', { ...productInfo, productSKU: productSKUsAfterSort })
    setFieldValue('formInputAddSKU.productSKU', productSKUsAfterSort || [])
  }, [productInfo])

  return (
    <Layout hiddenHeaderSection>
      <div className={styles.container}>
        <HeadOfProductInfo updateProduct={updateProduct} handleOpenModal={handleOpenModal} />
        <ProductInfoDetailsSection />
        <ProductSKUSection updateSKU={updateSKU} refetch={refetch} />
        <OptionDetailsSection />
        <SpecDetailsSection />
        <TagsDetailsSection />
        <ModalAddProduct open={isModalOpen} handleClose={handleClose} updateProduct={updateProduct} refetch={refetch} />
      </div>
    </Layout>
  )
}

const EnhancedProductInfo = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  validateOnMount: true,
  validationSchema: yupSKU,
  handleSubmit: () => {
    console.log('Skip')
  },
})(ProductInfo)

export default EnhancedProductInfo
