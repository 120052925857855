// Lib
import React from 'react'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { EResCode, findPricingByModel } from 'utils/other'
import { convertCurrencyFormatDecimal, convertCurrencyFormat } from 'utils/convertMoney'
import { E_Trigger_Type, MutationCreateTransactionArgs, Pricing, ProductOption } from 'utils/generated'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { Modal, TextField, CopyInput, Tag, DropZoneUpload, Notification } from 'component'
import { IIventoryManagement } from 'pages/InventoryManagement'

interface Props {
  open: boolean
  handleClose: () => void
  refetch: () => void
  createTransection: ({ variables }: { variables: MutationCreateTransactionArgs }) => Promise<any>
}

interface IBox {
  title: string
  subTitle: string
}

const ModalAddStock: React.FC<Props> = ({ open, handleClose, refetch, createTransection }: Props): JSX.Element => {
  const { values, setFieldValue, errors } = useFormikContext<IIventoryManagement>()
  const { enqueueSnackbar } = useSnackbar()

  const handleUpdate = async () => {
    if (!_.isEmpty(errors)) return

    const packData = await transformationDataToServer({
      type: ETransformationDataToServer.CreateTransection,
      values: values,
    })

    createTransection({ variables: packData as MutationCreateTransactionArgs }).then((res) => {
      const resCreate = res.data.createTransaction
      if (resCreate.res_code !== EResCode.Success) return

      refetch()
      enqueueSnackbar(<Notification title="Success" description="Create Transection." />, { variant: 'success' })
      handleClose()
    })
  }

  const price = (values.formInput?.pricing as Pricing[])[0]

  const RenderBox: React.FC<IBox> = ({ title, subTitle }: IBox) => {
    return (
      <div className={styles.box}>
        <p className={styles.text}>{title}</p>
        <p>{subTitle}</p>
      </div>
    )
  }

  return (
    <Modal
      open={open}
      width="70%"
      height="90%"
      handleClose={handleClose}
      title="Add Product"
      buttonText="Save Product"
      handleSave={handleUpdate}
    >
      <div className={styles.main}>
        <h5>Inventory Management</h5>
        <div className={styles.wrapperItem}>
          <h6>Product Information</h6>
          <div className={styles.wrapperContainer}>
            <div className={styles.container}>
              <div className={styles.box}>
                <div className={styles.wrapper}>
                  <div className={styles.wrapperHeader}>
                    <p className="fontBold">Product SKU</p>
                    <Tag description="Automatic Lot Number" />
                  </div>
                  <TextField name="formInput.productSKUID" type="text" disabled />
                </div>
                <div>
                  <p className="fontBold">Preview (SKU-YYYY-MM-DD-001):</p>
                  <p>{values.formInput.productSKUID}</p>
                </div>
              </div>
              <TextField name="formInput.product.name" type="text" disabled label="Product Name" />
              <TextField name="formInput.product.brand.name" type="text" disabled label="Brand" />
            </div>
            <DropZoneUpload sizeDrop="SM" fileList={values.formInput.product?.productImgURL as string[]} readonly />
          </div>
        </div>

        <div className={styles.wrapperItem}>
          <h5>Product Option information</h5>
          <div className={styles.wrapperContainer}>
            {values.formInput.product?.productOptionGroup &&
              (values.formInput.product?.productOptionGroup.option as ProductOption[]).map((item, index: number) => (
                <div key={index}>
                  <p className="fontBold">{item.name}</p>
                  {item.variant &&
                    item.variant.map((list, idx: number) => (
                      <TextField
                        key={idx}
                        name={`formInput.product.productOptionGroup.option[${index}].variant[${idx}].name`}
                        type="text"
                        disabled
                      />
                    ))}
                </div>
              ))}
          </div>
        </div>

        <div className={styles.wrapperBox}>
          <h5>Inventory information</h5>
          <div className={styles.group}>
            <RenderBox title="PRICE" subTitle={`${convertCurrencyFormatDecimal(findPricingByModel(price))} ฿`} />
            <div className={styles.box}>
              <p className={styles.text}>PRICE ID</p>
              <CopyInput valueCopy={price?.pricingID as string} />
            </div>
            <RenderBox
              title="IN STOCK"
              subTitle={`${convertCurrencyFormat(values.formInput.inventory?.stockAccumulated || 0)}`}
            />
            <RenderBox
              title="COMMITTED"
              subTitle={`${convertCurrencyFormat(values.formInput.inventory?.stockCommitted || 0)}`}
            />
            <RenderBox
              title="INCOMING"
              subTitle={`${convertCurrencyFormat(values.formInput.inventory?.stockIncoming || 0)}`}
            />
            <RenderBox
              title="AVAILABLE"
              subTitle={`${convertCurrencyFormat(values.formInput.inventory?.stockAvailable || 0)}`}
            />
          </div>
        </div>

        <div className={styles.wrapperItem}>
          <h5>Update Stock</h5>
          <div className={styles.wrapperContainer}>
            <div className={styles.container}>
              <div className={styles.header}>
                <p className="fontBold">Action Type</p>
                <Tag
                  description={`
                  In Stock: ${convertCurrencyFormat(values.formInput.inventory?.stockAccumulated || 0)}, 
                  Committed: ${convertCurrencyFormat(values.formInput.inventory?.stockCommitted || 0)}, 
                  In Coming: ${convertCurrencyFormat(values.formInput.inventory?.stockIncoming || 0)}, 
                  Available: ${convertCurrencyFormat(values.formInput.inventory?.stockAvailable || 0)}`}
                />
              </div>
              <div className={styles.wrapperButton}>
                {triggerType.map((item, index: number) => (
                  <div
                    aria-checked={values.formInput?.triggerType === (item.value as E_Trigger_Type)}
                    className={styles.cardButton}
                    key={index}
                    onClick={() => setFieldValue('formInput.triggerType', item.value)}
                  >
                    {item.key}
                  </div>
                ))}
              </div>
            </div>
            <TextField name="formInput.qty" type="number" label="Qty" require />
          </div>
        </div>
        <TextField name="formInput.note" type="text" label="Note" placeholder="Note" />
      </div>
    </Modal>
  )
}
export default ModalAddStock

const triggerType = [
  { key: 'Goods Receipt', value: E_Trigger_Type.GoodsReceipt },
  { key: 'Goods Issue', value: E_Trigger_Type.GoodsIssue },
  { key: 'Inventory Adjustment', value: E_Trigger_Type.InventoryAdjustment },
  { key: 'Physical Count', value: E_Trigger_Type.PhysicalCount },
]
