// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'

//Images
import closeSVG from 'images/commons/closeCircle.svg'

// Include in project
import styles from './index.module.scss'
import { Notification, Button, Modal, TextField } from 'component'
import { ICategory } from 'pages/Category'
import { MutationUpdateProductCategoryArgs } from 'utils/generated'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { EResCode } from 'utils/other'
import type { RootState } from 'states/store'

interface Props {
  open: boolean
  handleClose: () => void
  updateCategory: ({ variables }: { variables: MutationUpdateProductCategoryArgs }) => Promise<any>
  refetch: () => void
}

const ModalFolder: React.FC<Props> = ({ open, handleClose, updateCategory, refetch }) => {
  const { values } = useFormikContext<ICategory>()
  const { enqueueSnackbar } = useSnackbar()
  const optionGroupReducer = useSelector((state: RootState) => state.optionGroup)

  const handleSave = async () => {
    let packData

    if (values.formInput.id === 'GENERATE') {
      packData = [...values.categoryList, values.formInput]
    } else {
      const index = values.categoryList.findIndex((ele) => ele.id === values.formInput.id)
      packData = [...values.categoryList]
      packData[index].data!.categoryName = values.formInput.data?.categoryName
    }

    const variables = await transformationDataToServer({
      values: packData,
      type: ETransformationDataToServer.UpdateCategory,
    })

    updateCategory({ variables: variables as MutationUpdateProductCategoryArgs }).then((res) => {
      const resUpdate = res.data.updateProductCategory
      if (resUpdate.res_code !== EResCode.Success) return

      refetch()
      optionGroupReducer.refetch?.()
      handleClose()
      enqueueSnackbar(<Notification title="Success" description="Update Category." />, { variant: 'success' })
    })
  }

  return (
    <Modal open={open} handleClose={handleClose} width="332px" height="auto" hiddenHeader>
      <div className={styles.container}>
        <section className={styles.header}>
          <h6>{values.formInput.data?.categoryID ? 'Rename' : 'New Folder'}</h6>
          <img src={closeSVG} alt="Icon Close" onClick={handleClose} />
        </section>

        <TextField name={`formInput.data.categoryName`} type="text" label="Folder name" placeholder="Folder name" />

        <section className={styles.wrapperButton}>
          <Button typeButton="button" type="primaryOutline" name="Cancel" size="medium" functionOnClick={handleClose} />
          <Button
            typeButton="button"
            type="info"
            name={values.formInput.data?.categoryID ? 'Save' : 'New Folder'}
            functionOnClick={handleSave}
            disabled={Boolean(!values.formInput.data?.categoryName)}
          />
        </section>
      </div>
    </Modal>
  )
}

export default ModalFolder
