// Lib
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Modal, TextField } from '@mui/material'

//Images
import closeCircleSVG from 'images/commons/closeCircle.svg'

// Include in project
import styles from './index.module.scss'
import type { RootState } from 'states/store'
import { setCloseDialog, setCommentDialog } from 'states/slice/modalDialog'
import { cancelColorList, okeyColorList, ModalSx } from './config'

const ModalDialog: React.FC = () => {
  const dispatch = useDispatch()
  const modalDialogReducer = useSelector((state: RootState) => state.modalDialog)

  if (!modalDialogReducer) return <></>
  else {
    const cancelColor = cancelColorList
    const okeyColor = useMemo(() => okeyColorList[modalDialogReducer.status], [modalDialogReducer.status])

    const isDisabledInput = () => {
      if (!modalDialogReducer.titleInput) return false
      if (modalDialogReducer?.comment) {
        return false
      } else {
        return true
      }
    }

    return (
      <Modal keepMounted sx={ModalSx} onClose={() => dispatch(setCloseDialog())} open={modalDialogReducer.isOpen}>
        <Box>
          <div className={`${styles.main}`}>
            <div className={`${styles.head}`}>
              <img
                src={closeCircleSVG}
                className={`${styles.cross}`}
                alt="close Icon"
                onClick={() => dispatch(setCloseDialog())}
              />
            </div>

            <div className={styles.wrapperBox}>
              <h6>{modalDialogReducer.title}</h6>
              <p className={styles.text}>{modalDialogReducer.description}</p>
            </div>

            {modalDialogReducer.titleInput && (
              <div className={styles.formInput}>
                <p>{modalDialogReducer.titleInput}</p>
                <TextField
                  fullWidth
                  size="small"
                  onChange={(e) => dispatch(setCommentDialog({ comment: e.target.value }))}
                />
              </div>
            )}

            <div className={`${styles.footer}`}>
              <Button
                sx={cancelColor}
                onClick={() => dispatch(setCloseDialog())}
                style={{ gap: '10px', textTransform: 'none', boxShadow: 'none', borderRadius: '40px' }}
                variant="contained"
                className="fontBold"
                size="medium"
              >
                {modalDialogReducer.textButtonCancle ? modalDialogReducer.textButtonCancle : 'Cancle'}
              </Button>
              <Button
                sx={okeyColor}
                onClick={() => modalDialogReducer.functionOnSubmit(modalDialogReducer.comment)}
                style={{ gap: '10px', textTransform: 'none', boxShadow: 'none', borderRadius: '40px' }}
                variant="contained"
                className="fontBold"
                disabled={isDisabledInput()}
                size="medium"
              >
                {modalDialogReducer.textButtonConfirm ? modalDialogReducer.textButtonConfirm : 'Confirm'}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    )
  }
}

export default ModalDialog
