// Lib
import React from 'react'

//Images
import { InfoOutlined } from '@mui/icons-material'

// Include in project
import styles from './index.module.scss'

interface Props {
  description: string
}

const Tag: React.FC<Props> = ({ description }: Props) => {
  return (
    <div className={styles.wrapperChip}>
      <p className="caption">{description}</p>
      <InfoOutlined sx={{ width: '13px', height: '13px' }} />
    </div>
  )
}
export default Tag
