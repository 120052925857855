export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']>;
  addressID?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<E_Country>;
  createdAt?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  googleMapURL?: Maybe<Scalars['String']>;
  isActive?: Maybe<E_Is_Active>;
  isDefault?: Maybe<E_Is_Active>;
  note?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  province?: Maybe<E_Province>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  subDistrict?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  taxAddress?: Maybe<TaxAddress>;
  taxID?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  address?: Maybe<Scalars['String']>;
  country?: Maybe<E_Country>;
  district?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  googleMapURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  province?: Maybe<E_Province>;
  subDistrict?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  taxType?: Maybe<Tax_Type>;
  tel?: Maybe<Scalars['String']>;
};

export type Brand = {
  __typename?: 'Brand';
  brandID?: Maybe<Scalars['String']>;
  brandImgURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<E_Is_Active>;
  name?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type BrandFilter = {
  brandID?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<E_Is_Active>;
};

export type BrandQuery = {
  __typename?: 'BrandQuery';
  items?: Maybe<Array<Maybe<Brand>>>;
  nextToken?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
};

export type CmsCompany = {
  __typename?: 'CMSCompany';
  announcement?: Maybe<Scalars['JSON']>;
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  poster?: Maybe<Scalars['JSON']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Category = {
  __typename?: 'Category';
  categoryID?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Array<Maybe<Scalars['String']>>>;
  index?: Maybe<Scalars['Int']>;
  isActive?: Maybe<E_Is_Active>;
  items?: Maybe<Array<Maybe<Category>>>;
  joinItemWithCategoryID?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Array<Maybe<Scalars['String']>>>;
  productOptionGroupID?: Maybe<Scalars['String']>;
};

export type CategoryInput = {
  categoryID?: InputMaybe<Scalars['String']>;
  categoryName?: InputMaybe<Scalars['String']>;
  customAttribute?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  features?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  index?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<E_Is_Active>;
  items?: InputMaybe<Array<InputMaybe<CategoryInput>>>;
  joinItemWithCategoryID?: InputMaybe<Scalars['String']>;
  pdfUrl?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productOptionGroupID?: InputMaybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  companyID?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyNameTH?: Maybe<Scalars['String']>;
  companyType?: Maybe<E_Company_Type>;
  country?: Maybe<E_Country>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<E_Is_Active>;
  logoURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CreateBrand = {
  brandImgURL?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customAttribute?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateStaff = {
  birthday?: InputMaybe<Scalars['String']>;
  customAttribute?: InputMaybe<Scalars['JSON']>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  idCard: Scalars['String'];
  idCardImgURL?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  profileImgURL?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  surname: Scalars['String'];
  tagID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCompanyID: Scalars['String'];
  uuidFacebook?: InputMaybe<Scalars['String']>;
  uuidGoogle?: InputMaybe<Scalars['String']>;
  uuidLine?: InputMaybe<Scalars['String']>;
};

export type CreateTag = {
  color?: InputMaybe<Scalars['String']>;
  tagName: Scalars['String'];
};

export type CreateTransaction = {
  note?: InputMaybe<Scalars['String']>;
  productID: Scalars['String'];
  productSKUID: Scalars['String'];
  qty: Scalars['Int'];
  triggerType: E_Trigger_Type;
};

export type CreateUserRoleCompany = {
  permission?: InputMaybe<Array<InputMaybe<E_Permission>>>;
  userLevel?: InputMaybe<E_User_Level>;
  userRoleCompanyName: Scalars['String'];
};

export type DashboardChartQuery = {
  __typename?: 'DashboardChartQuery';
  items?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
};

export type DashboardFilter = {
  endedDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type DashboardLeaderboardsCustomersQuery = {
  __typename?: 'DashboardLeaderboardsCustomersQuery';
  averageCustomer?: Maybe<Scalars['Float']>;
  averageCustomerRatio?: Maybe<Scalars['Float']>;
  items?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  totalCustomer?: Maybe<Scalars['Float']>;
  totalCustomerRatio?: Maybe<Scalars['Float']>;
};

export type DashboardLeaderboardsInventoryQuery = {
  __typename?: 'DashboardLeaderboardsInventoryQuery';
  items?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  totalProductInventory?: Maybe<Scalars['Float']>;
  totalProductInventoryRatio?: Maybe<Scalars['Float']>;
};

export type DashboardLeaderboardsProductsQuery = {
  __typename?: 'DashboardLeaderboardsProductsQuery';
  categoryBestSeller?: Maybe<Scalars['String']>;
  categoryBestSellerRatio?: Maybe<Scalars['Float']>;
  items?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  productBestSeller?: Maybe<Scalars['String']>;
  productBestSellerRatio?: Maybe<Scalars['Float']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  totalProducts?: Maybe<Scalars['Float']>;
  totalProductsRatio?: Maybe<Scalars['Float']>;
};

export type DashboardPerformanceDetailed = {
  __typename?: 'DashboardPerformanceDetailed';
  percentage?: Maybe<Scalars['Float']>;
  previousTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type DashboardPerformanceDetailsQuery = {
  __typename?: 'DashboardPerformanceDetailsQuery';
  orderStatusPaid?: Maybe<Scalars['Int']>;
  orderStatusUnPaid?: Maybe<Scalars['Int']>;
  paymentStatusOutstanding?: Maybe<Scalars['Float']>;
  paymentStatusReceived?: Maybe<Scalars['Float']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  transaction?: Maybe<Array<Maybe<Order>>>;
};

export type DashboardPerformanceQuery = {
  __typename?: 'DashboardPerformanceQuery';
  amountOfOrders?: Maybe<DashboardPerformanceDetailed>;
  grossProfit?: Maybe<DashboardPerformanceDetailed>;
  netRevenue?: Maybe<DashboardPerformanceDetailed>;
  numberOfItems?: Maybe<DashboardPerformanceDetailed>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
};

export type DeleteProductCategory = {
  index: Scalars['String'];
};

export enum E_Bank_Company {
  AgricultureAndAgriculturalCooperatives = 'AGRICULTURE_AND_AGRICULTURAL_COOPERATIVES',
  Ayudhaya = 'AYUDHAYA',
  Bangkok = 'BANGKOK',
  CimbThai = 'CIMB_THAI',
  IslamicOfThailand = 'ISLAMIC_OF_THAILAND',
  Kasikorn = 'KASIKORN',
  Kiatnakin = 'KIATNAKIN',
  KrungThai = 'KRUNG_THAI',
  LandAndHouses = 'LAND_AND_HOUSES',
  SiamCommercial = 'SIAM_COMMERCIAL',
  ThaiCommercial = 'THAI_COMMERCIAL',
  Thanachart = 'THANACHART',
  TheGovernmentrSavings = 'THE_GOVERNMENTR_SAVINGS',
  TheGovernmentHousing = 'THE_GOVERNMENT_HOUSING',
  TheThaiCreditRetail = 'THE_THAI_CREDIT_RETAIL',
  Tisco = 'TISCO',
  Tmb = 'TMB',
  UnitedOverseas = 'UNITED_OVERSEAS'
}

export enum E_Chart {
  Day = 'DAY',
  Hourly = 'HOURLY',
  Month = 'MONTH',
  Weekly = 'WEEKLY'
}

export enum E_Company_Type {
  Enterprise = 'ENTERPRISE',
  PersonalBusiness = 'PERSONAL_BUSINESS',
  Smes = 'SMES',
  Startup = 'STARTUP'
}

export enum E_Country {
  Thailand = 'THAILAND'
}

export enum E_Currency {
  Thb = 'THB'
}

export enum E_Delivery_Status {
  Finish = 'FINISH',
  Pedding = 'PEDDING',
  WaitingDelivering = 'WAITING_DELIVERING',
  WaitingPacking = 'WAITING_PACKING'
}

export enum E_Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export enum E_Is_Active {
  False = 'FALSE',
  True = 'TRUE'
}

export enum E_Order_By_Role {
  Customer = 'CUSTOMER',
  Owner = 'OWNER',
  Staff = 'STAFF'
}

export enum E_Order_Status {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Fail = 'FAIL',
  Refund = 'REFUND',
  TimeoutPaid = 'TIMEOUT_PAID',
  WaitingApproval = 'WAITING_APPROVAL',
  WaitingPaid = 'WAITING_PAID'
}

export enum E_Payment_Status {
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

export enum E_Payment_Type {
  OneTime = 'ONE_TIME'
}

export enum E_Permission {
  AllProduct = 'ALL_PRODUCT',
  Brand = 'BRAND',
  Category = 'CATEGORY',
  Cms = 'CMS',
  CustomerList = 'CUSTOMER_LIST',
  Dashboard = 'DASHBOARD',
  InventoryAllProduct = 'INVENTORY_ALL_PRODUCT',
  InventoryTransaction = 'INVENTORY_TRANSACTION',
  Option = 'OPTION',
  Order = 'ORDER',
  Setting = 'SETTING',
  StaffList = 'STAFF_LIST',
  StaffPermission = 'STAFF_PERMISSION',
  Tags = 'TAGS'
}

export enum E_Pricing_Model {
  StandardPricing = 'STANDARD_PRICING',
  VolumePricing = 'VOLUME_PRICING'
}

export enum E_Province {
  AmnatCharoen = 'AMNAT_CHAROEN',
  AngThong = 'ANG_THONG',
  Bangkok = 'BANGKOK',
  Buogkan = 'BUOGKAN',
  BuriRam = 'BURI_RAM',
  Chachoengsao = 'CHACHOENGSAO',
  Chaiyaphum = 'CHAIYAPHUM',
  ChaiNat = 'CHAI_NAT',
  Chanthaburi = 'CHANTHABURI',
  ChiangMai = 'CHIANG_MAI',
  ChiangRai = 'CHIANG_RAI',
  ChonBuri = 'CHON_BURI',
  Chumphon = 'CHUMPHON',
  Kalasin = 'KALASIN',
  KamphaengPhet = 'KAMPHAENG_PHET',
  Kanchanaburi = 'KANCHANABURI',
  KhonKaen = 'KHON_KAEN',
  Krabi = 'KRABI',
  Lampang = 'LAMPANG',
  Lamphun = 'LAMPHUN',
  Loburi = 'LOBURI',
  Loei = 'LOEI',
  MaeHongSon = 'MAE_HONG_SON',
  MahaSarakham = 'MAHA_SARAKHAM',
  Mukdahan = 'MUKDAHAN',
  NakhonNayok = 'NAKHON_NAYOK',
  NakhonPathom = 'NAKHON_PATHOM',
  NakhonPhanom = 'NAKHON_PHANOM',
  NakhonRatchasima = 'NAKHON_RATCHASIMA',
  NakhonSawan = 'NAKHON_SAWAN',
  NakhonSiThammarat = 'NAKHON_SI_THAMMARAT',
  Nan = 'NAN',
  Narathiwat = 'NARATHIWAT',
  NongBuaLamPhu = 'NONG_BUA_LAM_PHU',
  NongKhai = 'NONG_KHAI',
  Nonthaburi = 'NONTHABURI',
  PathumThani = 'PATHUM_THANI',
  Pattani = 'PATTANI',
  Phangnga = 'PHANGNGA',
  Phatthalung = 'PHATTHALUNG',
  Phayao = 'PHAYAO',
  Phetchabun = 'PHETCHABUN',
  Phetchaburi = 'PHETCHABURI',
  Phichit = 'PHICHIT',
  Phitsanulok = 'PHITSANULOK',
  Phrae = 'PHRAE',
  PhraNakhonSiAyutthaya = 'PHRA_NAKHON_SI_AYUTTHAYA',
  Phuket = 'PHUKET',
  PrachinBuri = 'PRACHIN_BURI',
  PrachuapKhiriKhan = 'PRACHUAP_KHIRI_KHAN',
  Ranong = 'RANONG',
  Ratchaburi = 'RATCHABURI',
  Rayong = 'RAYONG',
  RoiEt = 'ROI_ET',
  SakonNakhon = 'SAKON_NAKHON',
  SamutPrakan = 'SAMUT_PRAKAN',
  SamutSakhon = 'SAMUT_SAKHON',
  SamutSongkhram = 'SAMUT_SONGKHRAM',
  Saraburi = 'SARABURI',
  Satun = 'SATUN',
  SaKaeo = 'SA_KAEO',
  SingBuri = 'SING_BURI',
  SiSaKet = 'SI_SA_KET',
  Songkhla = 'SONGKHLA',
  Sukhothai = 'SUKHOTHAI',
  SuphanBuri = 'SUPHAN_BURI',
  SuratThani = 'SURAT_THANI',
  Surin = 'SURIN',
  Tak = 'TAK',
  Trang = 'TRANG',
  Trat = 'TRAT',
  UbonRatchathani = 'UBON_RATCHATHANI',
  UdonThani = 'UDON_THANI',
  UthaiThani = 'UTHAI_THANI',
  Uttaradit = 'UTTARADIT',
  Yala = 'YALA',
  Yasothon = 'YASOTHON'
}

export enum E_Shipping_Company {
  BestExpress = 'BEST_EXPRESS',
  Deliveree = 'DELIVEREE',
  DhlExpress = 'DHL_EXPRESS',
  FlashExpress = 'FLASH_EXPRESS',
  JtExpress = 'JT_EXPRESS',
  KerryExpress = 'KERRY_EXPRESS',
  Lalamove = 'LALAMOVE',
  NinjaVan = 'NINJA_VAN',
  ScgExpress = 'SCG_EXPRESS',
  SelfDelivery = 'SELF_DELIVERY',
  ThailandPost = 'THAILAND_POST'
}

export enum E_Shipping_Type {
  PickUpAtStore = 'PICK_UP_AT_STORE',
  StandardDelivery = 'STANDARD_DELIVERY'
}

export enum E_Trigger_Type {
  Approved = 'APPROVED',
  GoodsIssue = 'GOODS_ISSUE',
  GoodsIssueVoid = 'GOODS_ISSUE_VOID',
  GoodsReceipt = 'GOODS_RECEIPT',
  GoodsReceiptVoid = 'GOODS_RECEIPT_VOID',
  InventoryAdjustment = 'INVENTORY_ADJUSTMENT',
  InventoryAdjustmentVoid = 'INVENTORY_ADJUSTMENT_VOID',
  Order = 'ORDER',
  OrderVoid = 'ORDER_VOID',
  PhysicalCount = 'PHYSICAL_COUNT',
  PhysicalCountVoid = 'PHYSICAL_COUNT_VOID'
}

export enum E_Type_Option {
  Checkbox = 'CHECKBOX',
  Tag = 'TAG'
}

export enum E_User_Level {
  Employee = 'EMPLOYEE',
  Manager = 'MANAGER'
}

export enum E_User_Role {
  Customer = 'CUSTOMER',
  Owner = 'OWNER',
  Staff = 'STAFF'
}

export type EvidencePaymenyInfo = {
  __typename?: 'EvidencePaymenyInfo';
  bankName?: Maybe<E_Bank_Company>;
  price?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['String']>;
};

export enum Freight_Model {
  FixFreight = 'FIX_FREIGHT',
  ProvinceFreight = 'PROVINCE_FREIGHT'
}

export type FreightModelFix = {
  __typename?: 'FreightModelFix';
  freight?: Maybe<Scalars['Float']>;
};

export type FreightModelFixInput = {
  freight?: InputMaybe<Scalars['Float']>;
};

export type FreightModelProvince = {
  __typename?: 'FreightModelProvince';
  isFreightOnDelivery?: Maybe<Scalars['Boolean']>;
  province?: Maybe<E_Province>;
  tableFreightProvince?: Maybe<Array<Maybe<TableFreightProvince>>>;
};

export type FreightModelProvinceInput = {
  isFreightOnDelivery?: InputMaybe<Scalars['Boolean']>;
  province?: InputMaybe<E_Province>;
  tableFreightProvince?: InputMaybe<Array<InputMaybe<TableFreightProvinceInput>>>;
};

export type Inventory = {
  __typename?: 'Inventory';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  isVoided?: Maybe<E_Is_Active>;
  order?: Maybe<Order>;
  orderID?: Maybe<Scalars['String']>;
  productID?: Maybe<Scalars['String']>;
  productSKU?: Maybe<ProductSku>;
  productSKUID?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Int']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  stockAccumulated?: Maybe<Scalars['Float']>;
  stockAvailable?: Maybe<Scalars['Float']>;
  stockCommitted?: Maybe<Scalars['Float']>;
  stockIncoming?: Maybe<Scalars['Float']>;
  transactionNo?: Maybe<Scalars['Int']>;
  triggerType?: Maybe<E_Trigger_Type>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type InventoryFilter = {
  productSKUID?: InputMaybe<Scalars['String']>;
};

export type InventoryQuery = {
  __typename?: 'InventoryQuery';
  items?: Maybe<Array<Maybe<Inventory>>>;
  nextToken?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
};

export type JoinProductCategoryProduct = {
  __typename?: 'JoinProductCategoryProduct';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
  joinItemWithCategoryID?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBrand?: Maybe<Brand>;
  createStaff?: Maybe<User>;
  createTag?: Maybe<Tag>;
  createTransaction?: Maybe<Inventory>;
  createUserRoleCompany?: Maybe<UserRoleCompany>;
  deleteProductCategory?: Maybe<ProductCategory>;
  updateBrand?: Maybe<Brand>;
  updateCMSCompany?: Maybe<CmsCompany>;
  updateJoinProductCategoryProduct?: Maybe<JoinProductCategoryProduct>;
  updateOrderStatus?: Maybe<Order>;
  updateProduct?: Maybe<Product>;
  updateProductCategory?: Maybe<ProductCategory>;
  updateProductOptionGroup?: Maybe<ProductOptionGroup>;
  updateProductSKU?: Maybe<ProductSkuQuery>;
  updateSettingCompany?: Maybe<SettingCompany>;
  updateStaff?: Maybe<User>;
  updateStripePaymentStatus?: Maybe<Order>;
  updateTag?: Maybe<Tag>;
  updateTagsAndIsActiveCustomer?: Maybe<User>;
  updateUserInfo?: Maybe<User>;
  updateUserRoleCompany?: Maybe<UserRoleCompany>;
  voidTransaction?: Maybe<Inventory>;
};


export type MutationCreateBrandArgs = {
  input: CreateBrand;
};


export type MutationCreateStaffArgs = {
  input: CreateStaff;
};


export type MutationCreateTagArgs = {
  input: CreateTag;
};


export type MutationCreateTransactionArgs = {
  input: CreateTransaction;
};


export type MutationCreateUserRoleCompanyArgs = {
  input: CreateUserRoleCompany;
};


export type MutationDeleteProductCategoryArgs = {
  input: DeleteProductCategory;
};


export type MutationUpdateBrandArgs = {
  input: UpdateBrand;
};


export type MutationUpdateCmsCompanyArgs = {
  input: UpdateCmsCompany;
};


export type MutationUpdateJoinProductCategoryProductArgs = {
  input: UpdateJoinProductCategoryProduct;
};


export type MutationUpdateOrderStatusArgs = {
  input: UpdateOrderStatus;
};


export type MutationUpdateProductArgs = {
  input: UpdateProduct;
};


export type MutationUpdateProductCategoryArgs = {
  input: UpdateProductCategory;
};


export type MutationUpdateProductOptionGroupArgs = {
  input: UpdateProductOptionGroup;
};


export type MutationUpdateProductSkuArgs = {
  input: Array<UpdateProductSku>;
};


export type MutationUpdateSettingCompanyArgs = {
  input: UpdateSettingCompany;
};


export type MutationUpdateStaffArgs = {
  input: UpdateStaff;
};


export type MutationUpdateStripePaymentStatusArgs = {
  input: UpdateStripePaymentStatus;
};


export type MutationUpdateTagArgs = {
  input: UpdateTag;
};


export type MutationUpdateTagsAndIsActiveCustomerArgs = {
  input: UpdateTagsAndIsActiveCustomer;
};


export type MutationUpdateUserInfoArgs = {
  input: UpdateUserInfo;
};


export type MutationUpdateUserRoleCompanyArgs = {
  input: UpdateUserRoleCompany;
};


export type MutationVoidTransactionArgs = {
  input: VoidTransaction;
};

export type Order = {
  __typename?: 'Order';
  addressID?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  approvedAt?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<BillingAddress>;
  buyerInfo?: Maybe<UserOrderInfo>;
  buyerUserID?: Maybe<Scalars['String']>;
  cartID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  confirmPaidAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  deliveryStatus?: Maybe<E_Delivery_Status>;
  discount?: Maybe<Scalars['Float']>;
  evidencePaymentInfo?: Maybe<EvidencePaymenyInfo>;
  evidencePaymentURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  evidenceReceivedURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  evidenceShippedURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  freight?: Maybe<Scalars['Float']>;
  fulfilledAt?: Maybe<Scalars['String']>;
  isFreightOnDelivery?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  orderByRole?: Maybe<E_Order_By_Role>;
  orderID?: Maybe<Scalars['String']>;
  orderItem?: Maybe<Array<Maybe<OrderItem>>>;
  orderNo?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<E_Order_Status>;
  pausedAt?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<E_Payment_Status>;
  pickedUpAt?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  profileImgURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  sellerInfo?: Maybe<UserOrderInfo>;
  sellerUserID?: Maybe<Scalars['String']>;
  sessionID?: Maybe<Scalars['String']>;
  sessionURL?: Maybe<Scalars['String']>;
  shippedAt?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<ShippingAddress>;
  shippingCompany?: Maybe<Scalars['String']>;
  shippingTrackingID?: Maybe<Scalars['String']>;
  shippingType?: Maybe<E_Shipping_Type>;
  tagID?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['Float']>;
  timeoutPaidAt?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};

export type OrderFilter = {
  orderNo?: InputMaybe<Scalars['String']>;
  orderStatus?: InputMaybe<E_Order_Status>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  brandName?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  optionAttributeName?: Maybe<Scalars['JSON']>;
  price?: Maybe<Scalars['Float']>;
  priceModel?: Maybe<E_Pricing_Model>;
  productDescription?: Maybe<Scalars['String']>;
  productID?: Maybe<Scalars['String']>;
  productImgURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  productName?: Maybe<Scalars['String']>;
  productSKU?: Maybe<Scalars['String']>;
  productSKUID?: Maybe<Scalars['String']>;
  productSKUName?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Int']>;
  unitLabel?: Maybe<Scalars['String']>;
};

export type OrderQuery = {
  __typename?: 'OrderQuery';
  items?: Maybe<Array<Maybe<Order>>>;
  nextToken?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
};

export type Pricing = {
  __typename?: 'Pricing';
  companyID?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  currency?: Maybe<E_Currency>;
  customAttribute?: Maybe<Scalars['JSON']>;
  priceModelStandard?: Maybe<PricingModelStandard>;
  priceModelVolumn?: Maybe<PricingModelVolumn>;
  pricingID?: Maybe<Scalars['String']>;
  pricingModel?: Maybe<E_Pricing_Model>;
  productID?: Maybe<Scalars['String']>;
  productSKUID?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type PricingInput = {
  cost?: InputMaybe<Scalars['Float']>;
  priceModelStandard?: InputMaybe<PricingModelStandardInput>;
  priceModelVolumn?: InputMaybe<PricingModelVolumnInput>;
  pricingID?: InputMaybe<Scalars['String']>;
  pricingModel?: InputMaybe<E_Pricing_Model>;
};

export type PricingModelStandard = {
  __typename?: 'PricingModelStandard';
  defaultPrice?: Maybe<Scalars['Float']>;
  paymentType?: Maybe<E_Payment_Type>;
  salePrice?: Maybe<Scalars['Float']>;
};

export type PricingModelStandardInput = {
  defaultPrice?: InputMaybe<Scalars['Float']>;
  paymentType?: InputMaybe<E_Payment_Type>;
  salePrice?: InputMaybe<Scalars['Float']>;
};

export type PricingModelVolumn = {
  __typename?: 'PricingModelVolumn';
  paymentType?: Maybe<E_Payment_Type>;
  tableVolumnPrice?: Maybe<Array<Maybe<TableVolumnPrice>>>;
};

export type PricingModelVolumnInput = {
  paymentType?: InputMaybe<E_Payment_Type>;
  tableVolumnPrice?: InputMaybe<Array<InputMaybe<TableVolumnPriceInput>>>;
};

export type Product = {
  __typename?: 'Product';
  brand?: Maybe<Brand>;
  brandID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['JSON']>;
  isActive?: Maybe<E_Is_Active>;
  isVisible?: Maybe<E_Is_Active>;
  minimumStock?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  productFileURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  productID?: Maybe<Scalars['String']>;
  productImgURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  productOptionGroup?: Maybe<ProductOptionGroup>;
  productOptionGroupID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  specAttribute?: Maybe<Scalars['JSON']>;
  tagID?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitLabel?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Category>>>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductCategoryLog = {
  __typename?: 'ProductCategoryLog';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Category>>>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type ProductOption = {
  __typename?: 'ProductOption';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isActive?: Maybe<E_Is_Active>;
  isFilter?: Maybe<E_Is_Active>;
  isOption?: Maybe<E_Is_Active>;
  name?: Maybe<Scalars['String']>;
  productOptionGroupID?: Maybe<Scalars['String']>;
  productOptionID?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  type?: Maybe<E_Type_Option>;
  updatedAt?: Maybe<Scalars['String']>;
  variant?: Maybe<Array<Maybe<ProductOptionVariant>>>;
};

export type ProductOptionGroup = {
  __typename?: 'ProductOptionGroup';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<E_Is_Active>;
  name?: Maybe<Scalars['String']>;
  option?: Maybe<Array<Maybe<ProductOption>>>;
  productOptionGroupID?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductOptionVariant = {
  __typename?: 'ProductOptionVariant';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isActive?: Maybe<E_Is_Active>;
  isDefault?: Maybe<E_Is_Active>;
  name?: Maybe<Scalars['String']>;
  productOptionID?: Maybe<Scalars['String']>;
  productOptionVariantID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductQuery = {
  __typename?: 'ProductQuery';
  items?: Maybe<Array<Maybe<Product>>>;
  nextToken?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
};

export type ProductSku = {
  __typename?: 'ProductSKU';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  inventory?: Maybe<Inventory>;
  isActive?: Maybe<E_Is_Active>;
  isVisible?: Maybe<E_Is_Active>;
  optionAttribute?: Maybe<Scalars['JSON']>;
  pricing?: Maybe<Array<Maybe<Pricing>>>;
  product?: Maybe<Product>;
  productID?: Maybe<Scalars['String']>;
  productSKU?: Maybe<Scalars['String']>;
  productSKUID?: Maybe<Scalars['String']>;
  productSKUName?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  tagID?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductSkuFilter = {
  productSKU?: InputMaybe<Scalars['String']>;
  productSKUID?: InputMaybe<Scalars['String']>;
};

export type ProductSkuQuery = {
  __typename?: 'ProductSKUQuery';
  items?: Maybe<Array<Maybe<ProductSku>>>;
  nextToken?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  brandList?: Maybe<BrandQuery>;
  companyInfo?: Maybe<Company>;
  dashboardChart?: Maybe<DashboardChartQuery>;
  dashboardLeaderboardsCustomers?: Maybe<DashboardLeaderboardsCustomersQuery>;
  dashboardLeaderboardsInventory?: Maybe<DashboardLeaderboardsInventoryQuery>;
  dashboardLeaderboardsProducts?: Maybe<DashboardLeaderboardsProductsQuery>;
  dashboardPerformance?: Maybe<DashboardPerformanceQuery>;
  dashboardPerformanceDetails?: Maybe<DashboardPerformanceDetailsQuery>;
  orderList?: Maybe<OrderQuery>;
  productSKUList?: Maybe<ProductSkuQuery>;
  settingCompanyInfo?: Maybe<SettingCompany>;
  tagList?: Maybe<TagQuery>;
  transactionList?: Maybe<InventoryQuery>;
  userInfo?: Maybe<User>;
  userList?: Maybe<UserQuery>;
  userRoleCompanyList?: Maybe<UserRoleCompanyQuery>;
};


export type QueryBrandListArgs = {
  filter: BrandFilter;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryDashboardChartArgs = {
  filter: DashboardFilter;
  selector: E_Chart;
};


export type QueryDashboardLeaderboardsCustomersArgs = {
  filter: DashboardFilter;
};


export type QueryDashboardLeaderboardsProductsArgs = {
  filter: DashboardFilter;
};


export type QueryDashboardPerformanceArgs = {
  filter: DashboardFilter;
};


export type QueryDashboardPerformanceDetailsArgs = {
  filter: DashboardFilter;
};


export type QueryOrderListArgs = {
  filter: OrderFilter;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryProductSkuListArgs = {
  filter: ProductSkuFilter;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryTagListArgs = {
  filter: TagFilter;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionListArgs = {
  filter: InventoryFilter;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryUserListArgs = {
  filter: UserFilter;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryUserRoleCompanyListArgs = {
  filter: UserRoleCompanyFilter;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type SettingCompany = {
  __typename?: 'SettingCompany';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  domainName?: Maybe<Scalars['String']>;
  freightModel?: Maybe<Freight_Model>;
  freightModelFix?: Maybe<FreightModelFix>;
  freightModelProvince?: Maybe<Array<Maybe<FreightModelProvince>>>;
  isClose?: Maybe<E_Is_Active>;
  lineNotifyToken?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  senderMail?: Maybe<Scalars['String']>;
  senderPassword?: Maybe<Scalars['String']>;
  stripeCancelPathURL?: Maybe<Scalars['String']>;
  stripeSecretKeyProd?: Maybe<Scalars['String']>;
  stripeSecretKeyTest?: Maybe<Scalars['String']>;
  stripeSuccessPathURL?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['Float']>;
  timeoutCart?: Maybe<Scalars['Float']>;
  timeoutPaid?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  address?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<E_Country>;
  district?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  googleMapURL?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  province?: Maybe<E_Province>;
  subDistrict?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
};

export enum Tax_Type {
  Corporate = 'CORPORATE',
  Personal = 'PERSONAL'
}

export type TableFreightProvince = {
  __typename?: 'TableFreightProvince';
  endPrice?: Maybe<Scalars['Float']>;
  freight?: Maybe<Scalars['Float']>;
  startPrice?: Maybe<Scalars['Float']>;
};

export type TableFreightProvinceInput = {
  endPrice?: InputMaybe<Scalars['Float']>;
  freight?: InputMaybe<Scalars['Float']>;
  startPrice?: InputMaybe<Scalars['Float']>;
};

export type TableVolumnPrice = {
  __typename?: 'TableVolumnPrice';
  endUnit?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Float']>;
  startUnit?: Maybe<Scalars['Float']>;
};

export type TableVolumnPriceInput = {
  endUnit?: InputMaybe<Scalars['Float']>;
  salePrice?: InputMaybe<Scalars['Float']>;
  startUnit?: InputMaybe<Scalars['Float']>;
};

export type Tag = {
  __typename?: 'Tag';
  color?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  isActive?: Maybe<E_Is_Active>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  tagID?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type TagFilter = {
  isActive?: InputMaybe<E_Is_Active>;
  tagID?: InputMaybe<Scalars['String']>;
};

export type TagQuery = {
  __typename?: 'TagQuery';
  items?: Maybe<Array<Maybe<Tag>>>;
  nextToken?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
};

export type TaxAddress = {
  __typename?: 'TaxAddress';
  address?: Maybe<Scalars['String']>;
  country?: Maybe<E_Country>;
  district?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  googleMapURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  province?: Maybe<E_Province>;
  subDistrict?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  taxType?: Maybe<Tax_Type>;
  tel?: Maybe<Scalars['String']>;
};

export type UpdateBrand = {
  brandID: Scalars['String'];
  brandImgURL?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customAttribute?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<E_Is_Active>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCmsCompany = {
  announcement?: InputMaybe<Scalars['JSON']>;
  customAttribute?: InputMaybe<Scalars['JSON']>;
  poster?: InputMaybe<Scalars['JSON']>;
};

export type UpdateJoinProductCategoryProduct = {
  joinItemWithCategoryID: Scalars['String'];
  productIDList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateOrderStatus = {
  deliveryStatus?: InputMaybe<E_Delivery_Status>;
  note?: InputMaybe<Scalars['String']>;
  orderID?: InputMaybe<Scalars['String']>;
  orderStatus?: InputMaybe<E_Order_Status>;
  shippingCompany?: InputMaybe<E_Shipping_Company>;
  shippingTrackingID?: InputMaybe<Scalars['String']>;
};

export type UpdateProduct = {
  brandID: Scalars['String'];
  customAttribute?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['JSON']>;
  isActive?: InputMaybe<E_Is_Active>;
  isVisible?: InputMaybe<E_Is_Active>;
  minimumStock?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  productFileURL?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productID?: InputMaybe<Scalars['String']>;
  productImgURL?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productOptionGroupID: Scalars['String'];
  remark?: InputMaybe<Scalars['String']>;
  specAttribute?: InputMaybe<Scalars['JSON']>;
  tagID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  unitLabel?: InputMaybe<Scalars['String']>;
};

export type UpdateProductCategory = {
  items: Array<CategoryInput>;
};

export type UpdateProductOption = {
  customAttribute?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<E_Is_Active>;
  isFilter?: InputMaybe<E_Is_Active>;
  isOption?: InputMaybe<E_Is_Active>;
  name?: InputMaybe<Scalars['String']>;
  productOptionID?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<E_Type_Option>;
  variant?: InputMaybe<Array<InputMaybe<UpdateProductOptionVariant>>>;
};

export type UpdateProductOptionGroup = {
  customAttribute?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<E_Is_Active>;
  name?: InputMaybe<Scalars['String']>;
  option?: InputMaybe<Array<InputMaybe<UpdateProductOption>>>;
  productOptionGroupID?: InputMaybe<Scalars['String']>;
};

export type UpdateProductOptionVariant = {
  customAttribute?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<E_Is_Active>;
  isDefault?: InputMaybe<E_Is_Active>;
  name?: InputMaybe<Scalars['String']>;
  productOptionVariantID?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
};

export type UpdateProductSku = {
  customAttribute?: InputMaybe<Scalars['JSON']>;
  isActive?: InputMaybe<E_Is_Active>;
  isVisible?: InputMaybe<E_Is_Active>;
  optionAttribute?: InputMaybe<Scalars['JSON']>;
  pricing?: InputMaybe<PricingInput>;
  productID?: InputMaybe<Scalars['String']>;
  productSKU?: InputMaybe<Scalars['String']>;
  productSKUID?: InputMaybe<Scalars['String']>;
  productSKUName?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  tagID?: InputMaybe<Scalars['String']>;
};

export type UpdateSettingCompany = {
  customAttribute?: InputMaybe<Scalars['JSON']>;
  domainName?: InputMaybe<Scalars['String']>;
  freight?: InputMaybe<Scalars['Float']>;
  freightModel?: InputMaybe<Freight_Model>;
  freightModelFix?: InputMaybe<FreightModelFixInput>;
  freightModelProvince?: InputMaybe<Array<InputMaybe<FreightModelProvinceInput>>>;
  isClose?: InputMaybe<E_Is_Active>;
  lineNotifyToken?: InputMaybe<Scalars['String']>;
  senderMail?: InputMaybe<Scalars['String']>;
  senderPassword?: InputMaybe<Scalars['String']>;
  stripeCancelPathURL?: InputMaybe<Scalars['String']>;
  stripeSecretKeyProd?: InputMaybe<Scalars['String']>;
  stripeSecretKeyTest?: InputMaybe<Scalars['String']>;
  stripeSuccessPathURL?: InputMaybe<Scalars['String']>;
  tax?: InputMaybe<Scalars['Float']>;
  timeoutCart?: InputMaybe<Scalars['Float']>;
  timeoutPaid?: InputMaybe<Scalars['Float']>;
};

export type UpdateStaff = {
  birthday?: InputMaybe<Scalars['String']>;
  customAttribute?: InputMaybe<Scalars['JSON']>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  idCard: Scalars['String'];
  idCardImgURL?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isActive?: InputMaybe<E_Is_Active>;
  phoneNumber: Scalars['String'];
  profileImgURL?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  surname: Scalars['String'];
  tagID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userID: Scalars['String'];
  userRoleCompanyID: Scalars['String'];
  uuidFacebook?: InputMaybe<Scalars['String']>;
  uuidGoogle?: InputMaybe<Scalars['String']>;
  uuidLine?: InputMaybe<Scalars['String']>;
};

export type UpdateStripePaymentStatus = {
  orderID: Scalars['String'];
  sessionID: Scalars['String'];
};

export type UpdateTag = {
  color?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<E_Is_Active>;
  tagID: Scalars['String'];
  tagName?: InputMaybe<Scalars['String']>;
};

export type UpdateTagsAndIsActiveCustomer = {
  isActive?: InputMaybe<E_Is_Active>;
  tagID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userID: Scalars['String'];
};

export type UpdateUserInfo = {
  birthday?: InputMaybe<Scalars['String']>;
  customAttribute?: InputMaybe<Scalars['JSON']>;
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<E_Gender>;
  idCard?: InputMaybe<Scalars['String']>;
  idCardImgURL?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isActive?: InputMaybe<E_Is_Active>;
  profileImgURL?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  surname?: InputMaybe<Scalars['String']>;
  uuidFacebook?: InputMaybe<Scalars['String']>;
  uuidGoogle?: InputMaybe<Scalars['String']>;
  uuidLine?: InputMaybe<Scalars['String']>;
};

export type UpdateUserRoleCompany = {
  isActive?: InputMaybe<E_Is_Active>;
  permission?: InputMaybe<Array<InputMaybe<E_Permission>>>;
  userLevel?: InputMaybe<E_User_Level>;
  userRoleCompanyID: Scalars['String'];
  userRoleCompanyName?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Array<Maybe<Address>>>;
  birthday?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  gender?: Maybe<E_Gender>;
  idCard?: Maybe<Scalars['String']>;
  idCardImgURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive?: Maybe<E_Is_Active>;
  order?: Maybe<Array<Maybe<Order>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  profileImgURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  tagID?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  userRoleCompany?: Maybe<UserRoleCompany>;
  userRoleCompanyID?: Maybe<Scalars['String']>;
  userRoleGlobal?: Maybe<E_User_Role>;
  uuidFacebook?: Maybe<Scalars['String']>;
  uuidGoogle?: Maybe<Scalars['String']>;
  uuidLine?: Maybe<Scalars['String']>;
};

export type UserFilter = {
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<E_Gender>;
  isActive?: InputMaybe<E_Is_Active>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  userRoleCompanyID?: InputMaybe<Scalars['String']>;
  userRoleGlobal: E_User_Role;
};

export type UserOrderInfo = {
  __typename?: 'UserOrderInfo';
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  userRole?: Maybe<E_User_Role>;
};

export type UserQuery = {
  __typename?: 'UserQuery';
  items?: Maybe<Array<Maybe<User>>>;
  nextToken?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
};

export type UserRoleCompany = {
  __typename?: 'UserRoleCompany';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  isActive?: Maybe<E_Is_Active>;
  permission?: Maybe<Array<Maybe<E_Permission>>>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userLevel?: Maybe<E_User_Level>;
  userRoleCompanyID?: Maybe<Scalars['String']>;
  userRoleCompanyName?: Maybe<Scalars['String']>;
};

export type UserRoleCompanyFilter = {
  userRoleCompanyID?: InputMaybe<Scalars['String']>;
};

export type UserRoleCompanyQuery = {
  __typename?: 'UserRoleCompanyQuery';
  items?: Maybe<Array<Maybe<UserRoleCompany>>>;
  nextToken?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
};

export type VoidTransaction = {
  note?: InputMaybe<Scalars['String']>;
  productSKUID: Scalars['String'];
  transactionNo: Scalars['Int'];
};
