// Lib
import { useEffect, useState } from 'react'

const useRightClickMenu = () => {
  const [show, setShow] = useState(false)
  const [points, setPoints] = useState<{ x: number; y: number; id: string | number }>({ x: 0, y: 0, id: '' })

  useEffect(() => {
    const handleClick = () => setShow(false)
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  return {
    show,
    setShow,
    points,
    setPoints,
  }
}
export default useRightClickMenu
