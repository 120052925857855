// Lib
import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { getIn, useFormikContext } from 'formik'

// Include in project
import { Props, ColorThemeTextField } from './config'
import { IOption } from 'utils/interface'

const AutoCompleteComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, setFieldValue, errors, touched } = useFormikContext()
  return (
    <>
      <p className="fontBold">
        {props.label}
        <span className="colorRed">{props.require && '*'}</span>
      </p>
      <Autocomplete
        size="small"
        id={props.name}
        fullWidth
        multiple
        value={getIn(values, props.name)}
        options={props.option}
        filterSelectedOptions
        disabled={props.disabled}
        onChange={(event, value, reason, details) => {
          if (reason === 'selectOption') {
            // Add
            setFieldValue(props.name, [...getIn(values, props.name), details?.option], false)
          } else if (reason === 'removeOption') {
            // Remove
            const editArr = getIn(values, props.name).filter((value: IOption) => {
              return details?.option.label !== value.label
            })
            setFieldValue(props.name, editArr, false)
          } else if (reason === 'clear') {
            // Clear
            setFieldValue(props.name, [], false)
          }
        }}
        renderOption={(props, option: IOption) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )
        }}
        // isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => {
          return (
            <TextField
              color="primary"
              error={getIn(touched, props.name) && Boolean(getIn(errors, props.name))}
              placeholder={props.placeholder}
              sx={ColorThemeTextField}
              {...params}
              helperText={
                getIn(touched, props.name) &&
                getIn(errors, props.name) && <span className="colorRed">{getIn(errors, props.name)}</span>
              }
            />
          )
        }}
      />
    </>
  )
}

export default AutoCompleteComponent
