// Lib
import React, { useEffect } from 'react'
import { useFormikContext, withFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { HeaderInformation, OptionInformationEditSection } from 'container'
import type { RootState } from 'states/store'
import { useOptionMutation, useLoadingControl } from 'hooks'
import yupOption from 'validations/yupOption.validate'
import { ProductOptionGroup, E_Is_Active } from 'utils/generatedNonAuth'

export interface IUpdateOption {
  formInput: ProductOptionGroup
  cloneProductOptionGroupID: string
}

const intitialValue: IUpdateOption = {
  formInput: {
    productOptionGroupID: null,
    name: '',
    description: '',
    isActive: E_Is_Active.True,
    customAttribute: {},
    option: [],
  },
  cloneProductOptionGroupID: '',
}

const UpdateOption: React.FC = () => {
  const { optionGroupID: productOptionGroupID } = useParams()
  const { setFieldValue } = useFormikContext()

  const optionGroupReducer = useSelector((state: RootState) => state.optionGroup)
  const { isLoading, updateOption } = useOptionMutation()
  useLoadingControl(isLoading)

  useEffect(() => {
    if (!productOptionGroupID || productOptionGroupID === 'create') return // Case Create New Option
    if (optionGroupReducer.optionGroupList.length === 0) return

    const optionGroupListClone: ProductOptionGroup[] = JSON.parse(JSON.stringify(optionGroupReducer.optionGroupList))

    const option = optionGroupListClone.find((ele) => ele.productOptionGroupID === productOptionGroupID)

    const mapperNewOptions = option?.option?.map((data) => {
      const reOrderIndexVariant = data?.variant?.map((variant, index) => ({
        ...variant,
        index: index,
      }))
      return {
        ...data,
        variant: reOrderIndexVariant || [],
      }
    })

    setFieldValue('formInput', { ...option, option: mapperNewOptions || [] })
  }, [optionGroupReducer.optionGroupList, productOptionGroupID])

  return (
    <Layout hiddenHeaderSection>
      <div className={styles.container}>
        <HeaderInformation section="Option Info" id={productOptionGroupID} />
        <OptionInformationEditSection updateOption={updateOption} />
      </div>
    </Layout>
  )
}

const EnhancedUpdateOption = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  validateOnMount: true,
  validationSchema: yupOption,
  handleSubmit: () => {
    console.log('Skip')
  },
})(UpdateOption)

export default EnhancedUpdateOption
