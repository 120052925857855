// Lib
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu, MenuItem } from '@mui/material'
import { useDispatch } from 'react-redux'

// Images
import bankSVG from 'images/commons/Bank.svg'
import bellSVG from 'images/commons/bell.svg'
import gearSVG from 'images/commons/gear.svg'
import userSVG from 'images/commons/user.svg'

// Include in project
import styles from './index.module.scss'
import { clearDecodeToken } from 'states/slice/decodeToken'

const NavTopBar: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.bgBlue} onClick={() => navigate('/')}>
          <img className={styles.bankLogo} src={bankSVG} />
        </div>
        <p>E-Commerce Admin</p>
      </div>

      <div className={styles.wrapperInput}>{/* <TextField name="search" type="text" placeholder="ค้นหา..." /> */}</div>
      <div className={styles.right}>
        {/* <img width={16} src={bellSVG} />
        <img width={16} src={gearSVG} /> */}
        <img width={16} src={userSVG} onClick={handleClick} />

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              handleClose()
              navigate('/user-info')
            }}
          >
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(clearDecodeToken())
              localStorage.clear()
              navigate('/login')
              handleClose()
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default NavTopBar
