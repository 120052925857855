import Color from 'abstracts/export.module.scss'

export interface Props {
  name: string
  label?: string
  value?: string
  disabled?: boolean
}

export const RadioSx = {
  color: `${Color.primaryMain}`,
  '&.Mui-checked': {
    color: `${Color.primaryMain}`,
  },
  '&.Mui-disabled': {
    color: `${Color.grey300}`,
  },
}
