// Lib
import React, { useEffect } from 'react'
import { Menu, MenuItem } from '@mui/material'
import { useFormikContext } from 'formik'
import { useSearchParams } from 'react-router-dom'

// Images
import { FilterListRounded } from '@mui/icons-material'

// Include in project
import styles from './index.module.scss'
import { E_Is_Active } from 'utils/generatedNonAuth'

interface Props {
  isHiddenAllStatus?: boolean
}

const ButtonFilterIsActive: React.FC<Props> = ({ isHiddenAllStatus }) => {
  const { values, setFieldValue } = useFormikContext<{ filter: { isActive: E_Is_Active | 'ALL' } }>()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (status: E_Is_Active | 'ALL' | null) => {
    setAnchorEl(null)
    setFieldValue('filter.isActive', status || values.filter.isActive)

    searchParams.set('isActive', status || values.filter.isActive)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    const isActive = searchParams.get('isActive')

    if (!isActive) return

    setFieldValue('filter.isActive', isActive)
  }, [])

  return (
    <div>
      <button className={styles.button} type="button" onClick={handleClick}>
        <FilterListRounded /> Filter Status
      </button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose(null)}>
        {!isHiddenAllStatus && (
          <MenuItem onClick={() => handleClose('ALL')} selected={values.filter.isActive === 'ALL'}>
            <p>All Status</p>
          </MenuItem>
        )}
        <MenuItem onClick={() => handleClose(E_Is_Active.True)} selected={values.filter.isActive === E_Is_Active.True}>
          <p>Active</p>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose(E_Is_Active.False)}
          selected={values.filter.isActive === E_Is_Active.False}
        >
          <p>Inactive</p>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default ButtonFilterIsActive
