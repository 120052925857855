import * as Yup from 'yup'

const yupTransection = Yup.object().shape({
  formInput: Yup.object().shape({
    triggerType: Yup.string().required('Plese Enter'),
    qty: Yup.number().required('Plese Enter'),
  }),
})

export default yupTransection
