// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { SelectChangeEvent } from '@mui/material'

// Include in project
import styles from './index.module.scss'
import { TextField, TextArea, DropZoneUpload, UploadPDF, Switch, SelectSingle, Tag, Divider } from 'component'
import { IProductManagement } from 'pages/ProductManagement'
import type { RootState } from 'states/store'
import { E_Is_Active } from 'utils/generated'
import { optionList } from 'utils/other'

const ProductInformation: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<IProductManagement>()
  const brandList = useSelector((state: RootState) => state.brand?.brandOptionList)

  const handleChangeIsActive = (e: SelectChangeEvent) => {
    const value = e.target.value
    setFieldValue('formInput.isActive', value)

    if (value === E_Is_Active.False) setFieldValue('formInput.isVisible', E_Is_Active.False)
  }

  return (
    <div className={styles.main}>
      <section className={styles.header}>
        <h6>Product Information</h6>
        <Divider />
        <p className="fontBold">Product Details</p>
      </section>

      <div className={styles.wrapperBox}>
        <TextField type="text" name="formInput.name" label="Name" placeholder="Product Name" require />
        <SelectSingle label="Brand" name="formInput.brandID" option={brandList} />
        <TextArea name="formInput.description" label="Description" placeholder="Description" />
        <TextField type="text" name="formInput.unitLabel" label="Unit" placeholder="Unit" require />
      </div>

      <div className={styles.box}>
        <section className={styles.headerSection}>
          <p className="fontBold">Images</p>
          <Tag description={`${values.formInput.productImgURLShow?.length || 0}/5 Files`} />
        </section>
        <DropZoneUpload
          name="formInput.productImgURLShow"
          fileList={values.formInput?.productImgURLShow}
          sizeDrop="SM"
          multiple
        />
      </div>

      <UploadPDF fileList={values.formInput.productFileURLShow} name="formInput.productFileURLShow" multiple />

      <div className={styles.flexBox}>
        <div className={styles.group}>
          <p className="fontBold">Visible</p>
          <div className={styles.wrapperBox}>
            <Switch
              name="formInput.isVisible"
              checked={values.formInput.isVisible === E_Is_Active.True ? true : false}
              onChange={() => {
                if (values.formInput?.isVisible === E_Is_Active.True) {
                  setFieldValue('formInput.isVisible', E_Is_Active.False)
                } else {
                  setFieldValue('formInput.isVisible', E_Is_Active.True)
                }
              }}
            />
            <Tag description="What’s visible?" />
          </div>
        </div>
        <div className={styles.wrapperInput}>
          <SelectSingle
            label="Status"
            name="formInput.isActive"
            option={optionList.isActiveOption}
            onChange={handleChangeIsActive}
          />
        </div>
      </div>

      <div className={styles.group}>
        <TextField label="Product SKU. (Prefix)" placeholder="Prefix" type="text" name="formInput.prefix" require />
        <TextArea name="formInput.remark" label="Note" placeholder="Note" />
      </div>
    </div>
  )
}
export default ProductInformation
