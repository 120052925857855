import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  Observable,
  ServerParseError,
  Operation,
  ApolloLink,
} from '@apollo/client'
import { ErrorResponse } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { queryClient } from './react-query'
import { refreshToken as refreshTokenService } from 'services/auth.service'
import { GRAPHQL_URL } from 'configs'

//Declare your endpoints
const endpointAdmin = createHttpLink({
  uri: `${GRAPHQL_URL}Admin`,
})

const endpointNonAuth = createHttpLink({
  uri: `${GRAPHQL_URL}NonAuth`,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }: ErrorResponse) => {
  if (graphQLErrors) {
    return forward(operation)
  }
  if (networkError) {
    if ((networkError as ServerParseError).statusCode === 403) {
      const expiredLogin = localStorage.getItem('expiredLogin')
      const dateNow = new Date().getTime()

      // If user not check remember me much check time for handle call api refresh token
      if (!expiredLogin || parseInt(expiredLogin) > dateNow) {
        const refreshToken = localStorage.getItem('refreshToken')
        const retryObservable: Observable<unknown> = new Observable((subscriber) => {
          if (subscriber.closed || !refreshToken) {
            return
          }
          refreshTokenService(refreshToken)
            .then((result: void | unknown) => {
              if (!result) {
                operation.setContext({
                  response: {
                    errors: null,
                  },
                })
                subscriber.complete()
                localStorage.clear()
              } else {
                const token = (result as { data: { accessToken: string; refreshToken: string } }).data
                if (token.accessToken) {
                  localStorage.setItem('accessToken', token.accessToken)
                  localStorage.setItem('refreshToken', token.refreshToken)

                  const oldHeaders = operation.getContext().headers
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: `Bearer ${token.accessToken}`,
                    },
                  })
                  subscriber.next(operation)
                  subscriber.complete()
                }
              }
            })
            .catch(() => {
              localStorage.clear()
              client.clearStore()
              queryClient.clear()
              location.reload()
              return
            })
        })

        return retryObservable.flatMap((newOperation) => forward(newOperation as Operation))
      } else {
        localStorage.clear()
        location.reload()
      }
    }
  }
})

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'endpointAdmin',
    authLink.concat(errorLink).concat(endpointAdmin), //if above
    endpointNonAuth,
  ),
  cache: new InMemoryCache(),
})

export { client }
