// Lib
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

// Images

// Include in project
import { setOpenLoadingMasterData, setCloseLoadingMasterData } from 'states/slice/loading'

const useLoadingControlMasterData = (isLoadingMasterData: boolean) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoadingMasterData) dispatch(setOpenLoadingMasterData())
    else dispatch(setCloseLoadingMasterData())
  }, [isLoadingMasterData])
}

export default useLoadingControlMasterData
