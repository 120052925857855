// Lib
import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import _ from 'lodash'

// Image

// Include in project
import Layout from 'layouts/home'
import styles from './index.module.scss'
import { menuPage } from 'utils/menu'
import type { RootState } from 'states/store'
import { convertDateLL } from 'utils/convertDate'

const Home: React.FC = () => {
  const decodeToken = useSelector((state: RootState) => state.decodeToken)
  const companyName = useSelector((state: RootState) => state.company.companyInfo?.companyName)

  const menuPageFilter = menuPage
    .map((data) => {
      const compare = _.difference(data.permission, decodeToken.permission)

      if (compare.length === data.permission.length) return null
      return data
    })
    .filter((ele) => ele)

  return (
    <Layout headerSectionName="Launchpad" hiddenHeaderSection hiddenNavigateMenu>
      <h2>Launchpad</h2>

      <div className={styles.container}>
        <div>
          <h5>{convertDateLL(new Date().toISOString())}</h5>
          <h4>Hi, {decodeToken.firstname}</h4>
          <h3>Welcome to E-Commerce (Admin)</h3>
        </div>

        <div className={styles.wrapperBox}>
          {menuPageFilter.map((data, index) => (
            <Link to={data?.path as string} key={index} className={styles.card}>
              <img className={styles.icon} src={data?.img} width={100} />
              <h5>{data?.name}</h5>
            </Link>
          ))}
        </div>

        <div>
          <h3>{companyName}</h3>
          <h4>Company Code: {decodeToken.companyID}</h4>
        </div>
      </div>

      <div className={styles.footer}>
        <p>Copyright © 2016 Datability Co.,Ltd. All rights reserved.</p>
        <p>Version 0.1.0</p>
      </div>
    </Layout>
  )
}

export default Home
