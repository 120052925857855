import { gql } from '@apollo/client'

export const OPTION_LIST = gql`
  query productOptionGroupList($filter: ProductOptionGroupFilter!, $pageSize: Int, $nextToken: String) {
    productOptionGroupList(filter: $filter, pageSize: $pageSize, nextToken: $nextToken) {
      res_code
      res_desc
      items {
        companyID
        productOptionGroupID
        name
        description
        customAttribute
        isActive
        option {
          productOptionGroupID
          productOptionID
          index
          name
          description
          customAttribute
          isOption
          isFilter
          isActive
          type
          variant {
            productOptionID
            productOptionVariantID
            index
            name
            description
            remark
            customAttribute
            isActive
            isDefault
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const UPDATE_OPTION = gql`
  mutation updateProductOptionGroup($input: UpdateProductOptionGroup!) {
    updateProductOptionGroup(input: $input) {
      res_code
      res_desc
      companyID
      productOptionGroupID
    }
  }
`
