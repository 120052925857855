// Lib
import React from 'react'

// Images

// Include in project
import styles from './index.module.scss'
import { NavTopBar } from 'container'
import { NavigateMenu, HeaderSection } from 'component'
import { E_Permission } from 'utils/generated'

interface Props {
  children?: JSX.Element | JSX.Element[]
  hiddenNavigateMenu?: boolean
  hiddenHeaderSection?: boolean
  headerSectionName?: string
  headerSectionManu?: { section: string; path: string; permission: E_Permission }[]
}

const Layout: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.gridLayout}>
      <div className={styles.gridNavTop}>
        <NavTopBar />
      </div>

      <div className={styles.gridData}>
        <div className={styles.flexColumn}>
          {!props.hiddenNavigateMenu && <NavigateMenu />}
          {!props.hiddenHeaderSection && (
            <HeaderSection
              sectionName={props.headerSectionName as string}
              listTab={props.headerSectionManu as { section: string; path: string; permission: E_Permission }[]}
            />
          )}
        </div>

        <div className={styles.children}>
          <>{props.children}</>
        </div>
      </div>
    </div>
  )
}

export default Layout
