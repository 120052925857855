// Lib
import React from 'react'
import { withFormik } from 'formik'
import { useSelector } from 'react-redux'

// Images

// Include in project
import Layout from 'layouts'
import { SettingSection } from 'container'
import { useLoadingControl, useSettingCompanyInfo } from 'hooks'
import { SettingCompany, E_Is_Active, Company, Freight_Model, E_Province, FreightModelProvince } from 'utils/generated'
import type { RootState } from 'states/store'

export interface ISetting {
  formInput: SettingCompany
}

export const intitialValue: ISetting = {
  formInput: {
    domainName: '',
    stripeSuccessPathURL: '',
    stripeCancelPathURL: '',
    stripeSecretKeyProd: '',
    stripeSecretKeyTest: '',
    senderMail: '',
    senderPassword: '',
    lineNotifyToken: '',
    tax: 0,
    timeoutCart: 0,
    timeoutPaid: 0,
    isClose: E_Is_Active.True,

    freightModel: Freight_Model.FixFreight,
    freightModelFix: {
      freight: 0,
    },
    freightModelProvince: [],
  },
}

const Setting: React.FC = () => {
  const {
    data: settingCompanyInfo,
    isLoading: loadingSettingCompanyInfo,
    refetch,
    updateSetting,
  } = useSettingCompanyInfo()

  const companyInfo = useSelector((state: RootState) => state.company.companyInfo)

  useLoadingControl(loadingSettingCompanyInfo)

  return (
    <Layout hiddenHeaderSection>
      <SettingSection
        companyInfo={companyInfo as Company}
        settingInfo={settingCompanyInfo}
        updateSetting={updateSetting}
        refetch={refetch}
      />
    </Layout>
  )
}

const EnhancedSetting = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  handleSubmit: () => {
    console.log('Skip')
  },
})(Setting)

export default EnhancedSetting
