import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useIntitialDecode, useIntitialMasterData, useLoadingControlMasterData } from 'hooks'

const ProtectedRoute: React.FC = () => {
  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')

  if (!accessToken && !refreshToken) {
    return <Navigate to="/login" replace />
  }

  useIntitialDecode()

  const { isLoading } = useIntitialMasterData()

  useLoadingControlMasterData(isLoading)

  return <Outlet />
}

export default ProtectedRoute
