import * as Yup from 'yup'

const yupOption = Yup.object().shape({
  formInput: Yup.object().shape({
    name: Yup.string().required('Plese Enter'),
    option: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Plese Enter'),
        variant: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required('Plese Enter'),
          }),
        ),
      }),
    ),
  }),
})

export default yupOption
