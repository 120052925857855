//lib
import { useEffect, useState } from 'react'

const otpTimer = (minuteDefault: number) => {
  const [minutes, setMinutes] = useState(minuteDefault)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds, minutes])

  const resetTimer = () => {
    setMinutes(1)
    setSeconds(0)
  }

  return { resetTimer, seconds, minutes }
}

export default otpTimer
