// Lib
import React from 'react'
import { MenuItem, FormControl, ListItemText, Select } from '@mui/material'
import { getIn, useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import Color from 'abstracts/export.module.scss'
import { Props, SelectSx, CheckboxSx, MenuProps } from './config'

const SelectSingle: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext()

  return (
    <div className={styles.wapper}>
      <p className="fontBold">
        {props.label}
        {props.require && <span className="colorRed">*</span>}
      </p>
      <FormControl size="small" fullWidth>
        <Select
          name={props.name}
          value={getIn(values, props.name)}
          displayEmpty
          onChange={props.onChange ? props.onChange : handleChange}
          sx={SelectSx}
          MenuProps={MenuProps}
          error={getIn(touched, props.name) && Boolean(getIn(errors, props.name))}
          fullWidth
          disabled={props.disabled}
        >
          {props.option &&
            props.option.map((e, i) => (
              <MenuItem key={i} value={e.value}>
                <ListItemText sx={CheckboxSx} primary={<p style={{ color: `${Color.primaryMain}` }}>{e.label}</p>} />
              </MenuItem>
            ))}
        </Select>
        {getIn(touched, props.name) && getIn(errors, props.name) && (
          <p className={`colorRed ${styles.errorMsg}`}>{getIn(errors, props.name)}</p>
        )}
      </FormControl>
    </div>
  )
}

export default SelectSingle
