// Lib

export const convertCurrencyFormat = (amount: number) => {
  return ((amount as number) || 0)
    .toFixed(0)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const convertCurrencyFormatDecimal = (amount: number) => {
  return ((amount as number) || 0)
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
