// Lib
import React from 'react'
import { useFormikContext } from 'formik'

//Images
import { AddCircleRounded } from '@mui/icons-material'

// Include in project
import styles from './index.module.scss'
import { useModal } from 'hooks'
import { ModalUpdateProductSKU } from 'container'
import { Button, Divider, Table } from 'component'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { IProductInfo } from 'pages/ProductInfo'
import { MutationUpdateProductSkuArgs } from 'utils/generated'

interface Props {
  updateSKU: ({ variables }: { variables: MutationUpdateProductSkuArgs }) => Promise<any>
  refetch: () => void
}

const ProductSKUSection: React.FC<Props> = ({ updateSKU, refetch }) => {
  const { values } = useFormikContext<IProductInfo>()
  const { isModalOpen, handleClose, handleOpen } = useModal()

  const dataTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.ProductSKUList,
    dataList: values.productInfo?.productSKU || [],
  })

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h6>Product SKU</h6>
        <Button
          typeButton="button"
          type="info"
          name="Add / Edit Product SKU"
          size="small"
          startIcon={<AddCircleRounded />}
          functionOnClick={() => handleOpen()}
        />
      </div>
      <Divider />

      <Table
        objectKey={{
          // imageHTML: 'Image', //TODO
          productSKUName: 'SKU Name',
          productSKUHTML: 'SKU',
          pricingTypeHTML: 'Pricing Type',
          pricingHTML: 'Pricing',
          visibleHTML: 'Visible',
        }}
        data={dataTable}
      />

      <ModalUpdateProductSKU open={isModalOpen} handleClose={handleClose} updateSKU={updateSKU} refetch={refetch} />
    </div>
  )
}

export default ProductSKUSection
