// Lib
import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { SelectChangeEvent } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

// Include in project
import styles from './index.module.scss'
import { IOption } from 'utils/interface'
import { TextField, SelectSingle, Button } from 'component'

export type TOptionkey = (IOption & { child?: Record<string, string | IOption[]> })[]

interface Props {
  optionKey: TOptionkey
}

const SearchBox: React.FC<Props> = ({ optionKey }: Props): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<{ filter: { key: string; value: string } }>()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleFilterKey = (e: SelectChangeEvent) => {
    const value = e.target.value
    setFieldValue('filter.key', value)
  }

  const findSelectedKeyChild = optionKey.find((ele) => ele.value === values.filter.key)?.child

  const handleSearchByKeyAndValue = () => {
    // Set All filter null and specific key to value
    optionKey.map((key) => {
      if (key.value === values.filter.key) setFieldValue(`filter.${key.value}`, values.filter.value)
      else setFieldValue(`filter.${key.value}`, null)
    })

    searchParams.set('key', values.filter.key)
    searchParams.set('value', values.filter.value)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    const key = searchParams.get('key')
    const value = searchParams.get('value')

    if (!key || !value) return

    setFieldValue(`filter.${key}`, value)
    setFieldValue('filter.key', key)
    setFieldValue('filter.value', value)
  }, [])

  return (
    <div className={styles.wrapperSearch}>
      <SelectSingle
        name="filter.key"
        option={optionKey.map((data) => {
          delete data['child']
          return data
        })}
        label="Key"
        onChange={handleFilterKey}
      />
      {findSelectedKeyChild?.type === 'TEXT_FIELD' ? (
        <TextField name="filter.value" label="Value" type="text" disabled={!Boolean(values.filter.key)} />
      ) : (
        <SelectSingle
          name="filter.value"
          option={findSelectedKeyChild?.options as IOption[]}
          label="Value"
          disabled={!Boolean(values.filter.key)}
        />
      )}

      <Button
        name="Search"
        typeButton="button"
        type="info"
        size="medium"
        disabled={!Boolean(values.filter.key)}
        functionOnClick={handleSearchByKeyAndValue}
      />
    </div>
  )
}

export default SearchBox
