// Lib
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'

// Include in project
import { EResCode } from 'utils/other'
import { useErrMsgControl } from 'hooks'
import {
  forgotPassword as forgotPasswordFetch,
  forgotPasswordVerify as forgotPasswordVerifyFetch,
  resetNewPassword as resetNewPasswordFetch,
} from 'services/auth.service'

const useForgotPassword = () => {
  const {
    mutate: mutateForgotPassword,
    data: dataForgotPassword,
    error,
    isLoading: loading,
  } = useMutation(({ companyID, email }: { companyID: string; email: string }) =>
    forgotPasswordFetch({ companyID, email }),
  )

  const {
    mutate: mutateForgotPasswordVerify,
    data: dataForgotPasswordVerify,
    error: errorForgotPasswordVerify,
    isLoading: loadingForgotPasswordVerify,
  } = useMutation(({ companyID, email, OTPCode }: { companyID: string; email: string; OTPCode: string }) =>
    forgotPasswordVerifyFetch({ companyID, email, OTPCode }),
  )

  const {
    mutate: mutateResetNewPassword,
    data: dataResetNewPassword,
    error: errorResetNewPassword,
    isLoading: loadingResetNewPassword,
  } = useMutation(({ companyID, email, newPassword }: { companyID: string; email: string; newPassword: string }) =>
    resetNewPasswordFetch({ companyID, email, newPassword }),
  )

  const isLoading = loading || loadingForgotPasswordVerify || loadingResetNewPassword

  const errMsg = () => {
    if (error || (dataForgotPassword?.data?.res_code && dataForgotPassword?.data?.res_code !== EResCode.Success))
      return (
        ((error as AxiosError)?.response?.data as { res_desc: string })?.res_desc || dataForgotPassword?.data?.res_desc
      )

    if (
      errorForgotPasswordVerify ||
      (dataForgotPasswordVerify?.data?.res_code && dataForgotPasswordVerify?.data?.res_code !== EResCode.Success)
    )
      return (
        ((errorForgotPasswordVerify as AxiosError)?.response?.data as { res_desc: string })?.res_desc ||
        dataForgotPasswordVerify?.data?.res_desc
      )

    if (
      errorResetNewPassword ||
      (dataResetNewPassword?.data?.res_code && dataResetNewPassword?.data?.res_code !== EResCode.Success)
    )
      return (
        ((errorResetNewPassword as AxiosError)?.response?.data as { res_desc: string })?.res_desc ||
        dataResetNewPassword?.data?.res_desc
      )
  }

  useErrMsgControl(errMsg())

  return {
    forgotPassword: mutateForgotPassword,
    dataForgotPassword,

    forgotPasswordVerify: mutateForgotPasswordVerify,
    dataForgotPasswordVerify,

    resetNewPassword: mutateResetNewPassword,
    dataResetNewPassword,
    isLoading,
  }
}

export default useForgotPassword
