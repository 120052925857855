// Lib
import React from 'react'
import { Route, Routes } from 'react-router-dom'

// Images

// Include in project
import {
  Home,
  Login,
  UserInfo,
  CMS,
  Setting,
  ProductInfo,
  CustomerManagement,
  ForgotPassword,
  PermissionManagement,
  ProductManagement,
  Brand,
  OptionManagement,
  InventoryManagement,
  OrderManagement,
  Dashboard,
  Category,
  Tags,
  DashboardDetails,
  UpdateOption,
  OptionInformation,
  StaffManagement,
  Transection,
  MainUserManagement,
  MainProductManagement,
  MainInventoryManagement,
} from 'pages'
import {
  ModalDialog,
  Loading,
  LoadingMasterData,
  ProtectedRoute,
  ProtectedRoutePermission,
  ModalExpanseImage,
} from 'component'
import 'i18n/i18n'
import { E_Permission } from 'utils/generated'

const App: React.FC = () => {
  console.log('ENV', process.env.REACT_APP_STATE)

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="" element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />

          {/* dashboard */}
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.Dashboard} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/details" element={<DashboardDetails />} />
          </Route>

          {/* user-management */}
          <Route path="/user-management" element={<MainUserManagement />} />
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.CustomerList} />}>
            <Route path="/user-management/customer" element={<CustomerManagement />} />
          </Route>
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.StaffList} />}>
            <Route path="/user-management/staff" element={<StaffManagement />} />
          </Route>
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.StaffPermission} />}>
            <Route path="/user-management/staff-permission" element={<PermissionManagement />} />
          </Route>

          {/* product-management */}
          <Route path="/product-management" element={<MainProductManagement />} />
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.AllProduct} />}>
            <Route path="/product-management/product" element={<ProductManagement />} />
            <Route path="/product-management/product-info/:productID" element={<ProductInfo />} />
          </Route>
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.Category} />}>
            <Route path="/product-management/category" element={<Category />} />
          </Route>
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.Brand} />}>
            <Route path="/product-management/brand" element={<Brand />} />
          </Route>
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.Tags} />}>
            <Route path="/product-management/tags" element={<Tags />} />
          </Route>
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.Option} />}>
            <Route path="/product-management/option" element={<OptionManagement />} />
            <Route path="/product-management/option-info/:optionGroupID" element={<OptionInformation />} />
            <Route path="/product-management/option-update/:optionGroupID" element={<UpdateOption />} />
          </Route>

          {/* inventory-management */}
          <Route path="/inventory-management" element={<MainInventoryManagement />} />
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.InventoryAllProduct} />}>
            <Route path="/inventory-management/product" element={<InventoryManagement />} />
          </Route>
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.InventoryTransaction} />}>
            <Route path="/inventory-management/transection" element={<Transection />} />
          </Route>

          {/* order-management */}
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.Order} />}>
            <Route path="/order-management" element={<OrderManagement />} />
          </Route>

          {/* cms */}
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.Cms} />}>
            <Route path="/cms" element={<CMS />} />
          </Route>

          {/* setting */}
          <Route path="" element={<ProtectedRoutePermission permission={E_Permission.Setting} />}>
            <Route path="/setting" element={<Setting />} />
          </Route>

          {/* user-info */}
          <Route path="/user-info" element={<UserInfo />} />
        </Route>
      </Routes>

      <ModalDialog />
      <ModalExpanseImage />
      <Loading />
      <LoadingMasterData />
    </>
  )
}

export default App
