// Lib
import { useState } from 'react'

const useMenuList = (anchorElList: null[]) => {
  const [anchorEl, setAnchorEl] = useState<(null | HTMLElement)[]>(anchorElList)
  const open = (index: number) => Boolean(anchorEl[index])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.stopPropagation()

    const cloneAnchor = [...anchorEl]
    cloneAnchor[index] = event.currentTarget

    setAnchorEl(cloneAnchor)
  }

  const handleClose = (index: number) => {
    const cloneAnchor = [...anchorEl]
    cloneAnchor[index] = null

    setAnchorEl(cloneAnchor)
  }

  return { anchorEl, setAnchorEl, open, handleClick, handleClose }
}

export default useMenuList
