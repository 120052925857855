// Lib
import React from 'react'

// Include in project
import { Chip } from 'component'
import { E_Is_Active, E_Payment_Status, E_Order_Status, E_Delivery_Status, E_Pricing_Model } from 'utils/generated'

export interface Props {
  type: string | boolean
}
const ChipByStatus: React.FC<Props> = ({ type }): JSX.Element => {
  switch (type) {
    case E_Is_Active.True:
      return <Chip label="Active" color="success" />

    case E_Is_Active.False:
      return <Chip label="Inactive" color="danger" />

    case E_Delivery_Status.Pedding:
      return <Chip label="Pedding" color="gray" />

    case E_Delivery_Status.WaitingPacking:
      return <Chip label="Waiting Packing" color="grayOutlined" />

    case E_Delivery_Status.WaitingDelivering:
      return <Chip label="Waiting Delivering" color="ligthGray" />

    case E_Delivery_Status.Finish:
      return <Chip label="Finish" color="success" />

    case E_Payment_Status.Paid:
      return <Chip label="Paid" color="success" />

    case E_Payment_Status.Unpaid:
      return <Chip label="Unpaid" color="danger" />

    case E_Order_Status.TimeoutPaid:
      return <Chip label="Timeout Paid" color="ligthGray" />

    case E_Order_Status.WaitingPaid:
      return <Chip label="Waiting Paid" color="dark" />

    case E_Order_Status.WaitingApproval:
      return <Chip label="Waiting Approval" color="warning" />

    case E_Order_Status.Approved:
      return <Chip label="Approval" color="success" />

    case E_Order_Status.Cancelled:
      return <Chip label="Cancelled" color="gray" />

    case E_Order_Status.Fail:
      return <Chip label="Fail" color="danger" />

    case E_Pricing_Model.StandardPricing:
      return <Chip label="Standard Pricing" color="dark" />

    case E_Pricing_Model.VolumePricing:
      return <Chip label="Volume Pricing" color="gray" />

    default:
      return <></>
  }
}

export default ChipByStatus
