import { useMutation, useQuery } from '@apollo/client'
import { CMS_COMPANY_INFO, UPDATE_CMS } from 'queries/cms'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'
import { CmsCompany, QueryCmsCompanyInfoArgs } from 'utils/generatedNonAuth'

const useCMS = (variables?: QueryCmsCompanyInfoArgs) => {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(CMS_COMPANY_INFO, {
    fetchPolicy: 'no-cache',
    variables: variables,
    context: { clientName: 'endpointNonAuth' },
  })

  const [updateCms, optionUpdate] = useMutation(UPDATE_CMS, { context: { clientName: 'endpointAdmin' } })

  const isLoading = loading || optionUpdate.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.cmsCompanyInfo?.res_code !== EResCode.Success)
      return JSON.stringify(errorQuery || data?.cmsCompanyInfo?.res_desc)

    // optionUpdate
    if (
      optionUpdate?.error ||
      (optionUpdate.data?.updateCMSCompany?.res_code &&
        optionUpdate.data?.updateCMSCompany?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateCMSCompany?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { data: data?.cmsCompanyInfo as CmsCompany, refetch, updateCms, isLoading }
}

export default useCMS
