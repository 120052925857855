// Lib
import React from 'react'
import { useFormikContext, withFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

// Include in project
import styles from './index.module.scss'
import Layout from 'layouts'
import yupTransection from 'validations/yupTransection'
import { ModalAddStock } from 'container'
import { menuHeaderInventory } from 'utils/menu'
import { useLoadingControl, useModal, useProductSKU, useTransection, useInfiniteScroll } from 'hooks'
import { Table } from 'component'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { Maybe, E_Trigger_Type, ProductSku } from 'utils/generated'
import { pageSize } from 'configs'
import SearchBox, { TOptionkey } from 'container/SearchBox'

// Images
import { IosShare, Search } from '@mui/icons-material'

export interface IIventoryManagement {
  formInput: {
    triggerType: E_Trigger_Type
    qty: number
    note: string
  } & ProductSku
  filter: {
    key: string
    value: string
    sku: Maybe<string>
  }
}

const intitialValue: IIventoryManagement = {
  filter: {
    key: 'sku',
    value: '',
    sku: '',
  },
  formInput: {
    productSKUID: '',
    product: null,
    inventory: null,
    triggerType: E_Trigger_Type.GoodsReceipt,
    qty: 0,
    note: '',
    pricing: [
      {
        priceModelStandard: {
          defaultPrice: 0,
          salePrice: 0,
        },
      },
    ],
  },
}

const InventoryManagement: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<IIventoryManagement>()

  const navigate = useNavigate()
  const { data, isLoading, refetch } = useProductSKU({
    filter: {
      productSKUID: null,
      productSKU: values.filter?.sku || null,
    },
    pageSize: pageSize,
    nextToken: null,
  })
  const { dataList, handleResetCount } = useInfiniteScroll({
    result: data,
    refetch: refetch,
    variables: {
      filter: {
        productSKUID: null,
        productSKU: values.filter?.sku || null,
      },
      pageSize: pageSize,
      nextToken: data?.nextToken || null,
    },
    filter: [values.filter?.sku],
    key: 'productSKUList',
  })
  const { isLoading: loadingTransaction, createTransection } = useTransection({
    filter: {
      productSKUID: null,
    },
    pageSize: pageSize,
    nextToken: null,
  })
  useLoadingControl(isLoading || loadingTransaction)

  const { isModalOpen, handleOpen, handleClose } = useModal(intitialValue.formInput)

  const refreshProductSKU = () => {
    handleResetCount()
    refetch()
  }

  const dataToTable = transformationDataIntoTable({
    dataList: dataList || [],
    type: ETranformationDataIntoTable.InventoryManagementList,
    handleOpen: handleOpen,
  })

  const optionKey: TOptionkey = [
    {
      value: 'sku',
      label: 'Sku',
      child: {
        type: 'TEXT_FIELD',
      },
    },
  ]

  return (
    <Layout headerSectionName="Inventory Management" headerSectionManu={menuHeaderInventory}>
      <div className={styles.container}>
        <div className={styles.wrapperButton}>
          <div className={styles.box}>
            <SearchBox optionKey={optionKey} />
          </div>
          {/* <Button typeButton="button" type="gray" size="small" name="Export" startIcon={<IosShare />} /> */}
        </div>

        <Table
          objectKey={{
            productImgURLHTML: 'Image',
            productNameHTML: 'Name',
            productSKU: 'Sku',
            brandHTML: 'Brand',
            stockAccumulatedHTML: 'In Stock',
            committedHTML: 'Committed',
            incomingHTML: 'Incoming',
            availableHTML: 'Available',
            costPerPcsHTML: 'Cost Per Pcs.',
            stockValueHTML: 'Stock Value (฿)',
            isActiveHTML: 'Status',
            action: 'Action',
          }}
          data={dataToTable}
          handleClick={(data) => navigate(`/inventory-management/transection?productSKUID=${data.productSKUID}`)}
        />
      </div>
      <ModalAddStock
        open={isModalOpen}
        handleClose={handleClose}
        refetch={refreshProductSKU}
        createTransection={createTransection}
      />
    </Layout>
  )
}

const EnhancedInventoryManagement = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  validationSchema: yupTransection,
  validateOnMount: true,
  handleSubmit: () => {
    console.log('Skip')
  },
})(InventoryManagement)

export default EnhancedInventoryManagement
