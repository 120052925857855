import axiosBase from 'axios'
import { BASE_URL } from 'configs'

const endpoint = BASE_URL

const axiosInstance = axiosBase.create({
  baseURL: endpoint,
})

export { axiosInstance }
