import { useLazyQuery } from '@apollo/client'
import { PRODUCT_LIST } from 'queries/product'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'

const useProductList = () => {
  const [getProductList, optionQuery] = useLazyQuery(PRODUCT_LIST, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'endpointNonAuth' },
  })

  const isLoading = optionQuery.loading

  const errMsg = () => {
    // Query PRODUCT_LIST
    if (
      optionQuery?.error ||
      (optionQuery.data?.productList?.res_code && optionQuery.data?.productList?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionQuery?.error || optionQuery.data?.productList?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { isLoading, getProductList }
}

export default useProductList
