// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import { NodeModel } from '@minoru/react-dnd-treeview'

//Images
import folderSVG from 'images/categories/folder.svg'
import moveSVG from 'images/categories/move.svg'
import { KeyboardArrowDownRounded } from '@mui/icons-material'

// Include in project
import styles from './index.module.scss'
import { ICategory } from 'pages/Category'
import { Category as GenerateInterfaceCategory } from 'utils/generatedNonAuth'

interface Props {
  node: NodeModel<GenerateInterfaceCategory>
  depth: number
  isOpen: boolean
  onToggle: () => void
  handleRef: any
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  setPoint: React.Dispatch<React.SetStateAction<{ x: number; y: number; id: string | number }>>
  selectFolder: string | number
  setSelectFolder: React.Dispatch<React.SetStateAction<string | number>>
}

const BranchCategory: React.FC<Props> = ({
  node,
  depth,
  isOpen,
  handleRef,
  onToggle,
  setShow,
  setPoint,
  selectFolder,
  setSelectFolder,
}: Props): JSX.Element => {
  const { values } = useFormikContext<ICategory>()

  const handleToggle = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    node: NodeModel<unknown>,
    onToggle: (id: string | number) => void,
  ) => {
    e.stopPropagation()
    onToggle(node.id)
  }

  const groupBy = (items: NodeModel<GenerateInterfaceCategory>[], key: string) => {
    const result = items.reduce(
      (result: any, item: any) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {},
    )

    return result
  }

  return (
    <div
      className={styles.container}
      aria-checked={node.id === selectFolder}
      onClick={() => setSelectFolder(node.id)}
      onContextMenu={(e) => {
        e.preventDefault()
        setShow(true)
        setPoint({ x: e.pageX, y: e.pageY, id: node.id })
      }}
      style={{ paddingLeft: depth * 24 }}
    >
      <div className={styles.wrapper}>
        <div className={styles.expandIconWrapper} aria-expanded={isOpen}>
          {groupBy(values.categoryList, 'parent')[node.id]?.length > 0 && (
            <div onClick={(e) => handleToggle(e, node, onToggle)}>
              <KeyboardArrowDownRounded />
            </div>
          )}
        </div>
        <img src={folderSVG} alt="" />
        <p>{node.text}</p>
      </div>
      <img className={styles.icon} ref={handleRef} src={moveSVG} alt="" />
    </div>
  )
}

export default BranchCategory
