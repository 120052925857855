// Lib
import { useFormikContext } from 'formik'
import { useState } from 'react'

const useModalForm = (initialObject?: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { setFieldValue } = useFormikContext()

  const handleOpen = (data?: any) => {
    if (data) setFieldValue('formInput', data)
    else if (initialObject) setFieldValue('formInput', initialObject)
    setIsModalOpen(true)
  }

  const handleClose = () => {
    if (initialObject) setFieldValue('formInput', initialObject)
    setIsModalOpen(false)
  }

  return { isModalOpen, handleClose, handleOpen }
}

export default useModalForm
