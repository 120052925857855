import { useMutation } from '@apollo/client'
import { UPDATE_SKU } from 'queries/productSKU'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'

const useProductSKUMutation = () => {
  const [updateSKU, optionUpdate] = useMutation(UPDATE_SKU, { context: { clientName: 'endpointAdmin' } })
  const isLoading = optionUpdate.loading

  const errMsg = () => {
    // optionUpdate
    if (
      optionUpdate?.error ||
      (optionUpdate.data?.updateProductSKU?.res_code &&
        optionUpdate.data?.updateProductSKU?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateProductSKU?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { updateSKU, isLoading }
}

export default useProductSKUMutation
