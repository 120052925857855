import { gql } from '@apollo/client'

export const COMPANY_INFO = gql`
  query companyInfo {
    companyInfo {
      res_code
      res_desc
      companyID
      companyType
      companyName
      companyNameTH
      customAttribute
      isActive
      logoURL
      country
      taxID
      tel
      email
      createdAt
      updatedAt
    }
  }
`
