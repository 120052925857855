// Lib
import React from 'react'
import { withFormik } from 'formik'

//Image
import editeSVG from 'images/commons/editicon.svg'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { useUserInfo, useLoadingControl } from 'hooks'
import { User, E_Is_Active } from 'utils/generated'
import { useModal } from 'hooks'
import { ModalUserEdit, FormInput } from 'container'
import { Button } from 'component'
import { IRenderInput } from 'utils/interface'
import { convertDateLL } from 'utils/convertDate'

export interface IUserInfo {
  formInput: {
    profileImgURLShow: (string & File)[]
  } & User
}

const intitialValue: IUserInfo = {
  formInput: {
    profileImgURL: [],
    profileImgURLShow: [],
    isActive: E_Is_Active.True,
    firstname: '',
    surname: '',
    email: '',
    phoneNumber: '',
    birthday: '',
    idCard: '',
  },
}

const UserInfo: React.FC = () => {
  const { isModalOpen, handleOpen, handleClose } = useModal(intitialValue.formInput)

  const { data: userInfo, isLoading, updateUserInfo, refetch } = useUserInfo()
  useLoadingControl(isLoading)

  const fieldInputs: IRenderInput[] = [
    {
      heading: 'Profile',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: userInfo?.profileImgURL || [],
      readonly: true,
      multiple: false,
    },
    {
      heading: 'Profile',
      type: 'CHIP_BY_STATUS',
      chipType: userInfo?.isActive,
    },
    {
      heading: 'name',
      type: 'TEXT',
      words: userInfo?.firstname + ' ' + userInfo?.surname,
    },
    {
      heading: 'Phone number',
      type: 'TEXT',
      words: userInfo?.phoneNumber,
    },
    {
      heading: 'Birthday',
      type: 'TEXT',
      words: convertDateLL(userInfo?.birthday),
    },
    {
      heading: 'ID Card',
      type: 'TEXT',
      words: userInfo?.idCard,
    },
  ]

  return (
    <Layout hiddenNavigateMenu hiddenHeaderSection>
      <div className={styles.container}>
        <div className={styles.wrapperForm}>
          <section className={styles.header}>
            <h5>User Details</h5>
            <div>
              <Button
                typeButton="submit"
                type="gray"
                size="small"
                name="Edit User Info"
                startIcon={<img src={editeSVG} />}
                functionOnClick={() =>
                  handleOpen?.({
                    ...userInfo,
                    profileImgURLShow: userInfo.profileImgURL,
                  })
                }
              />
            </div>
          </section>

          <FormInput title="User Info" fieldInputs={fieldInputs} />
        </div>

        <ModalUserEdit open={isModalOpen} handleClose={handleClose} updateUser={updateUserInfo} refetch={refetch} />
      </div>
    </Layout>
  )
}

const EnhancedUserInfo = withFormik({
  mapPropsToValues: () => ({ ...intitialValue }),
  handleSubmit: () => {
    console.log('Skip')
  },
})(UserInfo)

export default EnhancedUserInfo
