import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Brand } from 'utils/generated'
import { IOption } from 'utils/interface'

const initialState: {
  brandList: Brand[]
  brandOptionList: IOption[]
  refetch: (() => void) | null
} = {
  brandList: [],
  brandOptionList: [],
  refetch: null,
}

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    setBrand: (
      state,
      action: PayloadAction<{
        items: Brand[]
        refetch: () => void
      }>,
    ) => {
      state.brandList = action.payload.items
      state.brandOptionList = action.payload.items.map((data) => ({
        value: data.brandID as string,
        label: data.name as string,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setBrand } = brandSlice.actions

export default brandSlice.reducer
