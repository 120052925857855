// Lib
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

//Image
import { MoreHorizRounded } from '@mui/icons-material'

// Include in project
import styles from './index.module.scss'
import { useMenuList } from 'hooks'
import { HeaderInformation } from 'container'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import { ChipByStatus, MenuList, Switch, Notification } from 'component'
import { IProductInfo } from 'pages/ProductInfo'
import { convertDateLL } from 'utils/convertDate'
import { E_Is_Active, MutationUpdateProductArgs } from 'utils/generated'
import { convertCurrencyFormatDecimal } from 'utils/convertMoney'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { EResCode } from 'utils/other'
import type { RootState } from 'states/store'
import { s3URL } from 'configs'

interface Props {
  updateProduct: ({ variables }: { variables: MutationUpdateProductArgs }) => Promise<any>
  handleOpenModal: (data: any) => void
}

const HeadOfProductInfo: React.FC<Props> = ({ updateProduct, handleOpenModal }) => {
  const { values } = useFormikContext<IProductInfo>()
  const { anchorEl, open, handleClose, handleClick } = useMenuList([null])
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const tagsReducer = useSelector((state: RootState) => state.tags)
  const productReducer = useSelector((state: RootState) => state.product)

  const mapperTagID = values.productInfo.tagID?.map((tag) =>
    tagsReducer.tagsOptionList?.find((ele) => ele.value === tag),
  )

  const findAvailableStock = values.productInfo?.productSKU?.reduce(
    (prev, curr) => prev + (curr?.inventory?.stockAvailable as number),
    0,
  )

  const checkStatusDelete = values.productInfo.isActive === E_Is_Active.True // True is Delete | False is Recovery
  const handleArchiveProduct = async () => {
    const packData = await transformationDataToServer({
      values: values.productInfo,
      type: ETransformationDataToServer.ArchiveProduct,
    })

    updateProduct({ variables: packData as MutationUpdateProductArgs }).then((res) => {
      const resUpdate = res.data.updateProduct
      if (resUpdate.res_code !== EResCode.Success) return

      navigate('/product-management/product')
      productReducer?.refetch?.()
      enqueueSnackbar(
        <Notification title="Success" description={`${checkStatusDelete ? 'Archive' : 'Recovery'} product`} />,
        { variant: 'success' },
      )
    })
  }

  const imageSrc = values.productInfo?.productImgURL?.[0] ? `${s3URL}/${values.productInfo?.productImgURL?.[0]}` : ''

  return (
    <div className={styles.container}>
      <HeaderInformation section="Product Management" id={`${values.productInfo?.productID}`} />

      <section className={styles.box}>
        <div className={styles.wrapperGroup}>
          <div className={styles.wrapperImg}>
            <img src={imageSrc} alt="" />
            <div className={styles.wrapperText}>
              <p className="fontBold">{values.productInfo?.name}</p>
              <p>{convertCurrencyFormatDecimal(values.productInfo?.productSKU?.[0]?.pricing?.[0]?.cost || 0)} ฿</p>
            </div>
          </div>
          <div className={styles.wrapperText}>
            <p className={styles.text}>Created At</p>
            <p>{convertDateLL(values.productInfo?.createdAt)}</p>
          </div>
          <div className={styles.wrapperText}>
            <p className={styles.text}>Updated At</p>
            <p>{convertDateLL(values.productInfo?.updatedAt)}</p>
          </div>
          <div className={styles.wrapperText}>
            <p className={styles.text}>Available Stock</p>
            <p>{`${findAvailableStock} ${values.productInfo?.unitLabel}`}</p>
          </div>

          <div className={styles.wrapperText}>
            <p className={styles.text}>Status</p>
            <ChipByStatus type={values.productInfo?.isActive as E_Is_Active} />
          </div>

          <div className={styles.wrapperText}>
            <p className={styles.text}>Visible</p>
            <Switch checked={values.productInfo?.isVisible === E_Is_Active.True ? true : false} />
          </div>
        </div>

        <div className={styles.wrapperButton}>
          <button className="buttonIcon" onClick={(e) => handleClick(e, 0)}>
            <MoreHorizRounded />
          </button>

          <MenuList
            open={open(0)}
            anchorEl={anchorEl[0]}
            handleClose={() => handleClose(0)}
            listMenu={[
              {
                title: 'Edit Product',
                functionOnClick: () => {
                  handleClose(0)
                  handleOpenModal({
                    ...values.productInfo,
                    productImgURLShow: values.productInfo.productImgURL || [],
                    productFileURLShow: values.productInfo.productFileURL || [],
                    tagID: mapperTagID?.map((tag) => tag),
                  })
                },
              },
              {
                title: `${checkStatusDelete ? 'Archive' : 'Recovery'} product`,
                functionOnClick: () => {
                  handleClose(0)
                  dispatch(
                    setOpenDialog({
                      title: `${checkStatusDelete ? 'Archive' : 'Recovery'} product`,
                      description: `Product ${values.productInfo.name} will be ${
                        checkStatusDelete ? 'archive' : 'recovery'
                      }.`,
                      status: checkStatusDelete ? 'danger' : 'info',
                      textButtonCancle: '',
                      textButtonConfirm: `${checkStatusDelete ? 'Archive' : 'Recovery'} product`,
                      functionOnSubmit: () => {
                        handleArchiveProduct()
                        dispatch(setCloseDialog())
                      },
                    }),
                  )
                },
              },
            ]}
          />
        </div>
      </section>
    </div>
  )
}

export default HeadOfProductInfo
