import { gql } from '@apollo/client'

export const PRODUCT_SKU_LIST = gql`
  query productSKUList($filter: ProductSKUFilter!, $pageSize: Int, $nextToken: String) {
    productSKUList(filter: $filter, pageSize: $pageSize, nextToken: $nextToken) {
      res_code
      res_desc
      nextToken
      items {
        companyID
        productSKUID
        productID
        productSKU
        productSKUName
        customAttribute
        optionAttribute
        isActive
        isVisible
        tagID
        remark
        product {
          productID
          brandID
          name
          description
          unitLabel
          productOptionGroupID
          productImgURL
          productFileURL
          isVisible
          isActive
          tagID
          customAttribute
          minimumStock
          prefix
          remark
          brand {
            brandID
            name
            description
            brandImgURL
            customAttribute
            isActive
          }
        }
        pricing {
          productSKUID
          pricingID
          companyID
          productID
          pricingModel
          priceModelStandard {
            paymentType
            defaultPrice
            salePrice
          }
          priceModelVolumn {
            paymentType
            tableVolumnPrice {
              startUnit
              endUnit
              salePrice
            }
          }
          cost
          currency
          customAttribute
        }
        inventory {
          productSKUID
          transactionNo
          companyID
          productID
          orderID
          triggerType
          stockIncoming
          stockCommitted
          stockAvailable
          stockAccumulated
          customAttribute
        }
      }
    }
  }
`

export const UPDATE_SKU = gql`
  mutation updateProductSKU($input: [UpdateProductSKU!]!) {
    updateProductSKU(input: $input) {
      res_code
      res_desc
    }
  }
`
