import { useMutation, useQuery } from '@apollo/client'
import { SETTING_COMPANY_INFO, UPDATE_SETTING_COMPANY } from 'queries/settingCompanyInfo'
import { useErrMsgControl } from 'hooks'
import { SettingCompany } from 'utils/generated'
import { EResCode } from 'utils/other'

function useSettingCompanyInfo() {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(SETTING_COMPANY_INFO, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'endpointAdmin' },
  })
  const [updateSetting, optionUpdate] = useMutation(UPDATE_SETTING_COMPANY, {
    context: { clientName: 'endpointAdmin' },
  })

  const isLoading = loading || optionUpdate.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.settingCompanyInfo?.res_code !== EResCode.Success)
      return JSON.stringify(errorQuery || data?.settingCompanyInfo?.res_desc)

    // optionUpdate
    if (
      optionUpdate?.error ||
      (optionUpdate.data?.settingCompanyInfo?.res_code &&
        optionUpdate.data?.settingCompanyInfo?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.settingCompanyInfo?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { data: data?.settingCompanyInfo as SettingCompany, refetch, isLoading, updateSetting }
}

export default useSettingCompanyInfo
