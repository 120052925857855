// Lib
import React from 'react'
import { getIn, useFormikContext } from 'formik'
import { SvgIconComponent } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'

// Include in project
import styles from './index.module.scss'
import Color from 'abstracts/export.module.scss'
import Font from 'abstracts/export.module.scss'

interface Props {
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  dataAdornment?: SvgIconComponent
  require?: boolean
}

const TextFieldDateComponent: React.FC<Props> = (props: Props) => {
  const { values, touched, errors, handleChange } = useFormikContext()

  return (
    <div className={styles.wapper}>
      <p className="fontBold">
        {props.label}
        <span className="colorRed">{props.require && '*'}</span>
      </p>
      <TextField
        InputProps={{
          sx: {
            fontSize: Font.fontSizeP,
            backgroundColor: Color.grey100,
            borderRadius: '8px',
            fontFamily: Font.fontFamilyRegular,
          },
          endAdornment: (
            <InputAdornment position="end">{props.dataAdornment && <props.dataAdornment />}</InputAdornment>
          ),
        }}
        size="small"
        name={props.name}
        type="date"
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={getIn(values, props.name)}
        onChange={handleChange}
        error={getIn(touched, props.name) && Boolean(getIn(errors, props.name))}
        helperText={
          <span className="colorRed">
            {getIn(touched, props.name) && getIn(errors, props.name) && getIn(errors, props.name)}
          </span>
        }
        fullWidth
      />
    </div>
  )
}

export default TextFieldDateComponent
