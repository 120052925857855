// Lib
import React, { useEffect } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Form, useFormikContext, withFormik } from 'formik'

// Include in project
import styles from './index.module.scss'
import { EResCode } from 'utils/other'
import { useForgotPassword, useLoadingControl } from 'hooks'
import {
  ForgotPasswordStepOne,
  ForgotPasswordStepTwo,
  ForgotPasswordStepThree,
  ForgotPasswordStepFour,
} from 'container'

//Images
import imageLoginSVG from 'images/login/imageLogin.png'
import { ArrowBackRounded } from '@mui/icons-material'
import yupForgotPassword from 'validations/yupForgotPassword.validate'

export interface IForgotPassword {
  step: number
  otpCode: string
  email: string
  password: string
  passwordConfirm: string
  companyID: string
}

const intitialValue: IForgotPassword = {
  step: 1,
  otpCode: '',
  password: '',
  passwordConfirm: '',
  email: '',
  companyID: '',
}

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate()
  const { values, errors, setFieldValue } = useFormikContext<IForgotPassword>()
  const {
    forgotPassword,
    dataForgotPassword,
    forgotPasswordVerify,
    dataForgotPasswordVerify,
    resetNewPassword,
    dataResetNewPassword,
    isLoading,
  } = useForgotPassword()
  useLoadingControl(isLoading)

  const handleNextStep = () => {
    if (!_.isEmpty(errors)) return
    if (values.step === 1) {
      const packData = {
        companyID: values.companyID,
        email: values.email,
      }
      forgotPassword(packData)
    } else if (values.step === 2) {
      const packData = {
        companyID: values.companyID,
        email: values.email,
        OTPCode: values.otpCode,
      }
      forgotPasswordVerify(packData)
    } else if (values.step === 3) {
      const packData = {
        companyID: values.companyID,
        email: values.email,
        newPassword: values.password,
      }
      resetNewPassword(packData)
    }
  }

  useEffect(() => {
    if (!dataForgotPassword) return
    if (dataForgotPassword.status !== 200) return
    if (dataForgotPassword.data.res_code !== EResCode.Success) return

    setFieldValue('step', 2)
  }, [dataForgotPassword])

  useEffect(() => {
    if (!dataForgotPasswordVerify) return
    if (dataForgotPasswordVerify.status !== 200) return
    if (dataForgotPasswordVerify.data.res_code !== EResCode.Success) return

    setFieldValue('step', 3)
  }, [dataForgotPasswordVerify])

  useEffect(() => {
    if (!dataResetNewPassword) return
    if (dataResetNewPassword.status !== 200) return
    if (dataResetNewPassword.data.res_code !== EResCode.Success) return

    setFieldValue('step', 4)
  }, [dataResetNewPassword])

  const ChangeContainer = () => {
    switch (values.step) {
      case 1:
        return <ForgotPasswordStepOne handleNextStep={handleNextStep} />
      case 2:
        return <ForgotPasswordStepTwo forgotPassword={forgotPassword} handleNextStep={handleNextStep} />
      case 3:
        return <ForgotPasswordStepThree handleNextStep={handleNextStep} />
      case 4:
        return <ForgotPasswordStepFour />
      default:
        return <></>
    }
  }

  return (
    <Form className={styles.container}>
      <section className={styles.leftItem}>
        <img className={styles.photo} src={imageLoginSVG} alt="" />
      </section>
      <section className={styles.rightItem}>
        <section className={styles.header} onClick={() => navigate(-1)}>
          <div className={styles.icon}>
            <ArrowBackRounded />
          </div>
          <p className={styles.text}>Back to login</p>
        </section>
        <section className={styles.wrapperContainer}>{ChangeContainer()}</section>
        <p className={styles.textFooter}>version 0.1.0</p>
      </section>
    </Form>
  )
}

const ForgotPasswordPage = withFormik({
  mapPropsToValues: () => ({ ...intitialValue }),
  validateOnMount: true,
  validationSchema: yupForgotPassword,
  handleSubmit: () => {
    console.log('Skip')
  },
})(ForgotPassword)

export default ForgotPasswordPage
