// Lib
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// Include in project
import { useMasterData } from 'hooks'
import { setUserRole } from 'states/slice/userRole'
import { setTags } from 'states/slice/tags'
import { setBrand } from 'states/slice/brand'
import { setOptionGroup } from 'states/slice/optionGroup'
import { setProduct } from 'states/slice/product'
import { setCompany } from 'states/slice/company'
import type { RootState } from 'states/store'
import { pageSizeUnlimit } from 'configs'
import { Brand, Product, ProductOptionGroup, Tag, UserRoleCompany } from 'utils/generated'

const useIntitialMasterData = () => {
  const dispatch = useDispatch()
  const decodeTokenReducer = useSelector((state: RootState) => state.decodeToken)

  const {
    dataCompany,
    refetchCompany,

    dataBrand,
    refetchBrand,

    dataTags,
    refetchTags,

    dataUserRole,
    refetchUserRole,

    dataOption,
    refetchOption,

    dataProduct,
    refetchProduct,

    isLoading,
  } = useMasterData({
    brandVariables: {
      filter: {
        brandID: null,
        isActive: null,
      },
      pageSize: pageSizeUnlimit,
      nextToken: null,
    },
    tagsVariables: {
      filter: {
        tagID: null,
        isActive: null,
      },
      pageSize: pageSizeUnlimit,
      nextToken: null,
    },
    userRoleVariables: {
      filter: {
        userRoleCompanyID: null,
      },
      pageSize: pageSizeUnlimit,
      nextToken: null,
    },
    optionVariables: {
      filter: {
        companyID: decodeTokenReducer.companyID,
        productOptionGroupID: null,
        isActive: null,
      },
      pageSize: pageSizeUnlimit,
      nextToken: null,
    },
    productVariables: {
      companyID: decodeTokenReducer.companyID,
      filter: {
        name: null,
        isActive: null,
        tagName: null,
      },
      pageSize: pageSizeUnlimit,
      nextToken: null,
    },
  })

  useEffect(() => {
    if (!dataCompany) return

    dispatch(setCompany({ companyInfo: dataCompany, refetch: refetchCompany }))
  }, [dataCompany])

  useEffect(() => {
    if (!dataBrand) return

    dispatch(setBrand({ items: (dataBrand?.items as Brand[]) || [], refetch: refetchBrand }))
  }, [dataBrand])

  useEffect(() => {
    if (!dataTags) return

    dispatch(setTags({ items: (dataTags?.items as Tag[]) || [], refetch: refetchTags }))
  }, [dataTags])

  useEffect(() => {
    if (!dataUserRole) return

    dispatch(setUserRole({ items: (dataUserRole?.items as UserRoleCompany[]) || [], refetch: refetchUserRole }))
  }, [dataUserRole])

  useEffect(() => {
    if (!dataOption) return

    dispatch(setOptionGroup({ items: (dataOption?.items as ProductOptionGroup[]) || [], refetch: refetchOption }))
  }, [dataOption])

  useEffect(() => {
    if (!dataProduct) return

    dispatch(setProduct({ items: (dataProduct?.items as Product[]) || [], refetch: refetchProduct }))
  }, [dataProduct])

  return { isLoading }
}

export default useIntitialMasterData
