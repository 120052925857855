// Lib
import React from 'react'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { Divider } from 'component'
import { CardLeaderborads } from 'container'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { IDashboard } from 'pages/Dashboard'

const LeaderboradsSection: React.FC = () => {
  const { values } = useFormikContext<IDashboard>()

  const dataProductsTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.DashboradTable,
    dataList: values.leaderBoardsProducts?.items,
  })

  const dataCustomersTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.DashboradTable,
    dataList: values.leaderBoardsCustomers?.items,
  })

  const dataInventoryTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.DashboradTable,
    dataList: values.leaderBoardsInventory?.items,
  })
  return (
    <div className={styles.container}>
      <h4>Leaderborads</h4>
      <Divider />

      <div className={styles.wrapperCard}>
        <CardLeaderborads section="PRODUCTS" dataList={dataProductsTable as []} />
        <CardLeaderborads section="CUSTOMERS" dataList={dataCustomersTable as []} />
        <CardLeaderborads section="INVENTORY" dataList={dataInventoryTable as []} />
      </div>
    </div>
  )
}

export default LeaderboradsSection
