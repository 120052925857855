import * as Yup from 'yup'

const yupCreateStaff = Yup.object().shape({
  formInput: Yup.object().shape({
    firstname: Yup.string().required('Plese Enter'),
    surname: Yup.string().required('Plese Enter'),
    idCard: Yup.string().required('Plese Enter'),
    email: Yup.string().required('Plese Enter'),
    phoneNumber: Yup.string().required('Plese Enter'),
    userRoleCompanyID: Yup.string().required('Plese Enter'),
    brandImgURLShow: Yup.array().min(1, 'Please Upload Image'),
    password: Yup.string().required('Plese Enter'),
  }),
})

export default yupCreateStaff
