// Lib
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useFormikContext, withFormik } from 'formik'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { menuHeaderProduct } from 'utils/menu'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { Table, Button, ButtonFilterIsActive, Notification } from 'component'
import { useOptionMutation, useLoadingControl } from 'hooks'
import type { RootState } from 'states/store'
import { ProductOptionGroup, MutationUpdateProductOptionGroupArgs, E_Is_Active } from 'utils/generated'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { EResCode } from 'utils/other'

// Images
import { AddCircle, IosShare } from '@mui/icons-material'

export interface IOptionManagement {
  filter: {
    isActive: E_Is_Active | 'ALL'
  }
}

const intitialValue: IOptionManagement = {
  filter: {
    isActive: E_Is_Active.True,
  },
}

const OptionManagement: React.FC = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { values } = useFormikContext<IOptionManagement>()

  const optionGroupReducer = useSelector((state: RootState) => state.optionGroup)

  const { isLoading, updateOption } = useOptionMutation()
  useLoadingControl(isLoading)

  const optionListAfterFilter = optionGroupReducer?.optionGroupList?.filter((ele) => {
    if (values.filter.isActive === 'ALL') return true
    return ele.isActive === values.filter.isActive
  })

  const dataTable = transformationDataIntoTable({
    dataList: optionListAfterFilter || [],
    type: ETranformationDataIntoTable.OptionManagementList,
    handleOpen: (data) => navigate(`/product-management/option-update/${data.productOptionGroupID}`),
    handleArchive: (data) => handleArchiveOption(data),
  })

  const handleArchiveOption = async (data: ProductOptionGroup) => {
    const checkStatusDelete = data.isActive === E_Is_Active.True // True is Delete | False is Recovery
    const packData = await transformationDataToServer({
      values: data,
      type: ETransformationDataToServer.ArchiveOption,
    })

    updateOption({ variables: packData as MutationUpdateProductOptionGroupArgs }).then((res) => {
      const resUpdate = res.data.updateProductOptionGroup
      if (resUpdate.res_code !== EResCode.Success) return

      optionGroupReducer.refetch?.()
      enqueueSnackbar(
        <Notification title="Success" description={`${checkStatusDelete ? 'Archive' : 'Recovery'} option`} />,
        { variant: 'success' },
      )
    })
  }

  return (
    <Layout headerSectionName="Product Management" headerSectionManu={menuHeaderProduct}>
      <div className={styles.container}>
        <div className={styles.wrapperButton}>
          <ButtonFilterIsActive />
          {/* <Button typeButton="button" type="gray" size="small" name="Export" startIcon={<IosShare />} /> */}
          <Button
            typeButton="button"
            type="info"
            size="small"
            name="Add Product Option"
            startIcon={<AddCircle sx={{ color: 'white' }} />}
            functionOnClick={() => navigate(`/product-management/option-update/create`)}
          />
        </div>

        <Table
          objectKey={{
            name: 'Option Name',
            description: 'Description',
            isActiveHTML: 'Status',
            createdAt: 'Created At',
            updatedAt: 'Update At',
            action: '',
          }}
          data={dataTable}
          handleClick={(data) => navigate(`/product-management/option-info/${data.productOptionGroupID}`)}
        />
      </div>
    </Layout>
  )
}

const EnhancedOptionManagement = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  handleSubmit: () => {
    console.log('skip')
  },
})(OptionManagement)

export default EnhancedOptionManagement
