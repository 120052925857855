// Lib
import React, { useState } from 'react'
import { getIn, useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import _ from 'lodash'

// Include in project
import styles from './index.module.scss'
import { Modal, TextField, Notification, SelectSingle, Checkbox, Divider } from 'component'
import { EResCode, convertEnumToWord, findNameProvinceByEnum } from 'utils/other'
import { transformationDataToServer, ETransformationDataToServer } from 'utils/transformationDataToServer'
import {
  E_Is_Active,
  FreightModelProvince,
  Freight_Model,
  MutationUpdateSettingCompanyArgs,
  TableFreightProvince,
} from 'utils/generated'
import { ISetting } from 'pages/Setting'
import { TType } from 'container/SettingSection'

//Images
import AddIcon from '@mui/icons-material/Add'

interface Props {
  open: boolean
  handleClose: () => void
  updateSetting: ({ variables }: { variables: MutationUpdateSettingCompanyArgs }) => Promise<any>
  refetch: () => void
  section: TType | ''
}

const ModalEditSetting: React.FC<Props> = ({ open, handleClose, section, updateSetting, refetch }: Props) => {
  const { values, errors, setFieldValue } = useFormikContext<ISetting>()
  const { enqueueSnackbar } = useSnackbar()

  const handleUpdate = async () => {
    if (!_.isEmpty(errors)) return

    const packData = await transformationDataToServer({
      values: values,
      type: ETransformationDataToServer.UpdateSetting,
    })

    updateSetting({ variables: packData as MutationUpdateSettingCompanyArgs }).then((res) => {
      const resUpdate = res.data.updateSettingCompany
      if (resUpdate.res_code !== EResCode.Success) return

      refetch()
      enqueueSnackbar(<Notification title="Success" description="Update Setting Company." />, { variant: 'success' })
      handleClose()
    })
  }

  const RenderTitle = () => {
    switch (section) {
      case 'BRAND':
        return 'Brand Commerce'
      case 'STRIPE':
        return 'SMS Stripe'
      case 'EMAIL':
        return 'Email'
      case 'LINE_NOTIFY':
        return 'Line Notify'
      case 'FREIGHT':
        return 'Freight'
      default:
        return <></>
    }
  }

  const RenderProvinceFrieght = (province: FreightModelProvince, indexProvince: number) => {
    const [showTries, setShowTries] = useState(true)
    const freightProvince = values.formInput.freightModelProvince?.[indexProvince]
    const keyInput = `formInput.freightModelProvince[${indexProvince}]`

    // Update endUnit +10 of last tier
    // And push New Tier
    const handleAddNewTier = () => {
      const lastIndex = getIn(values, `${keyInput}.tableFreightProvince`).length - 1
      const lastTier = getIn(values, `formInput.freightModelProvince[${indexProvince}].tableFreightProvince`)[lastIndex]

      const clone = JSON.parse(JSON.stringify(getIn(values, `${keyInput}.tableFreightProvince`)))

      clone[lastIndex].endPrice = lastTier.startPrice + 10
      const newTier = {
        startPrice: lastTier.startPrice + 11,
        endPrice: null,
        freight: 0,
      }
      clone.push(newTier)

      setFieldValue(`${keyInput}.tableFreightProvince`, clone)
    }

    const handleChangeEndUnit = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const value = parseInt(e.target.value) || 0
      setFieldValue(`${keyInput}.tableFreightProvince[${index}].endPrice`, value)
      setFieldValue(`${keyInput}.tableFreightProvince[${index + 1}].startPrice`, value + 1)
    }
    return (
      <>
        <div>
          <div className="row align-center gapChips justify-space-between">
            <div className="row align-center gapChips">
              <Checkbox
                name={`${keyInput}.isFreightOnDelivery`}
                value=""
                checked={values.formInput.freightModelProvince?.[indexProvince]?.isFreightOnDelivery || false}
                label="FOD"
                onChange={() =>
                  setFieldValue(`${keyInput}.isFreightOnDelivery`, freightProvince?.isFreightOnDelivery ? false : true)
                }
              />
              <h6 key={indexProvince}>{findNameProvinceByEnum(province?.province)}</h6>
            </div>
            <p
              className={freightProvince?.isFreightOnDelivery ? '' : 'linkText'}
              onClick={() => !freightProvince?.isFreightOnDelivery && setShowTries(!showTries)}
            >
              ดู Tries
            </p>
          </div>
          {showTries && !freightProvince?.isFreightOnDelivery && (
            <>
              <div className="row justify-space-between">
                <h6>Tiers</h6>

                <div className={`row ${styles.groupAdd}`} onClick={() => handleAddNewTier()}>
                  <AddIcon />
                  <p>Add another tier</p>
                </div>
              </div>

              <table className={styles.table}>
                <thead>
                  <tr>
                    <th style={{ minWidth: '80px' }}></th>
                    <th style={{ minWidth: '80px' }}>First Price</th>
                    <th>Last Price</th>
                    <th>Fright Per Price</th>
                  </tr>
                </thead>
                <tbody>
                  {getIn(values, `${keyInput}.tableFreightProvince`).map(
                    (fright: TableFreightProvince, index: number) => (
                      <tr key={index}>
                        <th>Total price</th>
                        <td>{fright.startPrice}</td>
                        <td>
                          {getIn(values, `${keyInput}.tableFreightProvince`).length !== index + 1 ? (
                            <TextField
                              name={`${keyInput}.tableFreightProvince[${index}].endPrice`}
                              type="number"
                              onChange={(e) => handleChangeEndUnit(e, index)}
                            />
                          ) : (
                            '∞'
                          )}
                        </td>
                        <td>
                          <TextField name={`${keyInput}.tableFreightProvince[${index}].freight`} type="number" />
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
        <Divider />
      </>
    )
  }

  const RenderInputList = () => {
    switch (section) {
      case 'BRAND':
        return ListKeyBrand.map((data, index: number) => {
          if (data.typeInput === 'text' || data.typeInput === 'number') {
            return (
              <TextField
                name={data.name}
                type={data.typeInput as 'text' | 'number'}
                disabled={data.disabled}
                label={data.section}
                key={index}
              />
            )
          } else if (data.typeInput === 'select') {
            return (
              <SelectSingle
                key={index}
                label={data.section}
                name={data.name}
                disabled={data.disabled}
                option={[
                  {
                    label: 'Active',
                    value: E_Is_Active.True,
                  },
                  {
                    label: 'Inactive',
                    value: E_Is_Active.False,
                  },
                ]}
              />
            )
          } else return <></>
        })

      case 'STRIPE':
        return ListKeyStripeService.map((data, index: number) => (
          <TextField
            name={data.name}
            type={data.typeInput as 'text' | 'date' | 'number'}
            disabled={data.disabled}
            label={data.section}
            key={index}
          />
        ))

      case 'EMAIL':
        return ListKeyEmailService.map((data, index: number) => (
          <TextField
            name={data.name}
            type={data.typeInput as 'text' | 'date' | 'number'}
            disabled={data.disabled}
            label={data.section}
            key={index}
          />
        ))

      case 'LINE_NOTIFY':
        return ListKeyLineNotifyService.map((data, index: number) => (
          <TextField
            name={data.name}
            type={data.typeInput as 'text' | 'date' | 'number'}
            disabled={data.disabled}
            label={data.section}
            key={index}
          />
        ))

      case 'FREIGHT':
        return (
          <>
            <SelectSingle
              label="Freight Model"
              name="formInput.freightModel"
              option={[
                {
                  label: 'Fix Freight',
                  value: Freight_Model.FixFreight,
                },
                {
                  label: 'Province Freight',
                  value: Freight_Model.ProvinceFreight,
                },
              ]}
            />

            {values.formInput.freightModel === Freight_Model.FixFreight && (
              <TextField name="formInput.freightModelFix.freight" type="number" label="Freight" />
            )}

            <div className={`column ${styles.wrapperProvinceFreight}`}>
              {values.formInput.freightModel === Freight_Model.ProvinceFreight &&
                values.formInput.freightModelProvince?.map((province, index) => {
                  return (
                    <React.Fragment key={index}>
                      {RenderProvinceFrieght(province as FreightModelProvince, index)}
                    </React.Fragment>
                  )
                })}
            </div>
          </>
        )

      default:
        return <></>
    }
  }

  return (
    <Modal
      handleSave={handleUpdate}
      open={open}
      handleClose={handleClose}
      width={section === 'FREIGHT' ? '40%' : '30%'}
      height={section === 'FREIGHT' ? '700px' : 'auto'}
      title={`${RenderTitle()}`}
    >
      <div className={styles.container}>
        <section className={styles.form}>
          <div className={styles.card}>
            <h5>{RenderTitle()}</h5>
            {RenderInputList()}
          </div>
        </section>
      </div>
    </Modal>
  )
}

export default ModalEditSetting

const ListKeyStripeService = [
  {
    section: 'Success Path URL',
    name: 'formInput.stripeSuccessPathURL',
    typeInput: 'text',
    disabled: false,
  },
  {
    section: 'Cancel Path URL',
    name: 'formInput.stripeCancelPathURL',
    typeInput: 'text',
    disabled: false,
  },
  {
    section: 'Secret Key Production',
    name: 'formInput.stripeSecretKeyProd',
    typeInput: 'text',
    disabled: false,
  },
  {
    section: 'Secret Key Test',
    name: 'formInput.stripeSecretKeyTest',
    typeInput: 'text',
    disabled: false,
  },
]

const ListKeyBrand = [
  {
    section: 'Domain Name',
    name: 'formInput.domainName',
    typeInput: 'text',
    disabled: false,
  },
  {
    section: 'Tax (%)',
    name: 'formInput.tax',
    typeInput: 'number',
    disabled: false,
  },
  {
    section: 'Store (Open/Close)',
    name: 'formInput.isClose',
    typeInput: 'select',
    disabled: false,
  },
  {
    section: 'Timeout Cart (Day)',
    name: 'formInput.timeoutCart',
    typeInput: 'number',
    disabled: false,
  },
  {
    section: 'Timeout Paid (Day)',
    name: 'formInput.timeoutPaid',
    typeInput: 'number',
    disabled: false,
  },
]

const ListKeyEmailService = [
  {
    section: 'Sender Email',
    name: 'formInput.senderMail',
    typeInput: 'text',
    disabled: false,
  },
  {
    section: 'Sender Password',
    name: 'formInput.senderPassword',
    typeInput: 'text',
    disabled: false,
  },
]

const ListKeyLineNotifyService = [
  {
    section: 'Line Token Notify',
    name: 'formInput.lineNotifyToken',
    typeInput: 'text',
    disabled: false,
  },
]
