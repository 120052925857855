//Lib
import { useMutation, useQuery } from '@apollo/client'

//Include In Project
import { EResCode } from 'utils/other'
import { useErrMsgControl } from 'hooks'
import { QueryTransactionListArgs } from 'utils/generated'
import { CREATE_TRANSECTION, TRANSECTION_LIST, VOID_TRANSECTION } from 'queries/transection'

function useTransection(variables?: QueryTransactionListArgs) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(TRANSECTION_LIST, {
    fetchPolicy: 'no-cache',
    variables: variables,
    context: { clientName: 'endpointAdmin' },
  })

  const [createTransection, optionCreate] = useMutation(CREATE_TRANSECTION, {
    context: { clientName: 'endpointAdmin' },
  })

  const [voidTransection, optionVoid] = useMutation(VOID_TRANSECTION, {
    context: { clientName: 'endpointAdmin' },
  })

  const isLoading = optionCreate.loading || loading || optionVoid.loading

  const errMsg = () => {
    if (errorQuery || data?.transactionList?.res_code !== EResCode.Success)
      return JSON.stringify(errorQuery || data?.transactionList?.res_desc)

    // Create
    if (
      optionCreate.error ||
      (optionCreate.data?.createTransaction?.res_code &&
        optionCreate.data?.createTransaction?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionCreate.error || optionCreate.data?.createTransaction?.res_desc)

    //Void
    if (
      optionVoid.error ||
      (optionVoid.data?.voidTransaction?.res_code && optionVoid.data?.voidTransaction?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionVoid.error || optionVoid.data?.voidTransaction?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { isLoading, createTransection, data: data?.transactionList, refetch, voidTransection }
}

export default useTransection
