// Lib
import React from 'react'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { CardSummary, Table } from 'component'
import { IDashboard } from 'pages/Dashboard'
import { convertCurrencyFormatDecimal, convertCurrencyFormat } from 'utils/convertMoney'

//Images
import { PaidOutlined, ShoppingCartOutlined } from '@mui/icons-material'
import boxSVG from 'images/dashBoard/box.svg'

interface Props {
  section: string
  dataList: { no: JSX.Element; name: string; amount: number; total: number }[]
}

const CardLeaderborads: React.FC<Props> = ({ section, dataList }: Props) => {
  const { values } = useFormikContext<IDashboard>()

  const RenderCard = () => {
    switch (section) {
      case 'PRODUCTS':
        return (
          <>
            <div className={styles.wrapperCard}>
              <CardSummary
                title="Total Product"
                value={`${convertCurrencyFormat(values.leaderBoardsProducts?.totalProducts || 0)} Products`}
                subTitle={`Prod. Active Ratio: ${values.leaderBoardsProducts?.totalProductsRatio}%`}
              />
              <div className={styles.line} />
              <CardSummary
                title="Product Best Seller"
                value={values.leaderBoardsProducts?.productBestSeller || ''}
                subTitle={`Prod. Active Ratio: ${values.leaderBoardsProducts?.productBestSellerRatio}%`}
                isNotSplit
              />
              <div className={styles.line} />
              <CardSummary
                title="Category Best Seller"
                value={values.leaderBoardsProducts?.categoryBestSeller || ''}
                subTitle={`Prod. Active Ratio: ${values.leaderBoardsProducts?.categoryBestSellerRatio}%`}
                isNotSplit
              />
            </div>
            <h6 className={styles.title}>Top 10 : Product Best Seller</h6>

            <Table
              colSize={{
                no: '50px',
                name: 'auto',
                amount: 'auto',
                total: 'auto',
              }}
              objectKey={{
                no: '#',
                name: 'Product Name',
                amount: <ShoppingCartOutlined />,
                total: <PaidOutlined />,
              }}
              data={dataList}
            />
          </>
        )
      case 'CUSTOMERS':
        return (
          <>
            <div className={styles.wrapperCard}>
              <CardSummary
                title="Total Customer"
                value={`${convertCurrencyFormat(values.leaderBoardsCustomers?.totalCustomer || 0)} Customer`}
                subTitle={`Customer Active Ratio: ${values.leaderBoardsCustomers?.totalCustomerRatio}%`}
              />
              <div className={styles.line} />
              <CardSummary
                title="Avg. Revenue/Customer"
                value={`${convertCurrencyFormatDecimal(values.leaderBoardsCustomers?.averageCustomer || 0)} ฿/customer`}
                subTitle={`Revenue Ratio: ${values.leaderBoardsCustomers?.averageCustomerRatio}%`}
              />
            </div>
            <h6 className={styles.title}>Top 10 : Customer Loyalty</h6>

            <Table
              colSize={{
                no: '50px',
                name: 'auto',
                amount: 'auto',
                total: 'auto',
              }}
              objectKey={{
                no: '#',
                name: 'Customer Name',
                amount: <ShoppingCartOutlined />,
                total: <PaidOutlined />,
              }}
              data={dataList}
            />
          </>
        )
      case 'INVENTORY':
        return (
          <>
            <div className={styles.wrapperCard}>
              <img src={boxSVG} alt="" />
              <div className={styles.line} />
              <CardSummary
                title="Minimum Inventory Levels"
                value={`${convertCurrencyFormat(values.leaderBoardsInventory?.totalProductInventory || 0)} products`}
                subTitle={`Lower Inventory Levels: ${values.leaderBoardsInventory?.totalProductInventoryRatio}%`}
              />
            </div>
            <h6 className={styles.title}>Top 10 : Minimum Inventory Levels</h6>

            <Table
              colSize={{
                no: '50px',
                name: 'auto',
                amount: 'auto',
              }}
              objectKey={{
                no: '#',
                name: 'Product SKU',
                amount: <ShoppingCartOutlined />,
              }}
              data={dataList}
            />
          </>
        )
      default:
        break
    }
  }

  return (
    <div className={styles.container}>
      <h6 className={styles.text}>{section}</h6>
      {RenderCard()}
    </div>
  )
}

export default CardLeaderborads
