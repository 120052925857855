// Lib
import React, { useEffect } from 'react'
import { useFormikContext, withFormik } from 'formik'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { Table, DatePickers } from 'component'
import { PerformanceCharts } from 'container'
import { useDashboard, useLoadingControl } from 'hooks'
import { EResCode } from 'utils/other'
import { DashboardPerformanceDetailsQuery, Maybe } from 'utils/generated'

export interface IDashboardDetail {
  timeDuration: Date[]
  performanceDetails: Maybe<DashboardPerformanceDetailsQuery>
}

const intitialValue: IDashboardDetail = {
  timeDuration: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
  performanceDetails: null,
}

const DashboardDetails: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<IDashboardDetail>()

  const { isLoading, getDashboardPerformanceDetails } = useDashboard()
  useLoadingControl(isLoading)

  useEffect(() => {
    if (!values.timeDuration[0] || !values.timeDuration[1]) return

    const startDate = values.timeDuration[0].toISOString().split('T')[0] + 'T00:00:00.000Z'
    const endDate = values.timeDuration[1].toISOString().split('T')[0] + 'T23:59:59.000Z'

    const timeDurationTranform = {
      startDate: startDate,
      endedDate: endDate,
    }

    getDashboardPerformanceDetails({
      variables: {
        filter: timeDurationTranform,
      },
    }).then((res) => {
      const resGet = res.data.dashboardPerformanceDetails
      if (resGet.res_code !== EResCode.Success) return

      setFieldValue('performanceDetails', resGet)
    })
  }, [values.timeDuration])

  const dataTable = transformationDataIntoTable({
    dataList: values.performanceDetails?.transaction,
    type: ETranformationDataIntoTable.DetailsList,
  })

  return (
    <Layout hiddenHeaderSection>
      <div className={styles.container}>
        <div className={styles.headerTop}>
          <h4>E-Commerce</h4>
          <div className={styles.dateInput}>
            <DatePickers name="timeDuration" />
          </div>
        </div>
        <div className={styles.header}>
          <h5>Performance</h5>
        </div>
        <PerformanceCharts />
        <div className={styles.header}>
          <h5>Transaction</h5>
        </div>
        <Table
          objectKey={{
            image: 'Profile image',
            orderNumber: 'Order No.',
            createdAt: 'Order Date',
            customerName: 'Customer Name',
            contact: 'Contact',
            amountHTML: 'Items',
            orderStatusHTML: 'Order Status',
            paymentStatusHTML: 'Payment Status',
            note: 'Remark',
            tagsHTML: 'Tags',
          }}
          data={dataTable}
        />
        <div className={styles.header}>
          <p>Copyright © 2016 Datability Co.,Ltd. All rights reserved.</p>
          <p>Q</p>
        </div>
      </div>
    </Layout>
  )
}

const EnhancedDashboardDetails = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  handleSubmit: () => {
    console.log('Skip')
  },
})(DashboardDetails)

export default EnhancedDashboardDetails
