import { gql } from '@apollo/client'

export const DASHBOARD_PERFORMANCE = gql`
  query dashboardPerformance($filter: DashboardFilter!) {
    dashboardPerformance(filter: $filter) {
      res_code
      res_desc
      netRevenue {
        total
        percentage
        previousTotal
      }
      grossProfit {
        total
        percentage
        previousTotal
      }
      amountOfOrders {
        total
        percentage
        previousTotal
      }
      numberOfItems {
        total
        percentage
        previousTotal
      }
    }
  }
`

export const DASHBOARD_PERFORMANCEP_DETAILS = gql`
  query dashboardPerformanceDetails($filter: DashboardFilter!) {
    dashboardPerformanceDetails(filter: $filter) {
      res_code
      res_desc
      paymentStatusReceived
      paymentStatusOutstanding
      orderStatusPaid
      orderStatusUnPaid
      transaction {
        orderStatus
        paymentStatus
        orderID
        orderNo
        createdAt
        amount
        total
        buyerInfo {
          firstname
          surname
          userRole
          phoneNumber
          email
        }
        profileImgURL
        note
        tagID
      }
    }
  }
`

export const DASHBOARD_CHART = gql`
  query dashboardChart($filter: DashboardFilter!, $selector: E_CHART!) {
    dashboardChart(filter: $filter, selector: $selector) {
      res_code
      res_desc
      items
    }
  }
`

export const DASHBOARD_LEADER_BOARDS_CUSTOMERS = gql`
  query dashboardLeaderboardsCustomers($filter: DashboardFilter!) {
    dashboardLeaderboardsCustomers(filter: $filter) {
      res_code
      res_desc
      totalCustomer
      totalCustomerRatio
      averageCustomer
      averageCustomerRatio
      items
    }
  }
`

export const DASHBOARD_LEADER_BOARDS_PRODUCTS = gql`
  query dashboardLeaderboardsProducts($filter: DashboardFilter!) {
    dashboardLeaderboardsProducts(filter: $filter) {
      res_code
      res_desc
      totalProducts
      totalProductsRatio
      productBestSeller
      productBestSellerRatio
      categoryBestSeller
      categoryBestSellerRatio
      items
    }
  }
`

export const DASHBOARD_LEADER_BOARDS_INVENTORY = gql`
  query dashboardLeaderboardsInventory {
    dashboardLeaderboardsInventory {
      res_code
      res_desc
      totalProductInventory
      totalProductInventoryRatio
      items
    }
  }
`
