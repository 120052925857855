import { useMutation, useQuery } from '@apollo/client'
import { ORDER_LIST, UPDATE_ORDER_STATUS, UPDATE_STRIPE_PAYMENT_STATUS } from 'queries/order'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'
import { OrderQuery, QueryOrderListArgs } from 'utils/generated'

const useOrder = (variables?: QueryOrderListArgs) => {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(ORDER_LIST, {
    fetchPolicy: 'no-cache',
    variables: variables,
    context: { clientName: 'endpointAdmin' },
  })

  const [updateOrderStatus, optionUpdate] = useMutation(UPDATE_ORDER_STATUS, {
    context: { clientName: 'endpointAdmin' },
  })
  const [updateStripePaymentStatus, optionUpdateStripe] = useMutation(UPDATE_STRIPE_PAYMENT_STATUS, {
    context: { clientName: 'endpointAdmin' },
  })

  const isLoading = loading || optionUpdate.loading || optionUpdateStripe.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.orderList?.res_code !== EResCode.Success)
      return JSON.stringify(errorQuery || data?.orderList?.res_desc)

    // optionUpdate
    if (
      optionUpdate?.error ||
      (optionUpdate.data?.updateOrderStatus?.res_code &&
        optionUpdate.data?.updateOrderStatus?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateOrderStatus?.res_desc)

    if (
      optionUpdateStripe?.error ||
      (optionUpdateStripe.data?.updateStripePaymentStatus?.res_code &&
        optionUpdateStripe.data?.updateStripePaymentStatus?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdateStripe?.error || optionUpdateStripe.data?.updateStripePaymentStatus?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { data: data?.orderList as OrderQuery, refetch, updateOrderStatus, updateStripePaymentStatus, isLoading }
}

export default useOrder
