import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface ModalExpanseImageState {
  isOpen: boolean
  imageUrl: string
}

interface SetOpenExpanseImagePayload {
  imageUrl: string
}

const initialState: ModalExpanseImageState = {
  isOpen: false,
  imageUrl: '',
}

export const modalExpanseImageSlice = createSlice({
  name: 'modalExpanseImage',
  initialState,
  reducers: {
    setOpenExpanse: (state, action: PayloadAction<SetOpenExpanseImagePayload>) => {
      state.isOpen = true
      state.imageUrl = action.payload.imageUrl
    },
    setCloseExpanse: (state) => {
      state.isOpen = false
      state.imageUrl = ''
    },
  },
})

// Action creators are generated for each case reducer function
export const { setOpenExpanse, setCloseExpanse } = modalExpanseImageSlice.actions

export default modalExpanseImageSlice.reducer
