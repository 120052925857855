// Lib
import React, { useState } from 'react'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { Charts } from 'container'
import { IDashboard } from 'pages/Dashboard'
import { unPackValueToChart } from 'utils/other'

const ChartsSection: React.FC = () => {
  const { values } = useFormikContext<IDashboard>()

  const unPackHourlyDataList = unPackValueToChart(values.chartHourly?.items || [])
  const chartHourlyDataList = {
    labels: unPackHourlyDataList.labelChart,
    datasets: [
      {
        label: 'Net Revenue',
        data: unPackHourlyDataList.valueChart,
        backgroundColor: '#35B779',
      },
    ],
  }

  const unPackWeeklyDataList = unPackValueToChart(values.chartWeekly?.items || [])
  const chartWeeklyDataList = {
    labels: unPackWeeklyDataList.labelChart,
    datasets: [
      {
        label: 'Net Revenue',
        data: unPackWeeklyDataList.valueChart,
        backgroundColor: '#35B779',
      },
    ],
  }

  const unPackDaylyDataList = unPackValueToChart(values.chartDayly?.items || [])
  const chartDaylyDataList = {
    labels: unPackDaylyDataList.labelChart,
    datasets: [
      {
        label: 'Net Revenue',
        data: unPackDaylyDataList.valueChart,
        backgroundColor: '#35B779',
      },
    ],
  }

  const unPackMonthlyDataList = unPackValueToChart(values.chartMonthly?.items || [])
  const chartMonthlyDataList = {
    labels: unPackMonthlyDataList.labelChart,
    datasets: [
      {
        label: 'Net Revenue',
        data: unPackMonthlyDataList.valueChart,
        backgroundColor: '#35B779',
      },
    ],
  }

  const [mode, setMode] = useState<'DAYLY' | 'MONTHLY'>('DAYLY')
  const RenderHeader: React.FC<{ title: string; hiddenMode?: boolean }> = ({ title, hiddenMode }) => {
    return (
      <div className={styles.header}>
        <h6 className={styles.textGray}>{title}</h6>
        {hiddenMode && (
          <div className={styles.wrapperButton}>
            <div className={styles.cardButton} onClick={() => setMode('DAYLY')}>
              Day
            </div>
            <div className={styles.cardButton} onClick={() => setMode('MONTHLY')}>
              Month
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <h6>Charts</h6>
      <div className={styles.wrapperCard}>
        <div className={styles.card}>
          <RenderHeader title="NET REVENUE" hiddenMode />
          <Charts dataList={mode === 'DAYLY' ? chartDaylyDataList : chartMonthlyDataList} />
        </div>
        <div className={styles.box}>
          <div className={styles.card}>
            <RenderHeader title="REVENUE PER TIME" />
            <Charts dataList={chartHourlyDataList} />
          </div>
          <div className={styles.card}>
            <RenderHeader title="REVENUE PER DAY" />
            <Charts dataList={chartWeeklyDataList} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChartsSection
