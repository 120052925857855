import * as Yup from 'yup'
import _ from 'lodash'

const yupSKU = Yup.object().shape({
  formInput: Yup.object().shape({
    name: Yup.string().required('Plese Enter'),
    unitLabel: Yup.string().required('Plese Enter'),
    // productImgURLShow: Yup.array().min(1, 'Please Upload Image'),
    prefix: Yup.string().required('Plese Enter'),
    productOptionGroupID: Yup.string().required('Plese Enter'),
  }),
  formInputAddSKU: Yup.object().shape({
    productSKU: Yup.array().of(
      Yup.object().shape({
        productSKU: Yup.string().required('Plese Enter'),
        productSKUName: Yup.string().required('Plese Enter'),
        optionAttribute: Yup.lazy((value) => {
          if (!_.isEmpty(value)) {
            const newEntries = Object.keys(value).reduce((acc, val) => {
              return {
                ...acc,
                [val]: Yup.string().required('Plese Enter'),
              }
            }, {})

            return Yup.object().shape(newEntries)
          }
          return Yup.mixed().notRequired()
        }),
        pricing: Yup.array().of(
          Yup.object().shape({
            cost: Yup.number().min(0, 'Plese Enter').required('Plese Enter'),
            priceModelStandard: Yup.object().when('pricingModel', {
              is: 'STANDARD_PRICING',
              then: () =>
                Yup.object().shape({
                  defaultPrice: Yup.number().min(0, 'Plese Enter').required('Plese Enter'),
                  salePrice: Yup.number().min(0, 'Plese Enter').required('Plese Enter'),
                }),
            }),
            priceModelVolumn: Yup.object().when('pricingModel', {
              is: 'VOLUME_PRICING',
              then: () =>
                Yup.object().shape({
                  tableVolumnPrice: Yup.array().of(
                    Yup.lazy((value, option) => {
                      if (!_.isEmpty(value)) {
                        if (value === option.parent[option.parent.length - 1]) {
                          return Yup.object().shape({
                            startUnit: Yup.number().min(1, 'Plese Enter').required('Plese Enter'),
                            salePrice: Yup.number().min(0, 'Plese Enter').required('Plese Enter'),
                          })
                        } else {
                          return Yup.object().shape({
                            startUnit: Yup.number().min(1, 'Plese Enter').required('Plese Enter'),
                            endUnit: Yup.number()
                              .min(1, 'Plese Enter')
                              .required('Plese Enter')
                              .moreThan(Yup.ref('startUnit'), 'Error'), //<-- a whole lot neater than using a when conditional...
                            salePrice: Yup.number().min(0, 'Plese Enter').required('Plese Enter'),
                          })
                        }
                      }

                      return Yup.mixed().notRequired()
                    }),
                  ),
                }),
            }),
          }),
        ),
      }),
    ),
  }),
})

export default yupSKU
