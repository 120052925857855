// Lib
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import _ from 'lodash'

// Include in project
import styles from './index.module.scss'
import { menuPage } from 'utils/menu'
import type { RootState } from 'states/store'

const NavigateMenu: React.FC = () => {
  const location = useLocation()
  const path = location.pathname.split('/')[1]
  const newPathname = `/${path}`

  const userPermission = useSelector((state: RootState) => state.decodeToken.permission)

  const menuPageFilter = menuPage
    .map((data) => {
      const compare = _.difference(data.permission, userPermission)

      if (compare.length === data.permission.length) return null
      return data
    })
    .filter((ele) => ele)

  return (
    <div className={styles.container}>
      {menuPageFilter.map((data, index) => {
        return (
          <Link to={data?.path as string} key={index}>
            <p aria-checked={data?.path === newPathname} className={styles.wrapperPill}>
              {data?.name}
            </p>
          </Link>
        )
      })}
    </div>
  )
}
export default NavigateMenu
