import { E_Permission } from './generated'

export const menuHeaderProduct = [
  {
    section: 'All Products',
    path: '/product-management/product',
    permission: E_Permission.AllProduct,
  },
  {
    section: 'Category',
    path: '/product-management/category',
    permission: E_Permission.Category,
  },
  {
    section: 'Brand',
    path: '/product-management/brand',
    permission: E_Permission.Brand,
  },
  {
    section: 'Option',
    path: '/product-management/option',
    permission: E_Permission.Option,
  },
  {
    section: 'Tags',
    path: '/product-management/tags',
    permission: E_Permission.Tags,
  },
]

export const menuHeaderCustomer = [
  {
    section: 'Customer Lists',
    path: '/user-management/customer',
    permission: E_Permission.CustomerList,
  },
  {
    section: 'Staff Lists',
    path: '/user-management/staff',
    permission: E_Permission.StaffList,
  },
  {
    section: 'Staff Permission',
    path: '/user-management/staff-permission',
    permission: E_Permission.StaffPermission,
  },
]

export const menuHeaderInventory = [
  {
    section: 'All Product',
    path: '/inventory-management/product',
    permission: E_Permission.InventoryAllProduct,
  },
  {
    section: 'Transaction',
    path: '/inventory-management/transection',
    permission: E_Permission.InventoryTransaction,
  },
]

//Image
import dashboardSVG from 'images/Home/dashboard.svg'
import customerSVG from 'images/Home/customer.svg'
import productSVG from 'images/Home/product.svg'
import inventorySVG from 'images/Home/inventory.svg'
import orderSVG from 'images/Home/order.svg'
import cmsSVG from 'images/Home/cms.svg'
import settingSVG from 'images/Home/setting.svg'

export const menuPage = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    permission: [E_Permission.Dashboard],
    img: dashboardSVG,
  },

  {
    name: 'User',
    path: '/user-management',
    permission: [E_Permission.CustomerList, E_Permission.StaffList, E_Permission.StaffPermission],
    img: customerSVG,
  },
  {
    name: 'Products',
    path: '/product-management',
    permission: [
      E_Permission.AllProduct,
      E_Permission.Category,
      E_Permission.Brand,
      E_Permission.Option,
      E_Permission.Tags,
    ],
    img: productSVG,
  },
  {
    name: 'Inventory',
    path: '/inventory-management',
    permission: [E_Permission.InventoryAllProduct, E_Permission.InventoryTransaction],
    img: inventorySVG,
  },

  {
    name: 'Orders',
    path: '/order-management',
    permission: [E_Permission.Order],
    img: orderSVG,
  },

  {
    name: 'CMS',
    path: '/cms',
    permission: [E_Permission.Cms],
    img: cmsSVG,
  },

  {
    name: 'Setting',
    path: '/setting',
    permission: [E_Permission.Setting],
    img: settingSVG,
  },
]
