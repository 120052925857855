// Lib
import React, { useRef, useState } from 'react'
import ExcelJS, { CellValue } from 'exceljs'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

// Image
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'

// Include in project
import styles from './index.module.scss'
import { Modal, Table } from 'component'
import { E_Is_Active, E_Pricing_Model, ProductOptionGroup, ProductOption } from 'utils/generatedNonAuth'
import {
  UpdateProduct,
  UpdateProductSku,
  E_Payment_Type,
  TableVolumnPriceInput,
  MutationUpdateProductArgs,
  MutationUpdateJoinProductCategoryProductArgs,
  MutationUpdateProductSkuArgs,
} from 'utils/generated'
import { Button, Notification } from 'component'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { RootState } from 'states/store'
import { ICategory } from 'pages/Category'

interface Props {
  open: boolean
  handleClose: () => void
  selectOptionGroup: ProductOptionGroup | undefined
  updateJoinProduct: ({ variables }: { variables: MutationUpdateJoinProductCategoryProductArgs }) => Promise<any>
  updateProduct: ({ variables }: { variables: MutationUpdateProductArgs }) => Promise<any>
  updateSKU: ({ variables }: { variables: MutationUpdateProductSkuArgs }) => Promise<any>
  joinItemWithCategoryID: string | null
  setFetchProductList: React.Dispatch<React.SetStateAction<number>>
}

const ModalPreviewProduct: React.FC<Props> = ({
  open,
  handleClose,
  selectOptionGroup,
  updateJoinProduct,
  updateProduct,
  updateSKU,
  joinItemWithCategoryID,
  setFetchProductList,
}) => {
  const { values, setFieldValue } = useFormikContext<ICategory>()

  const { enqueueSnackbar } = useSnackbar()

  const [previewProduct, setPreviewProduct] = useState<(UpdateProduct & { productSKU: UpdateProductSku[] })[]>([])
  const inputFileRef = useRef<any>()

  const brandList = useSelector((state: RootState) => state.brand.brandList)

  const handleUploadFileTemplate = () => {
    inputFileRef.current.click()
  }

  const onFileChangeCapture = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length === 0) return

    try {
      const fileRes = e.target.files?.[0] as File

      function readFile(fileRes: File) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsArrayBuffer(fileRes)
          reader.onload = () => {
            resolve(reader.result)
          }
        })
      }

      const buffer = await readFile(fileRes)
      const workbook = new ExcelJS.Workbook()
      const file = await workbook.xlsx.load(buffer as Buffer)
      const sheet = await file.getWorksheet(selectOptionGroup?.productOptionGroupID as string)

      if (!sheet)
        return enqueueSnackbar(
          <Notification title="Error" description="Sheet name not match please dowload template again" />,
          {
            variant: 'error',
          },
        )

      const uniqueProduct: (UpdateProduct & { productSKU: UpdateProductSku[] })[] = []
      const headerFormat: string[] = []
      sheet.eachRow(function (row, rowNumber) {
        // Count null must be 1 only
        // If more than 1, The user entered incomplete information.
        const countNull = (JSON.stringify(row.values).match(/null/g) || []).length
        if (countNull > 1) {
          throw new Error()
        }

        if (rowNumber === 1) {
          // This is header
          Object.values(row.values).map((header) => {
            // headerFormat[header as string] = null
            headerFormat.push(header as string)
          })
        } else {
          // This is row
          // Pack format JSON
          const mapper: Record<string, CellValue> = {}
          Object.values(row.values).map((body: CellValue, index) => {
            mapper[headerFormat[index]] = body
          })

          // Pack OptionAttribute
          const newOptionAttribute: Record<string, string> = {}
          for (let index = 0; index < (selectOptionGroup?.option as ProductOption[])?.length; index++) {
            const option = selectOptionGroup?.option?.[index]
            const findVariant = option?.variant?.find(
              (ele) => `${ele?.name}` === (`${mapper[option.name as string]}` as string),
            )

            if (mapper[option?.name as string] === '-') newOptionAttribute[option?.productOptionID as string] = '-'
            else
              newOptionAttribute[option?.productOptionID as string] =
                (findVariant?.productOptionVariantID as string) || 'NOT FOUND'
          }

          // Pack Volumn Pricing
          const newTableVolumnPrice: TableVolumnPriceInput[] = []
          for (let i = 1; i < 5; i++) {
            const priceLabel = mapper[`Price Label ${i}`] as string
            const salePrice = mapper[`Price ${i}`] as string

            if (priceLabel === '-' || salePrice === '-') {
              if (i === 1) {
                newTableVolumnPrice.push({ startUnit: 1, endUnit: null, salePrice: 0 })
                break
              } else break
            }

            if (priceLabel.search('>=') === -1) {
              const [startUnit, endUnit] = priceLabel.split('-')
              newTableVolumnPrice.push({
                startUnit: parseFloat(startUnit),
                endUnit: parseFloat(endUnit),
                salePrice: parseFloat(salePrice),
              })
            } else {
              const lastStartUnit = priceLabel.split('>=')[1] // for the last child
              newTableVolumnPrice.push({
                startUnit: parseFloat(lastStartUnit),
                endUnit: null,
                salePrice: parseFloat(salePrice),
              })
            }
          }

          // Find unique product for create new
          // And Push SKU into productSKU
          const findProduct = uniqueProduct.findIndex((ele) => ele.name === mapper['Product Name'])

          const mapperSKU = {
            productID: null,
            productSKUID: null,
            productSKU: mapper['SKU'] as string,
            productSKUName: mapper['SKU Name'] as string,
            customAttribute: {},
            optionAttribute: newOptionAttribute,
            isActive: E_Is_Active.True,
            isVisible: E_Is_Active.True,
            tagID: null,
            remark: '',
            pricing: {
              pricingID: null,
              pricingModel: mapper['Payment Type'] as E_Pricing_Model,
              priceModelStandard: {
                paymentType: E_Payment_Type.OneTime,
                defaultPrice: mapper['Default Price'] === '-' ? 0 : (mapper['Default Price'] as number),
                salePrice: mapper['Sale Price'] === '-' ? 0 : (mapper['Sale Price'] as number),
              },
              priceModelVolumn: {
                paymentType: E_Payment_Type.OneTime,
                tableVolumnPrice: newTableVolumnPrice,
              },
              cost: mapper['Cost'] === '-' ? 0 : (mapper['Cost'] as number),
            },
          }

          if (findProduct === -1) {
            uniqueProduct.push({
              productID: null,
              brandID: brandList.find((ele) => ele.name === (mapper['Brand'] as string))?.brandID as string,
              name: mapper['Product Name'] as string,
              description: '',
              unitLabel: mapper['Unit'] as string,
              productOptionGroupID: selectOptionGroup?.productOptionGroupID as string,
              productImgURL: [],
              productFileURL: [],
              isVisible: E_Is_Active.True,
              isActive: E_Is_Active.True,
              tagID: [],
              customAttribute: null,
              specAttribute: {},
              minimumStock: 0,
              prefix: mapper['Prefix'] as string,
              remark: '',

              // For Keep Child of product
              productSKU: [mapperSKU],
            })
          } else {
            uniqueProduct[findProduct].productSKU.push(mapperSKU)
          }
        }
      })

      // After File unique product just push sku into product because it is child
      setPreviewProduct(uniqueProduct)
    } catch {
      setPreviewProduct([])
      enqueueSnackbar(<Notification title="Error" description="Format excel not correct" />, {
        variant: 'error',
      })
    }
  }

  const dataTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.PreviewProduct,
    dataList: previewProduct,
  })

  const columnTable = (): Record<string, string> => {
    const mapperOption =
      selectOptionGroup?.option
        ?.filter((ele) => ele?.isOption === E_Is_Active.True)
        .map((option) => {
          return {
            [option?.productOptionID as string]: option?.name as string,
          }
        }) || []

    return {
      productSKU: 'SKU',
      productSKUName: 'SKU Name',
      ...Object.assign({}, ...mapperOption),
      pricingTypeHTML: 'Payment Type',
      pricingHTML: 'Pricing',
    }
  }

  const handleCloseModal = () => {
    handleClose()
    setPreviewProduct([])
  }

  const handleAddProduct = async () => {
    setFieldValue('isLoadingImportProduct', true)
    // Create Product -> Create SKU
    // Update Join Product Category
    const clonePreviewProduct: (UpdateProduct & {
      productSKU: UpdateProductSku[]
    })[] = JSON.parse(JSON.stringify(previewProduct))

    const promiseCreateProduct = previewProduct.map(async (product, i) => {
      const cloneProduct = JSON.parse(JSON.stringify(product))
      delete cloneProduct.productSKU

      const res = await updateProduct({ variables: { input: cloneProduct } as MutationUpdateProductArgs })
      const resUpdate = res.data.updateProduct
      clonePreviewProduct[i].productID = resUpdate.productID

      return resUpdate.productID
    })

    await Promise.all(promiseCreateProduct)

    const promiseCreateSKU = clonePreviewProduct
      .map((product) => {
        return product.productSKU.map(async (sku) => {
          Object.keys(sku.optionAttribute).map((optionKey) => {
            if (sku.optionAttribute[optionKey] !== '-') return

            // Remove Option when variant is `-`
            delete sku.optionAttribute[optionKey]
          })

          const res = await updateSKU({
            variables: { input: { ...sku, productID: product.productID, productSKU: `${sku.productSKU}` } } as any,
          })
          const resUpdate = res.data.updateProductSKU
          return resUpdate.res_code
        })
      })
      .flat()

    const productIDList = values.productList.map((data) => data.productID)
    const newProductIDList = clonePreviewProduct.map((data) => data.productID)

    await Promise.all([...promiseCreateSKU.flat()])

    await updateJoinProduct({
      variables: {
        input: {
          joinItemWithCategoryID: joinItemWithCategoryID as string,
          productIDList: [...productIDList, ...newProductIDList],
        },
      } as MutationUpdateJoinProductCategoryProductArgs,
    })

    setFieldValue('isLoadingImportProduct', false)
    setFetchProductList((prev) => prev + 1)
    handleCloseModal()
    enqueueSnackbar(<Notification title="Success" description="Done! Import Product." />, {
      variant: 'success',
    })
  }

  return (
    <Modal open={open} handleClose={handleCloseModal} width="70%" height="90%" title="Import Product" hiddenButton>
      <div className={styles.container}>
        <input
          type="file"
          onChange={onFileChangeCapture}
          onClick={(e) => ((e.target as unknown as { value: null }).value = null)}
          ref={inputFileRef}
          style={{ display: 'none' }}
          accept=".xlsx"
        />
        <div className="row justify-space-between">
          <Button
            name="Select File .xlsx"
            typeButton="button"
            type="gray"
            size="small"
            startIcon={<FolderOpenIcon />}
            functionOnClick={handleUploadFileTemplate}
          />
          <Button
            name="Import Product"
            typeButton="button"
            type="info"
            size="small"
            startIcon={<CloudUploadIcon />}
            functionOnClick={handleAddProduct}
            disabled={!Boolean(previewProduct.length) || JSON.stringify(previewProduct).search('NOT FOUND') > -1}
          />
        </div>

        <Table objectKey={columnTable()} data={dataTable} />
      </div>
    </Modal>
  )
}

export default ModalPreviewProduct
