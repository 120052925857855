// Lib
import React from 'react'
import { useFormikContext } from 'formik'

// Images
import imageMessageSVG from 'images/forgotPassword/Message.svg'
import imageGmailIconSVG from 'images/forgotPassword/GmailIcon.svg'
import imageOutLookIconSVG from 'images/forgotPassword/OutLookIcon.svg'

// Include in project
import styles from './index.module.scss'
import { IForgotPassword } from 'pages/ForgotPassword'
import { Button, OTPInput } from 'component'

interface Props {
  forgotPassword: (data: { companyID: string; email: string }) => void
  handleNextStep: () => void
}

const ForgotPasswordStepTwo: React.FC<Props> = ({ handleNextStep, forgotPassword }: Props) => {
  const { values, errors } = useFormikContext<IForgotPassword>()

  const resendOTP = () => {
    const packData = {
      companyID: values.companyID,
      email: values.email,
    }
    forgotPassword(packData)
  }

  return (
    <div className={styles.container}>
      <div className={styles.group}>
        <img src={imageMessageSVG} alt="" />
        <div className={styles.wrapperText}>
          <h6>เราได้ส่งอีเมลให้คุณแล้ว</h6>
          <p className="fontBold">กรอกรหัส 6 หลักที่เราส่งให้ในอีเมล เพื่อกู้คืนรหัสของคุณ </p>
        </div>
        <OTPInput value={values.otpCode} name="otpCode" resendOTP={resendOTP} />

        {errors.otpCode ? <p className="colorRed">{errors.otpCode}</p> : ''}
        <Button name="ยืนยัน" typeButton="submit" type="info" size="large" functionOnClick={handleNextStep} />
      </div>

      <div className={styles.iconBelow}>
        <img src={imageGmailIconSVG} alt="" />
        <img src={imageOutLookIconSVG} alt="" />
      </div>
    </div>
  )
}

export default ForgotPasswordStepTwo
