import { gql } from '@apollo/client'

export const CATEGORY_LIST = gql`
  query productCategoryListInfo($companyID: String!) {
    productCategoryListInfo(companyID: $companyID) {
      res_code
      res_desc
      companyID
      # Dimension 1
      items {
        categoryID
        categoryName
        productOptionGroupID
        joinItemWithCategoryID
        description
        isActive
        index
        customAttribute
        features
        pdfUrl
        imageUrl
        # Dimension 2
        items {
          categoryID
          categoryName
          productOptionGroupID
          joinItemWithCategoryID
          description
          isActive
          index
          customAttribute
          features
          pdfUrl
          imageUrl
          # Dimension 3
          items {
            categoryID
            categoryName
            productOptionGroupID
            joinItemWithCategoryID
            description
            isActive
            index
            customAttribute
            features
            pdfUrl
            imageUrl
            # Dimension 4
            items {
              categoryID
              categoryName
              productOptionGroupID
              joinItemWithCategoryID
              description
              isActive
              index
              customAttribute
              features
              pdfUrl
              imageUrl
              # Dimension 5
              items {
                categoryID
                categoryName
                productOptionGroupID
                joinItemWithCategoryID
                description
                isActive
                index
                customAttribute
                features
                pdfUrl
                imageUrl
                items {
                  categoryID
                  categoryName
                  productOptionGroupID
                  joinItemWithCategoryID
                  description
                  isActive
                  index
                  customAttribute
                  features
                  pdfUrl
                  imageUrl
                  # End of 5 Dimension
                  # items {
                  # }
                }
              }
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_CATEGORY = gql`
  mutation updateProductCategory($input: UpdateProductCategory!) {
    updateProductCategory(input: $input) {
      res_code
      res_desc
      companyID
      # Dimension 1
      items {
        categoryID
        categoryName
        productOptionGroupID
        joinItemWithCategoryID
        description
        isActive
        index
        customAttribute
        features
        pdfUrl
        imageUrl
        # Dimension 2
        items {
          categoryID
          categoryName
          productOptionGroupID
          joinItemWithCategoryID
          description
          isActive
          index
          customAttribute
          features
          pdfUrl
          imageUrl
          # Dimension 3
          items {
            categoryID
            categoryName
            productOptionGroupID
            joinItemWithCategoryID
            description
            isActive
            index
            customAttribute
            features
            pdfUrl
            imageUrl
            # Dimension 4
            items {
              categoryID
              categoryName
              productOptionGroupID
              joinItemWithCategoryID
              description
              isActive
              index
              customAttribute
              features
              pdfUrl
              imageUrl
              # Dimension 5
              items {
                categoryID
                categoryName
                productOptionGroupID
                joinItemWithCategoryID
                description
                isActive
                index
                customAttribute
                features
                pdfUrl
                imageUrl
                items {
                  categoryID
                  categoryName
                  productOptionGroupID
                  joinItemWithCategoryID
                  description
                  isActive
                  index
                  customAttribute
                  features
                  pdfUrl
                  imageUrl
                  # End of 5 Dimension
                  # items {
                  # }
                }
              }
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_JOIN_PRODUCT_CATEGORY = gql`
  mutation updateJoinProductCategoryProduct($input: UpdateJoinProductCategoryProduct!) {
    updateJoinProductCategoryProduct(input: $input) {
      res_code
      res_desc
      companyID
      joinItemWithCategoryID
      items
    }
  }
`

export const DELETE_CATEGORY = gql`
  mutation deleteProductCategory($input: DeleteProductCategory!) {
    deleteProductCategory(input: $input) {
      res_code
      res_desc
    }
  }
`
