// Lib
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Include in project
import styles from './index.module.scss'
import { Divider } from 'component'
import { E_Permission } from 'utils/generated'
import type { RootState } from 'states/store'

interface Props {
  sectionName: string
  listTab: { section: string; path: string; permission: E_Permission }[]
}

const HeaderSection: React.FC<Props> = ({ listTab, sectionName }: Props) => {
  const location = useLocation()
  const path = location.pathname

  const userPermission = useSelector((state: RootState) => state.decodeToken.permission)

  const listTabFilter = listTab
    .map((data) => {
      const isHave = userPermission.includes(data.permission)

      if (!isHave) return null
      return data
    })
    .filter((ele) => ele)

  return (
    <>
      <div className={styles.main}>
        <h6>{sectionName}</h6>
        <div className={styles.groupContent}>
          {listTabFilter.map((data, index) => {
            return (
              <Link to={data?.path as string} key={index}>
                <p aria-checked={data?.path === path} className={styles.text}>
                  {data?.section}
                </p>
              </Link>
            )
          })}
        </div>
      </div>
      <Divider />
    </>
  )
}

export default HeaderSection
