import Color from 'abstracts/export.module.scss'
import FontSize from 'abstracts/export.module.scss'
import { ReactElement } from 'react'

export interface Props {
  typeButton: 'button' | 'submit'
  type:
    | 'primary'
    | 'secondary'
    | 'secondaryOutline'
    | 'primaryOutline'
    | 'danger'
    | 'dangerOutline'
    | 'info'
    | 'infoOutline'
    | 'gray'
    | 'outlined'
  name: string
  startIcon?: ReactElement
  endIcon?: ReactElement
  functionOnClick?: () => void
  disabled?: boolean
  fullWidth?: boolean
  size?: 'small' | 'large' | 'medium'
}

export const colorButton = {
  primary: {
    backgroundColor: `${Color.primaryMain}`,
    fontSize: FontSize.fontSizeP,

    '&:hover': {
      backgroundColor: `${Color.primaryHover}`,
    },
    '&:pressed': {
      backgroundColor: `${Color.primaryPressed}`,
    },
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${Color.primaryFocus}`,
    },
  },
  secondary: {
    color: `${Color.white}`,
    backgroundColor: `${Color.secondaryMain}`,
    fontSize: FontSize.fontSizeP,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',

    '&:hover': {
      color: `${Color.white}`,
      backgroundColor: `${Color.secondaryHover}`,
    },
    '&:pressed': {
      color: `${Color.primaryPressed}`,
      backgroundColor: `${Color.primarySecond}`,
    },
  },

  secondaryOutline: {
    color: `${Color.secondaryMain}`,
    backgroundColor: `${Color.white}`,
    border: `1px solid ${Color.secondaryMain}`,
    fontSize: FontSize.fontSizeP,
    '&:hover': {
      color: `${Color.secondaryMain}`,
      backgroundColor: `${Color.white}`,
      border: `1px solid ${Color.secondaryMain}`,
    },
    '&:pressed': {
      color: `${Color.secondaryMain}`,
      backgroundColor: `${Color.white}`,
      border: `1px solid ${Color.secondaryMain}`,
    },
    '&.Mui-disabled': {
      border: `1px solid ${Color.grey500}`,
    },
  },
  primaryOutline: {
    color: `${Color.primaryMain}`,
    backgroundColor: `${Color.white}`,
    border: `1px solid ${Color.primaryMain}`,
    fontSize: FontSize.fontSizeP,
    boxShadow: 'none',
    '&:hover': {
      color: `${Color.primaryMain}`,
      backgroundColor: `${Color.white}`,
      border: `1px solid ${Color.primaryMain}`,
    },
    '&:pressed': {
      color: `${Color.primaryMain}`,
      backgroundColor: `${Color.white}`,
      border: `1px solid ${Color.primaryMain}`,
    },
    '&.Mui-disabled': {
      border: `1px solid ${Color.grey500}`,
    },
  },
  danger: {
    backgroundColor: `${Color.dangerMain}`,
    fontSize: FontSize.fontSizeP,

    '&:hover': {
      backgroundColor: `${Color.dangerHover}`,
    },
    '&:pressed': {
      backgroundColor: `${Color.dangerPressed}`,
    },
  },
  dangerOutline: {
    color: `${Color.dangerMain}`,
    backgroundColor: `${Color.white}`,
    border: `1px solid ${Color.dangerMain}`,
    fontSize: FontSize.fontSizeP,
    boxShadow: 'none',
    '&:hover': {
      color: `${Color.dangerMain}`,
      backgroundColor: `${Color.white}`,
      border: `1px solid ${Color.dangerMain}`,
    },
    '&:pressed': {
      color: `${Color.dangerMain}`,
      backgroundColor: `${Color.white}`,
      border: `1px solid ${Color.dangerMain}`,
    },
    '&.Mui-disabled': {
      border: `1px solid ${Color.grey500}`,
    },
  },
  info: {
    color: `${Color.white}`,
    backgroundColor: `${Color.infoMain}`,
    fontSize: FontSize.fontSizeP,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',

    '&:hover': {
      color: `${Color.white}`,
      backgroundColor: `${Color.infoHover}`,
    },
    '&:pressed': {
      color: `${Color.infoPressed}`,
      backgroundColor: `${Color.infoSecond}`,
    },
  },
  infoOutline: {
    color: `${Color.infoMain}`,
    backgroundColor: `${Color.white}`,
    border: `1px solid ${Color.infoMain}`,
    fontSize: FontSize.fontSizeP,
    '&:hover': {
      color: `${Color.infoMain}`,
      backgroundColor: `${Color.white}`,
      border: `1px solid ${Color.infoMain}`,
    },
    '&:pressed': {
      color: `${Color.infoMain}`,
      backgroundColor: `${Color.white}`,
      border: `1px solid ${Color.secondaryMain}`,
    },
    '&.Mui-disabled': {
      border: `1px solid ${Color.grey500}`,
    },
  },
  gray: {
    color: `${Color.grey700}`,
    backgroundColor: `${Color.white}`,
    border: `1px solid ${Color.grey200}`,
    fontSize: FontSize.fontSizeP,
    boxShadow: 'none',

    '&:hover': {
      color: `${Color.grey700}`,
      backgroundColor: `${Color.white}`,
      border: `1px solid ${Color.grey200}`,
    },
    '&:pressed': {
      color: `${Color.grey700}`,
      backgroundColor: `${Color.white}`,
      border: `1px solid ${Color.grey200}`,
    },
    '&.Mui-disabled': {
      border: `1px solid ${Color.grey500}`,
    },
  },
  outlined: {
    color: `${Color.infoMain}`,
    backgroundColor: 'transparent',
    border: `1px solid ${Color.white}`,
    fontSize: FontSize.fontSizeP,
    boxShadow: 'none',
    '&:hover': {
      color: `${Color.white}`,
      backgroundColor: 'transparent',
      border: `1px solid ${Color.white}`,
    },
    '&.Mui-disabled': {
      border: `1px solid ${Color.grey500}`,
    },
  },
}
