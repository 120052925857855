// Lib
import React from 'react'
import { useFormikContext, withFormik } from 'formik'

//Image
import IosShareIcon from '@mui/icons-material/IosShare'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { ModalUserDetail, SearchBox } from 'container'
import { menuHeaderCustomer } from 'utils/menu'
import { E_Gender, E_Is_Active, E_User_Role, Maybe, User } from 'utils/generated'
import { useLoadingControl, useModal, useUser, useInfiniteScroll } from 'hooks'
import { ButtonFilterIsActive, Table } from 'component'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { IOption } from 'utils/interface'
import { pageSize } from 'configs'
import { optionList } from 'utils/other'
import { TOptionkey } from 'container/SearchBox'

export interface ICustomerManagement {
  formInput: { tagID?: IOption[] } & User
  filter: {
    key: string
    value: string
    firstname: Maybe<string>
    surname: Maybe<string>
    phoneNumber: Maybe<string>
    email: Maybe<string>
    isActive: E_Is_Active | 'ALL'
    gender: Maybe<E_Gender>
  }
}

const intitialValue: ICustomerManagement = {
  formInput: {
    profileImgURL: [],
    isActive: E_Is_Active.False,
    firstname: '',
    surname: '',
    userID: '',
    idCard: '',
    email: '',
    phoneNumber: '',
    tagID: [],
    address: [],
  },
  filter: {
    key: 'phoneNumber',
    value: '',
    firstname: null,
    surname: null,
    phoneNumber: null,
    email: null,
    isActive: E_Is_Active.True,
    gender: null,
  },
}

const CustomerManagement: React.FC = () => {
  const { values } = useFormikContext<ICustomerManagement>()

  const filterObject = {
    userRoleGlobal: E_User_Role.Customer,
    firstname: values.filter.firstname,
    surname: values.filter.surname,
    phoneNumber: values.filter.phoneNumber,
    email: values.filter.email,
    isActive: values.filter.isActive !== 'ALL' ? values.filter.isActive : null,
    gender: values.filter.gender,
  }

  const { data, isLoading, updateTagsAndIsActiveCustomer, refetch } = useUser({
    filter: filterObject,
    pageSize: pageSize,
    nextToken: null,
  })
  const { dataList } = useInfiniteScroll({
    result: data,
    refetch: refetch,
    variables: {
      filter: filterObject,
      pageSize: pageSize,
      nextToken: data?.nextToken || null,
    },
    filter: [
      values.filter.firstname,
      values.filter.surname,
      values.filter.phoneNumber,
      values.filter.email,
      values.filter.isActive,
      values.filter.gender,
    ],
    key: 'userList',
  })
  useLoadingControl(isLoading)

  const { isModalOpen, handleClose, handleOpen } = useModal(intitialValue.formInput)

  const dataTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.CustomerManagement,
    dataList: dataList || [],
  })

  const optionKey: TOptionkey = [
    {
      value: 'firstname',
      label: 'Firstname',
      child: {
        type: 'TEXT_FIELD',
      },
    },
    {
      value: 'surname',
      label: 'Surname',
      child: {
        type: 'TEXT_FIELD',
      },
    },
    {
      value: 'phoneNumber',
      label: 'PhoneNumber',
      child: {
        type: 'TEXT_FIELD',
      },
    },
    {
      value: 'email',
      label: 'Email',
      child: {
        type: 'TEXT_FIELD',
      },
    },
    {
      value: 'gender',
      label: 'Gender',
      child: {
        type: 'SELEC_SINGLE',
        options: optionList.genderOption,
      },
    },
  ]

  return (
    <Layout headerSectionName="User Management" headerSectionManu={menuHeaderCustomer}>
      <div className={styles.container}>
        <div className={styles.wrapperHeader}>
          <SearchBox optionKey={optionKey} />
          <ButtonFilterIsActive />
          {/* <Button name="Export" typeButton="button" type="gray" size="medium" startIcon={<IosShareIcon />} /> */}
        </div>
        <Table
          objectKey={{
            imageHTML: 'Profile image',
            nameHTML: 'Name',
            contactHTML: 'Contact',
            addressHTML: 'Address',
            // tagsHTML: 'Tags',
            statusHTML: 'Status',
            createdAt: 'Created At',
            updatedAt: 'Updated At',
          }}
          data={dataTable}
          handleClick={handleOpen}
        />
      </div>
      <ModalUserDetail
        open={isModalOpen}
        handleClose={handleClose}
        handleUpdate={updateTagsAndIsActiveCustomer}
        refetch={refetch}
      />
    </Layout>
  )
}

const EnhancedCustomerManagement = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  handleSubmit: () => {
    console.log('Skip')
  },
})(CustomerManagement)

export default EnhancedCustomerManagement
