// Lib
import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useFormikContext } from 'formik'

// Include in project
import { Props, ColorTheme } from './config'

const CheckboxComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const { handleChange } = useFormikContext()

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={props.name}
          value={props.value}
          disabled={props.disabled ? props.disabled : false}
          onChange={props.onChange || handleChange}
          checked={props.checked}
          sx={ColorTheme}
        />
      }
      label={props.label && <p style={{ marginTop: '3px' }}>{props.label}</p>}
    />
  )
}

export default CheckboxComponent
