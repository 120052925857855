//Lib
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFormikContext } from 'formik'

//Image
import uploadSVG from 'images/commons/upload.svg'
import pdfSVG from 'images/commons/pdf.svg'

// Include in project
import styles from './index.module.scss'
import { Chip, ChipDelete, Tag } from 'component'
import { s3URL } from 'configs'

interface Props {
  fileList: string[]
  name?: string
  multiple?: boolean
  readonly?: boolean
}

const UploadPDF: React.FC<Props> = ({ multiple, fileList, name, readonly }: Props) => {
  const { setFieldValue } = useFormikContext()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!multiple) {
        setFieldValue(`${name}`, [acceptedFiles[0]])
      } else {
        setFieldValue(`${name}`, [...fileList, ...acceptedFiles.map((item) => item)])
      }
    },
    [multiple, fileList],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept: {
      'application/pdf': ['.pdf'],
    },
  })

  const onDelete = (index: number) => {
    setFieldValue(`${name}`, [...fileList.slice(0, index), ...fileList.slice(index + 1)])
  }

  return (
    <div className={styles.main}>
      <div className={styles.section}>
        <p className="fontBold">PDF Files</p>
        <Tag description={`${fileList?.length || 0}/5 Files`} />
      </div>
      <div className={styles.boxUpLoad}>
        {!readonly && (
          <div className={styles.boxUpLoad} {...getRootProps()}>
            <label className={styles.uploadFile} htmlFor="upLoadFile">
              <img className={styles.maxPhoto} src={uploadSVG} width={13} height={13} />
              <p>Upload</p>
            </label>
            <input {...getInputProps()} className={styles.noneSeen} />
          </div>
        )}

        {fileList ? (
          <div className={styles.boxUpLoad}>
            {fileList?.map((data: File | string, index) => {
              const nameFile = (): string => {
                if (typeof data === 'string') {
                  const splitUrl = decodeURIComponent(data).split('/')
                  return splitUrl[splitUrl.length - 2]
                } else return data.name
              }

              const srcFile = () => {
                if (typeof data === 'string') return `${s3URL}/${data}`
                else return URL.createObjectURL(data)
              }

              if (!readonly) {
                return (
                  <ChipDelete
                    key={index}
                    label={nameFile()}
                    onDelete={() => onDelete(index)}
                    startIcon={<img src={pdfSVG} />}
                    onClick={() => window.open(srcFile())}
                  />
                )
              } else {
                return (
                  <Chip
                    key={index}
                    label={nameFile()}
                    startIcon={<img src={pdfSVG} />}
                    color="grayOutlined"
                    onClick={() => window.open(srcFile())}
                  />
                )
              }
            })}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default UploadPDF
