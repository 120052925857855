// Lib
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'

//Images
import editSVG from 'images/commons/editicon.svg'

// Include in project
import styles from './index.module.scss'
import { Button, Checkbox, Divider, TextArea, TextField } from 'component'
import { ProductOption, ProductOptionVariant, E_Is_Active, Maybe } from 'utils/generatedNonAuth'
import { IRenderInput } from 'utils/interface'
import { FormInput } from 'container'
import { convertDateLL } from 'utils/convertDate'
import { IOptionInfomation } from 'pages/OptionInformation'

const OptionInformationSection: React.FC = () => {
  const { values } = useFormikContext<IOptionInfomation>()
  const navigate = useNavigate()

  const fieldInputs: IRenderInput[] = [
    {
      heading: 'Name',
      type: 'TEXT_FIELD',
      name: 'optionInfo.name',
      disabled: true,
    },
    {
      heading: 'Description',
      type: 'TEXT_FIELD',
      name: 'optionInfo.description',
      disabled: true,
    },
    {
      heading: 'Created At',
      type: 'TEXT',
      words: convertDateLL(values.optionInfo?.createdAt),
    },
    {
      heading: 'Updated At',
      type: 'TEXT',
      words: convertDateLL(values.optionInfo?.updatedAt),
    },
    {
      heading: 'Status',
      type: 'CHIP_BY_STATUS',
      chipType: values.optionInfo?.isActive,
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.containerCard}>
        <div className={styles.wrapperBox}>
          <h5>{values.optionInfo?.name}</h5>
        </div>

        <div className={styles.wrapperBox}>
          <h6>Details</h6>
          <Button
            typeButton="button"
            type="gray"
            name="Edit"
            size="small"
            startIcon={<img src={editSVG} />}
            functionOnClick={() =>
              navigate(`/product-management/option-update/${values.optionInfo.productOptionGroupID}`)
            }
          />
        </div>

        <Divider />

        <FormInput title="" fieldInputs={fieldInputs} />
      </div>

      <div className={styles.containerCard}>
        <h5>Option information</h5>
        <h6>Option Details</h6>

        <div className={styles.wrapperInput}>
          <div className={styles.header}>
            <p className="fontBold">Option Name</p>
            <p className="fontBold">Type</p>
            <p className="fontBold">Description (Optional)</p>
            <p className="fontBold">Values</p>
          </div>

          {values.optionInfo?.option &&
            values.optionInfo?.option.map((item: Maybe<ProductOption>, index: number) => {
              return (
                <div className={styles.box} key={index}>
                  <div>
                    <TextField name={`optionInfo.option[${index}].name`} type="text" disabled />
                    <div className={styles.wrapperCheck}>
                      <Checkbox
                        name="test"
                        value=""
                        checked={item?.isOption === E_Is_Active.True ? true : false}
                        label="Options"
                        disabled
                      />
                      <Checkbox
                        name="test"
                        value=""
                        checked={item?.isFilter === E_Is_Active.True ? true : false}
                        label="Filter"
                        disabled
                      />
                    </div>
                  </div>
                  <TextField name={`optionInfo.option[${index}].type`} type="text" disabled />
                  <TextArea name={`optionInfo.option[${index}].description`} placeholder="Description" disabled />
                  <div className={styles.colBox}>
                    {item?.variant?.map((itemVariant: ProductOptionVariant | null, idx: number) => {
                      return (
                        <div key={idx}>
                          <TextField name={`optionInfo.option[${index}].variant[${idx}].name`} type="text" disabled />
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default OptionInformationSection
