// Lib
import React from 'react'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { IOrderManagement } from 'pages/OrderManagement'
import { IRenderInput } from 'utils/interface'
import { FormInput } from 'container'
import { findNameProvinceByEnum } from 'utils/other'

const CustomerInformationSection: React.FC = () => {
  const { values } = useFormikContext<IOrderManagement>()

  const fieldInputs: IRenderInput[] = [
    {
      heading: 'Customer ID',
      type: 'TEXT',
      words: values.formInput.buyerUserID,
    },
    {
      heading: 'Customer Name',
      type: 'TEXT',
      words: `${values.formInput.buyerInfo?.firstname} ${values.formInput.buyerInfo?.surname}`,
    },
    {
      heading: 'Email',
      type: 'TEXT',
      words: values.formInput.buyerInfo?.email,
    },
    {
      heading: 'Contact',
      type: 'TEXT',
      words: values.formInput.buyerInfo?.phoneNumber,
    },
    {
      heading: 'Shipping Address',
      type: 'TEXT',
      words:
        values.formInput.addressID &&
        values.formInput.shippingAddress?.address +
          ' ' +
          values.formInput.shippingAddress?.subDistrict +
          ' ' +
          values.formInput.shippingAddress?.district +
          ' ' +
          findNameProvinceByEnum(values.formInput.shippingAddress?.province) +
          ' ' +
          values.formInput.shippingAddress?.postCode,
    },
    {
      heading: 'Tax ID',
      type: 'TEXT',
      words: values.formInput.billingAddress?.taxID,
    },
    {
      heading: 'Billing Address',
      type: 'TEXT',
      words:
        values.formInput.billingAddress?.taxID &&
        values.formInput.billingAddress?.name +
          ' ' +
          values.formInput.billingAddress?.address +
          ' ' +
          values.formInput.billingAddress?.subDistrict +
          ' ' +
          values.formInput.billingAddress?.district +
          ' ' +
          findNameProvinceByEnum(values.formInput.billingAddress?.province) +
          ' ' +
          values.formInput.billingAddress?.postCode,
    },
  ]

  return (
    <div className={styles.container}>
      <FormInput title="Customer Infomation" fieldInputs={fieldInputs} />
    </div>
  )
}

export default CustomerInformationSection
