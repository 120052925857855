import { gql } from '@apollo/client'

export const ORDER_LIST = gql`
  query orderList($filter: OrderFilter!, $pageSize: Int, $nextToken: String) {
    orderList(filter: $filter, pageSize: $pageSize, nextToken: $nextToken) {
      res_code
      res_desc
      nextToken
      items {
        companyID
        orderID
        orderNo
        userID
        cartID
        orderStatus
        paymentStatus
        deliveryStatus
        orderItem {
          productID
          productSKUID
          qty
          productSKU
          productSKUName
          productName
          productDescription
          unitLabel
          productImgURL
          brandName
          optionAttributeName
          price
          note
        }
        customAttribute
        shippingType
        shippingCompany
        shippingTrackingID
        note
        tagID
        sessionID
        sessionURL
        orderByRole
        buyerUserID
        buyerInfo {
          firstname
          surname
          userRole
          phoneNumber
          email
        }
        sellerUserID
        sellerInfo {
          firstname
          surname
          userRole
          phoneNumber
          email
        }
        addressID
        billingAddress {
          name
          note
          taxType
          taxID
          googleMapURL
          email
          tel
          address
          subDistrict
          district
          province
          postCode
          country
        }
        shippingAddress {
          firstname
          surname
          companyName
          googleMapURL
          email
          tel
          note
          address
          subDistrict
          district
          province
          postCode
          country
        }
        profileImgURL
        evidencePaymentURL
        evidenceShippedURL
        evidenceReceivedURL
        evidencePaymentInfo {
          bankName
          price
          timestamp
        }
        isFreightOnDelivery
        price
        freight
        tax
        amount
        discount
        total
        timeoutPaidAt
        confirmPaidAt
        approvedAt
        pickedUpAt
        shippedAt
        fulfilledAt
        pausedAt
        createdAt
        updatedAt
      }
    }
  }
`

export const UPDATE_ORDER_STATUS = gql`
  mutation updateOrderStatus($input: UpdateOrderStatus!) {
    updateOrderStatus(input: $input) {
      res_code
      res_desc
    }
  }
`
export const UPDATE_STRIPE_PAYMENT_STATUS = gql`
  mutation updateStripePaymentStatus($input: UpdateStripePaymentStatus!) {
    updateStripePaymentStatus(input: $input) {
      res_code
      res_desc
      orderID
    }
  }
`
