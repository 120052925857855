// Lib
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFormikContext, getIn } from 'formik'
import { useDispatch } from 'react-redux'

// Images
import uploadSVG from 'images/commons/upload.svg'
import cloudUploadSVG from 'images/commons/cloudUpload.svg'
import closeSVG from 'images/commons/closeCircle.svg'

//Include In Project
import styles from './index.module.scss'
import { setOpenExpanse } from 'states/slice/modalExpanseImage'
import { s3URL } from 'configs'

interface Props {
  multiple?: boolean
  sizeDrop: 'SM' | 'LG'
  fileList: string[]
  name?: string
  readonly?: boolean
}

const DropZoneUpload: React.FC<Props> = ({ multiple, sizeDrop, fileList, name, readonly }) => {
  const dispatch = useDispatch()
  const { setFieldValue, errors, touched } = useFormikContext()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!multiple) {
        setFieldValue(`${name}`, [acceptedFiles[0]])
      } else {
        setFieldValue(`${name}`, [...fileList, ...acceptedFiles.map((item) => item)])
      }
    },
    [multiple, fileList],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  })

  const onDelete = async (index: number) => {
    setFieldValue(`${name}`, [...fileList.slice(0, index), ...fileList.slice(index + 1)])
  }

  return (
    <div className={styles.container} aria-checked={sizeDrop === 'LG'}>
      {!readonly && (
        <div
          className={styles.wrapperDrop}
          {...getRootProps()}
          aria-checked={getIn(touched, name || '') && Boolean(getIn(errors, name || ''))}
        >
          <input {...getInputProps()} />
          {sizeDrop === 'SM' ? (
            <div className={styles.wrapperCaption}>
              <img src={uploadSVG} alt="UploadSVG" width={13} height={13} />
              <p>Upload</p>
            </div>
          ) : (
            <div className={styles.wrapperCaptionCol}>
              <img src={cloudUploadSVG} alt="CloudUploadSVG" className={styles.uploadSVG} />
              <p className={`fontBold`}>Select a file or drag and drop here</p>
              <p>JPG, PNG file size no more than 10MB</p>
            </div>
          )}
        </div>
      )}

      <div className={styles.box}>
        {fileList.length > 0
          ? fileList.map((img, index) => {
              if (!img || img === 'null') return <div key={index}></div>

              const srcImage = () => {
                if (typeof img === 'string') {
                  if (img.search('https') > -1) {
                    return img
                  } else {
                    return `${s3URL}/${img}`
                  }
                } else return URL.createObjectURL(img)
              }
              return (
                <div className={styles.group} key={index}>
                  <div className={styles.wrapperImg}>
                    <img
                      src={srcImage()}
                      className={styles.imgList}
                      onClick={() => dispatch(setOpenExpanse({ imageUrl: srcImage() }))}
                    />
                  </div>
                  {!readonly && <img src={closeSVG} alt="" className={styles.icon} onClick={() => onDelete(index)} />}
                </div>
              )
            })
          : ''}
      </div>
    </div>
  )
}

export default DropZoneUpload
