// Lib
import React from 'react'

// Images

// Include in project
import Layout from 'layouts'
import { menuHeaderProduct } from 'utils/menu'

const MainProductManagement: React.FC = () => {
  return <Layout headerSectionName="Product Management" headerSectionManu={menuHeaderProduct}></Layout>
}

export default MainProductManagement
