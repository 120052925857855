// Lib
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

// Images

// Include in project
import { setOpenLoading, setCloseLoading } from 'states/slice/loading'

const useLoadingControl = (isLoading: boolean) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoading) dispatch(setOpenLoading())
    else dispatch(setCloseLoading())
  }, [isLoading])
}
export default useLoadingControl
