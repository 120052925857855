import { EResCode } from 'utils/other'
import { useErrMsgControl } from 'hooks'
import { useMutation } from '@apollo/client'
import { CREATE_USER_ROLE_COMPANY, UPDATE_USER_ROLE_COMPANY } from 'queries/userRoleCompany'

function useUserRole() {
  const [updateUserRole, optionUpdate] = useMutation(UPDATE_USER_ROLE_COMPANY, {
    context: { clientName: 'endpointAdmin' },
  })

  const [createUserRole, optionCreate] = useMutation(CREATE_USER_ROLE_COMPANY, {
    context: { clientName: 'endpointAdmin' },
  })

  const isLoading = optionUpdate.loading || optionCreate.loading

  const errMsg = () => {
    if (
      optionUpdate?.error ||
      (optionUpdate.data?.updateUserRoleCompany?.res_code &&
        optionUpdate.data?.updateUserRoleCompany?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateUserRoleCompany?.res_desc)

    if (
      optionCreate?.error ||
      (optionCreate.data?.createUserRoleCompany?.res_code &&
        optionCreate.data?.createUserRoleCompany?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createUserRoleCompany?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { isLoading, updateUserRole, createUserRole }
}

export default useUserRole
