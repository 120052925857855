// Lib
import React, { useState } from 'react'
import { getIn, useFormikContext } from 'formik'
import { OutlinedInput, IconButton, FormControl, FormHelperText } from '@mui/material'

//Images
import visibleSVG from 'images/commons/visibleGray.svg'
import unVisibleSVG from 'images/commons/unvisibleGray.svg'

// Include in project
import styles from './index.module.scss'
import Color from 'abstracts/export.module.scss'
import Font from 'abstracts/export.module.scss'

interface Props {
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  require?: boolean
}

const TextFieldPassword: React.FC<Props> = (props: Props) => {
  const { values, touched, errors, handleChange } = useFormikContext()
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  return (
    <div className={styles.wapper}>
      <p className="fontBold">
        {props.label}
        <span className="startRequire">{props.require && '*'}</span>
      </p>
      <div style={{ backgroundColor: Color.white }}>
        <FormControl variant="outlined" fullWidth>
          <OutlinedInput
            sx={{
              marginTop: '0.3em',
              fontSize: Font.fontSizeP,
              fontFamily: Font.fontFamilyRegular,
              borderRadius: '8px',
              backgroundColor: Color.grey100,
            }}
            inputProps={{
              sx: {
                fontSize: Font.fontSizeP,
              },
            }}
            size="small"
            name={props.name}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                {showPassword ? <img src={unVisibleSVG} alt="" /> : <img src={visibleSVG} alt="" />}
              </IconButton>
            }
            disabled={props.disabled}
            placeholder={props.placeholder}
            value={getIn(values, props.name)}
            onChange={handleChange}
            error={getIn(touched, props.name) && Boolean(getIn(errors, props.name))}
          />
          <FormHelperText error>
            {getIn(touched, props.name) && getIn(errors, props.name) && getIn(errors, props.name)}
          </FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

export default TextFieldPassword
