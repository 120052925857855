import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Company, Maybe } from 'utils/generated'

const initialState: {
  companyInfo: Maybe<Company>
  refetch: (() => void) | null
} = {
  companyInfo: null,
  refetch: null,
}

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany: (
      state,
      action: PayloadAction<{
        companyInfo: Company
        refetch: () => void
      }>,
    ) => {
      state.companyInfo = action.payload.companyInfo
      state.refetch = action.payload.refetch
    },
  },
})

export const { setCompany } = companySlice.actions

export default companySlice.reducer
