// Lib
import React from 'react'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { convertCurrencyFormatDecimal, convertCurrencyFormat } from 'utils/convertMoney'
import { IDashboardDetail } from 'pages/DashboardDetails'

const PerformanceCharts: React.FC = () => {
  const { values } = useFormikContext<IDashboardDetail>()

  ChartJS.register(ArcElement, Tooltip, Legend)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  }

  const received = values.performanceDetails?.paymentStatusReceived
  const outstanding = values.performanceDetails?.paymentStatusOutstanding

  const paid = values.performanceDetails?.orderStatusPaid
  const unPaid = values.performanceDetails?.orderStatusUnPaid

  const data = {
    labels: [],
    datasets: [
      {
        label: 'PAYMENT STATUS',
        data: [outstanding, received],
        backgroundColor: ['rgba(208, 26, 108, 1)', 'rgba(53, 183, 121, 1)'],
        hoverOffset: 4,
      },
    ],
  }

  const data2 = {
    labels: [],
    datasets: [
      {
        label: 'ORDER STATUS',
        data: [unPaid, paid],
        backgroundColor: ['rgba(208, 26, 108, 1)', 'rgba(102, 160, 254, 1)'],
        hoverOffset: 4,
      },
    ],
  }
  return (
    <div className={styles.container}>
      <div className={styles.contentGroupBox}>
        <h6 className={styles.textGray}>PAYMENT STATUS</h6>
        <div className={styles.box}>
          <div className={styles.chartbox}>
            <Doughnut data={data} options={options} />
          </div>
          <div className={styles.valueBox}>
            <div className={styles.colorGroup}>
              <div className={styles.colorGreen}></div>
              <h5>{convertCurrencyFormatDecimal(received || 0)} ฿</h5>
            </div>
            <p className={`fontBold ${styles.textGray}`}>Received</p>
            <div className={styles.colorGroup}>
              <div className={styles.colorPink}></div>
              <h5>{convertCurrencyFormatDecimal(outstanding || 0)} ฿</h5>
            </div>
            <p className={`fontBold ${styles.textGray}`}>Outstanding</p>
          </div>
        </div>
      </div>
      <div className={styles.contentGroupBox}>
        <h6 className={styles.textGray}>ORDER STATUS</h6>
        <div className={styles.box}>
          <div className={styles.chartbox}>
            <Doughnut data={data2} options={options} />
          </div>
          <div className={styles.valueBox}>
            <div className={styles.colorGroup}>
              <div className={styles.colorPink}></div>
              <h5>{convertCurrencyFormat(unPaid || 0)}</h5>
            </div>
            <p className={`fontBold ${styles.textGray}`}>Un-Paid</p>
            <div className={styles.colorGroup}>
              <div className={styles.colorBlue}></div>
              <h5>{convertCurrencyFormat(paid || 0)}</h5>
            </div>
            <p className={`fontBold ${styles.textGray}`}>Paid</p>
          </div>
        </div>
      </div>
      <div className={styles.contentGroupBox}>
        <h6 className={styles.textGray}>OUTSTANDING</h6>
        <div className={styles.textBox}>
          <h4>{convertCurrencyFormatDecimal(outstanding || 0)} ฿</h4>
        </div>
      </div>
    </div>
  )
}

export default PerformanceCharts
