import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UserRoleCompany } from 'utils/generated'
import { IOption } from 'utils/interface'

const initialState: {
  userRoleList: UserRoleCompany[]
  userRoleOptionList: IOption[]
  refetch: (() => void) | null
} = {
  userRoleList: [],
  userRoleOptionList: [],
  refetch: null,
}

export const userRoleSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    setUserRole: (
      state,
      action: PayloadAction<{
        items: UserRoleCompany[]
        refetch: () => void
      }>,
    ) => {
      state.userRoleList = action.payload.items
      state.userRoleOptionList = action.payload.items.map((data) => ({
        value: data.userRoleCompanyID as string,
        label: data.userRoleCompanyName as string,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setUserRole } = userRoleSlice.actions

export default userRoleSlice.reducer
