// Lib
import React from 'react'

// Include in project
import styles from './index.module.scss'

interface Prop {
  title: string
  value: string
  subTitle: string
  isNotSplit?: boolean
}

const CardSummary: React.FC<Prop> = ({ title, value, subTitle, isNotSplit }: Prop) => {
  const substringValue = value.length > 12 ? `${value.substring(0, 12)}...` : value
  const splitValue = substringValue?.split(' ')
  return (
    <div className={styles.container}>
      <h6>{title}</h6>
      <h4 className={styles.text}>
        {isNotSplit ? (
          substringValue
        ) : (
          <>
            {splitValue[0]}{' '}
            <small>
              <small>{splitValue[1]}</small>
            </small>
          </>
        )}
      </h4>
      <p>{subTitle}</p>
    </div>
  )
}

export default CardSummary
