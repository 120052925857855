import Color from 'abstracts/export.module.scss'
import Font from 'abstracts/export.module.scss'
import { ReactElement } from 'react'

export interface Props {
  color:
    | 'outlined'
    | 'danger'
    | 'info'
    | 'success'
    | 'warning'
    | 'secondary'
    | 'infoSecond'
    | 'gray'
    | 'ligthGray'
    | 'grayOutlined'
    | 'primary'
    | 'dark'
  label: string
  disabled?: boolean
  startIcon?: ReactElement
  onClick?: () => void
}

export const ThemeColor = {
  outlined: {
    color: `${Color.black}`,
    padding: '0 0.5em',
    border: `1px solid ${Color.black}`,
    backgroundColor: `${Color.white}`,
    '& span': {
      fontSize: Font.fontSizeP,
      fontFamily: Font.fontFamilyRegular,
    },
  },

  danger: {
    color: `${Color.white}`,
    padding: '0 0.5em',
    backgroundColor: `${Color.dangerMain}`,
    '& span': {
      fontSize: Font.fontSizeP,
      fontFamily: Font.fontFamilyRegular,
    },
  },
  warning: {
    color: `${Color.white}`,
    padding: '0 0.5em',
    backgroundColor: `${Color.warningMain}`,
    '& span': {
      fontSize: Font.fontSizeP,
      fontFamily: Font.fontFamilyRegular,
    },
  },
  success: {
    color: `${Color.white}`,
    padding: '0 0.5em',
    backgroundColor: `${Color.successMain}`,
    '& span': {
      fontSize: Font.fontSizeP,
      fontFamily: Font.fontFamilyRegular,
    },
  },
  info: {
    color: `${Color.white}`,
    padding: '0 0.5em',
    backgroundColor: `${Color.infoMain}`,
    '& span': {
      fontSize: Font.fontSizeP,
      fontFamily: Font.fontFamilyRegular,
    },
  },
  infoSecond: {
    color: `${Color.infoMain}`,
    padding: '0 0.5em',
    backgroundColor: `${Color.infoSecond}`,
    '& span': {
      fontSize: Font.fontSizeP,
      fontFamily: Font.fontFamilyBold,
    },
  },
  secondary: {
    color: `${Color.white}`,
    padding: '0 0.5em',
    backgroundColor: `${Color.secondaryMain}`,
    '& span': {
      fontSize: Font.fontSizeP,
      fontFamily: Font.fontFamilyRegular,
    },
  },
  gray: {
    color: `${Color.white}`,
    backgroundColor: `${Color.grey400}`,
    padding: '0 0.5em',
    '& span': {
      fontSize: Font.fontSizeP,
      fontFamily: Font.fontFamilyRegular,
    },
  },
  ligthGray: {
    color: `${Color.grey700}`,
    padding: '0 0.5em',
    backgroundColor: `${Color.grey200}`,
    '& span': {
      fontSize: Font.fontSizeP,
      fontFamily: Font.fontFamilyRegular,
    },
  },
  grayOutlined: {
    color: `${Color.grey400}`,
    border: `1px solid ${Color.grey200}`,
    padding: '0 0.5em',
    backgroundColor: `${Color.grey100}`,
    '& span': {
      fontSize: Font.fontSizeP,
      fontFamily: Font.fontFamilyRegular,
    },
  },
  primary: {
    color: `${Color.primaryMain}`,
    border: `1px solid ${Color.primaryFocus}`,
    padding: '0 0.5em',
    backgroundColor: `${Color.primaryFocus}`,
    '& span': {
      fontSize: Font.fontSizeP,
      fontFamily: Font.fontFamilyRegular,
    },
  },
  dark: {
    color: `${Color.white}`,
    padding: '0 0.5em',
    backgroundColor: `${Color.black}`,
    '& span': {
      fontSize: Font.fontSizeP,
      fontFamily: Font.fontFamilyRegular,
    },
  },
}
