// Lib
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import jwt_decode from 'jwt-decode'

// Include in project
import { E_Permission } from 'utils/generated'

const ProtectedRoutePermission: React.FC<{ permission: E_Permission }> = ({ permission }) => {
  const accessToken = localStorage.getItem('accessToken')
  const decode: { permission: string[] } = jwt_decode(accessToken as string)

  if (!decode.permission.includes(permission)) {
    // Can not open this page
    // Force Redirect to main page
    return <Navigate to="/" replace />
  }

  return <Outlet />
}

export default ProtectedRoutePermission
