// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'

// Include in project
import styles from './index.module.scss'
import { Modal, Notification } from 'component'
import { FormInput } from 'container'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { MutationUpdateCmsCompanyArgs } from 'utils/generated'
import { EResCode } from 'utils/other'
import { ICMS } from 'pages/CMS'
import { IRenderInput } from 'utils/interface'
import { setOpenLoading } from 'states/slice/loading'

interface Props {
  open: boolean
  handleClose: () => void
  updateCms: ({ variables }: { variables: MutationUpdateCmsCompanyArgs }) => Promise<any>
  refetch: () => void
}

const ModalCMS: React.FC<Props> = ({ open, handleClose, updateCms, refetch }) => {
  const { values } = useFormikContext<ICMS>()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const handleSave = async () => {
    dispatch(setOpenLoading())

    const packData = await transformationDataToServer({
      values: values,
      type: ETransformationDataToServer.UpdateCMS,
    })

    updateCms({ variables: packData as MutationUpdateCmsCompanyArgs }).then((res) => {
      const resUpdate = res.data.updateCMSCompany
      if (resUpdate.res_code !== EResCode.Success) return

      refetch()
      enqueueSnackbar(<Notification title="Success" description="Update CMS information." />, { variant: 'success' })
      handleClose()
    })
  }

  const fieldInputsPoster: IRenderInput[] = [
    {
      heading: 'Banner 1',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: values?.formInput?.poster?.banner1Show || [],
      name: 'formInput.poster.banner1Show',
      readonly: false,
      multiple: true,
    },
    {
      heading: 'Banner 2',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: values?.formInput?.poster?.banner2Show || [],
      name: 'formInput.poster.banner2Show',
      readonly: false,
      multiple: true,
    },
    {
      heading: 'Banner 3',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: values?.formInput?.poster?.banner3Show || [],
      name: 'formInput.poster.banner3Show',
      readonly: false,
      multiple: true,
    },
    {
      heading: 'Banner 4',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: values?.formInput?.poster?.banner4Show || [],
      name: 'formInput.poster.banner4Show',
      readonly: false,
      multiple: true,
    },
    {
      heading: 'Promotion banner',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: values?.formInput?.poster?.promotionBannerShow || [],
      name: 'formInput.poster.promotionBannerShow',
      readonly: false,
      multiple: true,
    },
    {
      heading: 'bestSellerBanner',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: values?.formInput?.poster?.bestSellerBannerShow || [],
      name: 'formInput.poster.bestSellerBannerShow',
      readonly: false,
      multiple: true,
    },
    {
      heading: 'Recommended banner',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: values?.formInput?.poster?.recommendedBannerShow || [],
      name: 'formInput.poster.recommendedBannerShow',
      readonly: false,
      multiple: true,
    },
  ]

  // const fieldInputsAnnouncement: IRenderInput[] = [
  //   {
  //     heading: 'Announcement',
  //     type: 'DROP_ZONE',
  //     sizeDrop: 'SM',
  //     fileList: values?.formInput?.announcement?.announcementShow || [],
  //     name: 'formInput.announcement.announcementShow',
  //     readonly: false,
  //     multiple: true,
  //   },
  // ]

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      handleSave={handleSave}
      width="80%"
      height="90%"
      title="Content Management System (CMS)"
    >
      <div className={styles.container}>
        <section className={styles.form}>
          <h5>Content Management System (CMS)</h5>
          <div className={styles.wrapperCard}>
            <div className={styles.box}>
              <FormInput title="Poster" fieldInputs={fieldInputsPoster} />
            </div>
            {/* <div className={styles.box}>
              <FormInput title="Announcement" fieldInputs={fieldInputsAnnouncement} />
            </div> */}
          </div>
        </section>
      </div>
    </Modal>
  )
}

export default ModalCMS
