// Lib
import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext, Form } from 'formik'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

//Images
import moveSVG from 'images/categories/move.svg'
import deleteSVG from 'images/commons/deleteDanger.svg'
import AddIcon from '@mui/icons-material/Add'

// Include in project
import styles from './index.module.scss'
import { OptionDetailEditSection } from 'container'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import { Button, TextArea, TextField, SelectSingle, Checkbox } from 'component'
import { ProductOption, ProductOptionVariant, E_Is_Active, Maybe } from 'utils/generatedNonAuth'
import { compareNumbers, optionList } from 'utils/other'
import { MutationUpdateProductOptionGroupArgs } from 'utils/generated'
import { IUpdateOption } from 'pages/UpdateOption'

interface Props {
  updateOption: ({ variables }: { variables: MutationUpdateProductOptionGroupArgs }) => Promise<any>
}

const OptionInformationEditSection: React.FC<Props> = ({ updateOption }) => {
  const { values, setFieldValue } = useFormikContext<IUpdateOption>()
  const dispatch = useDispatch()

  const reorder = (list: ProductOption[] | ProductOptionVariant[], startIndex: number, endIndex: number) => {
    const cloneList = JSON.parse(JSON.stringify(list))

    const startOfRange = Math.min(startIndex, endIndex)
    const endOfRange = Math.max(startIndex, endIndex)

    for (let index = startOfRange; index <= endOfRange; index++) {
      const findIndex = list.findIndex((ele) => ele.index === index)

      if (startIndex < endIndex) {
        if (index === startOfRange) cloneList[findIndex].index = endOfRange
        else cloneList[findIndex].index = index - 1
      } else {
        if (index === endOfRange) cloneList[findIndex].index = startOfRange
        else cloneList[findIndex].index = index + 1
      }
    }

    return cloneList
  }

  const onDragEnd = (result: DropResult) => {
    // console.log(result.source.index, result.destination.index, '<<<<INDEX')
    if (!result.destination) {
      return
    }
    if (result.type === 'droppableItem') {
      const items = reorder(values.formInput.option as ProductOption[], result.source.index, result.destination.index)

      setFieldValue('formInput.option', items)
    } else if (result.type.includes('droppableSubItem')) {
      const parentID = result.type.split('droppableSubItem')[1]

      const findParent = values.formInput?.option?.find((ele) => ele?.productOptionID === parentID)
      const findParentIndex = values.formInput?.option?.findIndex((ele) => ele?.productOptionID === parentID)

      const newOrderItemList = reorder(
        findParent?.variant as ProductOptionVariant[],
        result.source.index,
        result.destination.index,
      )

      setFieldValue(`formInput.option[${findParentIndex}].variant`, newOrderItemList)
    }
  }

  const handleAddOption = () => {
    const productOptionIDGenerate = `GENERATE${Math.floor(1000 + Math.random() * 9000)}`

    const listIndex: number[] = (values.formInput.option as ProductOption[])?.map((data) => data?.index as number) || []
    const newIndex = listIndex.length !== 0 ? Math.max(...listIndex) + 1 : 0

    const newOption = {
      customAttribute: {},
      description: '',
      index: newIndex,
      isActive: E_Is_Active.True,
      isFilter: E_Is_Active.True,
      isOption: E_Is_Active.True,
      name: '',
      productOptionGroupID: values.formInput.productOptionGroupID,
      productOptionID: productOptionIDGenerate,
      type: 'TAG',
      variant: [],
    }
    setFieldValue('formInput.option', [...((values.formInput?.option as ProductOption[]) || []), newOption])
  }

  const handleAddOptionVariant = (optionIndex: number) => {
    const productOptionVariantIDGenerate = `GENERATE${Math.floor(1000 + Math.random() * 9000)}`
    const listIndex: number[] = (
      (values.formInput.option as ProductOption[])[optionIndex].variant as ProductOptionVariant[]
    ).map((data) => data?.index as number)

    const newIndex = listIndex.length !== 0 ? Math.max(...listIndex) + 1 : 0

    const newOptionVariant = {
      customAttribute: {},
      description: '',
      index: newIndex,
      isActive: E_Is_Active.True,
      isDefault: E_Is_Active.False,
      name: '',
      productOptionID: (values.formInput.option as ProductOption[])[optionIndex].productOptionID,
      productOptionVariantID: productOptionVariantIDGenerate,
      remark: null,
    }
    setFieldValue(`formInput.option[${optionIndex}].variant`, [
      ...((values.formInput?.option as ProductOption[])[optionIndex].variant as ProductOptionVariant[]),
      newOptionVariant,
    ])
  }

  const handleDeleteOption = (index: number) => {
    const option = (values.formInput?.option as ProductOption[])[index]

    dispatch(
      setOpenDialog({
        title: 'Delete Option',
        description: `Delete Option ? ${option.name} `,
        status: 'danger',
        textButtonCancle: '',
        textButtonConfirm: '',
        functionOnSubmit: () => {
          dispatch(setCloseDialog())

          const cloneOption = JSON.parse(JSON.stringify(values.formInput?.option))

          if ((option.productOptionID as string).includes('GENERATE')) {
            setFieldValue(`formInput.option`, [...cloneOption.slice(0, index), ...cloneOption.slice(index + 1)])
          } else {
            setFieldValue(`formInput.option[${index}].isActive`, E_Is_Active.False)
          }
        },
      }),
    )
  }

  const handleDeleteOptionVariant = (index: number, indexSubItem: number) => {
    const vairant = ((values.formInput?.option as ProductOption[])[index].variant as ProductOptionVariant[])[
      indexSubItem
    ]

    dispatch(
      setOpenDialog({
        title: 'Delete Option',
        description: `Delete Option ? ${vairant.name} `,
        status: 'danger',
        textButtonCancle: '',
        textButtonConfirm: '',
        functionOnSubmit: () => {
          dispatch(setCloseDialog())

          const cloneOptionVariant = JSON.parse(
            JSON.stringify((values.formInput?.option as ProductOption[])[index].variant),
          )

          if ((vairant?.productOptionVariantID as string).includes('GENERATE')) {
            setFieldValue(`formInput.option[${index}].variant`, [
              ...cloneOptionVariant.slice(0, indexSubItem),
              ...cloneOptionVariant.slice(indexSubItem + 1),
            ])
          } else {
            setFieldValue(`formInput.option[${index}].variant[${indexSubItem}].isActive`, E_Is_Active.False)
          }
        },
      }),
    )
  }

  const handleChangeIsOption = (option: ProductOption, index: number) => {
    if (option?.isOption === E_Is_Active.True) {
      setFieldValue(`formInput.option[${index}].isOption`, E_Is_Active.False)

      // Clear Type To Null When isOption = false
      setFieldValue(`formInput.option[${index}].type`, null)

      // Clear isFilter false
      setFieldValue(`formInput.option[${index}].isFilter`, E_Is_Active.False)
    } else {
      setFieldValue(`formInput.option[${index}].isOption`, E_Is_Active.True)

      setFieldValue(`formInput.option[${index}].type`, 'TAG')

      setFieldValue(`formInput.option[${index}].isFilter`, E_Is_Active.True)
    }
  }

  const handleChangeIsFilter = (option: ProductOption, index: number) => {
    if (option?.isFilter === E_Is_Active.True) {
      setFieldValue(`formInput.option[${index}].isFilter`, E_Is_Active.False)
    } else {
      setFieldValue(`formInput.option[${index}].isFilter`, E_Is_Active.True)
    }
  }

  return (
    <Form className={styles.container}>
      <OptionDetailEditSection updateOption={updateOption} />

      <div className={styles.containerCard}>
        <h5>Option information</h5>
        <h6>Option Details</h6>

        <div className={styles.wrapperInput}>
          <div className={styles.header}>
            <p className="fontBold">Option Name</p>
            <p className="fontBold">Type</p>
            <p className="fontBold">Description (Optional)</p>
            <p className="fontBold">Values</p>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" type="droppableItem">
              {(providedRoot) => {
                return (
                  <div className={styles.box} ref={providedRoot.innerRef}>
                    {values.formInput?.option &&
                      values.formInput.option
                        ?.sort((prev, curr) => compareNumbers(prev?.index as number, curr?.index as number))
                        .map((option: Maybe<ProductOption>, index: number) => {
                          if (option?.isActive === E_Is_Active.False) return <></>

                          return (
                            <Draggable
                              key={option?.productOptionID}
                              draggableId={option?.productOptionID as string}
                              index={index}
                            >
                              {(providedSecond, snapshot) => (
                                <div
                                  aria-checked={snapshot.isDragging}
                                  className={styles.flexBox}
                                  ref={providedSecond.innerRef}
                                  {...providedSecond.draggableProps}
                                >
                                  <div className={styles.wrapper}>
                                    <img src={moveSVG} alt="" {...providedSecond.dragHandleProps} />
                                    <div className={styles.group}>
                                      <TextField name={`formInput.option[${index}].name`} type="text" />
                                      <div className={styles.wrapperCheck}>
                                        <Checkbox
                                          name={`formInput.option[${index}].isOption`}
                                          value=""
                                          checked={option?.isOption === E_Is_Active.True ? true : false}
                                          label="Option"
                                          onChange={() => handleChangeIsOption(option as ProductOption, index)}
                                        />
                                        <Checkbox
                                          name={`formInput.option[${index}].isFilter`}
                                          value=""
                                          checked={option?.isFilter === E_Is_Active.True ? true : false}
                                          label="Filter"
                                          onChange={() => handleChangeIsFilter(option as ProductOption, index)}
                                        />
                                        <div className={styles.buttonDel} onClick={() => handleDeleteOption(index)}>
                                          <img src={deleteSVG} alt="icon delete" />
                                          <p>Delete options</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <SelectSingle
                                    name={`formInput.option[${index}].type`}
                                    option={optionList.typeOption}
                                    disabled={option?.isOption === E_Is_Active.False}
                                  />
                                  <TextArea name={`formInput.option[${index}].description`} placeholder="Description" />

                                  <Droppable
                                    droppableId={option?.productOptionID as string}
                                    type={`droppableSubItem${option?.productOptionID}`}
                                  >
                                    {(providedThrid) => (
                                      <div className={styles.subContainer} ref={providedThrid.innerRef}>
                                        {option?.variant &&
                                          option.variant
                                            ?.sort((prev, curr) =>
                                              compareNumbers(prev?.index as number, curr?.index as number),
                                            )
                                            .map((optionVariant: Maybe<ProductOptionVariant>, idx: number) => {
                                              if (optionVariant?.isActive === E_Is_Active.False) return <></>

                                              return (
                                                <Draggable
                                                  key={optionVariant?.productOptionVariantID}
                                                  draggableId={optionVariant?.productOptionVariantID as string}
                                                  index={idx}
                                                >
                                                  {(providedFourth) => (
                                                    <div
                                                      className={styles.subBox}
                                                      ref={providedFourth.innerRef}
                                                      {...providedFourth.draggableProps}
                                                    >
                                                      <img src={moveSVG} alt="" {...providedFourth.dragHandleProps} />

                                                      <div className={styles.groupInput}>
                                                        <TextField
                                                          name={`formInput.option[${index}].variant[${idx}].name`}
                                                          type="text"
                                                        />
                                                        <img
                                                          src={deleteSVG}
                                                          alt=""
                                                          onClick={() => handleDeleteOptionVariant(index, idx)}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </Draggable>
                                              )
                                            })}
                                        {providedThrid.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                  <div className={styles.seftGridColumnStart}>
                                    <AddIcon className={styles.addIcon} onClick={() => handleAddOptionVariant(index)} />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        })}
                    {providedRoot.placeholder}
                  </div>
                )
              }}
            </Droppable>
          </DragDropContext>

          <div className={styles.wrapperButton}>
            <Button typeButton="button" type="info" name="Add Option" size="small" functionOnClick={handleAddOption} />
          </div>
        </div>
      </div>
    </Form>
  )
}

export default OptionInformationEditSection
