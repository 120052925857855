import Color from 'abstracts/export.module.scss'

export const cancelColorList = {
  backgroundColor: 'transparent',
  color: `${Color.primaryMain}`,
  border: `1px solid ${Color.primaryMain}`,
  '&:hover': {
    backgroundColor: `${Color.primaryMain}`,
    color: `${Color.white}`,
  },
}

export const okeyColorList = {
  default: {
    backgroundColor: `${Color.primaryMain}`,
    color: `${Color.white}`,
    '&:hover': {
      backgroundColor: `${Color.primaryMain}`,
    },
  },
  danger: {
    backgroundColor: `${Color.dangerMain}`,
    color: `${Color.white}`,
    '&:hover': {
      backgroundColor: `${Color.dangerMain}`,
    },
  },
  success: {
    backgroundColor: `${Color.successMain}`,
    color: `${Color.white}`,
    '&:hover': {
      backgroundColor: `${Color.successMain}`,
    },
  },
  warning: {
    backgroundColor: `${Color.warningMain}`,
    color: `${Color.white}`,
    '&:hover': {
      backgroundColor: `${Color.warningMain}`,
    },
  },
  info: {
    backgroundColor: `${Color.infoMain}`,
    color: `${Color.white}`,
    '&:hover': {
      backgroundColor: `${Color.infoMain}`,
    },
  },
}

export const ModalSx = {
  zIndex: '3000',
  '*': {
    outline: 'none',
  },
}
