import { useQuery } from '@apollo/client'
import { OPTION_LIST } from 'queries/option'
import { BRAND_LIST } from 'queries/brand'
import { TAG_LIST } from 'queries/tags'
import { USER_ROLE_COMPANY_LIST } from 'queries/userRoleCompany'
import { PRODUCT_LIST_NONE_CATEGORY_FOR_MASTERDATA } from 'queries/product'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'
import {
  BrandQuery,
  QueryBrandListArgs,
  TagQuery,
  QueryTagListArgs,
  QueryUserRoleCompanyListArgs,
  UserRoleCompanyQuery,
  Company,
} from 'utils/generated'
import {
  QueryProductOptionGroupListArgs,
  ProductOptionGroupQuery,
  QueryProductListNonCategoryArgs,
  ProductQuery,
} from 'utils/generatedNonAuth'
import { COMPANY_INFO } from 'queries/companyInfo'

interface Props {
  brandVariables: QueryBrandListArgs
  tagsVariables: QueryTagListArgs
  userRoleVariables: QueryUserRoleCompanyListArgs
  optionVariables: QueryProductOptionGroupListArgs
  productVariables: QueryProductListNonCategoryArgs
}

const useMasterData = ({
  brandVariables,
  tagsVariables,
  userRoleVariables,
  optionVariables,
  productVariables,
}: Props) => {
  const {
    data: dataCompany,
    loading: loadingCompany,
    error: errorCompany,
    refetch: refetchCompany,
  } = useQuery(COMPANY_INFO, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'endpointAdmin' },
  })

  const {
    data: dataBrand,
    loading: loadingBrand,
    error: errorBrand,
    refetch: refetchBrand,
  } = useQuery(BRAND_LIST, {
    fetchPolicy: 'no-cache',
    variables: brandVariables,
    context: { clientName: 'endpointAdmin' },
  })

  const {
    data: dataTags,
    loading: loadingTags,
    error: errorTags,
    refetch: refetchTags,
  } = useQuery(TAG_LIST, {
    fetchPolicy: 'no-cache',
    variables: tagsVariables,
    context: { clientName: 'endpointAdmin' },
  })

  const {
    data: dataUserRole,
    loading: loadingUserRole,
    error: errorUserRole,
    refetch: refetchUserRole,
  } = useQuery(USER_ROLE_COMPANY_LIST, {
    fetchPolicy: 'no-cache',
    variables: userRoleVariables,
    context: { clientName: 'endpointAdmin' },
  })

  const {
    data: dataOption,
    loading: loadingOption,
    error: errorOption,
    refetch: refetchOption,
  } = useQuery(OPTION_LIST, {
    fetchPolicy: 'no-cache',
    variables: optionVariables,
    context: { clientName: 'endpointNonAuth' },
  })

  const {
    data: dataProduct,
    loading: loadingProduct,
    error: errorProduct,
    refetch: refetchProduct,
  } = useQuery(PRODUCT_LIST_NONE_CATEGORY_FOR_MASTERDATA, {
    fetchPolicy: 'no-cache',
    variables: productVariables,
    context: { clientName: 'endpointNonAuth' },
  })

  const isLoading = loadingCompany || loadingBrand || loadingTags || loadingUserRole || loadingOption || loadingProduct

  const errMsg = () => {
    // Query Company
    if (errorCompany || (dataCompany?.companyInfo?.res_code && dataCompany?.companyInfo?.res_code !== EResCode.Success))
      return JSON.stringify(errorCompany || dataCompany?.companyInfo?.res_desc)

    // Query Brand
    if (errorBrand || (dataBrand?.brandList?.res_code && dataBrand?.brandList?.res_code !== EResCode.Success))
      return JSON.stringify(errorBrand || dataBrand?.brandList?.res_desc)

    // Query Tags
    if (errorTags || (dataTags?.tagList?.res_code && dataTags?.tagList?.res_code !== EResCode.Success))
      return JSON.stringify(errorTags || dataTags?.tagList?.res_desc)

    // Query User Role
    if (
      errorUserRole ||
      (dataUserRole?.userRoleCompanyList?.res_code && dataUserRole?.userRoleCompanyList?.res_code !== EResCode.Success)
    )
      return JSON.stringify(errorUserRole || dataUserRole?.userInfo?.res_desc)

    // Query Option
    if (
      errorOption ||
      (dataOption?.productOptionGroupList?.res_code &&
        dataOption?.productOptionGroupList?.res_code !== EResCode.Success)
    )
      return JSON.stringify(errorOption || dataOption?.productOptionGroupList?.res_desc)

    // Query Product
    if (
      errorProduct ||
      (dataProduct?.productListNonCategory?.res_code &&
        dataProduct?.productListNonCategory?.res_code !== EResCode.Success)
    )
      return JSON.stringify(errorProduct || dataProduct?.productListNonCategory?.res_desc)
  }

  useErrMsgControl(errMsg())

  return {
    dataCompany: dataCompany?.companyInfo as Company,
    refetchCompany,

    dataBrand: dataBrand?.brandList as BrandQuery,
    refetchBrand,

    dataTags: dataTags?.tagList as TagQuery,
    refetchTags,

    dataUserRole: dataUserRole?.userRoleCompanyList as UserRoleCompanyQuery,
    refetchUserRole,

    dataOption: dataOption?.productOptionGroupList as ProductOptionGroupQuery,
    refetchOption,

    dataProduct: dataProduct?.productListNonCategory as ProductQuery,
    refetchProduct,

    isLoading,
  }
}

export default useMasterData
