// Lib
import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'

// Images

// Include in project
import { Notification } from 'component'

const useErrMsgControl = (errMsg: string | undefined) => {
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!errMsg) return

    enqueueSnackbar(<Notification title="Error" description={errMsg} />, { variant: 'error' })
  }, [errMsg])
}
export default useErrMsgControl
