// Lib
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useNavigate } from 'react-router-dom'

// Include in project
import styles from './index.module.scss'
import { IOption, IRenderInput } from 'utils/interface'
import {
  DropZoneUpload,
  Switch,
  TextField,
  SelectSingle,
  TextFieldPassword,
  ChipByStatus,
  TextFieldDate,
  AutoComplete,
  TextArea,
  Button,
} from 'component'
import { E_Is_Active } from 'utils/generated'

interface Props {
  title: string
  fieldInputs: IRenderInput[]
}

const FormInput: React.FC<Props> = ({ fieldInputs, title }: Props): JSX.Element => {
  const navigate = useNavigate()

  const RenderInput = (ele: IRenderInput) => {
    if (ele.type === 'DROP_ZONE') {
      return (
        <DropZoneUpload
          sizeDrop={ele.sizeDrop as 'SM' | 'LG'}
          fileList={ele.fileList as any[]}
          readonly={ele.readonly}
          name={ele.name}
          multiple={ele.multiple}
        />
      )
    } else if (ele.type === 'SWITCH') {
      return (
        <Switch
          checked={ele.checked as boolean}
          labelDetail={ele.labelDetail as { true: string; false: string }}
          name={ele.name}
          disabled={ele.disabled}
          onChange={ele.onChange as () => void}
        />
      )
    } else if (ele.type === 'TEXT_FIELD') {
      return <TextField name={ele.name as string} disabled={ele.disabled} type="text" />
    } else if (ele.type === 'TEXT_AREA') {
      return <TextArea name={ele.name as string} disabled={ele.disabled} />
    } else if (ele.type === 'SELEC_SINGLE') {
      return <SelectSingle name={ele.name as string} disabled={ele.disabled} option={ele.option as IOption[]} />
    } else if (ele.type === 'TEXT_FIELD_PASSWORD') {
      return <TextFieldPassword name={ele.name as string} disabled={ele.disabled} />
    } else if (ele.type === 'TEXT') {
      return (ele?.words as string) || '-'
    } else if (ele.type === 'TEXT_LINK') {
      return (
        (
          <p className={styles.link} onClick={() => navigate(`${ele?.link}`)}>
            {ele?.words as string}
          </p>
        ) || '-'
      )
    } else if (ele.type === 'TEXT_LINK_EXTERNAL') {
      return (
        (
          <a className={styles.link} href={ele?.link} target="_blank" rel="noreferrer">
            {ele?.words as string}
          </a>
        ) || '-'
      )
    } else if (ele.type === 'CHIP_BY_STATUS') {
      return <ChipByStatus type={ele.chipType as E_Is_Active} />
    } else if (ele.type === 'TEXT_FIELD_DATE') {
      return <TextFieldDate name={ele.name as string} disabled={ele.disabled} />
    } else if (ele.type === 'AUTO_COMPLETE') {
      return <AutoComplete name={ele.name as string} option={ele.option as IOption[]} disabled={ele.disabled} />
    } else if (ele.type === 'TEXT_MARK_DOWN') {
      return <ReactMarkdown>{(ele?.words as string) || '-'}</ReactMarkdown>
    } else if (ele.type === 'BUTTON') {
      return (
        <Button
          name={(ele?.words as string) || '-'}
          typeButton="button"
          type="info"
          size="small"
          functionOnClick={ele.onClick}
        />
      )
    } else return <></>
  }

  return (
    <div className={styles.container}>
      <h6>{title}</h6>

      {fieldInputs.map((data, index) => {
        return (
          <div className={styles.box} key={index}>
            <div className={styles.wrapperText}>
              <p className="fontBold">
                {data.heading}
                <span className="colorRed">{data.require && '*'}</span>
              </p>
              {data.description && <p className={styles.text}>{data.description}</p>}
            </div>

            <div>{RenderInput(data)}</div>
          </div>
        )
      })}
    </div>
  )
}

export default FormInput
