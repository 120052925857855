// Lib
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Fade } from '@mui/material'

// Include in project
import styles from './index.module.scss'
import type { RootState } from 'states/store'
import { setCloseExpanse } from 'states/slice/modalExpanseImage'

const ModalExpanseImage: React.FC = () => {
  const dispatch = useDispatch()
  const modalExpanseImageReducer = useSelector((state: RootState) => state.modalExpanseImage)

  return (
    <Modal open={modalExpanseImageReducer.isOpen} onClose={() => dispatch(setCloseExpanse())} className={styles.main}>
      <Fade in={modalExpanseImageReducer.isOpen}>
        <img src={modalExpanseImageReducer.imageUrl} className={styles.imgExpanse} />
      </Fade>
    </Modal>
  )
}

export default ModalExpanseImage
