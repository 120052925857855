// Lib
import React from 'react'

//Images
import copySVG from 'images/commons/copy.svg'

// Include in project
import styles from './index.module.scss'
import { useCopyToClipboard } from 'hooks'

interface Props {
  valueCopy: string
}

const CopyInput: React.FC<Props> = ({ valueCopy }: Props) => {
  const { handleCopyToClipboard } = useCopyToClipboard()
  return (
    <div className={styles.container}>
      <p className={styles.text}>{valueCopy}</p>
      <img src={copySVG} alt="copySVG" onClick={() => handleCopyToClipboard(valueCopy)} />
    </div>
  )
}
export default CopyInput
