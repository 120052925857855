// Lib
import React from 'react'

// Include in project
import { Chip } from 'component'
import { E_Trigger_Type } from 'utils/generated'

export interface Props {
  type: string
}
const ChipByTrigger: React.FC<Props> = ({ type }): JSX.Element => {
  switch (type) {
    case E_Trigger_Type.GoodsIssue:
      return <Chip label="Goods Issue" color="danger" />
    case E_Trigger_Type.GoodsIssueVoid:
      return <Chip label="Goods Issue Void" color="gray" />

    case E_Trigger_Type.GoodsReceipt:
      return <Chip label="Goodss Receipt" color="dark" />
    case E_Trigger_Type.GoodsReceiptVoid:
      return <Chip label="Goods Receipt Void" color="gray" />

    case E_Trigger_Type.InventoryAdjustment:
      return <Chip label="Inventory Adjustment" color="secondary" />
    case E_Trigger_Type.InventoryAdjustmentVoid:
      return <Chip label="Inventory Adjustment Void" color="gray" />

    case E_Trigger_Type.PhysicalCount:
      return <Chip label="Physical Count" color="warning" />

    case E_Trigger_Type.Order:
      return <Chip label="Order" color="info" />
    case E_Trigger_Type.OrderVoid:
      return <Chip label="Order Void" color="gray" />

    case E_Trigger_Type.Approved:
      return <Chip label="Approved" color="success" />
    default:
      return <></>
  }
}

export default ChipByTrigger
