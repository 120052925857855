import { gql } from '@apollo/client'

export const PRODUCT_LIST_NONE_CATEGORY = gql`
  query productListNonCategory(
    $companyID: String!
    $filter: ProductListNonCategoryFilter!
    $filterSKU: ProductSKUFilter
    $pageSize: Int
    $nextToken: String
  ) {
    productListNonCategory(
      companyID: $companyID
      filter: $filter
      filterSKU: $filterSKU
      pageSize: $pageSize
      nextToken: $nextToken
    ) {
      res_code
      res_desc
      nextToken
      items {
        companyID
        productID
        brandID
        name
        description
        unitLabel
        productOptionGroupID
        productImgURL
        productFileURL
        isVisible
        isActive
        tagID
        customAttribute
        specAttribute
        minimumStock
        prefix
        remark
        createdAt
        updatedAt
        productSKU {
          productID
          productSKUID
          productID
          productSKU
          productSKUName
          customAttribute
          optionAttribute
          isActive
          isVisible
          tagID
          remark
          pricing {
            productSKUID
            pricingID
            productID
            pricingModel
            priceModelStandard {
              paymentType
              defaultPrice
              salePrice
            }
            cost
            currency
            customAttribute
            createdAt
            updatedAt
          }
          inventory {
            productSKUID
            transactionNo
            productID
            orderID
            triggerType
            stockIncoming
            stockCommitted
            stockAvailable
            stockAccumulated
            customAttribute
            createdAt
            updatedAt
          }
        }
        productOptionGroup {
          productOptionGroupID
          name
          description
          customAttribute
          isActive
          option {
            productOptionGroupID
            productOptionID
            index
            name
            description
            customAttribute
            isOption
            isActive
            companyID
            type
            variant {
              productOptionID
              productOptionVariantID
              index
              name
              description
              remark
              customAttribute
              isActive
              isDefault
              companyID
            }
          }
        }
      }
    }
  }
`

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($input: UpdateProduct!) {
    updateProduct(input: $input) {
      res_code
      res_desc
      companyID
      productID
    }
  }
`

export const PRODUCT_INFO = gql`
  query ProductInfo($productID: String!, $companyID: String!) {
    productInfo(productID: $productID, companyID: $companyID) {
      res_code
      res_desc
      companyID
      productID
      brandID
      name
      description
      unitLabel
      productOptionGroupID
      productImgURL
      productFileURL
      isVisible
      isActive
      tagID
      customAttribute
      specAttribute
      minimumStock
      prefix
      remark
      createdAt
      updatedAt
      productSKU {
        productID
        productSKUID
        productID
        productSKU
        productSKUName
        customAttribute
        optionAttribute
        isActive
        isVisible
        tagID
        remark
        pricing {
          productSKUID
          pricingID
          productID
          pricingModel
          priceModelStandard {
            paymentType
            defaultPrice
            salePrice
          }
          priceModelVolumn {
            paymentType
            tableVolumnPrice {
              startUnit
              endUnit
              salePrice
            }
          }
          cost
          currency
          customAttribute
          createdAt
          updatedAt
        }
        inventory {
          productSKUID
          transactionNo
          productID
          orderID
          triggerType
          stockIncoming
          stockCommitted
          stockAvailable
          stockAccumulated
          customAttribute
          createdAt
          updatedAt
        }
      }
      productOptionGroup {
        productOptionGroupID
        name
        description
        customAttribute
        isActive
        option {
          productOptionGroupID
          productOptionID
          index
          name
          description
          customAttribute
          isOption
          isActive
          companyID
          type
          variant {
            productOptionID
            productOptionVariantID
            index
            name
            description
            remark
            customAttribute
            isActive
            isDefault
            companyID
          }
        }
      }
    }
  }
`

export const PRODUCT_LIST = gql`
  query ProductList(
    $joinItemWithCategoryID: String!
    $companyID: String!
    $filter: JSON
    $filterSKU: ProductSKUFilter
    $isVisible: E_IS_ACTIVE
  ) {
    productList(
      joinItemWithCategoryID: $joinItemWithCategoryID
      companyID: $companyID
      filter: $filter
      filterSKU: $filterSKU
      isVisible: $isVisible
    ) {
      res_code
      res_desc
      items {
        companyID
        productID
        brandID
        name
        description
        unitLabel
        productOptionGroupID
        productImgURL
        productFileURL
        isVisible
        isActive
        tagID
        customAttribute
        specAttribute
        minimumStock
        prefix
        remark
        createdAt
        updatedAt
        productSKU {
          productID
          productSKUID
          productID
          productSKU
          productSKUName
          customAttribute
          optionAttribute
          isActive
          isVisible
          tagID
          remark
          pricing {
            productSKUID
            pricingID
            productID
            pricingModel
            priceModelStandard {
              paymentType
              defaultPrice
              salePrice
            }
            priceModelVolumn {
              paymentType
              tableVolumnPrice {
                startUnit
                endUnit
                salePrice
              }
            }
            cost
            currency
            customAttribute
            createdAt
            updatedAt
          }
          inventory {
            productSKUID
            transactionNo
            productID
            orderID
            triggerType
            stockIncoming
            stockCommitted
            stockAvailable
            stockAccumulated
            customAttribute
            createdAt
            updatedAt
          }
        }
        productOptionGroup {
          productOptionGroupID
          name
          description
          customAttribute
          isActive
          option {
            productOptionGroupID
            productOptionID
            index
            name
            description
            customAttribute
            isOption
            isActive
            companyID
            type
            variant {
              productOptionID
              productOptionVariantID
              index
              name
              description
              remark
              customAttribute
              isActive
              isDefault
              companyID
            }
          }
        }
      }
    }
  }
`

export const PRODUCT_LIST_NONE_CATEGORY_FOR_MASTERDATA = gql`
  query productListNonCategory(
    $companyID: String!
    $filter: ProductListNonCategoryFilter!
    $pageSize: Int
    $nextToken: String
  ) {
    productListNonCategory(companyID: $companyID, filter: $filter, pageSize: $pageSize, nextToken: $nextToken) {
      res_code
      res_desc
      nextToken
      items {
        companyID
        productID
        brandID
        name
        description
        unitLabel
        productOptionGroupID
        productImgURL
        productFileURL
        isVisible
        isActive
        tagID
        customAttribute
        specAttribute
        minimumStock
        prefix
        remark
        createdAt
        updatedAt
      }
    }
  }
`
