import Color from 'abstracts/export.module.scss'

export interface Props {
  checked: boolean
  name?: string
  onChange?: () => void
  disabled?: boolean
  label?: string
  labelDetail?: { true: string; false: string }
}

export const SwitchSx = {
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '2px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: Color.white,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: Color.successMain,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: Color.grey200,
    boxSizing: 'border-box',
  },
}
