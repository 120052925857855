import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface ModalDialogState {
  title: string
  description: string
  status: 'default' | 'danger' | 'success' | 'warning' | 'info'
  isOpen: boolean
  titleInput: string
  comment: string
  textButtonConfirm: string
  textButtonCancle: string
  functionOnSubmit: (arg?: string) => void
}

interface SetOpenDialogPayload {
  title: string
  description: string
  status: 'default' | 'danger' | 'success' | 'warning' | 'info'
  textButtonConfirm: string
  textButtonCancle: string
  functionOnSubmit: () => void
}

interface SetOpenDialogWithInputPayload {
  title: string
  description: string
  status: 'default' | 'danger' | 'success' | 'warning' | 'info'
  titleInput: string
  textButtonConfirm: string
  textButtonCancle: string
  functionOnSubmit: (arg?: string) => void
}

const initialState: ModalDialogState = {
  title: '',
  description: '',
  status: 'default',
  isOpen: false,
  comment: '',
  titleInput: '',
  textButtonConfirm: '',
  textButtonCancle: '',
  functionOnSubmit: () => null,
}

export const modalDialogSlice = createSlice({
  name: 'modalDialog',
  initialState,
  reducers: {
    setOpenDialog: (state, action: PayloadAction<SetOpenDialogPayload>) => {
      state.title = action.payload.title
      state.description = action.payload.description
      state.status = action.payload.status
      state.isOpen = true
      state.functionOnSubmit = action.payload.functionOnSubmit
      state.textButtonCancle = action.payload.textButtonCancle
      state.textButtonConfirm = action.payload.textButtonConfirm
    },
    setOpenDialogWithInput: (state, action: PayloadAction<SetOpenDialogWithInputPayload>) => {
      state.title = action.payload.title
      state.description = action.payload.description
      state.status = action.payload.status
      state.isOpen = true
      state.titleInput = action.payload.titleInput
      state.functionOnSubmit = action.payload.functionOnSubmit
      state.textButtonCancle = action.payload.textButtonCancle
      state.textButtonConfirm = action.payload.textButtonConfirm
    },
    setCommentDialog: (state, action: PayloadAction<{ comment: string }>) => {
      state.comment = action.payload.comment
    },
    setCloseDialog: (state) => {
      state.title = ''
      state.description = ''
      state.status = 'default'
      state.isOpen = false
      state.titleInput = ''
      state.comment = ''
      state.textButtonCancle = ''
      state.textButtonConfirm = ''
      state.functionOnSubmit = () => null
    },
  },
})

// Action creators are generated for each case reducer function
export const { setOpenDialog, setOpenDialogWithInput, setCommentDialog, setCloseDialog } = modalDialogSlice.actions

export default modalDialogSlice.reducer
