// Lib
import React, { useEffect } from 'react'
import { useFormikContext, withFormik } from 'formik'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { Divider, DatePickers } from 'component'
import { useDashboard, useLoadingControl } from 'hooks'
import { ChartsSection, LeaderboradsSection, PerformanceSection } from 'container'
import { EResCode } from 'utils/other'
import {
  DashboardChartQuery,
  DashboardLeaderboardsCustomersQuery,
  DashboardLeaderboardsInventoryQuery,
  DashboardLeaderboardsProductsQuery,
  DashboardPerformanceQuery,
  Maybe,
} from 'utils/generated'

export interface IDashboard {
  timeDuration: Date[]
  performance: Maybe<DashboardPerformanceQuery>
  chartHourly: Maybe<DashboardChartQuery>
  chartWeekly: Maybe<DashboardChartQuery>
  chartDayly: Maybe<DashboardChartQuery>
  chartMonthly: Maybe<DashboardChartQuery>
  leaderBoardsCustomers: Maybe<DashboardLeaderboardsCustomersQuery>
  leaderBoardsProducts: Maybe<DashboardLeaderboardsProductsQuery>
  leaderBoardsInventory: Maybe<DashboardLeaderboardsInventoryQuery>
}

const intitialValue: IDashboard = {
  timeDuration: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
  performance: null,
  chartHourly: null,
  chartWeekly: null,
  chartDayly: null,
  chartMonthly: null,
  leaderBoardsCustomers: null,
  leaderBoardsProducts: null,
  leaderBoardsInventory: null,
}

const Dashbord: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<IDashboard>()

  const {
    isLoading,
    getDashboardPerformance,
    getDashboardChartHourly,
    getDashboardChartWeekly,
    getDashboardChartDayly,
    getDashboardChartMonthly,
    getDashboardLeaderboardsProducts,
    getDashboardLeaderboardsCustomers,
    getDashboardLeaderboardsInventory,
  } = useDashboard()
  useLoadingControl(isLoading)

  useEffect(() => {
    if (!values.timeDuration[0] || !values.timeDuration[1]) return

    const startDate = values.timeDuration[0].toISOString().split('T')[0] + 'T00:00:00.000Z'
    const endDate = values.timeDuration[1].toISOString().split('T')[0] + 'T23:59:59.000Z'

    const timeDurationTranform = {
      startDate: startDate,
      endedDate: endDate,
    }

    getDashboardPerformance({
      variables: {
        filter: timeDurationTranform,
      },
    }).then((res) => {
      const resGet = res.data.dashboardPerformance
      if (resGet.res_code !== EResCode.Success) return

      setFieldValue('performance', resGet)
    })

    getDashboardChartHourly({
      variables: {
        filter: timeDurationTranform,
        selector: 'HOURLY',
      },
    }).then((res) => {
      const resGet = res.data.dashboardChart
      if (resGet.res_code !== EResCode.Success) return

      const plusSevenHour = resGet.items.map((data: Record<string, number>, index: number) => {
        let key = index + 7
        if (key > 23) key = key - 24
        return { [key]: data[index] }
      })
      plusSevenHour.sort((a: Record<string, number>, b: Record<string, number>) => {
        const aKey = parseInt(Object.keys(a)[0])
        const bKey = parseInt(Object.keys(b)[0])
        return aKey - bKey
      }),
        setFieldValue('chartHourly', { ...resGet, items: plusSevenHour })
    })

    getDashboardChartWeekly({
      variables: {
        filter: timeDurationTranform,
        selector: 'WEEKLY',
      },
    }).then((res) => {
      const resGet = res.data.dashboardChart
      if (resGet.res_code !== EResCode.Success) return

      setFieldValue('chartWeekly', resGet)
    })

    getDashboardChartDayly({
      variables: {
        filter: timeDurationTranform,
        selector: 'DAY',
      },
    }).then((res) => {
      const resGet = res.data.dashboardChart
      if (resGet.res_code !== EResCode.Success) return

      setFieldValue('chartDayly', resGet)
    })

    getDashboardChartMonthly({
      variables: {
        filter: timeDurationTranform,
        selector: 'MONTH',
      },
    }).then((res) => {
      const resGet = res.data.dashboardChart
      if (resGet.res_code !== EResCode.Success) return

      setFieldValue('chartMonthly', resGet)
    })

    getDashboardLeaderboardsProducts({
      variables: {
        filter: timeDurationTranform,
      },
    }).then((res) => {
      const resGet = res.data.dashboardLeaderboardsProducts
      if (resGet.res_code !== EResCode.Success) return

      setFieldValue('leaderBoardsProducts', resGet)
    })

    getDashboardLeaderboardsCustomers({
      variables: {
        filter: timeDurationTranform,
      },
    }).then((res) => {
      const resGet = res.data.dashboardLeaderboardsCustomers
      if (resGet.res_code !== EResCode.Success) return

      setFieldValue('leaderBoardsCustomers', resGet)
    })

    getDashboardLeaderboardsInventory({
      variables: {
        filter: timeDurationTranform,
      },
    }).then((res) => {
      const resGet = res.data.dashboardLeaderboardsInventory
      if (resGet.res_code !== EResCode.Success) return

      setFieldValue('leaderBoardsInventory', resGet)
    })
  }, [values.timeDuration])

  return (
    <Layout hiddenHeaderSection>
      <div className={styles.container}>
        <div className={styles.header}>
          <h4>E-Commerce</h4>
          <DatePickers name="timeDuration" />
        </div>
        <Divider />
        <PerformanceSection />
        <ChartsSection />
        <LeaderboradsSection />
      </div>
    </Layout>
  )
}

const EnhancedDashbord = withFormik({
  mapPropsToValues: () => ({ ...intitialValue }),
  handleSubmit: () => {
    console.log('Skip')
  },
})(Dashbord)

export default EnhancedDashbord
