// Lib
import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { useSnackbar } from 'notistack'

//Images
import { Add } from '@mui/icons-material'

// Include in project
import styles from './index.module.scss'
import { SelectSingle, Button, Divider, Modal, Table, TextField, Notification, ButtonFilterIsActive } from 'component'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { IProductInfo } from 'pages/ProductInfo'
import type { RootState } from 'states/store'
import { E_Currency, E_Is_Active, E_Payment_Type, E_Pricing_Model, ProductSku } from 'utils/generatedNonAuth'
import { transformationDataToServer, ETransformationDataToServer } from 'utils/transformationDataToServer'
import { MutationUpdateProductSkuArgs } from 'utils/generated'
import { EResCode } from 'utils/other'
import ModalUpdateProductPricing from 'container/ModalUpdateProductPricing'
import { useModal } from 'hooks'

interface Props {
  open: boolean
  handleClose: () => void
  updateSKU: ({ variables }: { variables: MutationUpdateProductSkuArgs }) => Promise<any>
  refetch: () => void
}

const ModalUpdateProductSKU: React.FC<Props> = ({ open, handleClose, updateSKU, refetch }) => {
  const { values, errors, setFieldValue } = useFormikContext<IProductInfo>()
  const optionReducer = useSelector((state: RootState) => state.optionGroup)
  const { enqueueSnackbar } = useSnackbar()
  const { isModalOpen: isModalOpenPricing, handleClose: handleClosePricing, handleOpen: handleOpenPricing } = useModal()

  // Delete and Recovery
  const handleDelete = (productSKUID: string) => {
    const findIndexSKU = values.formInputAddSKU.productSKU.findIndex((ele) => ele.productSKUID === productSKUID)
    setFieldValue(
      `formInputAddSKU.productSKU[${findIndexSKU}].isActive`,
      values.formInputAddSKU.productSKU[findIndexSKU].isActive === E_Is_Active.True
        ? E_Is_Active.False
        : E_Is_Active.True,
    )
  }

  const handleAddTableSKU = () => {
    const mapperOption =
      values.productInfo.productOptionGroup?.option
        ?.filter((ele) => ele?.isOption === E_Is_Active.True)
        .map((option) => {
          return {
            [`${option?.productOptionID}` as string]: '-',
          }
        }) || []

    const newSKU: ProductSku = {
      productID: values.productInfo.productID,
      productSKUID: `GENERATE${Math.floor(1000 + Math.random() * 9000)}`,
      productSKU: `${values.productInfo.prefix}`,
      productSKUName: '',
      customAttribute: {},
      optionAttribute: {
        ...Object.assign({}, ...mapperOption),
      },
      isActive: E_Is_Active.True,
      isVisible: E_Is_Active.True,
      tagID: null,
      remark: '',
      pricing: [
        {
          pricingID: null,
          pricingModel: E_Pricing_Model.StandardPricing,
          priceModelStandard: {
            paymentType: E_Payment_Type.OneTime,
            defaultPrice: 0,
            salePrice: 0,
          },
          priceModelVolumn: {
            paymentType: E_Payment_Type.OneTime,
            tableVolumnPrice: [{ startUnit: 1, endUnit: null, salePrice: 0 }],
          },
          cost: 0,
          currency: E_Currency.Thb,
          customAttribute: null,
        },
      ],
    }

    const cloneSKU: ProductSku[] = [...values.formInputAddSKU.productSKU]
    cloneSKU.push(newSKU)
    setFieldValue('formInputAddSKU.productSKU', cloneSKU)
  }

  const dataTable = transformationDataIntoTable({
    dataList: values.formInputAddSKU?.productSKU || [],
    type:
      values.filter.isActive === E_Is_Active.True
        ? ETranformationDataIntoTable.EditProductSKUList
        : ETranformationDataIntoTable.EditProductSKUListArchive,
    handleArchive: handleDelete,
    handleOpen: (index) => handleClickButtonPricing(index),
  })

  const columnTable = (): Record<string, string> => {
    const mapperOption =
      values.productInfo.productOptionGroup?.option
        ?.filter((ele) => ele?.isOption === E_Is_Active.True)
        .map((option) => {
          return {
            [option?.productOptionID as string]: option?.name as string,
          }
        }) || []

    return {
      productSKUHTML: 'SKU',
      productSKUNameHTML: 'SKU Name',
      ...Object.assign({}, ...mapperOption),
      pricingTypeHTML: 'Pricing Type',
      pricingHTML: 'Pricing',
      // imageHTML: 'Image',
      visibleHTML: 'Visible',
      action: '',
    }
  }

  const handleSaveSKU = async () => {
    if (!_.isEmpty(errors.formInputAddSKU)) return

    const packData = await transformationDataToServer({
      values: values,
      type: ETransformationDataToServer.UpdateSKU,
    })

    updateSKU({ variables: packData as MutationUpdateProductSkuArgs }).then((res) => {
      const resUpdate = res.data.updateProductSKU
      if (resUpdate.res_code !== EResCode.Success) return

      refetch()
      enqueueSnackbar(<Notification title="Success" description="Update SKU information." />, {
        variant: 'success',
      })
      handleClose()
    })
  }

  const [selectPricingSKUIndex, setSelectPricingSKUIndex] = useState<number>(0)
  const handleClickButtonPricing = (index: number) => {
    setSelectPricingSKUIndex(index)
    handleOpenPricing()
  }

  return (
    <>
      <Modal
        open={open}
        handleClose={handleClose}
        width="90%"
        height="90%"
        title="Add / Edit Product SKU"
        handleSave={handleSaveSKU}
      >
        <div className={styles.container}>
          <section className={styles.wrapperForm}>
            <h6>Product SKU</h6>
            <Divider />

            <div className={styles.wrapperBox}>
              <h6>Product Name</h6>
              <TextField name="productInfo.name" type="text" disabled />
            </div>
            <div className={styles.wrapperBox}>
              <h6>Product option group</h6>
              <SelectSingle
                name="productInfo.productOptionGroupID"
                option={optionReducer.optionGroupOptionList}
                disabled
              />
            </div>

            <div className={styles.headerTable}>
              <h6>Product option details</h6>
              <div className="row gapChips">
                <ButtonFilterIsActive isHiddenAllStatus />
                <Button
                  typeButton="button"
                  type="gray"
                  name="Add Table SKU"
                  size="small"
                  startIcon={<Add />}
                  functionOnClick={handleAddTableSKU}
                />
              </div>
            </div>

            <Table objectKey={columnTable()} data={dataTable} />
          </section>
        </div>
      </Modal>

      <ModalUpdateProductPricing
        open={isModalOpenPricing}
        handleClose={handleClosePricing}
        selectPricingSKUIndex={selectPricingSKUIndex}
      />
    </>
  )
}

export default ModalUpdateProductSKU
