export const pageSizeUnlimit = 999
export const pageSize = 50
export const s3URL = 'https://e-commerce-platform.s3.ap-southeast-1.amazonaws.com'

export const BUCKET_NAME = 'e-commerce-platform'
export const REGION_NAME = 'ap-southeast-1'
export const POOL_ID = 'ap-southeast-1:67776b8d-c959-452b-abcc-2f3c7ee10fc8'

export const BASE_URL =
  process.env.REACT_APP_STATE === 'PROD'
    ? 'https://4rh8occ262.execute-api.ap-southeast-1.amazonaws.com/prod/v1'
    : 'https://953834cma5.execute-api.ap-southeast-1.amazonaws.com/dev/v1'

export const GRAPHQL_URL =
  process.env.REACT_APP_STATE === 'PROD'
    ? 'https://4rh8occ262.execute-api.ap-southeast-1.amazonaws.com/prod/v1/graphql'
    : 'https://953834cma5.execute-api.ap-southeast-1.amazonaws.com/dev/v1/graphql'
