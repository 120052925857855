// Lib
import React from 'react'

//Include Project
import styles from './index.module.scss'

interface Props {
  objectKey: Record<string, string | JSX.Element>
  colSize?: Record<string, string>
  data: any[]
  handleClick?: (data: any) => void
}

const Table: React.FC<Props> = (props: Props) => {
  return (
    <section className={styles.wapperTable}>
      <table className={styles.tableMain}>
        {props.colSize && (
          <colgroup>
            {Object.keys(props.colSize).map((key, index) => (
              <col key={index} style={{ minWidth: props.colSize?.[key] }} />
            ))}
          </colgroup>
        )}

        <thead>
          <tr>
            {Object.keys(props.objectKey).map((key, index) => (
              <th key={index} className="fontBold">
                {props.objectKey[key]}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {props.data.length <= 0 ? (
            <tr>
              <td colSpan={Object.keys(props.objectKey).length}>No Data</td>
            </tr>
          ) : (
            <>
              {props.data.map((data, index) => {
                if (typeof data === 'string')
                  return (
                    <tr key={index}>
                      <td colSpan={Object.keys(props.objectKey).length} className={`${styles.rowHeadLine}`}>
                        <h6>{data}</h6>
                      </td>
                    </tr>
                  )

                return (
                  <tr key={index} onClick={() => props.handleClick?.(data)} aria-checked={Boolean(props.handleClick)}>
                    {Object.keys(props.objectKey).map((key, indexKey) => (
                      <td key={indexKey}>{data[key] ? data[key] : '-'}</td>
                    ))}
                  </tr>
                )
              })}
            </>
          )}
        </tbody>
      </table>
    </section>
  )
}

export default Table
