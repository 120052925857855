import { useMutation } from '@apollo/client'
import { CREATE_BRAND, UPDATE_BRAND } from 'queries/brand'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'

const useBrandMutation = () => {
  const [createBrand, optionCreate] = useMutation(CREATE_BRAND, { context: { clientName: 'endpointAdmin' } })
  const [updateBrand, optionUpdate] = useMutation(UPDATE_BRAND, { context: { clientName: 'endpointAdmin' } })
  const isLoading = optionCreate.loading || optionUpdate.loading

  const errMsg = () => {
    // optionCreate
    if (
      optionCreate?.error ||
      (optionCreate.data?.createBrand?.res_code && optionCreate.data?.createBrand?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createBrand?.res_desc)

    // optionUpdate
    if (
      optionUpdate?.error ||
      (optionUpdate.data?.updateBrand?.res_code && optionUpdate.data?.updateBrand?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateBrand?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { createBrand, updateBrand, isLoading }
}

export default useBrandMutation
