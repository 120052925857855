import { useLazyQuery } from '@apollo/client'
import {
  DASHBOARD_PERFORMANCE,
  DASHBOARD_PERFORMANCEP_DETAILS,
  DASHBOARD_CHART,
  DASHBOARD_LEADER_BOARDS_CUSTOMERS,
  DASHBOARD_LEADER_BOARDS_PRODUCTS,
  DASHBOARD_LEADER_BOARDS_INVENTORY,
} from 'queries/dashboard'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'

const useDashboard = () => {
  const [getDashboardPerformance, optionOne] = useLazyQuery(DASHBOARD_PERFORMANCE, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'endpointAdmin' },
  })

  const [getDashboardPerformanceDetails, optionTwo] = useLazyQuery(DASHBOARD_PERFORMANCEP_DETAILS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'endpointAdmin' },
  })

  const [getDashboardLeaderboardsCustomers, optionThree] = useLazyQuery(DASHBOARD_LEADER_BOARDS_CUSTOMERS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'endpointAdmin' },
  })

  const [getDashboardLeaderboardsProducts, optionFour] = useLazyQuery(DASHBOARD_LEADER_BOARDS_PRODUCTS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'endpointAdmin' },
  })

  const [getDashboardLeaderboardsInventory, optionFive] = useLazyQuery(DASHBOARD_LEADER_BOARDS_INVENTORY, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'endpointAdmin' },
  })

  const [getDashboardChartWeekly, optionSix] = useLazyQuery(DASHBOARD_CHART, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'endpointAdmin' },
  })

  const [getDashboardChartHourly, optionSeven] = useLazyQuery(DASHBOARD_CHART, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'endpointAdmin' },
  })

  const [getDashboardChartDayly, optionEight] = useLazyQuery(DASHBOARD_CHART, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'endpointAdmin' },
  })

  const [getDashboardChartMonthly, optionNine] = useLazyQuery(DASHBOARD_CHART, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'endpointAdmin' },
  })

  const isLoading =
    optionOne.loading ||
    optionTwo.loading ||
    optionThree.loading ||
    optionFour.loading ||
    optionFive.loading ||
    optionSix.loading ||
    optionSeven.loading ||
    optionEight.loading ||
    optionNine.loading

  const errMsg = () => {
    if (
      optionOne?.error ||
      (optionOne.data?.dashboardPerformance?.res_code &&
        optionOne.data?.dashboardPerformance?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionOne?.error || optionOne.data?.dashboardPerformance?.res_desc)

    if (
      optionTwo?.error ||
      (optionTwo.data?.dashboardPerformanceDetails?.res_code &&
        optionTwo.data?.dashboardPerformanceDetails?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionTwo?.error || optionTwo.data?.dashboardPerformanceDetails?.res_desc)

    if (
      optionThree?.error ||
      (optionThree.data?.dashboardLeaderboardsCustomers?.res_code &&
        optionThree.data?.dashboardLeaderboardsCustomers?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionThree?.error || optionThree.data?.dashboardLeaderboardsCustomers?.res_desc)

    if (
      optionFour?.error ||
      (optionFour.data?.dashboardLeaderboardsProducts?.res_code &&
        optionFour.data?.dashboardLeaderboardsProducts?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionFour?.error || optionFour.data?.dashboardLeaderboardsProducts?.res_desc)

    if (
      optionFive?.error ||
      (optionFive.data?.dashboardLeaderboardsInventory?.res_code &&
        optionFive.data?.dashboardLeaderboardsInventory?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionFive?.error || optionFive.data?.dashboardLeaderboardsInventory?.res_desc)

    if (
      optionSix?.error ||
      (optionSix.data?.dashboardChart?.res_code && optionSix.data?.dashboardChart?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionSix?.error || optionSix.data?.dashboardChart?.res_desc)

    if (
      optionSeven?.error ||
      (optionSeven.data?.dashboardChart?.res_code && optionSeven.data?.dashboardChart?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionSeven?.error || optionSeven.data?.dashboardChart?.res_desc)

    if (
      optionEight?.error ||
      (optionEight.data?.dashboardChart?.res_code && optionEight.data?.dashboardChart?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionEight?.error || optionEight.data?.dashboardChart?.res_desc)

    if (
      optionNine?.error ||
      (optionNine.data?.dashboardChart?.res_code && optionNine.data?.dashboardChart?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionNine?.error || optionNine.data?.dashboardChart?.res_desc)
  }

  useErrMsgControl(errMsg())

  return {
    isLoading,
    getDashboardPerformance,
    getDashboardPerformanceDetails,
    getDashboardLeaderboardsCustomers,
    getDashboardLeaderboardsProducts,
    getDashboardLeaderboardsInventory,
    getDashboardChartWeekly,
    getDashboardChartHourly,
    getDashboardChartDayly,
    getDashboardChartMonthly,
  }
}

export default useDashboard
