import Color from 'abstracts/export.module.scss'

export interface Props {
  name: string
  option: {
    label: string
    value: string
  }[]
  label?: string
  disabled?: boolean
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MenuProps = {
  sx: {
    height: '30px',
    '&& .Mui-selected': {
      backgroundColor: `${Color.primaryFocus}`,
      '&:hover': {
        backgroundColor: `${Color.primaryFocus}`,
      },
    },
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const SelectSx = {
  '&': {
    backgroundColor: `${Color.grey100}`,
    marginTop: '5px',
    borderRadius: '8px',
  },
}

export const CheckboxSx = {
  '&.Mui-checked': {
    color: `${Color.primaryMain}`,
  },
}

export const ListItemTextSx = {
  '&.Mui-selected': { color: `${Color.white}` },
}
