// Lib
import React, { useState } from 'react'
import { useFormikContext } from 'formik'

// Include in project
import { useModal } from 'hooks'
import { ModalOrderStatusTransection, FormInput } from 'container'
import { Button } from 'component'
import { IOrderManagement } from 'pages/OrderManagement'
import { IRenderInput } from 'utils/interface'
import { convertDateLL } from 'utils/convertDate'
import { E_Order_Status, E_Shipping_Type } from 'utils/generated'
import { optionList } from 'utils/other'

interface Props {
  handleUpdateStripe: () => void
}

const OrderInformationSection: React.FC<Props> = ({ handleUpdateStripe }) => {
  const { values } = useFormikContext<IOrderManagement>()
  const [inittialOrderStatus] = useState(values.formInput.orderStatus)
  // const { isModalOpen, handleClose, handleOpen } = useModal()

  const orderStatusOption = () => {
    if (inittialOrderStatus === E_Order_Status.WaitingPaid) {
      return [
        {
          value: E_Order_Status.WaitingPaid,
          label: 'Waiting Paid',
        },
        {
          value: E_Order_Status.Cancelled,
          label: 'Cancelled',
        },
      ]
    } else if (inittialOrderStatus === E_Order_Status.WaitingApproval) {
      return [
        {
          value: E_Order_Status.WaitingApproval,
          label: 'Waiting Approval',
        },
        {
          value: E_Order_Status.WaitingPaid,
          label: 'Waiting Paid',
        },
        {
          value: E_Order_Status.Approved,
          label: 'Approved',
        },
        {
          value: E_Order_Status.Cancelled,
          label: 'Cancelled',
        },
      ]
    } else if (inittialOrderStatus === E_Order_Status.Approved) {
      return [
        {
          value: E_Order_Status.Approved,
          label: 'Approved',
        },
        {
          value: E_Order_Status.Cancelled,
          label: 'Cancelled',
        },
      ]
    } else if (inittialOrderStatus === E_Order_Status.Cancelled) {
      return [
        {
          value: E_Order_Status.Cancelled,
          label: 'Cancelled',
        },
        {
          value: E_Order_Status.WaitingPaid,
          label: 'Waiting Paid',
        },
        {
          value: E_Order_Status.WaitingApproval,
          label: 'Waiting Approval',
        },
        {
          value: E_Order_Status.Approved,
          label: 'Approved',
        },
      ]
    } else return []
  }

  const fieldInputs: IRenderInput[] = [
    {
      heading: 'Order ID',
      type: 'TEXT',
      words: values.formInput.orderID,
    },
    {
      heading: 'Order Number',
      type: 'TEXT',
      words: values.formInput.orderNo,
    },
    {
      heading: 'Order Created',
      type: 'TEXT',
      words: convertDateLL(values.formInput.createdAt),
    },
    {
      heading: 'Order Updated',
      type: 'TEXT',
      words: convertDateLL(values.formInput.updatedAt),
    },
    {
      heading: 'Shipping Type',
      type: 'TEXT',
      words: values.formInput.shippingTypeHTML,
    },
    {
      heading: 'Payment Status',
      type: 'CHIP_BY_STATUS',
      chipType: values.formInput.paymentStatus,
    },
    {
      heading: 'Order Status',
      type: 'SELEC_SINGLE',
      name: 'formInput.orderStatus',
      disabled: false,
      option: orderStatusOption(),
    },
  ]

  const fieldInputsFilter = () => {
    if (values.formInput.orderStatus === E_Order_Status.TimeoutPaid) {
      fieldInputs.pop()
    }
    return fieldInputs
  }

  const fieldInputsShipping: IRenderInput[] = [
    {
      heading: 'Delivery Status',
      type: 'SELEC_SINGLE',
      name: 'formInput.deliveryStatus',
      disabled: false,
      option: optionList.deliveryStatusOption,
    },
    {
      heading: 'Shipping Company',
      type: 'SELEC_SINGLE',
      name: 'formInput.shippingCompany',
      disabled: values.formInput.shippingType === E_Shipping_Type.PickUpAtStore ? true : false,
      option: optionList.shippingCompanyOption,
    },
    {
      heading: 'Shipping Tracking ID',
      type: 'TEXT_FIELD',
      name: 'formInput.shippingTrackingID',
      disabled: values.formInput.shippingType === E_Shipping_Type.PickUpAtStore ? true : false,
    },
  ]

  return (
    <div>
      <FormInput title="Order Information" fieldInputs={fieldInputsFilter()} />
      {values.formInput.sessionID && values.formInput.orderStatus !== E_Order_Status.TimeoutPaid && (
        <div style={{ margin: '5px 0 0 auto', width: 'fit-content' }}>
          <Button
            typeButton="button"
            type="info"
            size="small"
            name="Check Stripe Status"
            functionOnClick={handleUpdateStripe}
          />
        </div>
      )}

      {values.formInput.rawOrderStatus === E_Order_Status.Approved && (
        <FormInput title="Shipping Information" fieldInputs={fieldInputsShipping} />
      )}

      {/* <ModalOrderStatusTransection open={isModalOpen} handleClose={handleClose} /> */}
    </div>
  )
}

export default OrderInformationSection
