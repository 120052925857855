// Lib
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFormikContext, withFormik } from 'formik'
import { useSelector } from 'react-redux'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { HeaderInformation, OptionInformationSection } from 'container'
import type { RootState } from 'states/store'
import { ProductOptionGroup } from 'utils/generatedNonAuth'

export interface IOptionInfomation {
  optionInfo: ProductOptionGroup
}

const intitialValue: IOptionInfomation = {
  optionInfo: {
    name: '',
    description: '',
  },
}

const OptionInformation: React.FC = () => {
  const { optionGroupID: productOptionGroupID } = useParams()
  const { setFieldValue } = useFormikContext()

  const optionGroupReducer = useSelector((state: RootState) => state.optionGroup)

  useEffect(() => {
    if (optionGroupReducer.optionGroupList.length === 0) return
    if (!productOptionGroupID) return

    const optionGroupListClone: ProductOptionGroup[] = JSON.parse(JSON.stringify(optionGroupReducer.optionGroupList))

    const optionGroup = optionGroupListClone.find((ele) => ele.productOptionGroupID === productOptionGroupID)
    setFieldValue('optionInfo', optionGroup)
  }, [optionGroupReducer.optionGroupList, productOptionGroupID])

  return (
    <Layout hiddenHeaderSection>
      <div className={styles.container}>
        <HeaderInformation section="Option Management" id={productOptionGroupID} />
        <OptionInformationSection />
      </div>
    </Layout>
  )
}

const EnhancedOptionInformation = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  validateOnMount: true,
  handleSubmit: () => {
    console.log('Skip')
  },
})(OptionInformation)

export default EnhancedOptionInformation
