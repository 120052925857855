// Lib
import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'

interface Props {
  dataList: any
}

const Charts: React.FC<Props> = ({ dataList }: Props) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        fullSize: true,
        labels: {
          boxWidth: 15,
        },
      },
      title: {
        display: false,
        text: '',
      },
    },
  }

  return <Bar options={options} data={dataList} />
}

export default Charts
