// Lib
import React, { useEffect } from 'react'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useFormikContext } from 'formik'
import { SelectChangeEvent } from '@mui/material'

// Include in project
import styles from './index.module.scss'
import { EResCode, convertEnumToWord, optionList } from 'utils/other'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { Modal, TextField, SelectSingle, Switch, Divider, Notification } from 'component'
import {
  E_Is_Active,
  E_Permission,
  E_User_Level,
  MutationCreateUserRoleCompanyArgs,
  MutationUpdateUserRoleCompanyArgs,
  UserRoleCompany,
} from 'utils/generated'

interface Props {
  open: boolean
  handleClose: () => void
  handleUpdate: ({ variables }: { variables: MutationUpdateUserRoleCompanyArgs }) => Promise<any>
  handleCreate: ({ variables }: { variables: MutationCreateUserRoleCompanyArgs }) => Promise<any>
  refetch: (() => void) | null
}

interface IPermissionProps {
  title: string
  permissionList: E_Permission[]
}

const ModalUpdatePermission: React.FC<Props> = ({ open, handleClose, refetch, handleUpdate, handleCreate }: Props) => {
  const { values, setFieldValue, errors } = useFormikContext<{ formInput: UserRoleCompany }>()
  const { enqueueSnackbar } = useSnackbar()

  const handleChangeSwitch = (permissionType: E_Permission) => {
    if (values.formInput.permission?.includes(permissionType)) {
      const data = values.formInput.permission.filter((item) => {
        return item !== permissionType
      })
      setFieldValue('formInput.permission', data)
    } else {
      setFieldValue('formInput.permission', [...(values.formInput.permission as E_Permission[]), permissionType])
    }
  }

  const handleSelectAll = (dataChecked: E_Permission[]) => {
    if (
      dataChecked.every((data) => {
        return values.formInput.permission?.includes(data)
      })
    ) {
      const data = values.formInput.permission?.filter((item: any) => !dataChecked.includes(item))
      setFieldValue('formInput.permission', data)
    } else {
      const data = [...(values.formInput.permission as E_Permission[]), ...dataChecked]
      setFieldValue('formInput.permission', data || [])
    }
  }

  const handleUpdateUserRole = async () => {
    if (!_.isEmpty(errors)) return

    const packData = await transformationDataToServer({
      type: ETransformationDataToServer.UpdateRoleCompany,
      values: values,
    })

    if (values.formInput.userRoleCompanyID) {
      handleUpdate({ variables: packData as MutationUpdateUserRoleCompanyArgs }).then((res) => {
        const resUpdate = res.data.updateUserRoleCompany
        if (resUpdate.res_code !== EResCode.Success) return

        refetch?.()
        handleClose()
        enqueueSnackbar(<Notification title="Success" description="Update User Role." />, {
          variant: 'success',
        })
      })
    } else {
      const packDataCreate = _.omit(packData, ['input.isActive'], ['input.userRoleCompanyID'])

      handleCreate({ variables: packDataCreate as MutationCreateUserRoleCompanyArgs }).then((res) => {
        const resCreate = res.data.createUserRoleCompany
        if (resCreate.res_code !== EResCode.Success) return

        refetch?.()
        handleClose()
        enqueueSnackbar(<Notification title="Success" description="Create New User Role." />, {
          variant: 'success',
        })
      })
    }
  }

  const RenderSwitchPermission: React.FC<IPermissionProps> = ({ title, permissionList }) => {
    return (
      <div className={styles.box}>
        <section className={styles.header}>
          <div className={styles.group}>
            <h6>{title}</h6>
            <div className={styles.wrapperCheck}>
              <input
                type="checkbox"
                checked={permissionList.every((value: E_Permission) => {
                  return values.formInput.permission?.includes(value)
                })}
                onChange={() => handleSelectAll(permissionList)}
              />
              All
            </div>
          </div>
          <Divider />
        </section>

        <div className={styles.wrapper}>
          {permissionList.map((permission, index) => (
            <Switch
              key={index}
              checked={values.formInput.permission?.includes(permission) as boolean}
              labelDetail={{ true: convertEnumToWord(permission), false: '' }}
              onChange={() => handleChangeSwitch(permission)}
            />
          ))}
        </div>
      </div>
    )
  }

  const handleChangeLevel = (e: SelectChangeEvent) => {
    const value = e.target.value
    setFieldValue('formInput.userLevel', value)

    if (value === E_User_Level.Employee) {
      setFieldValue('formInput.permission', [
        E_Permission.AllProduct,
        E_Permission.Category,
        E_Permission.Brand,
        E_Permission.Option,
        E_Permission.Tags,
        E_Permission.InventoryAllProduct,
        E_Permission.InventoryTransaction,
        E_Permission.Order,
      ])
    } else {
      setFieldValue('formInput.permission', [
        E_Permission.Dashboard,
        E_Permission.CustomerList,
        E_Permission.StaffList,
        E_Permission.StaffPermission,

        E_Permission.AllProduct,
        E_Permission.Category,
        E_Permission.Brand,
        E_Permission.Option,
        E_Permission.Tags,
        E_Permission.InventoryAllProduct,
        E_Permission.InventoryTransaction,
        E_Permission.Order,

        E_Permission.Cms,
        E_Permission.Setting,
      ])
    }
  }

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      handleSave={handleUpdateUserRole}
      width="50%"
      height="90%"
      title="Staff Permission"
      buttonText="Save Staff Permission"
    >
      <div className={styles.container}>
        <div className={styles.contentBox}>
          <section className={styles.boxHeader}>
            <h5>Staff Permission</h5>
            <Switch
              checked={(values.formInput?.isActive === E_Is_Active.True) as boolean}
              labelDetail={{ true: 'Active', false: 'Inactive' }}
              onChange={() => {
                if (values.formInput?.isActive === E_Is_Active.True) {
                  setFieldValue('formInput.isActive', E_Is_Active.False)
                } else {
                  setFieldValue('formInput.isActive', E_Is_Active.True)
                }
              }}
            />
          </section>
          <div>
            <h6>Permission details</h6>
            <TextField
              label="User Role"
              placeholder="User Role"
              type="text"
              name="formInput.userRoleCompanyName"
              require
            />
            <SelectSingle
              name="formInput.userLevel"
              label="User Level"
              option={optionList.userLevel}
              onChange={handleChangeLevel}
              require
            />
          </div>

          <div className={styles.wrapperBox}>
            <RenderSwitchPermission title="Dashboard" permissionList={[E_Permission.Dashboard]} />
            <RenderSwitchPermission
              title="Customers"
              permissionList={[E_Permission.CustomerList, E_Permission.StaffList, E_Permission.StaffPermission]}
            />
            <RenderSwitchPermission
              title="Products"
              permissionList={[
                E_Permission.AllProduct,
                E_Permission.Category,
                E_Permission.Brand,
                E_Permission.Option,
                E_Permission.Tags,
              ]}
            />
            <RenderSwitchPermission
              title="Inventory"
              permissionList={[E_Permission.InventoryAllProduct, E_Permission.InventoryTransaction]}
            />
            <RenderSwitchPermission title="Orders" permissionList={[E_Permission.Order]} />
            <RenderSwitchPermission title="CMS" permissionList={[E_Permission.Cms]} />
            <RenderSwitchPermission title="Setting" permissionList={[E_Permission.Setting]} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalUpdatePermission
