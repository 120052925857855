import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT } from 'queries/product'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'

const useProductMutation = () => {
  const [updateProduct, optionUpdate] = useMutation(UPDATE_PRODUCT, { context: { clientName: 'endpointAdmin' } })
  const isLoading = optionUpdate.loading

  const errMsg = () => {
    // optionUpdate
    if (
      optionUpdate?.error ||
      (optionUpdate.data?.updateProduct?.res_code && optionUpdate.data?.updateProduct?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateProduct?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { updateProduct, isLoading }
}

export default useProductMutation
