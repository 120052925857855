// Lib
import React from 'react'
import { withFormik, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

//Image
import { IosShare, AddCircle } from '@mui/icons-material'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import yupUserRole from 'validations/yupUserRole.validate'
import { E_Is_Active, UpdateUserRoleCompany } from 'utils/generated'
import { useModal, useUserRoleMutation } from 'hooks'
import { ModalUpdatePermission } from 'container'
import { menuHeaderCustomer } from 'utils/menu'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { Button, Table, ButtonFilterIsActive } from 'component'
import type { RootState } from 'states/store'

export interface IPermissionManagement {
  formInput: UpdateUserRoleCompany
  filter: {
    isActive: E_Is_Active | 'ALL'
  }
}

const intitialValue: IPermissionManagement = {
  formInput: {
    userRoleCompanyName: '',
    userRoleCompanyID: '',
    isActive: E_Is_Active.True,
    userLevel: null,
    permission: [],
  },
  filter: {
    isActive: E_Is_Active.True,
  },
}

const PermissionManagement: React.FC = () => {
  const { values } = useFormikContext<IPermissionManagement>()

  const { userRoleList, refetch } = useSelector((state: RootState) => state.userRole)
  const { updateUserRole, createUserRole } = useUserRoleMutation()

  const userRoleListAfterFilter = userRoleList?.filter((ele) => {
    if (values.filter.isActive === 'ALL') return true
    return ele.isActive === values.filter.isActive
  })

  const dataTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.PermissionManagementList,
    dataList: userRoleListAfterFilter || [],
  })

  const { isModalOpen, handleOpen, handleClose } = useModal(intitialValue.formInput)

  return (
    <Layout headerSectionName="User Management" headerSectionManu={menuHeaderCustomer}>
      <div className={styles.container}>
        <div className={styles.wrapperButton}>
          <ButtonFilterIsActive />
          <Button
            name="Add Staff Permission"
            typeButton="button"
            type="info"
            size="medium"
            startIcon={<AddCircle />}
            functionOnClick={() => handleOpen()}
          />
        </div>
        <Table
          objectKey={{
            userRoleCompanyNameHTML: 'User Role',
            userLevel: 'User Level',
            createdAt: 'Created At',
            statusHTML: 'Status',
          }}
          data={dataTable}
          handleClick={handleOpen}
        />
        <ModalUpdatePermission
          open={isModalOpen}
          handleClose={handleClose}
          handleUpdate={updateUserRole}
          refetch={refetch}
          handleCreate={createUserRole}
        />
      </div>
    </Layout>
  )
}
const EnhancedPermissionManagement = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  handleSubmit: () => {
    console.log('SKIP')
  },
  validationSchema: yupUserRole,
  validateOnMount: true,
})(PermissionManagement)

export default EnhancedPermissionManagement
