import { gql } from '@apollo/client'

export const USER_INFO = gql`
  query userInfo {
    userInfo {
      res_code
      res_desc
      userID
      companyID
      userRoleGlobal
      uuidGoogle
      uuidFacebook
      uuidLine
      phoneNumber
      email
      firstname
      surname
      idCard
      birthday
      customAttribute
      profileImgURL
      idCardImgURL
      isActive
      createdAt
      updatedAt
      userRoleCompany {
        companyID
        userRoleCompanyID
        userRoleCompanyName
        permission
        isActive
        createdAt
        updatedAt
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUserInfo($input: UpdateUserInfo!) {
    updateUserInfo(input: $input) {
      res_code
      res_desc
      userID
    }
  }
`
