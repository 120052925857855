// Lib
import React from 'react'
import { useNavigate } from 'react-router-dom'

// Images
import imageIconChangePassWordSVG from 'images/forgotPassword/iconChangePassWord.svg'

// Include in project
import styles from './index.module.scss'
import { Button } from 'component'

const ForgotPasswordStepFour: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.container}>
      <div className={styles.wrapperText}>
        <h6>เปลี่ยนรหัสเรียบร้อยแล้ว</h6>
        <p className="fontBold">อีเมลของคุณ example@email.com </p>
      </div>
      <img src={imageIconChangePassWordSVG} alt="" />
      <Button
        name="เข้าสู่ระบบ"
        typeButton="button"
        type="info"
        size="large"
        functionOnClick={() => navigate('/login')}
      />
    </div>
  )
}

export default ForgotPasswordStepFour
