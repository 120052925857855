// Lib
import React from 'react'

// Include in project
import styles from './index.module.scss'
import { convertCurrencyFormatDecimal } from 'utils/convertMoney'

//Images
import { CallMade, CallReceived } from '@mui/icons-material'

interface Props {
  section: string
  value: number
  circulation: number
  subTitle: string
  total: number
  unit: string
}

const CardPerformance: React.FC<Props> = ({ section, value, circulation, subTitle, total, unit }: Props) => {
  return (
    <div className={styles.container}>
      <p className={`fontBold ${styles.text}`}>{section}</p>
      <div className={styles.wrapperText}>
        <h6>
          {convertCurrencyFormatDecimal(value)} {unit}
        </h6>
        <section className={styles.groupText} aria-checked={circulation < 0}>
          {circulation < 0 ? (
            <CallReceived sx={{ width: '20px', height: '20px' }} />
          ) : (
            <CallMade sx={{ width: '20px', height: '20px' }} />
          )}
          <p>{circulation} %</p>
        </section>
      </div>
      <div className={styles.wrapperText}>
        <h6 className={styles.text}>{subTitle}</h6>
        <p className={`fontBold ${styles.text}`}>
          {convertCurrencyFormatDecimal(total)} {unit}
        </p>
      </div>
    </div>
  )
}

export default CardPerformance
