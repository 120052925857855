// Lib
import React from 'react'
import { useNavigate } from 'react-router-dom'

//Image
import copySVG from 'images/commons/copy.svg'
import { ArrowBackRounded } from '@mui/icons-material'

// Include in project
import styles from './index.module.scss'
import { useCopyToClipboard } from 'hooks'

interface Props {
  section: string
  id: string | undefined
}

const HeaderInformation: React.FC<Props> = ({ section, id }: Props) => {
  const navigate = useNavigate()
  const { handleCopyToClipboard } = useCopyToClipboard()

  return (
    <div className={styles.container}>
      <div className={styles.groupText} onClick={() => navigate(-1)}>
        <ArrowBackRounded className={styles.icon} />
        <p>{section}</p>
      </div>
      <div className={styles.groupText} onClick={() => handleCopyToClipboard(id || '')}>
        <p>{id}</p>
        <img src={copySVG} className={styles.icon} />
      </div>
    </div>
  )
}

export default HeaderInformation
