// Lib
import React from 'react'
import { Button } from '@mui/material'

// Include in project
import { Props, colorButton } from './config'

const ButtonComponent: React.FC<Props> = (props: Props) => {
  const seletedColor = colorButton[props.type]

  return (
    <Button
      type={props.typeButton}
      onClick={props.functionOnClick && props.functionOnClick}
      disabled={props.disabled}
      sx={seletedColor}
      size={props.size ? props.size : 'medium'}
      style={{
        textTransform: 'none',
        borderRadius: '8px',
        flexWrap: 'wrap',
      }}
      variant="contained"
      fullWidth={props.fullWidth}
      className={props.size === 'small' ? 'fontRegular' : 'fontBold'}
      startIcon={props.startIcon && props.startIcon}
      endIcon={props.endIcon && props.endIcon}
    >
      {props.name && props.name}
    </Button>
  )
}

export default ButtonComponent
