import { gql } from '@apollo/client'

export const CMS_COMPANY_INFO = gql`
  query cmsCompanyInfo($companyID: String!) {
    cmsCompanyInfo(companyID: $companyID) {
      res_code
      res_desc
      companyID
      poster
      announcement
      customAttribute
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_CMS = gql`
  mutation updateCMSCompany($input: UpdateCMSCompany!) {
    updateCMSCompany(input: $input) {
      res_code
      res_desc
      companyID
    }
  }
`
