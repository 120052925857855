import Color from 'abstracts/export.module.scss'
import Font from 'abstracts/export.module.scss'

export interface Props {
  name: string
  label?: string
  option: {
    value: string | number
    label: string
  }[]
  placeholder?: string
  disabled?: boolean
  require?: boolean
}

export const ColorThemeTextField = {
  '.MuiInputBase-root': {
    marginTop: '5px',
    backgroundColor: `${Color.white}`,
  },
  '& span': {
    fontSize: Font.fontSizeP,
    fontFamily: Font.fontFamilyRegular,
  },
  '.MuiChip-filled': {
    color: `${Color.grey700}`,
    backgroundColor: `${Color.grey200}`,
  },
}
