// Lib
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { IDashboard } from 'pages/Dashboard'

//Images
import { Button, CardPerformance } from 'component'

const PerformanceSection: React.FC = () => {
  const { values } = useFormikContext<IDashboard>()
  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h6>Performance</h6>
        <Button
          name="Details"
          typeButton="button"
          type="gray"
          size="small"
          functionOnClick={() => navigate('/dashboard/details')}
        />
      </section>

      <div className={styles.wrapperCard}>
        <CardPerformance
          section="NET REVENUE"
          value={values.performance?.netRevenue?.total || 0}
          circulation={values.performance?.netRevenue?.percentage || 0}
          subTitle="Previous Period:"
          total={values.performance?.netRevenue?.previousTotal || 0}
          unit="฿"
        />
        <CardPerformance
          section="GROSS PROFIT"
          value={values.performance?.grossProfit?.total || 0}
          circulation={values.performance?.grossProfit?.percentage || 0}
          subTitle="Previous Period:"
          total={values.performance?.grossProfit?.previousTotal || 0}
          unit="฿"
        />
        <CardPerformance
          section="AMOUNT OF ORDERS"
          value={values.performance?.amountOfOrders?.total || 0}
          circulation={values.performance?.amountOfOrders?.percentage || 0}
          subTitle="Previous Period:"
          total={values.performance?.amountOfOrders?.previousTotal || 0}
          unit="Orders"
        />
        <CardPerformance
          section="NUMBER OF ITEMS"
          value={values.performance?.amountOfOrders?.total || 0}
          circulation={values.performance?.amountOfOrders?.percentage || 0}
          subTitle="Previous Period:"
          total={values.performance?.amountOfOrders?.previousTotal || 0}
          unit="items"
        />
      </div>
    </div>
  )
}

export default PerformanceSection
