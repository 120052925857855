import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ProductOptionGroup } from 'utils/generatedNonAuth'
import { IOption } from 'utils/interface'

const initialState: {
  optionGroupList: ProductOptionGroup[]
  optionGroupOptionList: IOption[]
  refetch: (() => void) | null
} = {
  optionGroupList: [],
  optionGroupOptionList: [],
  refetch: null,
}

export const optionGroupSlice = createSlice({
  name: 'optionGroup',
  initialState,
  reducers: {
    setOptionGroup: (
      state,
      action: PayloadAction<{
        items: ProductOptionGroup[]
        refetch: () => void
      }>,
    ) => {
      state.optionGroupList = action.payload.items
      state.optionGroupOptionList = action.payload.items.map((data) => ({
        value: data.productOptionGroupID as string,
        label: data.name as string,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setOptionGroup } = optionGroupSlice.actions

export default optionGroupSlice.reducer
