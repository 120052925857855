// Lib
import React from 'react'
import { Menu, MenuItem } from '@mui/material'

//Include Project
import { ThemeMenu } from './config'

interface Props {
  open: boolean
  anchorEl: HTMLElement | null
  listMenu: { title: string; functionOnClick: () => void }[]
  handleClose: () => void
}

const MenuList: React.FC<Props> = ({ open, anchorEl, listMenu, handleClose }: Props) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClick={(e: any) => e.stopPropagation()}
      onClose={handleClose}
      sx={ThemeMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
        disablePadding: true,
      }}
    >
      {listMenu.map((item, index: number) => (
        <MenuItem
          onClick={(e: any) => {
            e.stopPropagation()
            item.functionOnClick()
          }}
          key={index}
        >
          <p>{item.title}</p>
        </MenuItem>
      ))}
    </Menu>
  )
}

export default MenuList
