import { useMutation } from '@apollo/client'
import { CREATE_TAG, UPDATE_TAG } from 'queries/tags'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'

function useTags() {
  const [createTags, optionCreate] = useMutation(CREATE_TAG, { context: { clientName: 'endpointAdmin' } })
  const [updateTags, optionUpdate] = useMutation(UPDATE_TAG, { context: { clientName: 'endpointAdmin' } })

  const isLoading = optionCreate.loading || optionUpdate.loading

  const errMsg = () => {
    // optionCreate
    if (
      optionCreate?.error ||
      (optionCreate.data?.createTag?.res_code && optionCreate.data?.createTag?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createTag?.res_desc)

    // optionUpdate
    if (
      optionUpdate?.error ||
      (optionUpdate.data?.updateTag?.res_code && optionUpdate.data?.updateTag?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateTag?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { createTags, updateTags, isLoading }
}

export default useTags
