import { useMutation, useQuery } from '@apollo/client'
import { CATEGORY_LIST, UPDATE_CATEGORY, UPDATE_JOIN_PRODUCT_CATEGORY, DELETE_CATEGORY } from 'queries/category'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'
import { QueryProductCategoryListInfoArgs, ProductCategory } from 'utils/generatedNonAuth'

const useCategory = (variables?: QueryProductCategoryListInfoArgs) => {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(CATEGORY_LIST, {
    fetchPolicy: 'no-cache',
    variables: variables,
    context: { clientName: 'endpointNonAuth' },
  })

  const [updateCategory, optionUpdate] = useMutation(UPDATE_CATEGORY, { context: { clientName: 'endpointAdmin' } })
  const [deleteCategory, optionDelete] = useMutation(DELETE_CATEGORY, { context: { clientName: 'endpointAdmin' } })
  const [updateJoinProduct, optionUpdateJoinProduct] = useMutation(UPDATE_JOIN_PRODUCT_CATEGORY, {
    context: { clientName: 'endpointAdmin' },
  })

  const isLoading = loading || optionUpdate.loading || optionDelete.loading || optionUpdateJoinProduct.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.productCategoryListInfo?.res_code !== EResCode.Success)
      return JSON.stringify(errorQuery || data?.productCategoryListInfo?.res_desc)

    // optionUpdate
    if (
      optionUpdate?.error ||
      (optionUpdate.data?.updateProductCategory?.res_code &&
        optionUpdate.data?.updateProductCategory?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateProductCategory?.res_desc)

    // optionDelete
    if (
      optionDelete?.error ||
      (optionDelete.data?.deleteProductCategory?.res_code &&
        optionDelete.data?.deleteProductCategory?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionDelete?.error || optionDelete.data?.deleteProductCategory?.res_desc)

    // optionUpdateJoinProduct
    if (
      optionUpdateJoinProduct?.error ||
      (optionUpdateJoinProduct.data?.updateJoinProductCategoryProduct?.res_code &&
        optionUpdateJoinProduct.data?.updateJoinProductCategoryProduct?.res_code !== EResCode.Success)
    )
      return JSON.stringify(
        optionUpdateJoinProduct?.error || optionUpdateJoinProduct.data?.updateJoinProductCategoryProduct?.res_desc,
      )
  }

  useErrMsgControl(errMsg())

  return {
    data: (data?.productCategoryListInfo as ProductCategory)?.items,
    refetch,
    updateCategory,
    deleteCategory,
    updateJoinProduct,
    isLoading,
  }
}

export default useCategory
