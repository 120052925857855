// Lib
import React from 'react'
import { useSnackbar } from 'notistack'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

// Include in project
import styles from './index.module.scss'
import { Modal, Notification } from 'component'
import { FormInput } from 'container'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { MutationUpdateUserInfoArgs } from 'utils/generated'
import { EResCode } from 'utils/other'
import { IUserInfo } from 'pages/UserInfo'
import { IRenderInput } from 'utils/interface'
import { setOpenLoading } from 'states/slice/loading'

interface Props {
  open: boolean
  handleClose: () => void
  updateUser: ({ variables }: { variables: MutationUpdateUserInfoArgs }) => Promise<any>
  refetch: () => void
}

const ModalUserEdit: React.FC<Props> = ({ open, handleClose, updateUser, refetch }) => {
  const { values, errors } = useFormikContext<IUserInfo>()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const handleUpdateUser = async () => {
    if (!_.isEmpty(errors)) return

    dispatch(setOpenLoading())

    const packData = await transformationDataToServer({
      values: values,
      type: ETransformationDataToServer.UpdateUserInfo,
    })

    updateUser({ variables: packData as MutationUpdateUserInfoArgs }).then((res) => {
      const resUpdate = res.data.updateUserInfo
      if (resUpdate.res_code !== EResCode.Success) return

      refetch()
      enqueueSnackbar(<Notification title="Success" description="Update User information." />, { variant: 'success' })
      handleClose()
    })
  }

  const fieldInputs: IRenderInput[] = [
    {
      heading: 'Profile',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: values?.formInput.profileImgURLShow,
      readonly: false,
      multiple: false,
      name: 'formInput.profileImgURLShow',
    },
    { heading: 'Firstname', type: 'TEXT_FIELD', name: 'formInput.firstname', disabled: false },
    { heading: 'Surname', type: 'TEXT_FIELD', name: 'formInput.surname', disabled: false },
    { heading: 'Email', type: 'TEXT_FIELD', name: 'formInput.email', disabled: false },
    { heading: 'Phone number', type: 'TEXT_FIELD', name: 'formInput.phoneNumber', disabled: true },
    { heading: 'Birthday', type: 'TEXT_FIELD_DATE', name: 'formInput.birthday', disabled: false },
    { heading: 'ID Card', type: 'TEXT_FIELD', name: 'formInput.idCard', disabled: false },
  ]

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      width="60%"
      height="90%"
      title="User Details"
      handleSave={handleUpdateUser}
    >
      <div className={styles.container}>
        <section className={styles.form}>
          <div className={styles.card}>
            <h5>User Details</h5>
            <FormInput title="User Info" fieldInputs={fieldInputs} />
          </div>
        </section>
      </div>
    </Modal>
  )
}

export default ModalUserEdit
