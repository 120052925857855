// Lib
import React from 'react'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { getIn, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

// Include in project
import styles from './index.module.scss'
import { EResCode } from 'utils/other'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { CardAddress, Modal, Notification, Table } from 'component'
import { FormInput } from 'container'
import { E_Is_Active, Address, MutationUpdateTagsAndIsActiveCustomerArgs } from 'utils/generated'
import { ICustomerManagement } from 'pages/CustomerManagement'
import { IRenderInput } from 'utils/interface'
import type { RootState } from 'states/store'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'

interface Props {
  open: boolean
  handleClose: () => void
  handleUpdate: ({ variables }: { variables: MutationUpdateTagsAndIsActiveCustomerArgs }) => Promise<any>
  refetch: () => void
}

const ModalUserDetail: React.FC<Props> = ({ open, handleClose, handleUpdate, refetch }) => {
  const { values, setFieldValue } = useFormikContext<ICustomerManagement>()
  const { enqueueSnackbar } = useSnackbar()
  const tagsReducer = useSelector((state: RootState) => state.tags)

  const handleUpdateRoleAndIsActive = async () => {
    const packData = await transformationDataToServer({
      values: values,
      type: ETransformationDataToServer.UpdateTagsAndIsActiveCustomer,
    })
    const data = _.omit(packData, ['input.userRoleCompanyID'])

    handleUpdate({ variables: data as MutationUpdateTagsAndIsActiveCustomerArgs }).then((res) => {
      const resUpdate = res.data.updateTagsAndIsActiveCustomer
      if (resUpdate.res_code !== EResCode.Success) return

      refetch()
      handleClose()
      enqueueSnackbar(<Notification title="Success" description="Update User." />, {
        variant: 'success',
      })
    })
  }

  const fieldInputs: IRenderInput[] = [
    {
      heading: 'Profile',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: values?.formInput.profileImgURL || [],
      readonly: true,
      multiple: false,
    },
    {
      heading: 'Status',
      type: 'SWITCH',
      checked: getIn(values, 'formInput.isActive') === E_Is_Active.True,
      labelDetail: { true: 'Active', false: 'Inactive' },
      name: 'formInput.isActive',
      disabled: false,
      onChange: () => {
        if (getIn(values, 'formInput.isActive') === E_Is_Active.True) {
          setFieldValue('formInput.isActive', E_Is_Active.False)
        } else {
          setFieldValue('formInput.isActive', E_Is_Active.True)
        }
      },
    },
    { heading: 'Firstname', type: 'TEXT_FIELD', name: 'formInput.firstname', disabled: true },
    { heading: 'Surname', type: 'TEXT_FIELD', name: 'formInput.surname', disabled: true },
    { heading: 'Email', type: 'TEXT_FIELD', name: 'formInput.email', disabled: true },
    { heading: 'Phone number', type: 'TEXT_FIELD', name: 'formInput.phoneNumber', disabled: true },
    { heading: 'ID Card', type: 'TEXT_FIELD', name: 'formInput.idCard', disabled: true },
    // {
    //   heading: 'Tags',
    //   type: 'AUTO_COMPLETE',
    //   name: 'formInput.tagID',
    //   disabled: false,
    //   option: tagsReducer.tagsOptionList,
    // },
  ]

  const dataTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.OrderHistoryList,
    dataList: values?.formInput?.order || [],
  })

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      width="90%"
      height="90%"
      title="User Details"
      handleSave={handleUpdateRoleAndIsActive}
    >
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.card}>
            <h5>User Details</h5>
            <FormInput title="User Info" fieldInputs={fieldInputs} />
          </div>
          <div className={styles.card}>
            <h6>รายละเอียดข้อมูลการจัดส่ง</h6>
            <div className={styles.wrapperBox}>
              {values.formInput.address &&
                values.formInput.address?.map((item, index: number) => (
                  <CardAddress key={index} addressDetail={item as Address} />
                ))}
            </div>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.card}>
            <h6> Order Histories</h6>

            <Table
              objectKey={{
                orderNoHTML: 'Order No.',
                createdAt: 'Order Date',
                amountHTML: 'Items',
                orderStatusHTML: 'Order Status',
                paymentStatusHTML: 'Payment Status',
                deliveryStatusHTML: 'Delivery Status',
                note: 'Remark',
                tagsName: 'Tags',
              }}
              data={dataTable}
              handleClick={(data) => window.open(`/order-management?orderNo=${data.orderNo}`)}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalUserDetail
