// Lib
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'

// Include in project
import { login as loginFetch, LoginParams } from 'services/auth.service'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'

const useLogin = () => {
  const {
    mutate,
    data,
    error,
    isLoading: loading,
  } = useMutation(({ companyID, email, password }: LoginParams) => loginFetch({ companyID, email, password }))

  const isLoading = loading

  const errMsg = () => {
    if (error || data?.data?.res_code !== EResCode.Success)
      return ((error as AxiosError)?.response?.data as { res_desc: string })?.res_desc || data?.data?.res_desc
  }

  useErrMsgControl(errMsg())

  return {
    login: mutate,
    data: data,
    isLoading,
  }
}

export default useLogin
