import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IDecodeToken {
  companyID: string
  email: string
  firstname: string
  exp: number
  iat: number
  isActive: 'TRUE' | 'FALSE' | null
  typeTokenAuth: string
  userID: string
  userRoleGlobal: 'STAFF' | 'OWNER' | null
  permission: string[]
}

const initialState: IDecodeToken = {
  companyID: '',
  email: '',
  firstname: '',
  exp: 0,
  iat: 0,
  isActive: null,
  typeTokenAuth: '',
  userID: '',
  userRoleGlobal: null,
  permission: [],
}

export const decodeTokenSlice = createSlice({
  name: 'decodeToken',
  initialState,
  reducers: {
    setDecodeToken: (state, action: PayloadAction<IDecodeToken>) => {
      state.companyID = action.payload.companyID
      state.firstname = action.payload.firstname
      state.email = action.payload.email
      state.isActive = action.payload.isActive
      state.typeTokenAuth = action.payload.typeTokenAuth
      state.userID = action.payload.userID
      state.userRoleGlobal = action.payload.userRoleGlobal
      state.exp = action.payload.exp
      state.iat = action.payload.iat
      state.permission = action.payload.permission
    },
    clearDecodeToken: (state) => {
      state.companyID = ''
      state.email = ''
      state.firstname = ''
      state.isActive = null
      state.typeTokenAuth = ''
      state.userID = ''
      state.userRoleGlobal = null
      state.exp = 0
      state.iat = 0
      state.permission = []
    },
  },
})

// Action creators are generated for each case reducer function
export const { setDecodeToken, clearDecodeToken } = decodeTokenSlice.actions

export default decodeTokenSlice.reducer
