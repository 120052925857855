// Lib
import React, { useState } from 'react'

//Image
import editeSVG from 'images/commons/editicon.svg'

// Include in project
import styles from './index.module.scss'
import { useModal } from 'hooks'
import { ModalEditSetting, FormInput } from 'container'
import { Button } from 'component'
import { MutationUpdateSettingCompanyArgs, SettingCompany, Company } from 'utils/generated'
import { IRenderInput } from 'utils/interface'
import { intitialValue } from 'pages/Setting'
import { convertCurrencyFormatDecimal } from 'utils/convertMoney'
import { convertEnumToWord } from 'utils/other'

interface Props {
  settingInfo: SettingCompany
  companyInfo: Company
  updateSetting: ({ variables }: { variables: MutationUpdateSettingCompanyArgs }) => Promise<any>
  refetch: () => void
}

export type TType = 'STRIPE' | 'BRAND' | 'EMAIL' | 'LINE_NOTIFY' | 'FREIGHT'

const SettingSection: React.FC<Props> = ({ settingInfo, updateSetting, refetch, companyInfo }: Props) => {
  const [type, setType] = useState<TType | ''>('')
  const { isModalOpen, handleOpen, handleClose } = useModal(intitialValue.formInput)

  const handleClick = (type: TType) => {
    handleOpen(settingInfo)
    setType(type)
  }

  const fieldCompany: IRenderInput[] = [
    {
      heading: 'Company Code',
      type: 'TEXT',
      words: companyInfo?.companyID,
    },
    {
      heading: 'Company Name (EN)',
      type: 'TEXT',
      words: companyInfo?.companyName,
    },
    {
      heading: 'Company Name (TH)',
      type: 'TEXT',
      words: companyInfo?.companyNameTH,
    },
    {
      heading: 'Logo',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: companyInfo?.logoURL || [],
      readonly: true,
      multiple: false,
    },
    {
      heading: 'Status',
      type: 'CHIP_BY_STATUS',
      chipType: companyInfo?.isActive,
    },
    {
      heading: 'Region',
      type: 'TEXT',
      words: companyInfo?.country,
    },
    {
      heading: 'Company Type',
      type: 'TEXT',
      words: companyInfo?.companyType,
    },
    {
      heading: 'Tax ID',
      type: 'TEXT',
      words: companyInfo?.taxID,
    },
    {
      heading: 'Tel',
      type: 'TEXT',
      words: companyInfo?.tel,
    },
    {
      heading: 'Email',
      type: 'TEXT',
      words: companyInfo?.email,
    },
  ]

  const fieldBrand: IRenderInput[] = [
    {
      heading: 'Domain Name',
      type: 'TEXT_LINK_EXTERNAL',
      words: settingInfo?.domainName,
      link: settingInfo?.domainName as string,
    },
    {
      heading: 'Tax',
      type: 'TEXT',
      words: `${settingInfo?.tax}%`,
    },
    {
      heading: 'Store (Open/Close)',
      type: 'CHIP_BY_STATUS',
      chipType: settingInfo?.isClose,
    },
    {
      heading: 'Timeout Cart',
      type: 'TEXT',
      words: `${settingInfo?.timeoutCart} Day`,
    },
    {
      heading: 'Timeout Paid',
      type: 'TEXT',
      words: `${settingInfo?.timeoutPaid} Day`,
    },
    {
      heading: 'Freight Model',
      type: 'BUTTON',
      words: `${convertEnumToWord(settingInfo?.freightModel || '')}`,
      onClick: () => handleClick('FREIGHT'),
    },
  ]

  const fieldStripe: IRenderInput[] = [
    {
      heading: 'Success Path URL',
      type: 'TEXT',
      words: settingInfo?.stripeSuccessPathURL,
    },
    {
      heading: 'Cancel Path URL',
      type: 'TEXT',
      words: settingInfo?.stripeCancelPathURL,
    },
    {
      heading: 'Secret Key Production',
      type: 'TEXT',
      words: 'xxxxxxxxxx',
    },
    {
      heading: 'Secret Key Test',
      type: 'TEXT',
      words: 'xxxxxxxxxx',
    },
  ]

  const fieldMail: IRenderInput[] = [
    {
      heading: 'Sender Email',
      type: 'TEXT',
      words: settingInfo?.senderMail,
    },
    {
      heading: 'Sender Password',
      type: 'TEXT',
      words: 'xxxxxxxxxx',
    },
  ]

  const fieldLineNotify: IRenderInput[] = [
    {
      heading: 'Line Token Notify',
      type: 'TEXT',
      words: 'xxxxxxxxxx',
    },
  ]

  return (
    <div className={styles.container}>
      <h5>Setting</h5>

      <div className={styles.wrapperBox}>
        <div className={styles.box}>
          <FormInput title="Company Info" fieldInputs={fieldCompany} />
          <div className={styles.group}>
            <FormInput title="Brand Commerce" fieldInputs={fieldBrand} />
            <div className={styles.wrapperButton}>
              <Button
                typeButton="button"
                type="gray"
                name="Edit Company"
                size="small"
                startIcon={<img src={editeSVG} />}
                functionOnClick={() => handleClick('BRAND')}
              />
            </div>
          </div>
        </div>

        <div className={styles.box}>
          <div className={styles.group}>
            <FormInput title="Stripe Service" fieldInputs={fieldStripe} />
            <div className={styles.wrapperButton}>
              <Button
                typeButton="button"
                type="gray"
                name="Edit Stripe Service"
                size="small"
                startIcon={<img src={editeSVG} />}
                functionOnClick={() => handleClick('STRIPE')}
              />
            </div>
          </div>

          <div className={styles.group}>
            <FormInput title="Email Service" fieldInputs={fieldMail} />
            <div className={styles.wrapperButton}>
              <Button
                typeButton="button"
                type="gray"
                name="Edit Email Service"
                size="small"
                startIcon={<img src={editeSVG} />}
                functionOnClick={() => handleClick('EMAIL')}
              />
            </div>
          </div>

          <div className={styles.group}>
            <FormInput title="Line Notify Service" fieldInputs={fieldLineNotify} />
            <div className={styles.wrapperButton}>
              <Button
                typeButton="button"
                type="gray"
                name="Edit Line Notify Service"
                size="small"
                startIcon={<img src={editeSVG} />}
                functionOnClick={() => handleClick('LINE_NOTIFY')}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalEditSetting
        updateSetting={updateSetting}
        refetch={refetch}
        open={isModalOpen}
        handleClose={handleClose}
        section={type}
      />
    </div>
  )
}

export default SettingSection
