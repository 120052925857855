// Lib
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { withFormik, useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'

// Images
import { AddCircle, IosShareOutlined } from '@mui/icons-material'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { useModal, useProduct, useLoadingControl, useProductMutation, useInfiniteScroll } from 'hooks'
import { ModalAddProduct, SearchBox } from 'container'
import { menuHeaderProduct } from 'utils/menu'
import { transformationDataIntoTable, ETranformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { Button, Table, ButtonFilterIsActive, Notification } from 'component'
import { E_Is_Active, MutationUpdateProductArgs, Product } from 'utils/generated'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { EResCode } from 'utils/other'
import yupProduct from 'validations/yupProduct.validate'
import { IOption } from 'utils/interface'
import type { RootState } from 'states/store'
import { pageSize } from 'configs'
import { Maybe } from 'utils/generatedNonAuth'
import { TOptionkey } from 'container/SearchBox'

export interface IProductManagement {
  formInput: {
    productImgURLShow: (string & File)[]
    productFileURLShow: (string & File)[]
    tagID: IOption[]
  } & Product
  filter: {
    isActive: E_Is_Active | 'ALL'
    key: string
    value: string
    name: Maybe<string>
    prefix: Maybe<string>
  }
}

const intitialValue: IProductManagement = {
  formInput: {
    name: '',
    brandID: '',
    description: '',
    unitLabel: '',
    productImgURL: [],
    productImgURLShow: [],
    productFileURL: [],
    productFileURLShow: [],
    isActive: E_Is_Active.True,
    isVisible: E_Is_Active.True,
    prefix: '',
    remark: '',
    productOptionGroupID: '',
    tagID: [],
    specAttribute: {},
  },
  filter: {
    isActive: E_Is_Active.True,
    key: 'prefix',
    value: '',
    name: null,
    prefix: null,
  },
}

const ProductManagement: React.FC = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { values, setFieldValue } = useFormikContext<IProductManagement>()
  const productReducer = useSelector((state: RootState) => state.product)
  const decodeTokenReducer = useSelector((state: RootState) => state.decodeToken)

  const filterObject = {
    name: values.filter.name,
    prefix: values.filter.prefix,
    isActive: values.filter.isActive !== 'ALL' ? values.filter.isActive : null,
    tagName: null,
  }

  const {
    data: productList,
    isLoading,
    refetch,
  } = useProduct({
    companyID: decodeTokenReducer.companyID,
    filter: filterObject,
    pageSize: pageSize,
    nextToken: null,
  })
  const { dataList } = useInfiniteScroll({
    result: productList,
    refetch: refetch,
    variables: {
      companyID: decodeTokenReducer.companyID,
      filter: filterObject,
      pageSize: pageSize,
      nextToken: productList?.nextToken || null,
    },
    filter: [values.filter.name, values.filter.prefix, values.filter.isActive],
    key: 'productListNonCategory',
  })

  const { isLoading: isLoadingUpdate, updateProduct } = useProductMutation()
  useLoadingControl(isLoading || isLoadingUpdate)

  const { isModalOpen, handleClose, handleOpen: handleOpenModal } = useModal(intitialValue.formInput)

  const dataTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.ProductManagementList,
    dataList: dataList || [],
    handleOpen: handleOpenModal,
    handleArchive: (data) => handleArchiveProduct(data),
  })

  const handleClickGoToViewProduct = (product: Product) => {
    navigate(`/product-management/product-info/${product.productID}`)
  }

  const handleArchiveProduct = async (data: Product) => {
    const checkStatusDelete = values.formInput.isActive === E_Is_Active.True // True is Delete | False is Recovery

    const packData = await transformationDataToServer({
      values: data,
      type: ETransformationDataToServer.ArchiveProduct,
    })

    updateProduct({ variables: packData as MutationUpdateProductArgs }).then((res) => {
      const resUpdate = res.data.updateProduct
      if (resUpdate.res_code !== EResCode.Success) return

      refetch()
      productReducer?.refetch?.()
      enqueueSnackbar(
        <Notification title="Success" description={`${checkStatusDelete ? 'Archive' : 'Recovery'} product`} />,
        { variant: 'success' },
      )
    })
  }

  const optionKey: TOptionkey = [
    {
      value: 'name',
      label: 'Name',
      child: {
        type: 'TEXT_FIELD',
      },
    },
    {
      value: 'prefix',
      label: 'Prefix',
      child: {
        type: 'TEXT_FIELD',
      },
    },
  ]

  return (
    <Layout headerSectionName="Product Management" headerSectionManu={menuHeaderProduct}>
      <div className={styles.container}>
        <div className={styles.wrapperGroup}>
          <div className={styles.boxInput}>
            <SearchBox optionKey={optionKey} />
          </div>

          <div className={styles.box}>
            <ButtonFilterIsActive />
            {/* <Button type="gray" typeButton="button" name="Export" startIcon={<IosShareOutlined />} /> */}
            <Button
              name="Add Product"
              typeButton="button"
              type="info"
              size="small"
              startIcon={<AddCircle />}
              functionOnClick={() => handleOpenModal()}
            />
          </div>
        </div>

        <Table
          objectKey={{
            imageHTML: 'Image',
            prefix: 'Prefix',
            productNameHTML: 'Name',
            description: 'Description',
            brandName: 'Brand',
            tagsHTML: 'Tags',
            skuQtyHTML: 'Sku Qty.',
            createdAt: 'Created At',
            updatedAt: 'Updated At',
            statusHTML: 'Status',
            visibleHTML: 'Visible',
            actionProductManagement: '',
          }}
          data={dataTable}
          handleClick={handleClickGoToViewProduct}
        />

        <ModalAddProduct open={isModalOpen} handleClose={handleClose} updateProduct={updateProduct} refetch={refetch} />
      </div>
    </Layout>
  )
}

const EnhancedProductManagement = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  validateOnMount: true,
  validationSchema: yupProduct,
  handleSubmit: () => {
    console.log('Skip')
  },
})(ProductManagement)

export default EnhancedProductManagement
