// Lib
import React from 'react'
import { getIn, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import _ from 'lodash'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

// Include in project
import styles from './index.module.scss'

interface Props {
  name: string
  label?: string
  require?: boolean
}

const DatePickers: React.FC<Props> = (props: Props) => {
  const { values, touched, errors, setFieldValue } = useFormikContext()

  return (
    <div className={styles.wapper}>
      <p>
        {props.label}
        <span className="colorRed">{props.require && '*'}</span>
      </p>
      <div className={styles.dateArea}>
        <DatePicker
          className={`${styles.customInput} ${
            getIn(touched, props.name) && Boolean(getIn(errors, props.name)) && styles.customInputError
          }`}
          name={props.name}
          selectsRange={true}
          value={getIn(values, props.name)}
          startDate={_.get(values, props.name)[0]}
          endDate={_.get(values, props.name)[1]}
          onChange={(date) => {
            setFieldValue(`${props.name}`, date)
          }}
        />
        <CalendarTodayIcon className={styles.icon} />
      </div>
      <p className={`colorRed ${styles.errorMsg}`}>
        {getIn(touched, props.name) && getIn(errors, props.name) && getIn(errors, props.name)}
      </p>
    </div>
  )
}

export default DatePickers
