import { E_Currency, E_Is_Active, E_Payment_Type, E_Province, Maybe, Pricing } from 'utils/generatedNonAuth'
import {
  E_User_Level,
  E_Order_Status,
  E_Gender,
  E_Delivery_Status,
  E_Shipping_Company,
  E_Pricing_Model,
} from 'utils/generated'
import thaiProvinceData from 'configs/thai-province-data.json'

export const EResCode = {
  Success: '00',
  Error: '99',
  Warning: '98',
}

export const optionList = {
  genderOption: [
    { label: 'Male', value: E_Gender.Male },
    { label: 'Female', value: E_Gender.Female },
  ],
  isActiveOption: [
    { label: 'Active', value: E_Is_Active.True },
    { label: 'Inactive', value: E_Is_Active.False },
  ],
  userLevel: [
    { label: 'Manager', value: E_User_Level.Manager },
    { label: 'Employee', value: E_User_Level.Employee },
  ],
  typeOption: [
    { label: 'Checkbox', value: 'CHECKBOX' },
    { label: 'Tag', value: 'TAG' },
  ],
  orderStatusOption: [
    { label: 'All', value: 'ALL' },
    {
      value: E_Order_Status.TimeoutPaid,
      label: 'Timeout Paid',
    },
    {
      value: E_Order_Status.WaitingPaid,
      label: 'Waiting Paid',
    },
    {
      value: E_Order_Status.WaitingApproval,
      label: 'Waiting Approval',
    },
    {
      value: E_Order_Status.Approved,
      label: 'Approved',
    },
    {
      value: E_Order_Status.Cancelled,
      label: 'Cancelled',
    },
  ],
  deliveryStatusOption: [
    {
      label: 'Pedding',
      value: E_Delivery_Status.Pedding,
    },
    {
      label: 'Waiting Packing',
      value: E_Delivery_Status.WaitingPacking,
    },
    {
      label: 'Waiting Delivering',
      value: E_Delivery_Status.WaitingDelivering,
    },
    {
      label: 'Finish',
      value: E_Delivery_Status.Finish,
    },
  ],
  shippingCompanyOption: [
    {
      label: 'Self Delivery',
      value: E_Shipping_Company.SelfDelivery,
    },
    {
      label: 'Best Express',
      value: E_Shipping_Company.BestExpress,
    },
    {
      label: 'Deliveree',
      value: E_Shipping_Company.Deliveree,
    },
    {
      label: 'DHL Express',
      value: E_Shipping_Company.DhlExpress,
    },
    {
      label: 'Flash Express',
      value: E_Shipping_Company.FlashExpress,
    },
    {
      label: 'J&T Express',
      value: E_Shipping_Company.JtExpress,
    },
    {
      label: 'Kerry Express',
      value: E_Shipping_Company.KerryExpress,
    },
    {
      label: 'Lalamove',
      value: E_Shipping_Company.Lalamove,
    },
    {
      label: 'Ninja Van',
      value: E_Shipping_Company.NinjaVan,
    },
    {
      label: 'SCG Express',
      value: E_Shipping_Company.ScgExpress,
    },
    {
      label: 'Thailand Post',
      value: E_Shipping_Company.ThailandPost,
    },
  ],
  pricingModelOption: [
    {
      label: 'Standard Pricing',
      value: E_Pricing_Model.StandardPricing,
    },
    {
      label: 'Volumn Pricing',
      value: E_Pricing_Model.VolumePricing,
    },
  ],
  currencyOption: [
    {
      label: 'THB',
      value: E_Currency.Thb,
    },
  ],
  paymenyTypeOption: [
    {
      label: 'One Time',
      value: E_Payment_Type.OneTime,
    },
  ],
}

export function getDefaultMonthYear(): {
  defaultYear: number
  defaultMonth: number
} {
  const defaultYear = new Date(new Date().getFullYear(), new Date().getMonth() - 1).getFullYear()
  const defaultMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1).getMonth()

  return {
    defaultYear: defaultYear,
    defaultMonth: defaultMonth,
  }
}

export function optionFilterYearMonth() {
  const monthListOption = [
    { label: 'January', value: 0 },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December  ', value: 11 },
  ]

  const yearsListOption = []

  const year = new Date().getFullYear()

  const startYear = 2022
  // Get List of Year start 2022 to now
  for (let i = startYear; i <= year; i++) {
    yearsListOption.push({ label: `${i}`, value: i })
  }

  return {
    monthListOption,
    yearsListOption: yearsListOption.sort((a, b) => a.value - b.value),
  }
}

export function compareNumbers(a: number, b: number): number {
  return a - b
}

export const unPackValueToChart = (items: Record<string, number>[]) => {
  const labelChart: string[] = []
  const valueChart: number[] = []

  items.forEach((element: Record<string, number>) => {
    const [key] = Object.entries(element)

    labelChart.push(key[0])
    valueChart.push(key[1])
  })

  return { labelChart, valueChart }
}

export const substringDescription = (text: string) => {
  const limit = 140
  if (text.length > limit) {
    return text.substring(0, limit) + '...'
  }
  return text
}

export const convertEnumToWord = (text: string) => {
  const first = text.substring(0, 1)
  const another = text.substring(1, text.length).toLowerCase().replaceAll('_', ' ')

  return first + another
}

export const findPricingByModel = (pricing: Pricing): number => {
  if (pricing?.pricingModel === E_Pricing_Model.StandardPricing) {
    return pricing?.priceModelStandard?.salePrice as number
  } else if (pricing?.pricingModel === E_Pricing_Model.VolumePricing) {
    return pricing?.priceModelVolumn?.tableVolumnPrice?.[0]?.salePrice as number
  } else return 0
}

export const convertNumberToColumn: Record<string, string> = {
  '1': 'A',
  '2': 'B',
  '3': 'C',
  '4': 'D',
  '5': 'E',
  '6': 'F',
  '7': 'G',
  '8': 'H',
  '9': 'I',
  '10': 'J',
  '11': 'K',
  '12': 'L',
  '13': 'M',
  '14': 'N',
  '15': 'O',
  '16': 'P',
  '17': 'Q',
  '18': 'R',
  '19': 'S',
  '20': 'T',
  '21': 'U',
  '22': 'V',
  '23': 'W',
  '24': 'X',
  '25': 'Y',
  '26': 'Z',
  '27': 'AA',
  '28': 'AB',
  '29': 'AC',
  '30': 'AD',
  '31': 'AE',
  '32': 'AF',
  '33': 'AG',
  '34': 'AH',
  '35': 'AI',
  '36': 'AJ',
  '37': 'AK',
  '38': 'AL',
  '39': 'AM',
  '40': 'AN',
  '41': 'AO',
  '42': 'AP',
  '43': 'AQ',
  '44': 'AR',
  '45': 'AS',
  '46': 'AT',
  '47': 'AU',
  '48': 'AV',
  '49': 'AW',
  '50': 'AX',
  '51': 'AY',
  '52': 'AZ',
}

export const enterNewLine = (text: string): string => {
  return text?.split('\n').join('<br />')
}

export const findNameProvinceByEnum = (province: Maybe<E_Province> | undefined): string => {
  return thaiProvinceData.find((ele) => ele.enum === province)?.label || ''
}
