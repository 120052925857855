// Lib
import React from 'react'

// Images
import imageLockAndKeySVG from 'images/forgotPassword/LockAndKey.svg'

// Include in project
import styles from './index.module.scss'
import { Button, TextField } from 'component'

interface Props {
  handleNextStep: () => void
}

const ForgotPasswordStepOne: React.FC<Props> = ({ handleNextStep }: Props) => {
  return (
    <div className={styles.container}>
      <img src={imageLockAndKeySVG} alt="" />

      <div className={styles.warpperText}>
        <h6>คุณลืมรหัสผ่านใช่หรือไม่</h6>
        <p className="fontBold">ไม่ต้องกังวลใจ เราจะส่งอีเมลให้คุณในการตั้งรหัสผ่านใหม่</p>
      </div>

      <div className={styles.wrapperInput}>
        <TextField name="companyID" type="text" label="Company Code" require />
        <TextField name="email" type="text" label="อีเมล" require />
        <div className={styles.selfCenter}>
          <Button name="ส่งอีเมล" typeButton="submit" type="info" size="large" functionOnClick={handleNextStep} />
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordStepOne
