// Lib
import React from 'react'

interface Props {
  title: 'Error' | 'Success' | 'Warning' | 'Info'
  description: string
}

const Notification: React.FC<Props> = ({ title, description }): JSX.Element => {
  return (
    <div>
      <h6>{title}</h6>
      <p>{description} </p>
    </div>
  )
}

export default Notification
