// Lib
import moment from 'moment'
import { Maybe } from './generated'

export const convertDateLL = (date: Maybe<string> | undefined) => {
  if (!date) return '-'
  return moment(date).format('LL')
}

export const convertDateWithTimeStamp = (date: Maybe<string> | undefined) => {
  return moment(date).format('LLL')
}
