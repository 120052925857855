// Lib
import { useEffect, useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'

interface Props {
  result: any
  refetch: (variables?: any) => Promise<ApolloQueryResult<any>>
  variables: any
  filter: any
  key: string
}

const useInfiniteScroll = ({ result, refetch, variables, filter, key }: Props) => {
  const [dataListAndNextToken, setDataListAndNextToken] = useState<{ nextToken: string; dataList: any[] }>({
    nextToken: '',
    dataList: [],
  })
  const [countFetch, setCountFetch] = useState<number>(0)

  // If scroll page to bottom fetch data
  const handleScroll = (e: any) => {
    if (window.innerHeight + e.target.documentElement.scrollTop + 1 >= e.target.documentElement.offsetHeight) {
      setCountFetch((prev) => prev + 1)
    }
  }
  useEffect(() => window.addEventListener('scroll', handleScroll), [])

  // <------ set value in first page ------>
  useEffect(() => {
    if (!result) return
    if (countFetch !== 0) return

    setDataListAndNextToken({
      nextToken: result.nextToken,
      dataList: result.items,
    })
  }, [result])

  // <------ call next page ------>
  useEffect(() => {
    if (!result) return handleResetCount()
    if (countFetch === 0) return

    if (!result.nextToken) {
      return setDataListAndNextToken((prev) => ({
        ...prev,
        nextToken: '',
      }))
    }

    refetch(variables).then(({ data }: any) => {
      if (data?.[key].res_code === '99') return
      if (data?.[key].nextToken === dataListAndNextToken.nextToken) return

      setDataListAndNextToken((prev) => {
        if (prev.dataList.includes(data?.[key].items[0]))
          return {
            ...prev,
          }
        else {
          return {
            nextToken: data?.[key].nextToken,
            dataList: [...prev.dataList, ...data?.[key].items],
          }
        }
      })
    })
  }, [countFetch])

  // <------ set state to default ------>
  useEffect(() => {
    setDataListAndNextToken({ nextToken: '', dataList: [] })
    handleResetCount()
  }, [...filter])

  const handleResetCount = () => setCountFetch(0)

  const handleRefreshAPI = () => {
    setCountFetch(0)
    refetch({ ...variables, nextToken: null }).then(({ data }: any) => {
      if (data?.[key].res_code === '99') return
      if (data?.[key].nextToken === dataListAndNextToken.nextToken) return

      setDataListAndNextToken({
        nextToken: data?.[key].nextToken,
        dataList: data?.[key].items,
      })
    })
  }
  return {
    dataList: dataListAndNextToken.dataList,
    handleResetCount: handleResetCount,
    handleRefreshAPI: handleRefreshAPI,
  }
}

export default useInfiniteScroll
