// Lib
import React from 'react'
import { IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'

// Include in project
import { useMenuList } from 'hooks'
import { convertCurrencyFormat, convertCurrencyFormatDecimal } from './convertMoney'
import {
  SelectSingle,
  Chip,
  ImageTable,
  ChipByStatus,
  Switch,
  MenuList,
  CopyInput,
  TextField,
  ChipByTrigger,
  Button,
} from 'component'
import { convertDateWithTimeStamp, convertDateLL } from './convertDate'
import Color from 'abstracts/export.module.scss'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import type { RootState } from 'states/store'
import {
  UpdateProduct,
  UpdateProductSku,
  ProductOptionGroup,
  Brand,
  Tag,
  E_Is_Active,
  User,
  UserRoleCompany,
  Inventory,
  Pricing,
  ProductSku,
  Order,
  E_Order_Status,
  E_Payment_Status,
  E_Trigger_Type,
  OrderItem,
  E_Delivery_Status,
} from './generated'
import { E_Pricing_Model, Product, ProductOption } from './generatedNonAuth'
import { IProductInfo } from 'pages/ProductInfo'

//images
import { MoreHorizRounded } from '@mui/icons-material'
import visibleSVG from 'images/commons/visible.svg'
import uvisibleSVG from 'images/commons/unvisible.svg'
import deleteSVG from 'images/commons/deleteDanger.svg'
import recoverySVG from 'images/commons/recovery.svg'
import { convertEnumToWord, findNameProvinceByEnum, substringDescription } from './other'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

export enum ETranformationDataIntoTable {
  DashboradTable = 'DASHBORAD_TABLE',
  CustomerManagement = 'CUSTOMER_MANAGEMENT',
  OrderHistoryList = 'ORDER_HISTORY_LIST',
  PermissionManagementList = 'PERMISSION_MANAGEMENT_LIST',
  ProductManagementList = 'PRODUCT_MANAGEMENT_LIST',
  BrandList = 'BRAND_LIST',
  ProductSKUList = 'PRODUCT_SKU_LIST',
  EditProductSKUList = 'EDIT_PRODUCT_SKU_LIST',
  EditProductSKUListArchive = 'EDIT_PRODUCT_SKU_LIST_ARCHIVE',
  ProductOptionList = 'PRODUCT_OPTION_LIST',
  OptionManagementList = 'OPTION_MANAGEMENT_LIST',
  TagsList = 'TAGS_LIST',
  InventoryManagementList = 'INVENTORY_MANAGEMENT_LIST',
  OrderManagementList = 'ORDER_MANAGEMENT_LIST',
  OrderProductList = 'ORDER_PRODUCT_LIST',
  OrderStatusTransectionList = 'ORDER_STATUS_TRANSECTION_LIST',
  DetailsList = 'DETAILS_LIST',
  TransectionList = 'TRANSECTION_LIST',
  PreviewProduct = 'PREVIEW_PRODUCT',
}

export const transformationDataIntoTable = ({
  dataList,
  type,
  handleOpen,
  handleArchive,
}: {
  dataList: any
  type: ETranformationDataIntoTable
  handleOpen?: (data?: any) => void
  handleArchive?: (data?: any) => void
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    open,
    anchorEl,
    handleClose: handleCloseMenu,
    handleClick: handleClickMenu,
  } = useMenuList(dataList?.map(() => null))
  const tagsReducer = useSelector((state: RootState) => state.tags)
  const brandReducer = useSelector((state: RootState) => state.brand)
  const optionGroupList = useSelector((state: RootState) => state.optionGroup.optionGroupList)

  if (!dataList) return []

  switch (type) {
    case ETranformationDataIntoTable.DashboradTable:
      return dataList.map(
        (
          data: {
            productName: string
            firstname: string
            surname: string
            amount: number
            productID: string
            total: number
            productSKUName: string
            stockAvailable: number
          },
          index: number,
        ) => {
          return {
            ...data,
            no: <div className="circleBox">{index + 1}</div>,
            name: data?.productSKUName || data?.productName || `${data.firstname} ${data.surname}`,
            amount: convertCurrencyFormat(data.amount || data.stockAvailable),
            total: convertCurrencyFormatDecimal(data.total || 0),
          }
        },
      )

    case ETranformationDataIntoTable.CustomerManagement:
      return dataList.map((data: User) => {
        const mapperTagID = data.tagID?.map((tag) => tagsReducer.tagsOptionList?.find((ele) => ele.value === tag))
        return {
          ...data,
          userRoleCompanyID: data.userRoleCompany?.userRoleCompanyID,
          userRoleCompanyName: data.userRoleCompany?.userRoleCompanyName,
          imageHTML: <ImageTable img={data.profileImgURL?.[0] || ''} readonly />,
          profileImgURLShow: data.profileImgURL || [],
          tagID: mapperTagID,
          tagsHTML: (
            <div className="gapChips">
              {mapperTagID?.map((tag, index) => {
                return <Chip key={index} color="ligthGray" label={tag?.label as string} />
              })}
            </div>
          ),
          nameHTML: (
            <div>
              <p className="fontBold">
                {data.firstname} {data.surname}
              </p>
              <p>{data.gender || '-'}</p>
            </div>
          ),
          contactHTML: (
            <div>
              <p className="fontBold">{data.email}</p>
              <p>{data.phoneNumber}</p>
            </div>
          ),
          addressHTML:
            data.address &&
            `${data.address[0]?.address} ${data.address[0]?.subDistrict} ${
              data.address[0]?.district
            } ${findNameProvinceByEnum(data.address[0]?.province)}
              ${data.address[0]?.postCode}`,
          statusHTML: <ChipByStatus type={data.isActive as E_Is_Active} />,
          statusRoleHTML: <ChipByStatus type={data.userRoleCompany?.isActive as E_Is_Active} />,
          createdAt: convertDateLL(data.createdAt),
          updatedAt: convertDateLL(data.updatedAt),
        }
      })

    case ETranformationDataIntoTable.OrderHistoryList:
      return dataList.map((data: Order) => {
        return {
          ...data,
          orderNoHTML: (
            <>
              <p className="fontBold">{data.orderNo}</p>
              <p>{convertCurrencyFormatDecimal(data?.price || 0)} ฿</p>
            </>
          ),
          amountHTML: convertCurrencyFormat(data.amount || 0),
          createdAt: convertDateLL(data.createdAt),
          orderStatusHTML: <ChipByStatus type={data.orderStatus as E_Order_Status} />,
          paymentStatusHTML: <ChipByStatus type={data.paymentStatus as E_Payment_Status} />,
          deliveryStatusHTML: <ChipByStatus type={data.deliveryStatus as E_Delivery_Status} />,
          tagsName: data.tagID && tagsReducer.tagsOptionList?.find((ele) => ele.value === data.tagID)?.label,
        }
      })

    case ETranformationDataIntoTable.PermissionManagementList:
      return dataList.map((data: UserRoleCompany) => {
        return {
          ...data,
          userRoleCompanyNameHTML: (
            <p className="fontBold" style={{ color: Color.infoMain }}>
              {data.userRoleCompanyName}
            </p>
          ),
          createdAt: convertDateLL(data.createdAt),
          statusHTML: <Switch checked={data.isActive === E_Is_Active.True} />,
        }
      })

    case ETranformationDataIntoTable.ProductManagementList:
      return dataList?.map((data: Product, index: number) => {
        const mapperTagID = data.tagID?.map((tag) => tagsReducer.tagsOptionList?.find((ele) => ele.value === tag))

        const sumWithInitial = data.productSKU?.reduce((accumulator, currentValue) => {
          if (currentValue?.isActive === E_Is_Active.True) return accumulator + 1
          else return accumulator
        }, 0)

        return {
          ...data,
          imageHTML: <ImageTable img={data.productImgURL?.[0] || ''} readonly />,
          productNameHTML: (
            <div>
              <p className="fontBold">{data.name}</p>
              <p>{convertCurrencyFormatDecimal(data.productSKU?.[0]?.pricing?.[0]?.cost || 0)} ฿</p>
            </div>
          ),
          description: substringDescription(data?.description || ''),
          brandName: data?.brandID && brandReducer.brandOptionList.find((ele) => ele.value === data.brandID)?.label,
          tagsHTML: (
            <div className="gapChips">
              {mapperTagID?.map((tag, index) => {
                return <Chip key={index} color="ligthGray" label={tag?.label as string} />
              })}
            </div>
          ),
          skuQtyHTML: convertCurrencyFormat(sumWithInitial || 0),
          visibleHTML: <Switch checked={data.isVisible === E_Is_Active.True ? true : false} />,
          statusHTML: <ChipByStatus type={data.isActive as E_Is_Active} />,
          createdAt: convertDateLL(data.createdAt),
          updatedAt: convertDateLL(data.updatedAt),
          actionProductManagement: (
            <>
              <IconButton onClick={(e) => handleClickMenu(e, index)}>
                <MoreHorizRounded />
              </IconButton>

              <MenuList
                open={open(index)}
                anchorEl={anchorEl[index]}
                listMenu={[
                  {
                    title: 'Edit product',
                    functionOnClick: () => {
                      handleOpen?.({
                        ...data,
                        productImgURLShow: data.productImgURL || [],
                        productFileURLShow: data.productFileURL || [],
                        tagID: mapperTagID?.map((tag) => tag),
                      })
                      handleCloseMenu(index)
                    },
                  },
                  {
                    title: `${data.isActive === E_Is_Active.True ? 'Archive' : 'Recovery'} product`,
                    functionOnClick: () => {
                      handleCloseMenu(index)
                      dispatch(
                        setOpenDialog({
                          title: `${data.isActive === E_Is_Active.True ? 'Archive' : 'Recovery'} product`,
                          description: `Product ${data.name} will be ${
                            data.isActive === E_Is_Active.True ? 'archive' : 'recovery'
                          }.`,
                          status: data.isActive === E_Is_Active.True ? 'danger' : 'info',
                          textButtonCancle: '',
                          textButtonConfirm: `${data.isActive === E_Is_Active.True ? 'Archive' : 'Recovery'} product`,
                          functionOnSubmit: () => {
                            handleArchive?.(data)
                            dispatch(setCloseDialog())
                          },
                        }),
                      )
                    },
                  },
                ]}
                handleClose={() => handleCloseMenu(index)}
              />
            </>
          ),
          actionCategory: (
            <img
              style={{ cursor: 'pointer' }}
              src={deleteSVG}
              alt=""
              onClick={(e) => {
                e.stopPropagation()

                dispatch(
                  setOpenDialog({
                    title: 'Delete product',
                    description: 'Product will be deleted. This action cannot be recovered',
                    status: 'danger',
                    textButtonCancle: '',
                    textButtonConfirm: 'Delete product',
                    functionOnSubmit: () => {
                      handleArchive?.(data.productID)
                      dispatch(setCloseDialog())
                    },
                  }),
                )
              }}
            />
          ),
        }
      })

    case ETranformationDataIntoTable.BrandList:
      return dataList.map((data: Brand) => {
        return {
          ...data,
          brandImgURLHTML: <ImageTable img={data?.brandImgURL?.[0] || ''} readonly />,
          brandImgURLShow: data?.brandImgURL || [],
          statusHTML: <ChipByStatus type={data.isActive as E_Is_Active} />,
          createdAt: convertDateLL(data?.createdAt),
          updatedAt: convertDateLL(data?.updatedAt),
        }
      })

    case ETranformationDataIntoTable.ProductSKUList:
      return (dataList as ProductSku[])
        .filter((ele) => ele.isActive === E_Is_Active.True)
        .map((data) => {
          return {
            ...data,
            // imageHTML: <ImageTable img={data} readonly />,
            productSKUHTML: <CopyInput valueCopy={data.productSKU || ''} />,
            pricingTypeHTML: <ChipByStatus type={data.pricing?.[0]?.pricingModel as E_Pricing_Model} />,
            pricingHTML: (
              <div className="row gap20">
                <div>
                  <p className="fontBold">Cost</p>
                  <p>{convertCurrencyFormatDecimal(data.pricing?.[0]?.cost || 0)}฿</p>
                </div>
                {data.pricing?.[0]?.pricingModel === E_Pricing_Model.StandardPricing && (
                  <div>
                    <p className="fontBold">
                      Default Price:{' '}
                      <span className="fontRegular">
                        {convertCurrencyFormatDecimal(data.pricing?.[0]?.priceModelStandard?.defaultPrice || 0)}฿
                      </span>
                    </p>
                    <p className="fontBold">
                      Sale Price:{' '}
                      <span className="fontRegular">
                        {convertCurrencyFormatDecimal(data.pricing?.[0]?.priceModelStandard?.salePrice || 0)}฿
                      </span>
                    </p>
                  </div>
                )}
                {data.pricing?.[0]?.pricingModel === E_Pricing_Model.VolumePricing && (
                  <div>
                    <p className="fontBold">Tiers</p>
                    {data.pricing?.[0].priceModelVolumn?.tableVolumnPrice?.map((price, index) => {
                      return (
                        <p key={index}>
                          Qty. {price?.startUnit}-{price?.endUnit || '∞'}:{' '}
                          {convertCurrencyFormatDecimal(price?.salePrice || 0)}฿
                        </p>
                      )
                    })}
                  </div>
                )}
              </div>
            ),
            visibleHTML: (
              <>
                {data.isVisible === E_Is_Active.True ? (
                  <Chip startIcon={<img src={visibleSVG} />} color="success" label="Enable" />
                ) : (
                  <Chip startIcon={<img src={uvisibleSVG} />} color="gray" label="Disable" />
                )}
              </>
            ),
          }
        })

    case ETranformationDataIntoTable.EditProductSKUList:
      const { values, setFieldValue } = useFormikContext<IProductInfo>()

      return (dataList as ProductSku[])
        .filter((ele) => ele.isActive === E_Is_Active.True)
        .map((data) => {
          const index = values.formInputAddSKU.productSKU.findIndex((ele) => ele.productSKUID === data.productSKUID)

          const mapperOption =
            values.productInfo.productOptionGroup?.option
              ?.filter((ele) => ele?.isOption === E_Is_Active.True)
              .map((option) => {
                const mapperVariant = option?.variant?.map((ele) => ({
                  label: ele?.name as string,
                  value: ele?.productOptionVariantID as string,
                }))

                mapperVariant?.unshift({ label: '-', value: '-' })

                return {
                  [`${option?.productOptionID}`]: (
                    <SelectSingle
                      name={`formInputAddSKU.productSKU[${index}].optionAttribute["${option?.productOptionID}"]`}
                      option={mapperVariant || []}
                    />
                  ),
                }
              }) || []

          return {
            ...data,
            productSKUHTML: <TextField name={`formInputAddSKU.productSKU[${index}].productSKU`} type="text" />,
            productSKUNameHTML: <TextField name={`formInputAddSKU.productSKU[${index}].productSKUName`} type="text" />,
            ...Object.assign({}, ...mapperOption),
            pricingTypeHTML: (
              <div style={{ width: '150px' }}>
                <Button
                  fullWidth
                  name={
                    data.pricing?.[0]?.pricingModel
                      ? convertEnumToWord(data.pricing?.[0]?.pricingModel)
                      : 'Select Pricing Type'
                  }
                  typeButton="button"
                  type={data.pricing?.[0]?.pricingModel ? 'info' : 'infoOutline'}
                  size="small"
                  endIcon={<ContentCopyIcon style={{ transform: 'scaleX(-1)' }} />}
                  functionOnClick={() => handleOpen?.(index)}
                />
              </div>
            ),
            pricingHTML: (
              <div className="row gap20">
                <div>
                  <p className="fontBold">Cost</p>
                  <p>{convertCurrencyFormatDecimal(data?.pricing?.[0]?.cost || 0)}฿</p>
                </div>
                {data.pricing?.[0]?.pricingModel === E_Pricing_Model.StandardPricing && (
                  <div>
                    <p className="fontBold">
                      Default Price:{' '}
                      <span className="fontRegular">
                        {convertCurrencyFormatDecimal(data?.pricing?.[0]?.priceModelStandard?.defaultPrice || 0)}฿
                      </span>
                    </p>
                    <p className="fontBold">
                      Sale Price:{' '}
                      <span className="fontRegular">
                        {convertCurrencyFormatDecimal(data?.pricing?.[0]?.priceModelStandard?.salePrice || 0)}฿
                      </span>
                    </p>
                  </div>
                )}
                {data.pricing?.[0]?.pricingModel === E_Pricing_Model.VolumePricing && (
                  <div>
                    <p className="fontBold">Tiers</p>
                    {data.pricing?.[0].priceModelVolumn?.tableVolumnPrice?.map((price, index) => {
                      return (
                        <p key={index}>
                          Qty. {price?.startUnit}-{price?.endUnit || '∞'}:{' '}
                          {convertCurrencyFormatDecimal(price?.salePrice || 0)}฿
                        </p>
                      )
                    })}
                  </div>
                )}
              </div>
            ),
            visibleHTML: (
              <Switch
                checked={data.isVisible === E_Is_Active.True ? true : false}
                name={`formInputAddSKU.productSKU[${index}].isVisible`}
                onChange={() => {
                  if (values.formInputAddSKU?.productSKU[index].isVisible === E_Is_Active.True) {
                    setFieldValue(`formInputAddSKU.productSKU[${index}].isVisible`, E_Is_Active.False)
                  } else {
                    setFieldValue(`formInputAddSKU.productSKU[${index}].isVisible`, E_Is_Active.True)
                  }
                }}
              />
            ),
            action: (
              <img
                style={{ cursor: 'pointer' }}
                src={deleteSVG}
                alt=""
                onClick={() => {
                  dispatch(
                    setOpenDialog({
                      title: 'Archive SKU',
                      description: 'Archiving removes SKU from product',
                      status: 'danger',
                      textButtonCancle: '',
                      textButtonConfirm: 'Archive SKU',
                      functionOnSubmit: () => {
                        handleArchive?.(data.productSKUID)
                        dispatch(setCloseDialog())
                      },
                    }),
                  )
                }}
              />
            ),
          }
        })

    case ETranformationDataIntoTable.EditProductSKUListArchive:
      const { values: valuesEditProductSKUListArchive, setFieldValue: setFieldValueEditProductSKUListArchive } =
        useFormikContext<IProductInfo>()

      return (dataList as ProductSku[])
        .filter((ele) => ele.isActive === E_Is_Active.False)
        .map((data) => {
          const index = valuesEditProductSKUListArchive.formInputAddSKU.productSKU.findIndex(
            (ele) => ele.productSKUID === data.productSKUID,
          )

          const mapperOption =
            valuesEditProductSKUListArchive.productInfo.productOptionGroup?.option
              ?.filter((ele) => ele?.isOption === E_Is_Active.True)
              .map((option) => {
                const mapperVariant = option?.variant?.map((ele) => ({
                  label: ele?.name as string,
                  value: ele?.productOptionVariantID as string,
                }))

                mapperVariant?.unshift({ label: '-', value: '-' })

                return {
                  [`${option?.productOptionID}`]: (
                    <SelectSingle
                      name={`formInputAddSKU.productSKU[${index}].optionAttribute["${option?.productOptionID}"]`}
                      option={mapperVariant || []}
                    />
                  ),
                }
              }) || []

          return {
            ...data,
            productSKUHTML: <TextField name={`formInputAddSKU.productSKU[${index}].productSKU`} type="text" />,
            productSKUNameHTML: <TextField name={`formInputAddSKU.productSKU[${index}].productSKUName`} type="text" />,
            ...Object.assign({}, ...mapperOption),
            pricingTypeHTML: (
              <div style={{ width: '150px' }}>
                <Button
                  fullWidth
                  name={
                    data.pricing?.[0]?.pricingModel
                      ? convertEnumToWord(data.pricing?.[0]?.pricingModel)
                      : 'Select Pricing Type'
                  }
                  typeButton="button"
                  type={data.pricing?.[0]?.pricingModel ? 'info' : 'infoOutline'}
                  size="small"
                  endIcon={<ContentCopyIcon style={{ transform: 'scaleX(-1)' }} />}
                  functionOnClick={() => handleOpen?.(index)}
                />
              </div>
            ),
            pricingHTML: (
              <div className="row gap20">
                <div>
                  <p className="fontBold">Cost</p>
                  <p>{convertCurrencyFormatDecimal(data?.pricing?.[0]?.cost || 0)}฿</p>
                </div>
                {data.pricing?.[0]?.pricingModel === E_Pricing_Model.StandardPricing && (
                  <div>
                    <p className="fontBold">
                      Default Price:{' '}
                      <span className="fontRegular">
                        {convertCurrencyFormatDecimal(data?.pricing?.[0]?.priceModelStandard?.defaultPrice || 0)}฿
                      </span>
                    </p>
                    <p className="fontBold">
                      Sale Price:{' '}
                      <span className="fontRegular">
                        {convertCurrencyFormatDecimal(data?.pricing?.[0]?.priceModelStandard?.salePrice || 0)}฿
                      </span>
                    </p>
                  </div>
                )}
                {data.pricing?.[0]?.pricingModel === E_Pricing_Model.VolumePricing && (
                  <div>
                    <p className="fontBold">Tiers</p>
                    {data.pricing?.[0].priceModelVolumn?.tableVolumnPrice?.map((price, index) => {
                      return (
                        <p key={index}>
                          Qty. {price?.startUnit}-{price?.endUnit || '∞'}:{' '}
                          {convertCurrencyFormatDecimal(price?.salePrice || 0)}฿
                        </p>
                      )
                    })}
                  </div>
                )}
              </div>
            ),
            visibleHTML: (
              <Switch
                checked={data.isVisible === E_Is_Active.True ? true : false}
                name={`formInputAddSKU.productSKU[${index}].isVisible`}
                onChange={() => {
                  if (
                    valuesEditProductSKUListArchive.formInputAddSKU?.productSKU[index].isVisible === E_Is_Active.True
                  ) {
                    setFieldValueEditProductSKUListArchive(
                      `formInputAddSKU.productSKU[${index}].isVisible`,
                      E_Is_Active.False,
                    )
                  } else {
                    setFieldValueEditProductSKUListArchive(
                      `formInputAddSKU.productSKU[${index}].isVisible`,
                      E_Is_Active.True,
                    )
                  }
                }}
              />
            ),
            action: (
              <img
                style={{ cursor: 'pointer' }}
                src={recoverySVG}
                alt=""
                onClick={() => {
                  dispatch(
                    setOpenDialog({
                      title: 'Recovery SKU',
                      description: 'Recovering SKU from product',
                      status: 'info',
                      textButtonCancle: '',
                      textButtonConfirm: 'Recovery SKU',
                      functionOnSubmit: () => {
                        handleArchive?.(data.productSKUID)
                        dispatch(setCloseDialog())
                      },
                    }),
                  )
                }}
              />
            ),
          }
        })

    case ETranformationDataIntoTable.ProductOptionList:
      return dataList
        ?.filter((ele: ProductOption) => ele?.isOption === E_Is_Active.True)
        .map((data: ProductOption) => {
          return {
            ...data,
            variantHTML: (
              <div className="gapChips">
                {data.variant?.map((item, index: number) => {
                  return <Chip key={index} color="ligthGray" label={item?.name || ''} />
                })}
              </div>
            ),
          }
        })

    case ETranformationDataIntoTable.OptionManagementList:
      return dataList.map((data: ProductOptionGroup, index: number) => {
        return {
          ...data,
          isActiveHTML: <ChipByStatus type={data.isActive as E_Is_Active} />,
          createdAt: convertDateLL(data.createdAt),
          updatedAt: convertDateLL(data.updatedAt),
          action: (
            <>
              <IconButton onClick={(e) => handleClickMenu(e, index)}>
                <MoreHorizRounded />
              </IconButton>
              <MenuList
                open={open(index)}
                anchorEl={anchorEl[index]}
                listMenu={[
                  {
                    title: `Edit product option`,
                    functionOnClick: () => {
                      handleOpen?.(data)
                      handleCloseMenu(index)
                    },
                  },
                  {
                    title: `${data.isActive === E_Is_Active.True ? 'Archive' : 'Recovery'} option`,
                    functionOnClick: () => {
                      handleCloseMenu(index)
                      dispatch(
                        setOpenDialog({
                          title: `${data.isActive === E_Is_Active.True ? 'Archive' : 'Recovery'} option`,
                          description: `Option ${data.name} will be ${
                            data.isActive === E_Is_Active.True ? 'archive' : 'recovery'
                          }.`,
                          status: data.isActive === E_Is_Active.True ? 'danger' : 'info',
                          textButtonCancle: '',
                          textButtonConfirm: `${data.isActive === E_Is_Active.True ? 'Archive' : 'Recovery'} option`,
                          functionOnSubmit: () => {
                            handleArchive?.(data)
                            dispatch(setCloseDialog())
                          },
                        }),
                      )
                    },
                  },
                ]}
                handleClose={() => handleCloseMenu(index)}
              />
            </>
          ),
        }
      })

    case ETranformationDataIntoTable.TagsList:
      return dataList.map((data: Tag) => {
        return {
          ...data,
          colorHTML: <div className="rectColor" style={{ backgroundColor: data?.color || '#000000' }}></div>,
          isActiveHTML: <ChipByStatus type={data.isActive as E_Is_Active} />,
          createdAt: convertDateLL(data?.createdAt),
          updatedAt: convertDateLL(data?.updatedAt),
        }
      })

    case ETranformationDataIntoTable.InventoryManagementList:
      return dataList.map((data: ProductSku, index: number) => {
        const price = (data.pricing as Pricing[])[0]
        const stockValue =
          (price.cost as number) * (data.inventory?.stockAccumulated as number) +
          (price.cost as number) * (data.inventory?.stockIncoming as number)
        return {
          ...data,
          productImgURLHTML:
            data.product?.productImgURL?.length !== 0 ? (
              <ImageTable img={(data.product?.productImgURL as string[])[0]} readonly />
            ) : (
              '-'
            ),
          productNameHTML: (
            <div>
              <p className="fontBold">{data.productSKUName}</p>
              <p>{convertCurrencyFormatDecimal(price?.priceModelStandard?.salePrice as number)} ฿</p>
            </div>
          ),
          brandHTML: data.product?.brand?.name,
          incomingHTML: convertCurrencyFormat(data.inventory?.stockIncoming || 0),
          committedHTML: convertCurrencyFormat(data.inventory?.stockCommitted || 0),
          availableHTML: convertCurrencyFormat(data.inventory?.stockAvailable || 0),
          stockAccumulatedHTML: convertCurrencyFormat(data.inventory?.stockAccumulated || 0),
          costPerPcsHTML: convertCurrencyFormatDecimal(price.cost as number),
          stockValueHTML: `${convertCurrencyFormatDecimal(stockValue)} ฿`,
          isActiveHTML: <ChipByStatus type={data.isActive as E_Is_Active} />,
          action: (
            <>
              <IconButton onClick={(e) => handleClickMenu(e, index)}>
                <MoreHorizRounded />
              </IconButton>
              <MenuList
                open={open(index)}
                anchorEl={anchorEl[index]}
                listMenu={[
                  {
                    title: 'Transaction',
                    functionOnClick: () => {
                      handleCloseMenu(index)
                      navigate(`/inventory-management/transection?productSKUID=${data.productSKUID}`)
                    },
                  },
                  {
                    title: 'Add Stock',
                    functionOnClick: () => {
                      handleCloseMenu(index)
                      handleOpen?.({ ...data, triggerType: E_Trigger_Type.GoodsReceipt, qty: 0, note: '' })
                    },
                  },
                  {
                    title: 'Export Stock',
                    functionOnClick: () => {
                      handleCloseMenu(index)
                      handleOpen?.({ ...data, triggerType: E_Trigger_Type.GoodsIssue, qty: 0, note: '' })
                    },
                  },
                  {
                    title: 'Inv. Adjustment',
                    functionOnClick: () => {
                      handleCloseMenu(index)
                      handleOpen?.({ ...data, triggerType: E_Trigger_Type.InventoryAdjustment, qty: 0, note: '' })
                    },
                  },
                  {
                    title: 'Physical count',
                    functionOnClick: () => {
                      handleCloseMenu(index)
                      handleOpen?.({ ...data, triggerType: E_Trigger_Type.PhysicalCount, qty: 0, note: '' })
                    },
                  },
                  {
                    title: 'Void',
                    functionOnClick: () => {
                      handleCloseMenu(index)
                      navigate(`/inventory-management/transection?productSKUID=${data.productSKUID}`)
                    },
                  },
                ]}
                handleClose={() => handleCloseMenu(index)}
              />
            </>
          ),
        }
      })

    case ETranformationDataIntoTable.OrderManagementList:
      return dataList.map((data: Order) => {
        return {
          ...data,
          createdAt: convertDateLL(data?.createdAt),
          customerName: `${data.buyerInfo?.firstname} ${data.buyerInfo?.surname}`,
          contact: data.buyerInfo?.phoneNumber || '-',
          amountHTML: convertCurrencyFormat(data?.amount || 0),
          rawOrderStatus: data.orderStatus,
          orderStatusHTML: <ChipByStatus type={data.orderStatus as E_Order_Status} />,
          paymentStatusHTML: <ChipByStatus type={data.paymentStatus as E_Payment_Status} />,
          paymentTypeHTML: data.evidencePaymentURL ? 'Scan' : data.sessionURL ? 'Stripe' : '-',
          shippingTypeHTML: convertEnumToWord(data.shippingType || ''),
          deliveryStatusHTML: <ChipByStatus type={data.deliveryStatus as E_Delivery_Status} />,
          profileImagesHTML: <ImageTable img={data.profileImgURL?.[0] as string} readonly />,
        }
      })

    case ETranformationDataIntoTable.OrderProductList:
      return dataList.map((data: OrderItem, index: number) => {
        const key = Object.keys(data?.optionAttributeName)?.find((ele) => ele.toLocaleLowerCase().search('color') > -1)
        return {
          ...data,
          imgHTML: <ImageTable img={data.productImgURL?.[0] as string} key={index} readonly />,
          productNameHTML: (
            <>
              <p className="fontBold">{data.productName}</p>
              <p>{data.productSKU}</p>

              <div
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '3px',
                  backgroundColor: data.optionAttributeName[`${key}`],
                }}
              ></div>
            </>
          ),
          qtyHTML: `x${convertCurrencyFormat(data.qty || 0)}`,
          priceHTML: `${convertCurrencyFormatDecimal(data.price as number)} ฿`,
          totalHTML: (
            <p className="fontBold">{convertCurrencyFormatDecimal((data.qty as number) * (data.price as number))} ฿</p>
          ),
        }
      })

    case ETranformationDataIntoTable.OrderStatusTransectionList:
      return dataList.map((data: any) => {
        return {
          ...data,
          timeStampFormateDate: <p>{convertDateLL(data.timeStamp)}</p>,
          detailHTML: <p>{data.detail}</p>,
        }
      })

    case ETranformationDataIntoTable.DetailsList:
      return dataList.map((data: Order) => {
        return {
          ...data,
          image: <ImageTable img={data?.profileImgURL?.[0] || ''} />,
          orderNumber: (
            <>
              <p className="fontBold">{data.orderNo}</p>
              <p>{convertCurrencyFormatDecimal(data.total || 0)} ฿</p>
            </>
          ),
          amountHTML: convertCurrencyFormat(data.amount || 0),
          createdAt: convertDateLL(data.createdAt),
          customerName: `${data.buyerInfo?.firstname} ${data.buyerInfo?.surname}`,
          contact: (
            <>
              <p>
                <b>Email: </b>
                {data.buyerInfo?.email}
              </p>
              <p>
                <b>Phone: </b>
                {data.buyerInfo?.phoneNumber}
              </p>
            </>
          ),
          orderStatusHTML: <ChipByStatus type={data.orderStatus as E_Order_Status} />,
          paymentStatusHTML: <ChipByStatus type={data.paymentStatus as E_Payment_Status} />,
          tagsHTML: (
            <div className="gapChips">
              {data?.tagID ? (
                <Chip
                  color="ligthGray"
                  label={tagsReducer.tagsOptionList?.find((ele) => ele.value === data.tagID)?.label as string}
                />
              ) : (
                '-'
              )}
            </div>
          ),
        }
      })

    case ETranformationDataIntoTable.TransectionList:
      return (dataList as Inventory[]).map((data) => {
        const price = (data.productSKU?.pricing as Pricing[])[0]
        const stockValue =
          (price.cost as number) * (data.stockAccumulated as number) +
          (price.cost as number) * (data.stockIncoming as number)

        return {
          ...data,
          orderNoHTML: data.order?.orderNo,
          productSKUName: data.productSKU?.productSKUName,
          productSKU: data.productSKU?.productSKU,
          statusHTML: <ChipByTrigger type={data.triggerType as string} />,
          stockAccumulatedHTML: convertCurrencyFormat(data?.stockAccumulated || 0),
          stockCommittedHTML: convertCurrencyFormat(data?.stockCommitted || 0),
          stockIncomingHTML: convertCurrencyFormat(data?.stockIncoming || 0),
          stockAvailableHTML: convertCurrencyFormat(data?.stockAvailable || 0),
          stockValueHTML: `${convertCurrencyFormatDecimal(stockValue)} ฿`,
          costPerPcsHTML: `${convertCurrencyFormatDecimal(price.cost as number)} ฿`,
          createdAt: convertDateWithTimeStamp(data?.createdAt),
          action: (
            <>
              {!data.productSKUID || !data.transactionNo || data.isVoided === E_Is_Active.True ? (
                '-'
              ) : data.triggerType !== E_Trigger_Type.GoodsReceipt &&
                data.triggerType !== E_Trigger_Type.GoodsIssue &&
                data.triggerType !== E_Trigger_Type.InventoryAdjustment ? (
                '-'
              ) : (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation()

                    dispatch(
                      setOpenDialog({
                        title: 'Void Transaction',
                        description: 'This transaction will be voided. This action cannot be recovered',
                        status: 'danger',
                        textButtonCancle: '',
                        textButtonConfirm: 'Void Transaction',
                        functionOnSubmit: () => {
                          handleArchive?.(data)
                          dispatch(setCloseDialog())
                        },
                      }),
                    )
                  }}
                >
                  <img src={deleteSVG} alt="" />
                  <p>Void</p>
                </div>
              )}
            </>
          ),
        }
      })

    case ETranformationDataIntoTable.PreviewProduct:
      const productList: any[] = []
      const findOptionGroup = optionGroupList.find(
        (ele) => ele?.productOptionGroupID === dataList[0]?.productOptionGroupID,
      )

      dataList.map((data: UpdateProduct & { productSKU: UpdateProductSku[] }, index: number) => {
        productList.push(
          `No. ${index + 1}, Product Name: ${data.name}, Prefix: ${data.prefix}, Unit: ${data.unitLabel}`,
        )

        data.productSKU.map((sku) => {
          const mapperOption = Object.keys(sku.optionAttribute).map((optionID) => {
            const findOption = findOptionGroup?.option?.find((ele) => ele?.productOptionID === optionID)
            const findVaraint = findOption?.variant?.find(
              (ele) => ele?.productOptionVariantID === sku.optionAttribute[optionID],
            )

            if (sku.optionAttribute[optionID] === '-')
              return {
                [findOption?.productOptionID as string]: '-',
              }
            else
              return {
                [findOption?.productOptionID as string]: findVaraint?.name || <h6 className="colorRed">Not Found</h6>,
              }
          })

          productList.push({
            productSKU: sku.productSKU,
            productSKUName: sku.productSKUName,

            ...Object.assign({}, ...mapperOption),

            pricingTypeHTML: <ChipByStatus type={sku.pricing?.pricingModel as E_Pricing_Model} />,
            pricingHTML: (
              <div className="row gap20">
                <div>
                  <p className="fontBold">Cost</p>
                  <p>{convertCurrencyFormatDecimal(sku.pricing?.cost || 0)}฿</p>
                </div>
                {sku.pricing?.pricingModel === E_Pricing_Model.StandardPricing && (
                  <div>
                    <p className="fontBold">
                      Default Price:{' '}
                      <span className="fontRegular">
                        {convertCurrencyFormatDecimal(sku.pricing?.priceModelStandard?.defaultPrice || 0)}฿
                      </span>
                    </p>
                    <p className="fontBold">
                      Sale Price:{' '}
                      <span className="fontRegular">
                        {convertCurrencyFormatDecimal(sku.pricing?.priceModelStandard?.salePrice || 0)}฿
                      </span>
                    </p>
                  </div>
                )}
                {sku.pricing?.pricingModel === E_Pricing_Model.VolumePricing && (
                  <div>
                    <p className="fontBold">Tiers</p>
                    {sku.pricing?.priceModelVolumn?.tableVolumnPrice?.map((price, index) => {
                      return (
                        <p key={index}>
                          Qty. {price?.startUnit}-{price?.endUnit || '∞'}:{' '}
                          {convertCurrencyFormatDecimal(price?.salePrice || 0)}฿
                        </p>
                      )
                    })}
                  </div>
                )}
              </div>
            ),
          })
        })
      })
      return productList

    default:
      return []
  }
}
