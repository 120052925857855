// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import { useNavigate } from 'react-router-dom'

// Include in project
import styles from './index.module.scss'
import { Divider, Table, Tag } from 'component'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { IProductInfo } from 'pages/ProductInfo'

const OptionDetailsSection: React.FC = () => {
  const { values } = useFormikContext<IProductInfo>()
  const navigate = useNavigate()

  const dataTable = transformationDataIntoTable({
    dataList: values.productInfo.productOptionGroup?.option || [],
    type: ETranformationDataIntoTable.ProductOptionList,
  })

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h6>Options details</h6>
        <Tag description="Boot revenue" />
      </div>
      <Divider />
      <div className={styles.groupText}>
        <p className={styles.text}>Option Group</p>
        <p
          aria-controls="true"
          onClick={() => navigate(`/product-management/option-info/${values.productInfo.productOptionGroupID}`)}
        >
          {values.productInfo.productOptionGroup?.name}
        </p>
      </div>

      <Table
        objectKey={{
          name: 'Option',
          description: 'Description',
          variantHTML: 'Values',
        }}
        data={dataTable}
      />
    </div>
  )
}

export default OptionDetailsSection
