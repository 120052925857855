import { gql } from '@apollo/client'

export const USER_LIST = gql`
  query userList($filter: UserFilter!, $pageSize: Int, $nextToken: String) {
    userList(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      res_code
      res_desc
      nextToken
      items {
        userID
        companyID
        userRoleGlobal
        uuidGoogle
        uuidFacebook
        uuidLine
        phoneNumber
        email
        firstname
        surname
        idCard
        birthday
        gender
        tagID
        customAttribute
        profileImgURL
        idCardImgURL
        isActive
        createdAt
        updatedAt
        order {
          companyID
          orderID
          orderNo
          userID
          cartID
          orderStatus
          paymentStatus
          deliveryStatus
          orderItem {
            productID
            productSKUID
            qty
            productSKU
            productSKUName
            productName
            productDescription
            unitLabel
            productImgURL
            brandName
            optionAttributeName
            price
            note
          }
          customAttribute
          shippingType
          shippingCompany
          shippingTrackingID
          note
          tagID
          sessionID
          sessionURL
          orderByRole
          buyerUserID
          buyerInfo {
            firstname
            surname
            userRole
            phoneNumber
            email
          }
          sellerUserID
          sellerInfo {
            firstname
            surname
            userRole
            phoneNumber
            email
          }
          billingAddress {
            name
            note
            taxType
            taxID
            googleMapURL
            email
            tel
            address
            subDistrict
            district
            province
            postCode
            country
          }
          shippingAddress {
            firstname
            surname
            companyName
            googleMapURL
            email
            tel
            note
            address
            subDistrict
            district
            province
            postCode
            country
          }
          profileImgURL
          evidencePaymentURL
          evidenceShippedURL
          evidenceReceivedURL
          evidencePaymentInfo {
            bankName
            price
            timestamp
          }
          isFreightOnDelivery
          price
          freight
          tax
          amount
          discount
          total
          timeoutPaidAt
          confirmPaidAt
          approvedAt
          pickedUpAt
          shippedAt
          fulfilledAt
          pausedAt
          createdAt
          updatedAt
        }
        address {
          userID
          addressID
          firstname
          surname
          address
          subDistrict
          district
          province
          postCode
          country
          companyName
          taxID
          tel
          email
          isActive
          isDefault
          createdAt
          updatedAt
          note
          googleMapURL
          taxAddress {
            taxType
            name
            address
            subDistrict
            district
            province
            postCode
            country
            taxID
            googleMapURL
            email
            tel
            note
          }
        }
        userRoleCompany {
          companyID
          userRoleCompanyID
          userRoleCompanyName
          permission
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const UPDATE_TAGS_ISACTIVE_CUSTOMER = gql`
  mutation updateTagsAndIsActiveCustomer($input: UpdateTagsAndIsActiveCustomer!) {
    updateTagsAndIsActiveCustomer(input: $input) {
      res_code
      res_desc
      userID
    }
  }
`

export const CREATE_STAFF = gql`
  mutation CreateStaff($input: CreateStaff!) {
    createStaff(input: $input) {
      res_code
      res_desc
      userID
    }
  }
`

export const UPDATE_STAFF = gql`
  mutation UpdateStaff($input: UpdateStaff!) {
    updateStaff(input: $input) {
      res_code
      res_desc
      userID
    }
  }
`
