import { gql } from '@apollo/client'

export const SETTING_COMPANY_INFO = gql`
  query settingCompanyInfo {
    settingCompanyInfo {
      res_code
      res_desc
      companyID
      tax
      stripeSuccessPathURL
      stripeCancelPathURL
      senderMail
      timeoutCart
      timeoutPaid
      customAttribute
      isClose
      domainName
      lineNotifyToken
      createdAt
      updatedAt
      freightModel
      freightModelFix {
        freight
      }
      freightModelProvince {
        province
        tableFreightProvince {
          startPrice
          endPrice
          freight
        }
        isFreightOnDelivery
      }
    }
  }
`
export const UPDATE_SETTING_COMPANY = gql`
  mutation updateSettingCompany($input: UpdateSettingCompany!) {
    updateSettingCompany(input: $input) {
      res_code
      res_desc
      companyID
    }
  }
`
