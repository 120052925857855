// Lib
import React from 'react'
import { useSnackbar } from 'notistack'
import { useFormikContext, getIn } from 'formik'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'

// Include in project
import styles from './index.module.scss'
import { EResCode } from 'utils/other'
import { Modal, Notification } from 'component'
import { FormInput } from 'container'
import { MutationCreateStaffArgs, MutationUpdateStaffArgs, E_Is_Active } from 'utils/generated'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { IRenderInput } from 'utils/interface'
import { IStaffManagement } from 'pages/StaffManagement'
import { RootState } from 'states/store'
import { setOpenLoading } from 'states/slice/loading'

interface Props {
  open: boolean
  handleClose: () => void
  handleUpdate: ({ variables }: { variables: MutationUpdateStaffArgs }) => Promise<any>
  createStaff: ({ variables }: { variables: MutationCreateStaffArgs }) => Promise<any>
  refetch: () => void
}

const ModalStaffEdit: React.FC<Props> = ({ open, handleClose, handleUpdate, refetch, createStaff }) => {
  const { values, errors, setFieldValue } = useFormikContext<IStaffManagement>()
  const { enqueueSnackbar } = useSnackbar()
  const getUserRoleListReducer = useSelector((state: RootState) => state.userRole)
  const tagsReducer = useSelector((state: RootState) => state.tags)
  const dispatch = useDispatch()

  const fieldInputs: IRenderInput[] = [
    {
      heading: 'Profile',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: values?.formInput.profileImgURLShow,
      readonly: false,
      multiple: false,
      name: 'formInput.profileImgURLShow',
    },
    {
      heading: 'Status',
      type: 'SWITCH',
      checked: getIn(values, 'formInput.isActive') === E_Is_Active.True,
      labelDetail: { true: 'Active', false: 'Inactive' },
      name: 'formInput.isActive',
      disabled: false,
      onChange: () => {
        if (getIn(values, 'formInput.isActive') === E_Is_Active.True) {
          setFieldValue('formInput.isActive', E_Is_Active.False)
        } else {
          setFieldValue('formInput.isActive', E_Is_Active.True)
        }
      },
    },
    { heading: 'Firstname', type: 'TEXT_FIELD', name: 'formInput.firstname', disabled: false, require: true },
    { heading: 'Surname', type: 'TEXT_FIELD', name: 'formInput.surname', disabled: false, require: true },
    { heading: 'Email', type: 'TEXT_FIELD', name: 'formInput.email', disabled: false, require: true },
    { heading: 'Phone number', type: 'TEXT_FIELD', name: 'formInput.phoneNumber', disabled: false, require: true },
    { heading: 'ID Card', type: 'TEXT_FIELD', name: 'formInput.idCard', disabled: false, require: true },
    {
      heading: 'User role',
      type: 'SELEC_SINGLE',
      name: 'formInput.userRoleCompanyID',
      disabled: false,
      option: getUserRoleListReducer.userRoleOptionList,
      require: true,
    },
    // {
    //   heading: 'Tags',
    //   type: 'AUTO_COMPLETE',
    //   name: 'formInput.tagID',
    //   disabled: false,
    //   option: tagsReducer.tagsOptionList,
    // },
  ]

  const fieldInputsCreate: IRenderInput[] = [
    {
      heading: 'Profile',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: values?.formInput.profileImgURLShow,
      readonly: false,
      multiple: false,
      name: 'formInput.profileImgURLShow',
    },
    { heading: 'Firstname', type: 'TEXT_FIELD', name: 'formInput.firstname', disabled: false, require: true },
    { heading: 'Surname', type: 'TEXT_FIELD', name: 'formInput.surname', disabled: false, require: true },
    { heading: 'Email', type: 'TEXT_FIELD', name: 'formInput.email', disabled: false, require: true },
    { heading: 'Phone number', type: 'TEXT_FIELD', name: 'formInput.phoneNumber', disabled: false, require: true },
    { heading: 'ID Card', type: 'TEXT_FIELD', name: 'formInput.idCard', disabled: false, require: true },
    {
      heading: 'User role',
      type: 'SELEC_SINGLE',
      name: 'formInput.userRoleCompanyID',
      disabled: false,
      option: getUserRoleListReducer.userRoleOptionList,
      require: true,
    },
    { heading: 'Password', type: 'TEXT_FIELD_PASSWORD', name: 'formInput.password', disabled: false, require: true },
    // {
    //   heading: 'Tags',
    //   type: 'AUTO_COMPLETE',
    //   name: 'formInput.tagID',
    //   disabled: false,
    //   option: tagsReducer.tagsOptionList,
    // },
  ]

  const handleUpdateUserRole = async () => {
    if (values.formInput.userID) {
      const packData = await transformationDataToServer({
        values: values,
        type: ETransformationDataToServer.UpdateStaff,
      })

      handleUpdate({ variables: packData as MutationUpdateStaffArgs }).then((res) => {
        const resUpdate = res.data.updateStaff
        if (resUpdate.res_code !== EResCode.Success) return

        handleClose()
        refetch()
        enqueueSnackbar(<Notification title="Success" description="Update User information." />, {
          variant: 'success',
        })
      })
    } else {
      if (!_.isEmpty(errors)) return

      dispatch(setOpenLoading())

      const packDataCreate = await transformationDataToServer({
        values: values,
        type: ETransformationDataToServer.CreateStaff,
      })

      createStaff({ variables: packDataCreate as MutationCreateStaffArgs }).then((res) => {
        const resCreate = res.data.createStaff
        if (resCreate.res_code !== EResCode.Success) return

        handleClose()
        refetch()
        enqueueSnackbar(<Notification title="Success" description="Create Staff." />, {
          variant: 'success',
        })
      })
    }
  }

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      width="50%"
      height="90%"
      title="User Details"
      handleSave={handleUpdateUserRole}
    >
      <div className={styles.container}>
        <section className={styles.form}>
          <div className={styles.card}>
            <h5>Staff Details</h5>
            <FormInput title="User Info" fieldInputs={values.formInput?.userID ? fieldInputs : fieldInputsCreate} />
          </div>
        </section>
      </div>
    </Modal>
  )
}

export default ModalStaffEdit
