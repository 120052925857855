// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'

// Include in project
import styles from './index.module.scss'
import { Modal, Table, Notification } from 'component'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { OrderInformationSection, EvidenceSection, CustomerInformationSection, SummayOrderSection } from 'container'
import { IOrderManagement } from 'pages/OrderManagement'
import { MutationUpdateOrderStatusArgs, MutationUpdateStripePaymentStatusArgs } from 'utils/generated'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { EResCode } from 'utils/other'

interface Props {
  open: boolean
  handleClose: () => void
  updateOrderStatus: ({ variables }: { variables: MutationUpdateOrderStatusArgs }) => Promise<any>
  updateStripePaymentStatus: ({ variables }: { variables: MutationUpdateStripePaymentStatusArgs }) => Promise<any>
  refetch: () => void
}

const ModalOrderInfo: React.FC<Props> = ({
  open,
  handleClose,
  updateOrderStatus,
  updateStripePaymentStatus,
  refetch,
}) => {
  const { values } = useFormikContext<IOrderManagement>()
  const { enqueueSnackbar } = useSnackbar()

  const dataTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.OrderProductList,
    dataList: values.formInput.orderItem,
  })

  const handleUpdateOrderStatus = async () => {
    const packData = await transformationDataToServer({
      values: values,
      type: ETransformationDataToServer.UpdateOrderStatus,
    })

    updateOrderStatus({ variables: packData as MutationUpdateOrderStatusArgs }).then((res) => {
      const resUpdate = res.data.updateOrderStatus
      if (resUpdate.res_code !== EResCode.Success) return

      refetch()
      handleClose()
      enqueueSnackbar(<Notification title="Success" description="Update Order Status." />, {
        variant: 'success',
      })
    })
  }

  const handleUpdateStripe = async () => {
    const packData = await transformationDataToServer({
      values: values,
      type: ETransformationDataToServer.UpdateStripePaymentStatus,
    })

    updateStripePaymentStatus({ variables: packData as MutationUpdateStripePaymentStatusArgs }).then((res) => {
      const resUpdate = res.data.updateStripePaymentStatus
      if (resUpdate.res_code !== EResCode.Success) return

      refetch()
      handleClose()
      enqueueSnackbar(<Notification title="Success" description="Update Order Status with stripe." />, {
        variant: 'success',
      })
    })
  }

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      width="90%"
      height="90%"
      title="Orders"
      buttonText="Save Order"
      handleSave={handleUpdateOrderStatus}
    >
      <div className={styles.container}>
        <section className={styles.wrapperCard}>
          <h5>Orders</h5>
          <OrderInformationSection handleUpdateStripe={handleUpdateStripe} />
          <EvidenceSection />
          <CustomerInformationSection />
        </section>
        <section className={styles.wrapperCard}>
          <h5>Products</h5>
          <Table
            objectKey={{
              imgHTML: 'Image',
              productNameHTML: 'Name',
              qtyHTML: 'QTY.',
              priceHTML: 'Price (฿)',
              totalHTML: 'Total (฿)',
              note: 'Note',
            }}
            data={dataTable}
          />
          <SummayOrderSection />
        </section>
      </div>
    </Modal>
  )
}

export default ModalOrderInfo
