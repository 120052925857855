// Lib
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFormikContext, withFormik } from 'formik'
import { NodeModel } from '@minoru/react-dnd-treeview'
import { useSnackbar } from 'notistack'

// Images
import renameSVG from 'images/contextMenu/rename.svg'
import deleteSVG from 'images/contextMenu/delete.svg'
import CreateNewFolderRoundedIcon from '@mui/icons-material/CreateNewFolderRounded'

// Include in project
import styles from './index.module.scss'
import Layout from 'layouts'
import { menuHeaderProduct } from 'utils/menu'
import {
  useModal,
  useRightClickMenu,
  useCategory,
  useLoadingControl,
  useProductList,
  useProductMutation,
  useProductSKUMutation,
} from 'hooks'
import { CategorySection, ModalFolder } from 'container'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import { TreeCategories, RigthClickMenu, Button, Notification } from 'component'
import { Category as GenerateInterfaceCategory, Product } from 'utils/generatedNonAuth'
import { E_Is_Active, MutationDeleteProductCategoryArgs } from 'utils/generated'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { EResCode } from 'utils/other'
import { IOption } from 'utils/interface'
import type { RootState } from 'states/store'

export interface ICategory {
  categoryList: NodeModel<
    GenerateInterfaceCategory & { pdfUrlShow?: (string & File)[]; imageUrlShow?: (string & File)[] }
  >[]
  productList: Product[]
  formInput: NodeModel<GenerateInterfaceCategory & { pdfUrlShow?: (string & File)[]; imageUrlShow?: (string & File)[] }>
  searchProducts: IOption[]
  isLoadingImportProduct: boolean
}

const intitialValue: ICategory = {
  categoryList: [],
  productList: [],
  formInput: {
    parent: 'ROOT',
    id: 'GENERATE',
    text: 'GENERATE',
    data: {
      categoryID: null,
      categoryName: '',
      productOptionGroupID: null,
      joinItemWithCategoryID: null,
      description: '',
      isActive: E_Is_Active.True,
      index: 0,
      customAttribute: null,
      features: '',
      pdfUrl: [],
      pdfUrlShow: [],
      imageUrl: [],
      imageUrlShow: [],
      items: null,
    },
  },
  searchProducts: [],
  isLoadingImportProduct: false,
}

const Category: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ICategory>()
  const { show, setShow, points, setPoints } = useRightClickMenu()
  const { isModalOpen, handleOpen, handleClose } = useModal(intitialValue.formInput)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const decodeTokenReducer = useSelector((state: RootState) => state.decodeToken)
  const optionGroupReducer = useSelector((state: RootState) => state.optionGroup)

  const [selectFolder, setSelectFolder] = useState<string | number>('')
  const [fetchProductList, setFetchProductList] = useState<number>(0)

  const {
    data: categoryList,
    isLoading,
    updateCategory,
    deleteCategory,
    updateJoinProduct,
    refetch,
  } = useCategory({
    companyID: decodeTokenReducer.companyID,
  })
  const { isLoading: isLoadingProductList, getProductList } = useProductList()
  const { updateProduct, isLoading: isLoadingUpdateProduct } = useProductMutation()
  const { updateSKU, isLoading: isLoadingUpdateSKU } = useProductSKUMutation()

  useLoadingControl(
    isLoading || isLoadingProductList || isLoadingUpdateProduct || isLoadingUpdateSKU || values.isLoadingImportProduct,
  )

  useEffect(() => {
    if (!categoryList) return

    const mapper = categoryList?.map((eleFirst) => ({
      parent: 'ROOT', // First Layer is Fix ROOT
      id: eleFirst?.categoryID as string,
      text: eleFirst?.categoryName as string,
      data: { ...eleFirst, pdfUrlShow: eleFirst?.pdfUrl || [], imageUrlShow: eleFirst?.imageUrl || [] },

      items: eleFirst?.items?.map((eleSecond) => ({
        parent: eleFirst.categoryID,
        id: eleSecond?.categoryID as string,
        text: eleSecond?.categoryName as string,
        data: { ...eleSecond, pdfUrlShow: eleSecond?.pdfUrl || [], imageUrlShow: eleSecond?.imageUrl || [] },

        items: eleSecond?.items?.map((eleThird) => ({
          parent: eleSecond.categoryID,
          id: eleThird?.categoryID as string,
          text: eleThird?.categoryName as string,
          data: { ...eleThird, pdfUrlShow: eleThird?.pdfUrl || [], imageUrlShow: eleThird?.imageUrl || [] },

          items: eleThird?.items?.map((eleFourth) => ({
            parent: eleThird.categoryID,
            id: eleFourth?.categoryID as string,
            text: eleFourth?.categoryName as string,
            data: { ...eleFourth, pdfUrlShow: eleFourth?.pdfUrl || [], imageUrlShow: eleFourth?.imageUrl || [] },

            items: eleFourth?.items?.map((eleFifth) => ({
              parent: eleFourth.categoryID,
              id: eleFifth?.categoryID as string,
              text: eleFifth?.categoryName as string,
              data: { ...eleFifth, pdfUrlShow: eleFifth?.pdfUrl || [], imageUrlShow: eleFifth?.imageUrl || [] },
            })),
          })),
        })),
      })),
    }))

    const flatMapper: NodeModel<GenerateInterfaceCategory>[] = []
    mapper.forEach((eleFirst) => {
      const cloneEleFirst = JSON.parse(JSON.stringify(eleFirst))
      delete cloneEleFirst.items
      cloneEleFirst.data.items = []

      flatMapper.push(cloneEleFirst)

      eleFirst.items?.forEach((eleSecond) => {
        const cloneEleSecond = JSON.parse(JSON.stringify(eleSecond))
        delete cloneEleSecond.items
        cloneEleSecond.data.items = []

        flatMapper.push(cloneEleSecond)

        eleSecond.items?.forEach((eleThird) => {
          const cloneEleThird = JSON.parse(JSON.stringify(eleThird))
          delete cloneEleThird.items
          cloneEleThird.data.items = []

          flatMapper.push(cloneEleThird)

          eleThird.items?.forEach((eleFourth) => {
            const cloneEleFourth = JSON.parse(JSON.stringify(eleFourth))
            delete cloneEleFourth.items
            cloneEleFourth.data.items = []

            flatMapper.push(cloneEleFourth)

            eleFourth.items?.forEach((eleFifth) => {
              const cloneEleFifth = JSON.parse(JSON.stringify(eleFifth))
              delete cloneEleFifth.items
              cloneEleFifth.data.items = []

              flatMapper.push(cloneEleFifth)
            })
          })
        })
      })
    })

    setFieldValue('categoryList', flatMapper)
  }, [categoryList])

  useEffect(() => {
    if (!selectFolder) return

    const findCategory = values.categoryList.find((ele) => ele.id === selectFolder)

    getProductList({
      variables: {
        companyID: decodeTokenReducer.companyID,
        joinItemWithCategoryID: findCategory?.data?.joinItemWithCategoryID,
        filter: null,
      },
    }).then((res) => {
      const resGet = res.data.productList
      if (resGet.res_code !== EResCode.Success) return

      setFieldValue('productList', resGet.items)
    })
  }, [selectFolder, fetchProductList])

  const handleDelete = () => {
    const findIndexCategory = values.categoryList.findIndex((ele) => ele.id === points.id)

    async function callAPIDelete() {
      const packData = JSON.parse(JSON.stringify(values.categoryList))
      packData[findIndexCategory].data!.index = -1

      const variables: any = await transformationDataToServer({
        values: packData,
        type: ETransformationDataToServer.UpdateCategory,
      })

      let targetIndex = ''

      variables?.input?.items.map((first: GenerateInterfaceCategory, indexFirst: number) => {
        if (first.index === -1) return (targetIndex = `${indexFirst}`)

        first.items?.map((second, indexSecond) => {
          if (second?.index === -1) return (targetIndex = `${indexFirst},${indexSecond}`)

          second?.items?.map((third, indexThird) => {
            if (third?.index === -1) return (targetIndex = `${indexFirst},${indexSecond},${indexThird}`)

            third?.items?.map((fourth, indexFourth) => {
              if (fourth?.index === -1)
                return (targetIndex = `${indexFirst},${indexSecond},${indexThird},${indexFourth}`)

              fourth?.items?.map((fifth, indexFifth) => {
                if (fifth?.index === -1)
                  return (targetIndex = `${indexFirst},${indexSecond},${indexThird},${indexFourth},${indexFifth}`)
              })
            })
          })
        })
      })

      deleteCategory({
        variables: {
          input: {
            index: targetIndex,
          },
        } as MutationDeleteProductCategoryArgs,
      }).then((res) => {
        const resUpdate = res.data.deleteProductCategory
        if (resUpdate.res_code !== EResCode.Success) return

        if (values.categoryList[findIndexCategory].id === selectFolder) {
          setSelectFolder('')
        }

        optionGroupReducer.refetch?.()
        refetch()
        enqueueSnackbar(<Notification title="Success" description="Delete Category." />, {
          variant: 'success',
        })
        dispatch(setCloseDialog())
        handleClose()
      })
    }

    dispatch(
      setOpenDialog({
        title: 'Delete Folder',
        description: `Delete Folder ${values.categoryList[findIndexCategory].text} ?`,
        status: 'danger',
        textButtonCancle: 'Cancel',
        textButtonConfirm: 'Delete',
        functionOnSubmit: callAPIDelete,
      }),
    )
  }

  const handleOpenModalFolder = (type: 'NEW' | 'RENAME') => {
    if (type === 'NEW') {
      handleOpen()
    } else {
      const findCategory = values.categoryList.find((ele) => ele.id === points.id)
      handleOpen(findCategory)
    }
  }

  return (
    <Layout headerSectionName="Product Management" headerSectionManu={menuHeaderProduct}>
      <div className={styles.container}>
        <div className={styles.wrapperBox}>
          <div className={styles.menuCard}>
            <div className={styles.header}>
              <h6>Categories</h6>

              <div className={styles.wrapperButton}>
                <Button
                  typeButton="button"
                  type="info"
                  name="New Category"
                  startIcon={<CreateNewFolderRoundedIcon />}
                  functionOnClick={() => handleOpenModalFolder('NEW')}
                />
              </div>
            </div>
            <TreeCategories
              setShow={setShow}
              setPoint={setPoints}
              selectFolder={selectFolder}
              setSelectFolder={setSelectFolder}
              updateCategory={updateCategory}
              refetch={refetch}
            />
          </div>

          {show && (
            <RigthClickMenu
              menuList={[
                { name: 'Rename', startIcon: renameSVG, funtionOnClick: () => handleOpenModalFolder('RENAME') },
                {
                  name: 'Delete',
                  startIcon: deleteSVG,
                  funtionOnClick: handleDelete,
                },
              ]}
              point={points}
              setOpenMenu={setShow}
            />
          )}

          {selectFolder ? (
            <CategorySection
              selectFolder={selectFolder}
              updateCategory={updateCategory}
              updateJoinProduct={updateJoinProduct}
              refetch={refetch}
              setFetchProductList={setFetchProductList}
              updateProduct={updateProduct}
              updateSKU={updateSKU}
            />
          ) : (
            <p className={styles.center}>Please Select Category</p>
          )}

          <ModalFolder open={isModalOpen} handleClose={handleClose} updateCategory={updateCategory} refetch={refetch} />
        </div>
      </div>
    </Layout>
  )
}

const EnhancedCategory = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  handleSubmit: () => {
    console.log('Skip')
  },
})(Category)

export default EnhancedCategory
