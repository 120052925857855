import * as Yup from 'yup'

const yupForgotPassword = Yup.object().shape({
  companyID: Yup.string().when('step', {
    is: 1,
    then: () => Yup.string().required('กรุณากรอก'),
  }),
  email: Yup.string().when('step', {
    is: 1,
    then: () => Yup.string().required('กรุณากรอกอีเมล'),
  }),
  otpCode: Yup.string().when('step', {
    is: 2,
    then: () => Yup.string().required('กรุณากรอก OTP').min(6, 'กรุณากรอก OTP'),
  }),
  password: Yup.string().when('step', {
    is: 3,
    then: () =>
      Yup.string()
        .required('กรุณากรอกรหัสผ่าน ')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*\d)[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\d]{8,}$/,
          'Password Condition',
        ),
  }),
  passwordConfirm: Yup.string().when('step', {
    is: 3,
    then: () =>
      Yup.string().when('password', (password, field) =>
        password ? field.required('กรุณากรอกรหัสผ่าน ').oneOf([Yup.ref('password')]) : field,
      ),
  }),
})

export default yupForgotPassword
