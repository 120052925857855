import { EResCode } from 'utils/other'
import { useErrMsgControl } from 'hooks'
import { useMutation, useQuery } from '@apollo/client'
import { USER_INFO, UPDATE_USER } from 'queries/userInfo'
import { QueryUserListArgs, User } from 'utils/generated'

function useUserInfo(variables?: QueryUserListArgs) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(USER_INFO, {
    fetchPolicy: 'no-cache',
    variables: variables,
    context: { clientName: 'endpointAdmin' },
  })

  const [updateUserInfo, optionUpdate] = useMutation(UPDATE_USER, { context: { clientName: 'endpointAdmin' } })

  const isLoading = loading || optionUpdate.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.userInfo?.res_code !== EResCode.Success)
      return JSON.stringify(errorQuery || data?.userInfo?.res_desc)

    // optionUpdate
    if (
      optionUpdate?.error ||
      (optionUpdate.data?.updateUserInfo?.res_code && optionUpdate.data?.updateUserInfo?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateUserInfo?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { data: data?.userInfo as User, refetch, updateUserInfo, isLoading }
}

export default useUserInfo
