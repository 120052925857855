// Lib
import React from 'react'
import { DndProvider } from 'react-dnd'
import { useFormikContext } from 'formik'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Tree, getBackendOptions, MultiBackend, NodeModel } from '@minoru/react-dnd-treeview'
import { useSnackbar } from 'notistack'

//Images
import folderSVG from 'images/categories/folder.svg'

// Include in project
import styles from './index.module.scss'
import { theme } from './config'
import { BranchCategory, Notification } from 'component'
import { ICategory } from 'pages/Category'
import { Category as GenerateInterfaceCategory } from 'utils/generatedNonAuth'
import { MutationUpdateProductCategoryArgs } from 'utils/generated'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { EResCode } from 'utils/other'

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  setPoint: React.Dispatch<React.SetStateAction<{ x: number; y: number; id: string | number }>>
  selectFolder: string | number
  setSelectFolder: React.Dispatch<React.SetStateAction<string | number>>
  updateCategory: ({ variables }: { variables: MutationUpdateProductCategoryArgs }) => Promise<any>
  refetch: () => void
}
const TreeCategories: React.FC<Props> = ({
  setShow,
  setPoint,
  selectFolder,
  setSelectFolder,
  updateCategory,
  refetch,
}): JSX.Element => {
  const { values } = useFormikContext<ICategory>()
  const { enqueueSnackbar } = useSnackbar()

  const handleDrop = async (newTree: NodeModel<GenerateInterfaceCategory>[]) => {
    const packData = await transformationDataToServer({
      values: newTree,
      type: ETransformationDataToServer.UpdateCategory,
    })

    updateCategory({ variables: packData as MutationUpdateProductCategoryArgs }).then((res) => {
      const resUpdate = res.data.updateProductCategory
      if (resUpdate.res_code !== EResCode.Success) return

      refetch()
      enqueueSnackbar(<Notification title="Success" description="Update Category." />, { variant: 'success' })
    })
  }

  return (
    <div className={styles.wrapperContainer}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          <div className={styles.container}>
            <Tree
              tree={values.categoryList}
              rootId={'ROOT'}
              sort={false}
              insertDroppableFirst={false}
              dropTargetOffset={5}
              canDrop={(tree, { dragSource, dropTargetId }) => {
                if (
                  dragSource?.id !== dropTargetId &&
                  dragSource?.id !== (tree && tree.find((item) => item.id === dropTargetId)?.parent)
                ) {
                  return true
                }
              }}
              render={(node, { depth, isOpen, onToggle, handleRef }) => {
                return (
                  <BranchCategory
                    node={node}
                    depth={depth}
                    isOpen={isOpen}
                    onToggle={onToggle}
                    handleRef={handleRef}
                    setShow={setShow}
                    setPoint={setPoint}
                    selectFolder={selectFolder}
                    setSelectFolder={setSelectFolder}
                  />
                )
              }}
              dragPreviewRender={(monitorProps) => (
                <div className={styles.containerDrag}>
                  <img className={styles.icon} src={folderSVG} alt="" />
                  <p>{monitorProps.item.text}</p>
                </div>
              )}
              placeholderRender={(node, { depth }) => {
                const left = depth * 24

                return <div className={styles.root} style={{ left }}></div>
              }}
              onDrop={handleDrop}
              classes={{
                root: styles.treeRoot,
                draggingSource: styles.draggingSource,
                placeholder: styles.placeholderContainer,
              }}
              enableAnimateExpand={true}
              initialOpen={values.categoryList.map((data) => data.id)}
            />
          </div>
        </DndProvider>
      </ThemeProvider>
    </div>
  )
}

export default TreeCategories

// Exam Lib DnD https://minop1205.github.io/react-dnd-treeview/?path=/docs/basic-examples-minimum-configuration--minimum-configuration-story
