import { EResCode } from 'utils/other'
import { useErrMsgControl } from 'hooks'
import { useMutation, useQuery } from '@apollo/client'
import { QueryUserListArgs, UserQuery } from 'utils/generated'
import { CREATE_STAFF, UPDATE_TAGS_ISACTIVE_CUSTOMER, USER_LIST, UPDATE_STAFF } from 'queries/user'

function useUser(variables?: QueryUserListArgs) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(USER_LIST, {
    fetchPolicy: 'no-cache',
    variables: variables,
    context: { clientName: 'endpointAdmin' },
  })

  const [updateTagsAndIsActiveCustomer, optionUpdate] = useMutation(UPDATE_TAGS_ISACTIVE_CUSTOMER, {
    context: { clientName: 'endpointAdmin' },
  })
  const [createStaff, optionCreate] = useMutation(CREATE_STAFF, { context: { clientName: 'endpointAdmin' } })
  const [updateStaff, optionUpdateStaff] = useMutation(UPDATE_STAFF, { context: { clientName: 'endpointAdmin' } })

  const isLoading = loading || optionUpdate.loading || optionCreate.loading || optionUpdateStaff.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.userList?.res_code !== EResCode.Success)
      return JSON.stringify(errorQuery || data?.userInfo?.res_desc)

    // Update
    if (
      optionUpdate?.error ||
      (optionUpdate?.data?.updateTagsAndIsActiveCustomer?.res_code &&
        optionUpdate?.data?.updateTagsAndIsActiveCustomer?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateTagsAndIsActiveCustomer?.res_desc)

    // Create
    if (
      optionCreate?.error ||
      (optionCreate?.data?.createStaff?.res_code && optionCreate?.data?.createStaff?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createStaff?.res_desc)

    // Update Staff
    if (
      optionUpdateStaff?.error ||
      (optionUpdateStaff?.data?.updateStaff?.res_code &&
        optionUpdateStaff?.data?.updateStaff?.res_code !== EResCode.Success)
    )
      return JSON.stringify(optionUpdateStaff?.error || optionUpdateStaff.data?.updateStaff?.res_desc)
  }

  useErrMsgControl(errMsg())

  return {
    data: data?.userList as UserQuery,
    refetch,
    isLoading,
    updateTagsAndIsActiveCustomer,
    createStaff,
    updateStaff,
  }
}

export default useUser
