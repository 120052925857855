import { axiosInstance } from 'configs/axios'
import { AxiosResponse } from 'axios'

export function login({ companyID, email, password }: LoginParams): Promise<AxiosResponse> {
  return axiosInstance.post(`/auth/admin/login`, {
    companyID,
    email,
    password,
  })
}

export interface LoginParams {
  companyID: string
  email: string
  password: string
}

export function refreshToken(refreshToken: string): Promise<AxiosResponse> {
  return axiosInstance.post(`/auth/admin/refresh_token`, {
    refreshToken: `${refreshToken}`,
  })
}

export function forgotPassword({ companyID, email }: { companyID: string; email: string }): Promise<any> {
  return axiosInstance.post(`/auth/forgotPassword`, {
    companyID,
    email,
  })
}

export function forgotPasswordVerify({
  companyID,
  email,
  OTPCode,
}: {
  companyID: string
  email: string
  OTPCode: string
}): Promise<any> {
  return axiosInstance.post(`/auth/forgotPasswordVerify`, {
    companyID,
    email,
    OTPCode,
  })
}

export function resetNewPassword({
  companyID,
  email,
  newPassword,
}: {
  companyID: string
  email: string
  newPassword: string
}): Promise<any> {
  return axiosInstance.post(`/auth/resetNewPassword`, {
    companyID,
    email,
    newPassword,
  })
}
