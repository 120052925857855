import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Tag } from 'utils/generated'
import { IOption } from 'utils/interface'

const initialState: {
  tagsList: Tag[]
  tagsOptionList: IOption[]
  refetch: (() => void) | null
} = {
  tagsList: [],
  tagsOptionList: [],
  refetch: null,
}

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTags: (
      state,
      action: PayloadAction<{
        items: Tag[]
        refetch: () => void
      }>,
    ) => {
      state.tagsList = action.payload.items
      state.tagsOptionList = action.payload.items.map((data) => ({
        value: data.tagID as string,
        label: data.tagName as string,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setTags } = tagsSlice.actions

export default tagsSlice.reducer
