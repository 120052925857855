// Lib
import React from 'react'

//Image
import editeSVG from 'images/commons/editicon.svg'

// Include in project
import styles from './index.module.scss'
import { useModal } from 'hooks'
import { ModalCMS, FormInput } from 'container'
import { Button } from 'component'
import { CmsCompany } from 'utils/generatedNonAuth'
import { MutationUpdateCmsCompanyArgs } from 'utils/generated'
import { IRenderInput } from 'utils/interface'

interface Props {
  cmsCompanyInfo: CmsCompany
  updateCms: ({ variables }: { variables: MutationUpdateCmsCompanyArgs }) => Promise<any>
  refetch: () => void
}

const CMSSection: React.FC<Props> = ({ cmsCompanyInfo, updateCms, refetch }) => {
  const { isModalOpen, handleOpen, handleClose } = useModal()

  const fieldInputsPoster: IRenderInput[] = [
    {
      heading: 'Banner 1',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: cmsCompanyInfo?.poster?.banner1 || [],
      readonly: true,
      multiple: true,
    },
    {
      heading: 'Banner 2',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: cmsCompanyInfo?.poster?.banner2 || [],
      readonly: true,
      multiple: true,
    },
    {
      heading: 'Banner 3',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: cmsCompanyInfo?.poster?.banner3 || [],
      readonly: true,
      multiple: true,
    },
    {
      heading: 'Banner 4',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: cmsCompanyInfo?.poster?.banner4 || [],
      readonly: true,
      multiple: true,
    },
    {
      heading: 'Promotion banner',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: cmsCompanyInfo?.poster?.promotionBanner || [],
      readonly: true,
      multiple: true,
    },
    {
      heading: 'bestSellerBanner',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: cmsCompanyInfo?.poster?.bestSellerBanner || [],
      readonly: true,
      multiple: true,
    },
    {
      heading: 'Recommended banner',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: cmsCompanyInfo?.poster?.recommendedBanner || [],
      readonly: true,
      multiple: true,
    },
  ]

  // const fieldInputsAnnouncement: IRenderInput[] = [
  //   {
  //     heading: 'Announcement',
  //     type: 'DROP_ZONE',
  //     sizeDrop: 'SM',
  //     fileList: cmsCompanyInfo?.announcement?.announcement || [],
  //     readonly: true,
  //     multiple: true,
  //   },
  // ]

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h5>Content Management System (CMS)</h5>
        <Button
          typeButton="button"
          type="gray"
          size="small"
          name="Edit"
          startIcon={<img src={editeSVG} />}
          functionOnClick={() =>
            handleOpen({
              ...cmsCompanyInfo,
              poster: {
                ...cmsCompanyInfo.poster,
                banner1Show: cmsCompanyInfo.poster.banner1 || [],
                banner2Show: cmsCompanyInfo.poster.banner2 || [],
                banner3Show: cmsCompanyInfo.poster.banner3 || [],
                banner4Show: cmsCompanyInfo.poster.banner4 || [],
                promotionBannerShow: cmsCompanyInfo.poster.promotionBanner || [],
                bestSellerBannerShow: cmsCompanyInfo.poster.bestSellerBanner || [],
                recommendedBannerShow: cmsCompanyInfo.poster.recommendedBanner || [],
              },
              announcement: {
                ...cmsCompanyInfo.announcement,
                announcementShow: cmsCompanyInfo.announcement.announcement || [],
              },
            })
          }
        />
      </section>

      <div className={styles.wrapperCard}>
        <div className={styles.box}>
          <FormInput title="Poster" fieldInputs={fieldInputsPoster} />
        </div>
        {/* <div className={styles.box}>
          <FormInput title="Announcement" fieldInputs={fieldInputsAnnouncement} />
        </div> */}
      </div>

      <ModalCMS open={isModalOpen} handleClose={handleClose} updateCms={updateCms} refetch={refetch} />
    </div>
  )
}

export default CMSSection
