// Lib
import React from 'react'
import { useSnackbar } from 'notistack'

// Images

// Include in project
import { Notification } from 'component'

const useCopyToClipboard = () => {
  const { enqueueSnackbar } = useSnackbar()

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value)
    enqueueSnackbar(<Notification title="Success" description={`Copied : ${value}`} />)
  }

  return { handleCopyToClipboard }
}
export default useCopyToClipboard
