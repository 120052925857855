// Lib
import React from 'react'
import { withFormik } from 'formik'
import { useSelector } from 'react-redux'

// Include in project
import Layout from 'layouts'
import { CMSSection } from 'container'
import { useCMS, useLoadingControl } from 'hooks'
import type { RootState } from 'states/store'
import { CmsCompany } from 'utils/generated'

export interface ICMS {
  formInput: {
    poster: {
      banner1: string[]
      banner2: string[]
      banner3: string[]
      banner4: string[]
      promotionBanner: string[]
      bestSellerBanner: string[]
      recommendedBanner: string[]

      banner1Show: (string & File)[]
      banner2Show: (string & File)[]
      banner3Show: (string & File)[]
      banner4Show: (string & File)[]
      promotionBannerShow: (string & File)[]
      bestSellerBannerShow: (string & File)[]
      recommendedBannerShow: (string & File)[]
    }
    // announcement: {
    //   announcement: string[]
    //   announcementShow: (string & File)[]
    // }
  } & CmsCompany
}

const intitialValue: ICMS = {
  formInput: {
    poster: {
      banner1: [],
      banner2: [],
      banner3: [],
      banner4: [],
      promotionBanner: [],
      bestSellerBanner: [],
      recommendedBanner: [],

      banner1Show: [],
      banner2Show: [],
      banner3Show: [],
      banner4Show: [],
      promotionBannerShow: [],
      bestSellerBannerShow: [],
      recommendedBannerShow: [],
    },
    // announcement: {
    //   announcement: [],
    //   announcementShow: [],
    // },
  },
}

const CMS: React.FC = () => {
  const decodeTokenReducer = useSelector((state: RootState) => state.decodeToken)
  const {
    data: cmsCompanyInfo,
    isLoading,
    updateCms,
    refetch,
  } = useCMS({
    companyID: decodeTokenReducer.companyID,
  })
  useLoadingControl(isLoading)

  return (
    <Layout hiddenHeaderSection>
      <CMSSection cmsCompanyInfo={cmsCompanyInfo} updateCms={updateCms} refetch={refetch} />
    </Layout>
  )
}

const EnhancedCMS = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  handleSubmit: () => {
    console.log('Skip')
  },
})(CMS)

export default EnhancedCMS
