// Lib
import React from 'react'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { Divider } from 'component'
import { IProductInfo } from 'pages/ProductInfo'

interface TextProps {
  optionID: string
  variantID: string
}
const SpecDetailsSection: React.FC = () => {
  const { values } = useFormikContext<IProductInfo>()

  const RenderText: React.FC<TextProps> = ({ optionID, variantID }: TextProps) => {
    const findOption = values.productInfo?.productOptionGroup?.option?.find((ele) => ele?.productOptionID === optionID)
    const findVariant = findOption?.variant?.find((ele) => ele?.productOptionVariantID === variantID)

    return (
      <div className={styles.boxText}>
        <p className={styles.text}>{findOption?.name}</p>
        <p>{findVariant?.name}</p>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <h6>Spec Details</h6>
      <Divider />
      <div className={styles.wrapperBoxText}>
        {values.productInfo?.specAttribute &&
          Object.keys(values.productInfo.specAttribute).map((key, index) => (
            <RenderText key={index} optionID={key} variantID={values.productInfo.specAttribute[key]} />
          ))}
      </div>
    </div>
  )
}

export default SpecDetailsSection
