// Lib
import React, { useMemo } from 'react'
import { getIn, useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { OTPTimer } from 'component'

export interface Props {
  value: string
  name?: string
  resendOTP?: () => void
}
const OTPInput: React.FC<Props> = ({ value, name, resendOTP }): JSX.Element => {
  const { setFieldValue, errors, touched } = useFormikContext()
  const RE_DIGIT = new RegExp(/^\d+$/)

  const digitItems = useMemo(() => {
    const items: Array<string> = []
    for (let i = 0; i < 6; i++) {
      const char = value.split('')[i]
      if (RE_DIGIT.test(char)) {
        items.push(char)
      } else {
        items.push('')
      }
    }
    return items
  }, [value])

  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    let targetValue = e.target.value
    const isTargetValueDigit = RE_DIGIT.test(targetValue)

    if (!RE_DIGIT.test(targetValue) && targetValue !== '') {
      return
    }
    targetValue = isTargetValueDigit ? targetValue : ''
    const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1)

    setFieldValue(`${name}`, newValue)

    if (!isTargetValueDigit) {
      return
    }

    const nextElementSibling = e.target.nextElementSibling as HTMLInputElement | null
    if (nextElementSibling) {
      nextElementSibling.focus()
    }
  }

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    if (e.key !== 'Backspace' || target.value !== '') {
      return
    }
    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null
    if (previousElementSibling) {
      previousElementSibling.focus()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.wrapperInput}>
          {digitItems.map((digit, index: number) => {
            return (
              <input
                className={styles.inputOTP}
                key={index}
                value={digit}
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputOnChange(e, index)}
                onKeyDown={inputOnKeyDown}
                maxLength={1}
              />
            )
          })}
        </div>
        <p className="errorMsg">{getIn(touched, 'otpCode') && getIn(errors, 'otpCode') && getIn(errors, 'otpCode')}</p>
      </div>

      <OTPTimer resendOTP={resendOTP} />
    </div>
  )
}

export default OTPInput
