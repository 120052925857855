// Lib
import React from 'react'
import { Chip } from '@mui/material'

// Include in project
import { Props, ThemeColor } from './config'

const ChipComponent: React.FC<Props> = (props: Props) => {
  const seletedColor = ThemeColor[props.color]

  return (
    <Chip
      size="medium"
      icon={props.startIcon && props.startIcon}
      sx={seletedColor}
      label={props.label}
      disabled={props.disabled}
      onClick={props.onClick && props.onClick}
    />
  )
}

export default ChipComponent
