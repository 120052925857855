import Color from 'abstracts/export.module.scss'
import Font from 'abstracts/export.module.scss'
import { ReactElement } from 'react'

export interface Props {
  label: string
  disabled?: boolean
  onDelete?: () => void
  onClick?: () => void
  startIcon?: ReactElement
}

export const ThemeColor = {
  color: `${Color.grey400}`,
  border: `1px solid ${Color.grey200}`,
  backgroundColor: `${Color.white}`,
  '& span': {
    fontSize: Font.fontSizeP,
    fontFamily: Font.fontFamilyRegular,
  },
  '& .MuiChip-deleteIcon': {
    color: `${Color.dangerMain}`,
  },
}
