// Lib
import React from 'react'
import { ClickAwayListener } from '@mui/material'

// Include in project
import styles from './index.module.scss'

interface Props {
  menuList: { name: string; funtionOnClick?: () => void; startIcon?: string }[]
  point: { x: number; y: number }
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const RigthClickMenu: React.FC<Props> = ({ menuList, point, setOpenMenu }: Props): JSX.Element => {
  return (
    <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
      <div className={styles.container} style={{ top: `${point.y}px`, left: `${point.x}px` }}>
        {menuList.map((item, index) => (
          <div className={styles.wrapperMenu} key={index} onClick={item.funtionOnClick}>
            {item.startIcon && <img src={item.startIcon} alt="" />}
            <p>{item.name}</p>
          </div>
        ))}
      </div>
    </ClickAwayListener>
  )
}

export default RigthClickMenu
