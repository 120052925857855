// Lib
import React from 'react'

// Include in project
import styles from './index.module.scss'

const DividerComponent: React.FC = () => {
  return <div className={styles.line} />
}
export default DividerComponent
