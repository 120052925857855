// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import parser from 'html-react-parser'

// Include in project
import styles from './index.module.scss'
import { UploadPDF, Divider, DropZoneUpload, Tag } from 'component'
import { IProductInfo } from 'pages/ProductInfo'
import type { RootState } from 'states/store'
import { enterNewLine } from 'utils/other'

interface TextProps {
  title: string
  values?: string
  path?: string
}

const ProductInfoDetailsSection: React.FC = () => {
  const { values } = useFormikContext<IProductInfo>()
  const brandOption = useSelector((state: RootState) => state.brand.brandOptionList)
  const navigate = useNavigate()

  const RenderText: React.FC<TextProps> = ({ title, values, path }: TextProps) => {
    return (
      <div className={styles.boxText}>
        <p className={styles.text}>{title}</p>
        <p aria-controls={`${Boolean(path)}`} onClick={() => path && navigate(path)}>
          {values ? parser(values) : '-'}
        </p>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <h6>Detail</h6>
      <Divider />

      <div className={styles.wrapperBoth}>
        <div className={styles.wrapperBoxText}>
          <RenderText title="Name" values={values.productInfo?.name || '-'} />
          <RenderText title="Description" values={enterNewLine(values.productInfo?.description) || '-'} />
          <RenderText title="Unit Label" values={values.productInfo?.unitLabel || '-'} />
          <RenderText
            title="Brand"
            values={brandOption.find((ele) => ele.value === values.productInfo?.brandID)?.label || '-'}
            path={`/product-management/brand?brandID=${values.productInfo?.brandID}`}
          />
          <RenderText title="Prefix SKU" values={values.productInfo?.prefix || '-'} />
          <RenderText title="Note" values={values.productInfo?.remark || '-'} />
        </div>

        <div className={styles.wrapperBox}>
          <div className={styles.box}>
            <section className={styles.header}>
              <p className="fontBold">Images</p>
              <Tag description={`${values.productInfo?.productImgURL?.length}/5 Files`} />
            </section>
            <DropZoneUpload sizeDrop="SM" fileList={(values.productInfo?.productImgURL as string[]) || []} readonly />
          </div>
          <div className={styles.box}>
            <UploadPDF
              fileList={(values.productInfo?.productFileURL as string[]) || []}
              name="formInput.productFileURL"
              multiple
              readonly
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductInfoDetailsSection
