// Lib
import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

// Include in project
import styles from './index.module.scss'
import { TextField, SelectSingle, Divider, AutoComplete } from 'component'
import { IProductManagement } from 'pages/ProductManagement'
import type { RootState } from 'states/store'
import { E_Is_Active } from 'utils/generated'

interface Props {
  title: string
}

const ProductOptionInformation: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<IProductManagement>()
  const optionReducer = useSelector((state: RootState) => state.optionGroup)
  const tagsList = useSelector((state: RootState) => state.tags.tagsOptionList)

  useEffect(() => {
    const productOptionGroupID = values.formInput.productOptionGroupID
    if (!productOptionGroupID) return

    const findOption = optionReducer.optionGroupList.find((ele) => ele.productOptionGroupID === productOptionGroupID)
    setFieldValue('formInput.productOptionGroup', findOption)
  }, [values.formInput.productOptionGroupID])

  const RenderHeader: React.FC<Props> = ({ title }: Props) => {
    return (
      <div className={styles.header}>
        <h6>{title}</h6>
        <Divider />
      </div>
    )
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <RenderHeader title="Product Option Information" />
        <SelectSingle
          name="formInput.productOptionGroupID"
          label="Product Option Group Master Data"
          option={optionReducer.optionGroupOptionList}
          require
        />
      </div>

      <div className={styles.container}>
        <RenderHeader title="Option Details" />
        <div className={styles.box}>
          <div className={styles.wrapperHeader}>
            <p className="fontBold">Option Name</p>
            <p className="fontBold">Type</p>
            <p className="fontBold">Description (Optionnal)</p>
            <p className="fontBold">Values</p>
          </div>
          <Divider />

          <div className={styles.wrapperInput}>
            {values.formInput.productOptionGroup?.option?.map((item, indexOption: number) => {
              if (item?.isOption === E_Is_Active.False) return <React.Fragment key={indexOption}></React.Fragment>

              return (
                <div className={styles.group} key={indexOption}>
                  <TextField name={`formInput.productOptionGroup.option[${indexOption}].name`} type="text" disabled />
                  <TextField name={`formInput.productOptionGroup.option[${indexOption}].type`} type="text" disabled />
                  <TextField
                    name={`formInput.productOptionGroup.option[${indexOption}].description`}
                    type="text"
                    disabled
                  />
                  <div>
                    {item?.variant?.map((data, indexVariant: number) => (
                      <TextField
                        key={indexVariant}
                        name={`formInput.productOptionGroup.option[${indexOption}].variant[${indexVariant}].name`}
                        type="text"
                        disabled
                      />
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <RenderHeader title="Spec Details" />
        <div className={styles.wrapperSelect}>
          {values.formInput.productOptionGroup?.option?.map((item, index: number) => {
            if (item?.isOption === E_Is_Active.True) return <React.Fragment key={index}></React.Fragment>

            return (
              <SelectSingle
                key={index}
                name={`formInput.specAttribute["${item?.productOptionID}"]`}
                label={item?.name as string}
                option={
                  item?.variant?.map((varaint) => ({
                    value: varaint?.productOptionVariantID as string,
                    label: varaint?.name as string,
                  })) || []
                }
              />
            )
          })}
        </div>
      </div>

      <div className={styles.container}>
        <RenderHeader title="Tags" />
        <AutoComplete name="formInput.tagID" placeholder="Tags" option={tagsList} />
      </div>
    </div>
  )
}
export default ProductOptionInformation
