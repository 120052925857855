// Lib
import React, { useEffect } from 'react'
import { useFormikContext, withFormik } from 'formik'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { useModal, useTagsMutation, useLoadingControl } from 'hooks'
import { ModalAddTags } from 'container'
import { menuHeaderProduct } from 'utils/menu'
import { Table, Button, ButtonFilterIsActive } from 'component'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import yupTags from 'validations/yupTags.validate'
import { RootState } from 'states/store'
import { E_Is_Active, Tag } from 'utils/generated'

// Images
import AddCircleIcon from '@mui/icons-material/AddCircle'

export interface ITags {
  formInput: Tag
  filter: {
    isActive: E_Is_Active | 'ALL'
  }
}

const intitialValue: ITags = {
  formInput: {
    tagID: '',
    tagName: '',
    color: '#ffffff',
    isActive: E_Is_Active.True,
  },
  filter: {
    isActive: E_Is_Active.True,
  },
}

const Tags: React.FC = () => {
  const [searchParams] = useSearchParams()
  const searchTagID = searchParams.get('tagID')
  const { values } = useFormikContext<ITags>()
  const tagsReducer = useSelector((state: RootState) => state.tags)

  const { isModalOpen, handleOpen, handleClose } = useModal(intitialValue.formInput)

  const { isLoading, createTags, updateTags } = useTagsMutation()
  useLoadingControl(isLoading)

  const tagsListAfterFilter = tagsReducer?.tagsList?.filter((ele) => {
    if (values.filter.isActive === 'ALL') return true
    return ele.isActive === values.filter.isActive
  })

  const dataTable = transformationDataIntoTable({
    dataList: tagsListAfterFilter || [],
    type: ETranformationDataIntoTable.TagsList,
  })

  useEffect(() => {
    // Trigger When params have search tagID
    // Will open modal that tagID
    if (!searchTagID) return
    if (tagsReducer?.tagsList.length === 0) return

    const findBrand = tagsReducer?.tagsList.find((ele) => ele.tagID === searchTagID)

    handleOpen(findBrand)
  }, [searchTagID, tagsReducer?.tagsList])

  return (
    <Layout headerSectionName="Product Management" headerSectionManu={menuHeaderProduct}>
      <div className={styles.container}>
        <div className={styles.addTags}>
          <ButtonFilterIsActive />
          <Button
            typeButton="button"
            type="info"
            size="small"
            name="Add Tags"
            startIcon={<AddCircleIcon />}
            functionOnClick={() => handleOpen()}
          />
        </div>

        <Table
          objectKey={{
            tagName: 'Tag Name',
            colorHTML: 'Color',
            isActiveHTML: 'Status',
            createdAt: 'Created At',
            updatedAt: 'Updated At',
          }}
          data={dataTable}
          handleClick={handleOpen}
        />

        <ModalAddTags
          open={isModalOpen}
          handleClose={handleClose}
          createTags={createTags}
          updateTags={updateTags}
          refetch={tagsReducer.refetch}
        />
      </div>
    </Layout>
  )
}

const EnhancedTags = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  validateOnMount: true,
  validationSchema: yupTags,
  handleSubmit: () => {
    console.log('skip')
  },
})(Tags)

export default EnhancedTags
