import { gql } from '@apollo/client'

export const USER_ROLE_COMPANY_LIST = gql`
  query userRoleCompanyList($filter: UserRoleCompanyFilter!, $pageSize: Int, $nextToken: String) {
    userRoleCompanyList(filter: $filter, pageSize: $pageSize, nextToken: $nextToken) {
      res_code
      res_desc
      nextToken
      items {
        companyID
        userRoleCompanyID
        userRoleCompanyName
        userLevel
        permission
        isActive
        createdAt
        updatedAt
      }
    }
  }
`

export const UPDATE_USER_ROLE_COMPANY = gql`
  mutation updateUserRoleCompany($input: UpdateUserRoleCompany!) {
    updateUserRoleCompany(input: $input) {
      res_code
      res_desc
      companyID
      userRoleCompanyID
    }
  }
`

export const CREATE_USER_ROLE_COMPANY = gql`
  mutation createUserRoleCompany($input: CreateUserRoleCompany!) {
    createUserRoleCompany(input: $input) {
      res_desc
      res_code
      companyID
      userRoleCompanyID
    }
  }
`
