// Lib
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'

// Include in project
import { setDecodeToken, IDecodeToken } from 'states/slice/decodeToken'
import type { RootState } from 'states/store'

const useIntitialDecode = () => {
  const dispatch = useDispatch()
  const decodeTokenReducer = useSelector((state: RootState) => state.decodeToken)
  const accessToken = localStorage.getItem('accessToken')

  useEffect(() => {
    if (decodeTokenReducer.userID) return
    if (!accessToken) return
    const decode: IDecodeToken = jwt_decode(accessToken)

    dispatch(setDecodeToken(decode))
  }, [accessToken])
}

export default useIntitialDecode
