// Lib
import React from 'react'

//Images

// Include in project
import styles from './index.module.scss'
import { Checkbox } from 'component'
import { Address, E_Is_Active } from 'utils/generated'
import { findNameProvinceByEnum } from 'utils/other'

interface Props {
  funtionOnChecked?: () => void
  addressDetail: Address
}

const CardAddress: React.FC<Props> = ({ addressDetail, funtionOnChecked }) => {
  return (
    <div className={styles.container} aria-checked={addressDetail.isDefault === 'TRUE'}>
      <div className={styles.box}>
        <h6>ที่อยู่</h6>
        <div className={styles.bothText}>
          <h6>
            {addressDetail.firstname} {addressDetail.surname}
          </h6>
          <h6>{addressDetail.tel}</h6>
        </div>
        <div className={styles.subText}>
          <p>
            {addressDetail.address} {addressDetail.subDistrict} {addressDetail.district}{' '}
            {findNameProvinceByEnum(addressDetail.province)} {addressDetail.postCode}
          </p>
          <p>Note: {addressDetail.note || '-'}</p>
        </div>
      </div>

      {addressDetail.taxAddress?.taxID && (
        <div className={styles.box}>
          <h6>ที่อยู่ใบกำกับภาษี</h6>
          <div className={styles.bothText}>
            <h6>{addressDetail.taxAddress.name}</h6>
            <h6>{addressDetail.taxAddress.tel}</h6>
            <h6>Tax ID.{addressDetail.taxAddress.taxID}</h6>
          </div>
          <div className={styles.subText}>
            <p>
              {addressDetail.taxAddress.address} {addressDetail.taxAddress.subDistrict}
              {addressDetail.taxAddress.district} {findNameProvinceByEnum(addressDetail.taxAddress.province)}{' '}
              {addressDetail.taxAddress.postCode}
            </p>
            <p>Note: {addressDetail.taxAddress.note || '-'}</p>
          </div>
        </div>
      )}

      <div className={styles.bottomCard}>
        <Checkbox
          value="test"
          checked={addressDetail.isDefault === E_Is_Active.True}
          label="ที่อยู่ผู้จัดส่งเริ่มต้น"
          name="test"
          onChange={funtionOnChecked}
          disabled
        />
      </div>
    </div>
  )
}

export default CardAddress
