// Lib
import React from 'react'
import { useFormikContext, withFormik } from 'formik'
import { useSelector } from 'react-redux'

//Image
import { AddCircleRounded, IosShare } from '@mui/icons-material'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { ModalStaffEdit, SearchBox } from 'container'
import { menuHeaderCustomer } from 'utils/menu'
import { E_Is_Active, E_User_Role, User, Maybe } from 'utils/generated'
import { useLoadingControl, useModal, useUser, useInfiniteScroll } from 'hooks'
import { Button, ButtonFilterIsActive, Table } from 'component'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import yupCreateStaff from 'validations/yupCreateStaff.validate'
import { IOption } from 'utils/interface'
import { pageSize } from 'configs'
import { TOptionkey } from 'container/SearchBox'
import type { RootState } from 'states/store'

export interface IStaffManagement {
  formInput: {
    tagID?: IOption[]
    profileImgURLShow: (string & File)[]
    password: string
  } & User
  filter: {
    key: string
    value: string
    firstname: Maybe<string>
    surname: Maybe<string>
    phoneNumber: Maybe<string>
    email: Maybe<string>
    isActive: E_Is_Active | 'ALL'
    userRoleCompanyID: Maybe<string>
  }
}

const intitialValue: IStaffManagement = {
  formInput: {
    profileImgURL: [],
    profileImgURLShow: [],
    isActive: E_Is_Active.True,
    firstname: '',
    surname: '',
    userID: '',
    idCard: '',
    email: '',
    phoneNumber: '',
    userRoleCompanyID: '',
    tagID: [],
    address: [],
    password: '', // User In Create Staff
  },
  filter: {
    key: 'phoneNumber',
    value: '',
    firstname: null,
    surname: null,
    phoneNumber: null,
    email: null,
    isActive: E_Is_Active.True,
    userRoleCompanyID: null,
  },
}

const StaffManagement: React.FC = () => {
  const { values } = useFormikContext<IStaffManagement>()
  const userRoleOptionList = useSelector((state: RootState) => state.userRole.userRoleOptionList)

  const filterObject = {
    userRoleGlobal: E_User_Role.Staff,
    firstname: values.filter.firstname,
    surname: values.filter.surname,
    phoneNumber: values.filter.phoneNumber,
    email: values.filter.email,
    isActive: values.filter.isActive !== 'ALL' ? values.filter.isActive : null,
    userRoleCompanyID: values.filter.userRoleCompanyID,
  }

  const { data, isLoading, updateStaff, refetch, createStaff } = useUser({
    filter: filterObject,
    pageSize: pageSize,
    nextToken: null,
  })
  const { dataList } = useInfiniteScroll({
    result: data,
    refetch: refetch,
    variables: {
      filter: filterObject,
      pageSize: pageSize,
      nextToken: data?.nextToken || null,
    },
    filter: [
      values.filter.firstname,
      values.filter.surname,
      values.filter.phoneNumber,
      values.filter.email,
      values.filter.isActive,
      values.filter.userRoleCompanyID,
    ],
    key: 'userList',
  })

  useLoadingControl(isLoading)

  const dataTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.CustomerManagement,
    dataList: dataList || [],
  })

  const { isModalOpen, handleClose, handleOpen } = useModal(intitialValue.formInput)

  const optionKey: TOptionkey = [
    {
      value: 'firstname',
      label: 'Firstname',
      child: {
        type: 'TEXT_FIELD',
      },
    },
    {
      value: 'surname',
      label: 'Surname',
      child: {
        type: 'TEXT_FIELD',
      },
    },
    {
      value: 'phoneNumber',
      label: 'PhoneNumber',
      child: {
        type: 'TEXT_FIELD',
      },
    },
    {
      value: 'email',
      label: 'Email',
      child: {
        type: 'TEXT_FIELD',
      },
    },
    {
      value: 'userRoleCompanyID',
      label: 'User Role',
      child: {
        type: 'SELEC_SINGLE',
        options: userRoleOptionList,
      },
    },
  ]

  return (
    <Layout headerSectionName="User Management" headerSectionManu={menuHeaderCustomer}>
      <div className={styles.container}>
        <div className={styles.wrapperHeader}>
          <SearchBox optionKey={optionKey} />
          {/* <Button name="Export" typeButton="button" type="gray" size="small" startIcon={<IosShare />} /> */}
          <div className={styles.row}>
            <ButtonFilterIsActive />
            <Button
              name="Add Staff"
              typeButton="button"
              type="info"
              size="small"
              startIcon={<AddCircleRounded />}
              functionOnClick={() => handleOpen()}
            />
          </div>
        </div>
        <Table
          objectKey={{
            imageHTML: 'Profile image',
            nameHTML: 'Name',
            contactHTML: 'Contact',
            userRoleCompanyName: 'User Role',
            // tagsHTML: 'Tags',
            statusRoleHTML: 'Status Role',
            statusHTML: 'Status',
            createdAt: 'Created At',
            updatedAt: 'Updated At',
          }}
          data={dataTable}
          handleClick={handleOpen}
        />
      </div>

      <ModalStaffEdit
        open={isModalOpen}
        handleClose={handleClose}
        handleUpdate={updateStaff}
        createStaff={createStaff}
        refetch={refetch}
      />
    </Layout>
  )
}

const EnhancedStaffManagement = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  validateOnMount: true,
  validationSchema: yupCreateStaff,
  handleSubmit: () => {
    console.log('Skip')
  },
})(StaffManagement)

export default EnhancedStaffManagement
