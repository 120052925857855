import thunk from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'
import modalDialogReducer from './slice/modalDialog'
import loadingReducer from './slice/loading'
import decodeTokenReducer from './slice/decodeToken'
import userRoleReducer from './slice/userRole'
import tagsReducer from './slice/tags'
import brandReducer from './slice/brand'
import optionGroupReducer from './slice/optionGroup'
import productReducer from './slice/product'
import companyReducer from './slice/company'
import modalExpanseImageReducer from './slice/modalExpanseImage'

export const store = configureStore({
  reducer: {
    modalDialog: modalDialogReducer,
    loading: loadingReducer,
    decodeToken: decodeTokenReducer,
    userRole: userRoleReducer,
    tags: tagsReducer,
    brand: brandReducer,
    optionGroup: optionGroupReducer,
    product: productReducer,
    company: companyReducer,
    modalExpanseImage: modalExpanseImageReducer,
  },
  middleware: [thunk],
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
