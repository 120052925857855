// Lib
import React from 'react'

//Image

// Include in project
import Layout from 'layouts'
import { menuHeaderCustomer } from 'utils/menu'

const MainUserManagement: React.FC = () => {
  return <Layout headerSectionName="User Management" headerSectionManu={menuHeaderCustomer}></Layout>
}

export default MainUserManagement
