// Lib
import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import ExcelJS from 'exceljs'

// Image
import { IosShare } from '@mui/icons-material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

// Include in project
import styles from './index.module.scss'
import { useModal } from 'hooks'
import { Button, UploadPDF, Notification } from 'component'
import { FormInput, ModalAddProductToCategory, ModalPreviewProduct } from 'container'
import { ICategory } from 'pages/Category'
import { IRenderInput } from 'utils/interface'
import { RootState } from 'states/store'
import {
  MutationUpdateProductCategoryArgs,
  MutationUpdateJoinProductCategoryProductArgs,
  MutationUpdateProductArgs,
  MutationUpdateProductSkuArgs,
} from 'utils/generated'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/transformationDataToServer'
import { EResCode, convertNumberToColumn } from 'utils/other'
import { setOpenLoading } from 'states/slice/loading'
import { E_Is_Active, ProductOption } from 'utils/generatedNonAuth'

interface Props {
  findIndexCategory: number
  updateCategory: ({ variables }: { variables: MutationUpdateProductCategoryArgs }) => Promise<any>
  handleArchiveProduct: (productID: string) => void
  handleAddProduct: () => void
  refetch: () => void
  updateJoinProduct: ({ variables }: { variables: MutationUpdateJoinProductCategoryProductArgs }) => Promise<any>
  updateProduct: ({ variables }: { variables: MutationUpdateProductArgs }) => Promise<any>
  updateSKU: ({ variables }: { variables: MutationUpdateProductSkuArgs }) => Promise<any>
  joinItemWithCategoryID: string | null
  setFetchProductList: React.Dispatch<React.SetStateAction<number>>
}

const CategoryHeader: React.FC<Props> = ({
  findIndexCategory,
  updateCategory,
  refetch,
  handleArchiveProduct,
  handleAddProduct,
  updateJoinProduct,
  joinItemWithCategoryID,
  updateProduct,
  updateSKU,
  setFetchProductList,
}: Props) => {
  const { values } = useFormikContext<ICategory>()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const [mode, setMode] = useState<'EDIT' | 'VIEW'>('VIEW')
  const category = values.categoryList[findIndexCategory]

  const brandList = useSelector((state: RootState) => state.brand.brandList)

  const optionGroupOptionList = useSelector((state: RootState) => state.optionGroup.optionGroupOptionList)
  const optionGroupList = useSelector((state: RootState) => state.optionGroup.optionGroupList)
  const selectOptionGroup = optionGroupList.find(
    (ele) => ele.productOptionGroupID === category?.data?.productOptionGroupID,
  )

  const { isModalOpen, handleOpen, handleClose } = useModal()

  const handleSave = async () => {
    dispatch(setOpenLoading())

    const variables = await transformationDataToServer({
      values: values.categoryList,
      type: ETransformationDataToServer.UpdateCategory,
    })

    updateCategory({ variables: variables as MutationUpdateProductCategoryArgs }).then((res) => {
      const resUpdate = res.data.updateProductCategory
      if (resUpdate.res_code !== EResCode.Success) return

      refetch()
      setMode('VIEW')
      enqueueSnackbar(<Notification title="Success" description="Update Category." />, {
        variant: 'success',
      })
    })
  }

  const fieldInputs: IRenderInput[] = [
    {
      heading: 'Image',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: category?.data?.imageUrlShow || [],
      name: `categoryList[${findIndexCategory}].data.imageUrlShow`,
      readonly: true,
      multiple: false,
    },
    {
      heading: 'Description',
      type: 'TEXT',
      words: category?.data?.description,
    },
    {
      heading: 'Product Option Group',
      type: 'TEXT_LINK',
      words: selectOptionGroup?.name,
      link: `/product-management/option-info/${selectOptionGroup?.productOptionGroupID}`,
    },
    {
      heading: 'Feature',
      type: 'TEXT_MARK_DOWN',
      words: category?.data?.features,
    },
  ]

  const fieldInputsEdit: IRenderInput[] = [
    {
      heading: 'Image',
      type: 'DROP_ZONE',
      sizeDrop: 'SM',
      fileList: category?.data?.imageUrlShow || [],
      name: `categoryList[${findIndexCategory}].data.imageUrlShow`,
      readonly: false,
      multiple: false,
    },
    {
      heading: 'Description',
      type: 'TEXT_FIELD',
      name: `categoryList[${findIndexCategory}].data.description`,
      disabled: false,
    },
    {
      heading: 'Product Option Group',
      type: 'SELEC_SINGLE',
      name: `categoryList[${findIndexCategory}].data.productOptionGroupID`,
      disabled: true,
      option: optionGroupOptionList,
    },
    {
      heading: 'Feature',
      type: 'TEXT_AREA',
      name: `categoryList[${findIndexCategory}].data.features`,
      disabled: false,
    },
  ]

  const handleDowloadExcel = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet(selectOptionGroup?.productOptionGroupID as string)

    const mapperOption: ProductOption[] =
      selectOptionGroup?.option
        ?.filter((ele) => ele?.isOption === E_Is_Active.True)
        .map((option) => {
          return {
            name: option?.name as string,
            variant: option?.variant,
          }
        }) || []
    const columnOptions = mapperOption.map((data) => ({ header: data.name as string, key: data.name as string }))

    // Set Column Table
    worksheet.columns = [
      // About Product
      {
        header: 'Product Name',
        key: 'Product Name',
      },
      { header: 'Prefix', key: 'Prefix' },
      { header: 'Unit', key: 'Unit' },
      { header: 'Brand', key: 'Brand' },

      // About Product SKU
      { header: 'SKU', key: 'SKU' },
      { header: 'SKU Name', key: 'SKU Name' },

      // About OptionAttribute Product SKU
      ...columnOptions,

      // About Pricing Product SKU
      { header: 'Payment Type', key: 'Payment Type' },
      { header: 'Cost', key: 'Cost' },
      { header: 'Default Price', key: 'Default Price' },
      { header: 'Sale Price', key: 'Sale Price' },

      { header: 'Price Label 1', key: 'Price Label 1' },
      { header: 'Price 1', key: 'Price 1' },
      { header: 'Price Label 2', key: 'Price Label 2' },
      { header: 'Price 2', key: 'Price 2' },
      { header: 'Price Label 3', key: 'Price Label 3' },
      { header: 'Price 3', key: 'Price 3' },
      { header: 'Price Label 4', key: 'Price Label 4' },
      { header: 'Price 4', key: 'Price 4' },
    ]

    // Handle Dropdawn in option column
    for (let i = 0; i < columnOptions.length; i++) {
      const keyOptionCol = worksheet.getColumn(columnOptions[i].key)
      const optionNameColumn = convertNumberToColumn[`${keyOptionCol.number}`]

      const variant = mapperOption.find((ele) => ele.name === columnOptions[i].key)?.variant || []
      const stringOfOption =
        '"' +
        JSON.stringify(variant.map((data) => data?.name as string))
          .replaceAll('[', '')
          .replaceAll(']', '')
          .replaceAll('"', '') +
        '"'

      worksheet.getCell(`${optionNameColumn}2`).dataValidation = {
        type: 'list',
        allowBlank: false,
        formulae: [stringOfOption],
      }
    }

    // Handle Dropdown in Brand column
    const stringOfBrand =
      '"' +
      JSON.stringify(brandList.map((data) => data?.name as string))
        .replaceAll('[', '')
        .replaceAll(']', '')
        .replaceAll('"', '') +
      '"'
    const keyBrandCol = worksheet.getColumn('Brand')
    const brandColumn = convertNumberToColumn[`${keyBrandCol.number}`]
    worksheet.getCell(`${brandColumn}2`).dataValidation = {
      type: 'list',
      allowBlank: false,
      formulae: [stringOfBrand],
    }

    // Handle Dropdown in Payment Type column
    const keyPricingModelCol = worksheet.getColumn('Payment Type')
    const pricingModelColumn = convertNumberToColumn[`${keyPricingModelCol.number}`]
    worksheet.getCell(`${pricingModelColumn}2`).dataValidation = {
      type: 'list',
      allowBlank: false,
      formulae: ['"STANDARD_PRICING,VOLUME_PRICING"'],
    }

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocement.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)

    const aTag = document.createElement('a')
    aTag.href = url
    aTag.setAttribute('download', `${category.data?.categoryName}.xlsx`)
    document.body.appendChild(aTag)
    aTag.click()
    aTag.remove()
  }

  const { isModalOpen: isModalPreviewOpen, handleOpen: handlePreviewOpen, handleClose: handlePreviewClose } = useModal()

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h6>{category?.data?.categoryName}</h6>
        {mode === 'VIEW' ? (
          <div className={styles.wrapperButton}>
            <Button
              name="Dowload Template"
              typeButton="button"
              type="gray"
              size="small"
              startIcon={<CloudDownloadIcon />}
              functionOnClick={handleDowloadExcel}
            />
            <Button
              name="Import Product"
              typeButton="button"
              type="gray"
              size="small"
              startIcon={<CloudUploadIcon />}
              functionOnClick={() => handlePreviewOpen(true)}
            />
            <Button
              name="Edit"
              typeButton="button"
              type="gray"
              size="small"
              startIcon={<IosShare />}
              functionOnClick={() => setMode('EDIT')}
            />
            <Button
              name="Add Product to Categories"
              typeButton="button"
              type="info"
              size="small"
              startIcon={<IosShare />}
              functionOnClick={() => handleOpen()}
            />
          </div>
        ) : (
          <Button name="Save" typeButton="button" type="info" size="small" functionOnClick={handleSave} />
        )}
      </section>
      {mode === 'VIEW' ? (
        <div className={styles.card}>
          <FormInput title="" fieldInputs={fieldInputs} />
          <UploadPDF
            fileList={category?.data?.pdfUrlShow || []}
            name={`categoryList[${findIndexCategory}].data.pdfUrlShow`}
            readonly
            multiple
          />
        </div>
      ) : (
        <div className={styles.card}>
          <FormInput title="" fieldInputs={fieldInputsEdit} />
          <UploadPDF
            fileList={category?.data?.pdfUrlShow || []}
            name={`categoryList[${findIndexCategory}].data.pdfUrlShow`}
            multiple
          />
        </div>
      )}

      <ModalAddProductToCategory
        open={isModalOpen}
        handleClose={handleClose}
        handleArchiveProduct={handleArchiveProduct}
        handleAddProduct={handleAddProduct}
      />
      <ModalPreviewProduct
        open={isModalPreviewOpen}
        handleClose={handlePreviewClose}
        selectOptionGroup={selectOptionGroup}
        updateJoinProduct={updateJoinProduct}
        joinItemWithCategoryID={joinItemWithCategoryID}
        updateProduct={updateProduct}
        updateSKU={updateSKU}
        setFetchProductList={setFetchProductList}
      />
    </div>
  )
}

export default CategoryHeader
