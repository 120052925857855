// Lib
import React from 'react'
import { Form } from 'formik'
import { Modal } from '@mui/material'

//Images
import { CloseRounded } from '@mui/icons-material'

// Include in project
import styles from './index.module.scss'
import { Button } from 'component'

interface Props {
  children?: JSX.Element | JSX.Element[]
  className?: string
  open: boolean
  handleClose: () => void
  handleSave?: () => void
  width?: string
  height?: string
  title?: string
  buttonText?: string
  hiddenHeader?: boolean
  hiddenButton?: boolean
}

const ModalComponent: React.FC<Props> = ({
  className,
  children,
  open,
  handleClose,
  width,
  height,
  title,
  buttonText,
  handleSave,
  hiddenHeader,
  hiddenButton,
}: Props) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Form style={{ width: `${width}`, height: `${height}` }} className={`${styles.container} ${className}`}>
        {!hiddenHeader && (
          <section className={styles.header}>
            <div className={styles.groupLeft}>
              <div className={styles.icon} onClick={handleClose}>
                <CloseRounded />
              </div>
              <p>{title}</p>
            </div>

            {!hiddenButton && (
              <Button
                typeButton="submit"
                type="info"
                name={buttonText ? `${buttonText}` : 'Save'}
                functionOnClick={() => (handleSave ? handleSave() : '')}
              />
            )}
          </section>
        )}

        <>{children}</>
      </Form>
    </Modal>
  )
}

export default ModalComponent
