import { gql } from '@apollo/client'

export const BRAND_LIST = gql`
  query brandList($filter: BrandFilter!, $pageSize: Int, $nextToken: String) {
    brandList(filter: $filter, pageSize: $pageSize, nextToken: $nextToken) {
      res_code
      res_desc
      nextToken
      items {
        companyID
        brandID
        name
        description
        brandImgURL
        customAttribute
        isActive
        createdAt
        updatedAt
      }
    }
  }
`

export const CREATE_BRAND = gql`
  mutation createBrand($input: CreateBrand!) {
    createBrand(input: $input) {
      res_code
      res_desc
      companyID
      brandID
    }
  }
`
export const UPDATE_BRAND = gql`
  mutation updateBrand($input: UpdateBrand!) {
    updateBrand(input: $input) {
      res_code
      res_desc
      companyID
      brandID
    }
  }
`
