// Lib
import React, { useEffect } from 'react'
import { useFormikContext, withFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'

// Images
// import { IosShare, Search } from '@mui/icons-material'
import AutorenewIcon from '@mui/icons-material/Autorenew'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { useModal, useLoadingControl, useOrder, useInfiniteScroll } from 'hooks'
import { ModalOrderInfo, SearchBox } from 'container'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { Table, Button, TextField, SelectSingle } from 'component'
import { E_Delivery_Status, E_Order_Status, E_Payment_Status, E_Shipping_Type, Maybe, Order } from 'utils/generated'
import { optionList } from 'utils/other'
import { pageSize } from 'configs'
import { TOptionkey } from 'container/SearchBox'

export interface IOrderManagement {
  formInput: Order & {
    rawOrderStatus: E_Order_Status
    shippingTypeHTML?: string
  }
  filter: {
    key: string
    value: string
    orderNo: Maybe<string>
    orderStatus: Maybe<E_Order_Status | 'ALL'>
  }
}

const intitialValue: IOrderManagement = {
  formInput: {
    orderID: '',
    orderNo: '',
    createdAt: '',
    updatedAt: '',
    paymentStatus: E_Payment_Status.Unpaid,
    orderStatus: E_Order_Status.WaitingPaid,
    rawOrderStatus: E_Order_Status.WaitingPaid,
    deliveryStatus: E_Delivery_Status.Pedding,
    note: '',
    shippingType: E_Shipping_Type.StandardDelivery,
    shippingCompany: null,
    shippingTrackingID: null,
    evidencePaymentURL: null,
    evidenceReceivedURL: null,
    evidenceShippedURL: null,
    buyerUserID: '',
    buyerInfo: {
      email: '',
      firstname: '',
      phoneNumber: '',
      surname: '',
    },
    billingAddress: null,
    shippingAddress: null,
    orderItem: [],
    amount: 0,
    price: 0,
    discount: 0,
    tax: 0,
    total: 0,
  },
  filter: {
    key: 'orderStatus',
    value: 'ALL',
    orderNo: '',
    orderStatus: 'ALL',
  },
}

const OrderManagement: React.FC = () => {
  const [searchParams] = useSearchParams()
  const orderNo = searchParams?.get('orderNo') || null

  const { values, setFieldValue } = useFormikContext<IOrderManagement>()

  const { data, isLoading, updateOrderStatus, updateStripePaymentStatus, refetch } = useOrder({
    filter: {
      orderNo: values.filter.orderNo,
      orderStatus: values.filter.orderStatus === 'ALL' ? null : values.filter.orderStatus,
    },
    pageSize: pageSize,
    nextToken: null,
  })
  const { dataList, handleRefreshAPI } = useInfiniteScroll({
    result: data,
    refetch: refetch,
    variables: {
      filter: {
        orderNo: values.filter.orderNo,
        orderStatus: values.filter.orderStatus === 'ALL' ? null : values.filter.orderStatus,
      },
      pageSize: pageSize,
      nextToken: data?.nextToken || null,
    },
    filter: [values.filter.orderNo, values.filter.orderStatus],
    key: 'orderList',
  })
  useLoadingControl(isLoading)

  const dataTable = transformationDataIntoTable({
    dataList: dataList,
    type: ETranformationDataIntoTable.OrderManagementList,
  })

  const { isModalOpen, handleClose, handleOpen } = useModal(intitialValue.formInput)

  // 2 UseEffect below is control open modal when params have orderNo
  // First useEffect set orderNo
  // Second useEffect open modal
  useEffect(() => {
    if (!orderNo) return

    setFieldValue('filter.orderNo', orderNo)
  }, [orderNo])
  useEffect(() => {
    if (dataList.length !== 1) return
    if (dataList[0].orderNo !== orderNo) return

    handleOpen(dataList[0])
  }, [dataList])

  const optionKey: TOptionkey = [
    {
      value: 'orderNo',
      label: 'Order No',
      child: {
        type: 'TEXT_FIELD',
      },
    },
    {
      value: 'orderStatus',
      label: 'Order Status',
      child: {
        type: 'SELEC_SINGLE',
        options: optionList.orderStatusOption,
      },
    },
  ]

  return (
    <Layout hiddenHeaderSection>
      <div className={styles.container}>
        <div className={styles.wrapperBox}>
          <div className={styles.leftBox}>
            <SearchBox optionKey={optionKey} />
          </div>
          <div className={styles.rigthBox}>
            <Button
              typeButton="button"
              type="gray"
              size="small"
              name="Reload"
              startIcon={<AutorenewIcon />}
              functionOnClick={() => handleRefreshAPI()}
            />
            {/* <Button typeButton="button" type="gray" size="small" name="Export" startIcon={<IosShare />} /> */}
          </div>
        </div>

        <Table
          objectKey={{
            profileImagesHTML: 'Profile image',
            orderNo: 'Order No.',
            createdAt: 'Order Date',
            customerName: 'Customer Name',
            contact: 'Contact',
            amountHTML: 'Items',
            shippingTypeHTML: 'Shipping Type',
            paymentTypeHTML: 'Payment Type',
            orderStatusHTML: 'Order Status',
            paymentStatusHTML: 'Payment Status',
            deliveryStatusHTML: 'Delivery Status',
            note: 'Remark',
          }}
          data={dataTable}
          handleClick={handleOpen}
        />
      </div>
      <ModalOrderInfo
        open={isModalOpen}
        handleClose={handleClose}
        updateOrderStatus={updateOrderStatus}
        updateStripePaymentStatus={updateStripePaymentStatus}
        refetch={refetch}
      />
    </Layout>
  )
}

const EnhancedOrderManagement = withFormik({
  mapPropsToValues: () => ({ ...intitialValue }),
  handleSubmit: () => null,
})(OrderManagement)

export default EnhancedOrderManagement
