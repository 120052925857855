import { useQuery } from '@apollo/client'
import { PRODUCT_LIST_NONE_CATEGORY } from 'queries/product'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'
import { QueryProductListNonCategoryArgs, ProductQuery } from 'utils/generatedNonAuth'

const useProduct = (variables?: QueryProductListNonCategoryArgs) => {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(PRODUCT_LIST_NONE_CATEGORY, {
    fetchPolicy: 'no-cache',
    variables: variables,
    context: { clientName: 'endpointNonAuth' },
  })

  const isLoading = loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.productListNonCategory?.res_code !== EResCode.Success)
      return JSON.stringify(errorQuery || data?.productListNonCategory?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { data: data?.productListNonCategory as ProductQuery, refetch, isLoading }
}

export default useProduct
