//Lib
import { useQuery } from '@apollo/client'

//Includes In Project
import { EResCode } from 'utils/other'
import { PRODUCT_SKU_LIST } from 'queries/productSKU'
import { useErrMsgControl } from 'hooks'
import { ProductSkuQuery, QueryProductSkuListArgs } from 'utils/generated'

function useProductSKU(variables?: QueryProductSkuListArgs) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(PRODUCT_SKU_LIST, {
    fetchPolicy: 'no-cache',
    variables: variables,
    context: { clientName: 'endpointAdmin' },
  })

  const isLoading = loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.productSKUList?.res_code !== EResCode.Success)
      return JSON.stringify(errorQuery || data?.productSKUList?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { data: data?.productSKUList as ProductSkuQuery, refetch, isLoading }
}

export default useProductSKU
