import { useQuery } from '@apollo/client'
import { PRODUCT_INFO } from 'queries/product'
import { useErrMsgControl } from 'hooks'
import { EResCode } from 'utils/other'
import { QueryProductInfoArgs, Product } from 'utils/generatedNonAuth'

const useProductInfo = (variables?: QueryProductInfoArgs) => {
  const {
    data,
    loading,
    refetch,
    error: errorQuery,
  } = useQuery(PRODUCT_INFO, {
    fetchPolicy: 'no-cache',
    variables: variables,
    context: { clientName: 'endpointNonAuth' },
  })

  const isLoading = loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.productInfo?.res_code !== EResCode.Success)
      return JSON.stringify(errorQuery || data?.productInfo?.res_desc)
  }

  useErrMsgControl(errMsg())

  return { data: data?.productInfo as Product, isLoading, refetch }
}

export default useProductInfo
