import * as AWS from 'aws-sdk'
import { BUCKET_NAME, POOL_ID, REGION_NAME } from 'configs'
import jwt_decode from 'jwt-decode'
import { IDecodeToken } from 'states/slice/decodeToken'

export const uploadFileToS3 = (file: File, path: string, fileName: string) => {
  // aws s3 config
  const bucket_name = BUCKET_NAME
  const region_name = REGION_NAME
  const identity_pool_id = POOL_ID

  AWS.config.update({
    region: region_name,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identity_pool_id as string,
    }),
  })

  const folder = process.env.REACT_APP_STATE + path

  const photoKey = folder + '/' + fileName + '.' + file.type.split('/')[1]

  const s3 = new AWS.S3()

  const upParams: AWS.S3.PutObjectRequest = {
    Bucket: bucket_name as string,
    Key: photoKey,
    Body: file,
    ContentType: file.type, // type of file image/png, file.mimeType
    ContentDisposition: `inline; filename=${fileName}`, // preview
    ACL: 'public-read-write', // public read access
  }

  const promise = s3.upload(upParams).promise()

  return promise
}

export const deleteFileToS3 = (key: string) => {
  // aws s3 config
  const bucket_name = BUCKET_NAME
  const region_name = REGION_NAME
  const identity_pool_id = POOL_ID

  AWS.config.update({
    region: region_name,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identity_pool_id as string,
    }),
  })
  const s3 = new AWS.S3()

  const params: AWS.S3.DeleteObjectRequest = {
    Bucket: bucket_name as string,
    Key: key,
  }
  s3.deleteObject(params, (error, data) => {
    if (error) {
      console.log(error)
      // res.status(500).send(error)
    }
    console.log(data)
    // res.status(200).send('File has been deleted successfully')
  })
}

export const packDataImage = async (
  imgURLRaw: string[],
  imgURLShow: (string & File)[],
  path: string,
): Promise<string[]> => {
  const accessToken = localStorage.getItem('accessToken')
  const decode: IDecodeToken = jwt_decode(accessToken || '')

  const imgURLDelete: string[] = []
  const imgURLUpload: File[] = []
  const imgURLReturn: string[] = []

  for (let index = 0; index < imgURLRaw?.length; index++) {
    const find = imgURLShow.find((ele) => ele === imgURLRaw[index])

    if (!find) imgURLDelete.push(imgURLRaw[index])
    else imgURLReturn.push(imgURLRaw[index] as string)
  }

  for (let index = 0; index < imgURLShow.length; index++) {
    if (typeof imgURLShow[index] !== 'string') imgURLUpload.push(imgURLShow[index])
  }

  const promiseResKeyList = imgURLUpload.map(async (data) => {
    const resLocation = await uploadFileToS3(data, `/${decode.companyID}/${path}`, `${new Date().toISOString()}`)

    return resLocation.Key
  })

  const resKeyList = await Promise.all(promiseResKeyList)

  imgURLDelete.map(async (key) => {
    if (!key) return

    await deleteFileToS3(key)
  })

  return [...imgURLReturn, ...resKeyList]
}

export const packDataFile = async (
  fileURLRaw: string[],
  fileURLShow: (string & File)[],
  path: string,
): Promise<string[]> => {
  const accessToken = localStorage.getItem('accessToken')
  const decode: IDecodeToken = jwt_decode(accessToken || '')

  const fileURLDelete: string[] = []
  const fileURLUpload: File[] = []
  const fileURLReturn: string[] = []

  for (let index = 0; index < fileURLRaw?.length; index++) {
    const find = fileURLShow.find((ele) => ele === fileURLRaw[index])

    if (!find) fileURLDelete.push(fileURLRaw[index])
    else fileURLReturn.push(fileURLRaw[index] as string)
  }

  for (let index = 0; index < fileURLShow.length; index++) {
    if (typeof fileURLShow[index] !== 'string') fileURLUpload.push(fileURLShow[index])
  }

  const promiseResKeyList = fileURLUpload.map(async (data) => {
    const resLocation = await uploadFileToS3(
      data,
      `/${decode.companyID}/${path}/${data.name}`,
      `${new Date().toISOString()}`,
    )

    return resLocation.Key
  })

  const resKeyList = await Promise.all(promiseResKeyList)

  fileURLDelete.map(async (key) => {
    if (!key) return

    await deleteFileToS3(key)
  })

  return [...fileURLReturn, ...resKeyList]
}
