// Lib
import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext, Form } from 'formik'
import _ from 'lodash'
import { useSnackbar } from 'notistack'

// Include in project
import styles from './index.module.scss'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import { Modal, TextField, Button } from 'component'
import { transformationDataToServer, ETransformationDataToServer } from 'utils/transformationDataToServer'
import { EResCode } from 'utils/other'
import { Notification } from 'component'
import { MutationCreateTagArgs, MutationUpdateTagArgs, E_Is_Active } from 'utils/generated'
import { ITags } from 'pages/Tags'

//Images
import DeleteSVG from 'images/brandPage/deleteWhiteBold.svg'
import { CloseRounded } from '@mui/icons-material'

interface Props {
  open: boolean
  handleClose: () => void
  createTags: ({ variables }: { variables: MutationCreateTagArgs }) => Promise<any>
  updateTags: ({ variables }: { variables: MutationUpdateTagArgs }) => Promise<any>
  refetch: (() => void) | null
}

const ModalAddTags: React.FC<Props> = ({ open, handleClose, createTags, updateTags, refetch }: Props) => {
  const { values, errors, setFieldValue } = useFormikContext<ITags>()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const handleUpdate = async () => {
    if (!_.isEmpty(errors)) return

    const packData = await transformationDataToServer({
      values: values,
      type: ETransformationDataToServer.UpdateTag,
    })

    if (values.formInput.tagID) {
      updateTags({ variables: packData as MutationUpdateTagArgs }).then((res) => {
        const resUpdate = res.data.updateTag
        if (resUpdate.res_code !== EResCode.Success) return

        refetch?.()
        enqueueSnackbar(<Notification title="Success" description="Update Tag information." />, { variant: 'success' })
        handleClose()
      })
    } else {
      const packDataCreate = _.omit(packData, ['input.tagID'], ['input.isActive'])

      createTags({ variables: packDataCreate as MutationCreateTagArgs }).then((res) => {
        const resCreate = res.data.createTag
        if (resCreate.res_code !== EResCode.Success) return

        refetch?.()
        enqueueSnackbar(<Notification title="Success" description="Create New Tag." />, { variant: 'success' })
        handleClose()
      })
    }
  }

  const checkStatusDelete = values.formInput.isActive === E_Is_Active.True // True is Delete | False is Recovery
  const handleDelete = () => {
    async function callAPIDelete() {
      const packData = (await transformationDataToServer({
        values: values,
        type: ETransformationDataToServer.UpdateTag,
      })) as MutationUpdateTagArgs

      packData.input.isActive = packData.input.isActive === E_Is_Active.False ? E_Is_Active.True : E_Is_Active.False

      updateTags({ variables: packData }).then((res) => {
        const resUpdate = res.data.updateTag
        if (resUpdate.res_code !== EResCode.Success) return

        refetch?.()
        enqueueSnackbar(
          <Notification title="Success" description={`${checkStatusDelete ? 'Archive' : 'Recovery'} tags`} />,
          {
            variant: 'success',
          },
        )
        dispatch(setCloseDialog())
        handleClose()
      })
    }

    dispatch(
      setOpenDialog({
        title: `${checkStatusDelete ? 'Archive' : 'Recovery'} tags`,
        description: `Tags ${values.formInput.tagName} will be ${checkStatusDelete ? 'archive' : 'recovery'}.`,
        status: checkStatusDelete ? 'danger' : 'info',
        textButtonCancle: '',
        textButtonConfirm: `${checkStatusDelete ? 'Archive' : 'Recovery'} tags`,
        functionOnSubmit: callAPIDelete,
      }),
    )
  }

  return (
    <Modal open={open} handleClose={handleClose} width="439px" height="auto" hiddenHeader>
      <div className={styles.container}>
        <div className={styles.header}>
          <h6>{values.formInput.tagID ? 'Edit Tags' : 'Add Tags'}</h6>
          <div className={styles.icon} onClick={handleClose}>
            <CloseRounded />
          </div>
        </div>
        <div className={styles.wrapperInput}>
          <TextField label="Tags name" placeholder="" type="text" name="formInput.tagName" require />
          <div className={styles.rowColor}>
            <div>
              <p className="fontBold">Color</p>
              <input
                className={styles.inputColor}
                type="color"
                value={values.formInput.color as string}
                onChange={(e) => setFieldValue('formInput.color', e.target.value)}
              />
            </div>
            <TextField label="HEX Code" placeholder="" type="text" name="formInput.color" />
          </div>
        </div>

        {!values.formInput.tagID && (
          <div className={styles.addGroup}>
            <Button typeButton="submit" type="info" size="small" name="Save" functionOnClick={handleUpdate} />
          </div>
        )}
        {values.formInput.tagID && (
          <div className={styles.editGroup}>
            <Button
              typeButton="button"
              type={checkStatusDelete ? 'danger' : 'info'}
              size="small"
              name={`${checkStatusDelete ? 'Archive' : 'Recovery'} tags`}
              startIcon={<img src={DeleteSVG} />}
              functionOnClick={handleDelete}
            />
            <Button typeButton="button" type="info" size="small" name="Save" functionOnClick={handleUpdate} />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalAddTags
