// Lib
import React from 'react'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { DropZoneUpload, TextArea } from 'component'
import { IOrderManagement } from 'pages/OrderManagement'

const EvidenceSection: React.FC = () => {
  const { values } = useFormikContext<IOrderManagement>()

  return (
    <div className={styles.container}>
      <h6>Evidence</h6>
      <div className={styles.wrapperImg}>
        <div className={styles.box}>
          <p className="fontBold">Payment</p>
          <p>(Customer)</p>
          <DropZoneUpload
            sizeDrop="SM"
            fileList={(values.formInput?.evidencePaymentURL as string[]) || []}
            name="evidencePaymentURL"
            readonly
          />
        </div>
      </div>

      <TextArea name="formInput.note" label="Note" placeholder="Note" />
    </div>
  )
}

export default EvidenceSection
