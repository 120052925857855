// Lib
import React from 'react'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { convertCurrencyFormatDecimal } from 'utils/convertMoney'
import { IOrderManagement } from 'pages/OrderManagement'

interface ICard {
  title: string
  values: number
  isTextDanger?: boolean
}

const SummaryOrderSection: React.FC = () => {
  const { values } = useFormikContext<IOrderManagement>()

  const RenderBox: React.FC<ICard> = ({ title, values, isTextDanger }: ICard) => {
    return (
      <div className={styles.box}>
        <p className={isTextDanger ? 'colorRed' : ''}>{title}</p>
        <p className={isTextDanger ? 'colorRed' : ''}>{convertCurrencyFormatDecimal(values)} ฿</p>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <RenderBox title="ราคาสินค้า" values={values.formInput.price as number} />
      <RenderBox title="ส่วนลดจากโปรโมชั่น" values={0} />
      {/* <RenderBox title="ค่าจัดส่ง" values={values.formInput.freight as number} /> */}
      <RenderBox title="ภาษีมูลค่าเพิ่ม 7%" values={values.formInput.tax as number} />
      <RenderBox title="ส่วนลดค่าจัดส่ง" values={0} isTextDanger />
      <div className={styles.box}>
        <h6>ราคาสุทธิ</h6>
        <h6>{convertCurrencyFormatDecimal(values.formInput.total as number)} ฿</h6>
      </div>
    </div>
  )
}

export default SummaryOrderSection
