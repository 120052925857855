import { SelectChangeEvent } from '@mui/material'
import Color from 'abstracts/export.module.scss'
import Font from 'abstracts/export.module.scss'

export interface Props {
  name: string
  option: {
    label: string
    value: string | number
  }[]
  label?: string
  disabled?: boolean
  require?: boolean
  placeholder?: string
  onChange?: (e: SelectChangeEvent) => void
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MenuProps = {
  sx: {
    height: '40px',
    '&& .Mui-selected': {
      backgroundColor: `${Color.primaryFocus}`,
      '&:hover': {
        backgroundColor: `${Color.primaryFocus}`,
      },
    },
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const SelectSx = {
  '&': {
    backgroundColor: `${Color.grey100}`,
    padding: '0.4em 0',
    // marginTop: '2px',
    borderRadius: '8px',
    fontFamily: Font.fontFamilyRegular,
  },
  '&& .MuiSelect-select': {
    padding: '0 14px',
    display: 'flex',
    alignItems: 'center',
    height: '26px',
  },
  '.css-yckgr3-MuiListItemText-root': {
    margin: '0',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {},
}

export const CheckboxSx = {
  '&.Mui-checked': {
    color: `${Color.primaryMain}`,
  },
}

export const ListItemTextSx = {
  '&.Mui-selected': { color: `${Color.white}` },
}
