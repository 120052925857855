import * as Yup from 'yup'

const yupUserRole = Yup.object().shape({
  formInput: Yup.object().shape({
    userRoleCompanyName: Yup.string().required('Plese Enter'),
    userLevel: Yup.string().required('Plese Enter'),
  }),
})

export default yupUserRole
