// Lib
import React from 'react'
import { useSelector } from 'react-redux'
import { Backdrop } from '@mui/material'

//Images

// Include in project
import styles from './index.module.scss'
import type { RootState } from 'states/store'

const LoadingMasterData: React.FC = (): JSX.Element => {
  const loadingReducer = useSelector((state: RootState) => state.loading)

  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 9999 }} open={loadingReducer.isOpenMasterData}>
      <span className={styles.loader}></span>{' '}
    </Backdrop>
  )
}

export default LoadingMasterData
