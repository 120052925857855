import * as Yup from 'yup'

const yupProduct = Yup.object().shape({
  formInput: Yup.object().shape({
    name: Yup.string().required('Plese Enter'),
    unitLabel: Yup.string().required('Plese Enter'),
    // productImgURLShow: Yup.array().min(1, 'Please Upload Image'),
    prefix: Yup.string().required('Plese Enter'),
    productOptionGroupID: Yup.string().required('Plese Enter'),
  }),
})

export default yupProduct
