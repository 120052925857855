import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Product } from 'utils/generatedNonAuth'
import { IOption } from 'utils/interface'

const initialState: {
  productList: Product[]
  productOptionList: IOption[]
  refetch: (() => void) | null
} = {
  productList: [],
  productOptionList: [],
  refetch: null,
}

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProduct: (
      state,
      action: PayloadAction<{
        items: Product[]
        refetch: () => void
      }>,
    ) => {
      state.productList = action.payload.items
      state.productOptionList = action.payload.items.map((data) => ({
        value: data.productID as string,
        label: data.name as string,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setProduct } = productSlice.actions

export default productSlice.reducer
