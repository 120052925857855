// Lib
import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import { useSnackbar } from 'notistack'

// Include in project
import styles from './index.module.scss'
import { IBrand } from 'pages/Brand'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import { Modal, DropZoneUpload, TextField, Button, Notification } from 'component'
import { MutationCreateBrandArgs, MutationUpdateBrandArgs, E_Is_Active } from 'utils/generated'
import { transformationDataToServer, ETransformationDataToServer } from 'utils/transformationDataToServer'
import { EResCode } from 'utils/other'
import { setOpenLoading } from 'states/slice/loading'

//Images
import DeleteSVG from 'images/brandPage/deleteWhiteBold.svg'
import { CloseRounded } from '@mui/icons-material'

interface Props {
  open: boolean
  handleClose: () => void
  createBrand: ({ variables }: { variables: MutationCreateBrandArgs }) => Promise<any>
  updateBrand: ({ variables }: { variables: MutationUpdateBrandArgs }) => Promise<any>
  refetch: (() => void) | null
}

const ModalAddBrand: React.FC<Props> = ({ open, handleClose, createBrand, updateBrand, refetch }: Props) => {
  const { values, errors } = useFormikContext<IBrand>()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const handleUpdate = async () => {
    if (!_.isEmpty(errors)) return

    dispatch(setOpenLoading())

    const packData = await transformationDataToServer({
      values: values,
      type: ETransformationDataToServer.UpdateBrand,
    })

    if (values.formInput.brandID) {
      updateBrand({ variables: packData as MutationUpdateBrandArgs }).then((res) => {
        const resUpdate = res.data.updateBrand
        if (resUpdate.res_code !== EResCode.Success) return

        refetch?.()
        enqueueSnackbar(<Notification title="Success" description="Update Brand information." />, {
          variant: 'success',
        })
        handleClose()
      })
    } else {
      const packDataCreate = _.omit(packData, ['input.brandID'], ['input.isActive'])

      createBrand({ variables: packDataCreate as MutationCreateBrandArgs }).then((res) => {
        const resCreate = res.data.createBrand
        if (resCreate.res_code !== EResCode.Success) return

        refetch?.()
        enqueueSnackbar(<Notification title="Success" description="Create New Brand." />, { variant: 'success' })
        handleClose()
      })
    }
  }

  const checkStatusDelete = values.formInput.isActive === E_Is_Active.True // True is Delete | False is Recovery
  const handleDelete = () => {
    async function callAPIDelete() {
      const packData = (await transformationDataToServer({
        values: values,
        type: ETransformationDataToServer.UpdateBrand,
      })) as MutationUpdateBrandArgs

      packData.input.isActive = packData.input.isActive === E_Is_Active.False ? E_Is_Active.True : E_Is_Active.False

      updateBrand({ variables: packData as MutationUpdateBrandArgs }).then((res) => {
        const resUpdate = res.data.updateBrand
        if (resUpdate.res_code !== EResCode.Success) return

        refetch?.()
        enqueueSnackbar(
          <Notification title="Success" description={`${checkStatusDelete ? 'Archive' : 'Recovery'} brand`} />,
          {
            variant: 'success',
          },
        )
        dispatch(setCloseDialog())
        handleClose()
      })
    }

    dispatch(
      setOpenDialog({
        title: `${checkStatusDelete ? 'Archive' : 'Recovery'} brand`,
        description: `Brand ${values.formInput.name} will be ${checkStatusDelete ? 'archive' : 'recovery'}.`,
        status: checkStatusDelete ? 'danger' : 'info',
        textButtonCancle: '',
        textButtonConfirm: `${checkStatusDelete ? 'Archive' : 'Recovery'} brand`,
        functionOnSubmit: callAPIDelete,
      }),
    )
  }

  return (
    <Modal open={open} handleClose={handleClose} width="439px" height="auto" hiddenHeader>
      <div className={styles.container}>
        <div className={styles.header}>
          <h6>{values.formInput.brandID ? 'Edit Brand' : 'Add Brand'}</h6>
          <div className={styles.icon} onClick={handleClose}>
            <CloseRounded />
          </div>
        </div>
        <div className={styles.wrapperInput}>
          <TextField label="Brand name" placeholder="" type="text" name="formInput.name" require />
        </div>
        <DropZoneUpload
          multiple={false}
          sizeDrop="SM"
          name="formInput.brandImgURLShow"
          fileList={values.formInput.brandImgURLShow}
        />

        {!values.formInput.brandID && (
          <div className={styles.addGroup}>
            <Button typeButton="submit" type="info" size="small" name="Save" functionOnClick={handleUpdate} />
          </div>
        )}
        {values.formInput.brandID && (
          <div className={styles.editGroup}>
            <Button
              typeButton="button"
              type={checkStatusDelete ? 'danger' : 'info'}
              size="small"
              name={`${checkStatusDelete ? 'Archive' : 'Recovery'} brand`}
              startIcon={<img src={DeleteSVG} />}
              functionOnClick={handleDelete}
            />
            <Button typeButton="submit" type="info" size="small" name="Save" functionOnClick={handleUpdate} />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalAddBrand
