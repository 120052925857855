import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './states/store'
import { queryClient } from './configs/react-query'
import { QueryClientProvider } from 'react-query'
import { ApolloProvider } from '@apollo/client'
import { client } from './configs/apollo'
import { SnackbarProvider } from 'notistack'
import { ReactComponent as Danger } from 'images/commons/danger.svg'
import { ReactComponent as Success } from 'images/commons/success.svg'
import { ReactComponent as Info } from 'images/commons/info.svg'
import { ReactComponent as Warning } from 'images/commons/warning.svg'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            iconVariant={{
              success: <Success />,
              error: <Danger />,
              warning: <Warning />,
              info: <Info />,
            }}
          >
            <App />
          </SnackbarProvider>
        </Provider>
      </QueryClientProvider>
    </ApolloProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
