// Lib
import React, { useEffect } from 'react'
import { useFormikContext, withFormik } from 'formik'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { useModal, useLoadingControl, useBrandMutation } from 'hooks'
import { ModalAddBrand } from 'container'
import { menuHeaderProduct } from 'utils/menu'
import { Table, Button, ButtonFilterIsActive } from 'component'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import yupBrand from 'validations/yupBrand.validate'
import { Brand as InterfaceBrand, E_Is_Active } from 'utils/generated'
import type { RootState } from 'states/store'

// Images
import AddCircleIcon from '@mui/icons-material/AddCircle'

export interface IBrand {
  formInput: InterfaceBrand & {
    brandImgURLShow: (string & File)[]
  }
  filter: {
    isActive: E_Is_Active | 'ALL'
  }
}

const intitialValue: IBrand = {
  formInput: {
    brandID: '',
    name: '',
    description: '',
    brandImgURL: [],
    brandImgURLShow: [],
  },
  filter: {
    isActive: E_Is_Active.True,
  },
}

const Brand: React.FC = () => {
  const [searchParams] = useSearchParams()
  const searchBrandID = searchParams.get('brandID')
  const { values } = useFormikContext<IBrand>()
  const brandReducer = useSelector((state: RootState) => state.brand)

  const { isModalOpen, handleOpen, handleClose } = useModal(intitialValue.formInput)

  const { isLoading, createBrand, updateBrand } = useBrandMutation()
  useLoadingControl(isLoading)

  const brandListAfterFilter = brandReducer?.brandList?.filter((ele) => {
    if (values.filter.isActive === 'ALL') return true
    return ele.isActive === values.filter.isActive
  })

  const dataTable = transformationDataIntoTable({
    dataList: brandListAfterFilter || [],
    type: ETranformationDataIntoTable.BrandList,
  })

  useEffect(() => {
    // Trigger When params have search brandID
    // Will open modal that brandID
    if (!searchBrandID) return
    if (brandReducer?.brandList.length === 0) return

    const findBrand = brandReducer?.brandList.find((ele) => ele.brandID === searchBrandID)

    handleOpen({ ...findBrand, brandImgURLShow: findBrand?.brandImgURL || [] })
  }, [searchBrandID, brandReducer?.brandList])

  return (
    <Layout headerSectionName="Product Management" headerSectionManu={menuHeaderProduct}>
      <div className={styles.container}>
        <div className={styles.addBrand}>
          <ButtonFilterIsActive />
          <Button
            typeButton="submit"
            type="info"
            size="small"
            name="Add Brand"
            startIcon={<AddCircleIcon />}
            functionOnClick={() => handleOpen()}
          />
        </div>

        <Table
          objectKey={{
            name: 'Brand Name',
            brandImgURLHTML: 'Logo',
            statusHTML: 'Status',
            createdAt: 'Created At',
            updatedAt: 'Updated At',
          }}
          data={dataTable}
          handleClick={handleOpen}
        />
      </div>
      <ModalAddBrand
        open={isModalOpen}
        handleClose={handleClose}
        createBrand={createBrand}
        updateBrand={updateBrand}
        refetch={brandReducer?.refetch}
      />
    </Layout>
  )
}

const EnhancedBrand = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  validateOnMount: true,
  validationSchema: yupBrand,
  handleSubmit: () => {
    console.log('Skip')
  },
})(Brand)

export default EnhancedBrand
