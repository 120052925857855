// Lib
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'

// Include in project
import styles from './index.module.scss'
import { Table, Notification } from 'component'
import { CategoryHeader } from 'container'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { ICategory } from 'pages/Category'
import {
  MutationUpdateJoinProductCategoryProductArgs,
  MutationUpdateProductCategoryArgs,
  MutationUpdateProductArgs,
  MutationUpdateProductSkuArgs,
} from 'utils/generated'
import { Product } from 'utils/generatedNonAuth'
import { EResCode } from 'utils/other'

interface Props {
  selectFolder: string | number
  setFetchProductList: React.Dispatch<React.SetStateAction<number>>
  updateCategory: ({ variables }: { variables: MutationUpdateProductCategoryArgs }) => Promise<any>
  updateJoinProduct: ({ variables }: { variables: MutationUpdateJoinProductCategoryProductArgs }) => Promise<any>
  updateProduct: ({ variables }: { variables: MutationUpdateProductArgs }) => Promise<any>
  updateSKU: ({ variables }: { variables: MutationUpdateProductSkuArgs }) => Promise<any>
  refetch: () => void
}

const CategorySection: React.FC<Props> = ({
  selectFolder,
  refetch,
  updateCategory,
  updateJoinProduct,
  setFetchProductList,
  updateProduct,
  updateSKU,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<ICategory>()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const findIndexCategory = values.categoryList.findIndex((ele) => ele.id === selectFolder)
  const joinItemWithCategoryID = values.categoryList[findIndexCategory].data?.joinItemWithCategoryID || null

  const dataTable = transformationDataIntoTable({
    type: ETranformationDataIntoTable.ProductManagementList,
    dataList: values.productList,
    handleArchive: (data) => handleArchiveProduct(data),
  })

  const handleClickGoToViewProduct = (product: Product) => {
    navigate(`/product-management/product-info/${product.productID}`)
  }

  const handleArchiveProduct = (productID: string) => {
    const productIDList = values.productList.map((data) => data.productID).filter((ele) => ele !== productID)
    const packData = {
      input: {
        joinItemWithCategoryID: joinItemWithCategoryID,
        productIDList: productIDList,
      },
    }

    updateJoinProduct({ variables: packData as MutationUpdateJoinProductCategoryProductArgs }).then((res) => {
      const resUpdate = res.data.updateJoinProductCategoryProduct
      if (resUpdate.res_code !== EResCode.Success) return

      setFetchProductList((prev) => prev + 1)
      enqueueSnackbar(<Notification title="Success" description="Archive Product." />, { variant: 'success' })
    })
  }

  const handleAddProduct = () => {
    const productIDList = values.productList.map((data) => data.productID)
    const searchProductIDList = values.searchProducts?.map((data) => data.value)
    const packData = {
      input: {
        joinItemWithCategoryID: joinItemWithCategoryID,
        productIDList: [...productIDList, ...searchProductIDList],
      },
    }

    updateJoinProduct({ variables: packData as MutationUpdateJoinProductCategoryProductArgs }).then((res) => {
      const resUpdate = res.data.updateJoinProductCategoryProduct
      if (resUpdate.res_code !== EResCode.Success) return

      setFetchProductList((prev) => prev + 1)
      setFieldValue('searchProducts', [])
      enqueueSnackbar(<Notification title="Success" description="Add Product." />, { variant: 'success' })
    })
  }

  return (
    <div className={styles.container}>
      <CategoryHeader
        findIndexCategory={findIndexCategory}
        updateCategory={updateCategory}
        refetch={refetch}
        handleArchiveProduct={handleArchiveProduct}
        handleAddProduct={handleAddProduct}
        updateJoinProduct={updateJoinProduct}
        joinItemWithCategoryID={joinItemWithCategoryID}
        updateProduct={updateProduct}
        updateSKU={updateSKU}
        setFetchProductList={setFetchProductList}
      />
      <Table
        objectKey={{
          imageHTML: 'Image',
          prefix: 'Prefix',
          productNameHTML: 'Name',
          description: 'Description',
          brandName: 'Brand',
          tagsHTML: 'Tags',
          skuQtyHTML: 'Sku Qty.',
          statusHTML: 'Status',
          visibleHTML: 'Visible',
          actionCategory: '',
        }}
        data={dataTable}
        handleClick={handleClickGoToViewProduct}
      />
    </div>
  )
}

export default CategorySection
