export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type CmsCompany = {
  __typename?: 'CMSCompany';
  announcement?: Maybe<Scalars['JSON']>;
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  poster?: Maybe<Scalars['JSON']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Category = {
  __typename?: 'Category';
  categoryID?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Array<Maybe<Scalars['String']>>>;
  index?: Maybe<Scalars['Int']>;
  isActive?: Maybe<E_Is_Active>;
  items?: Maybe<Array<Maybe<Category>>>;
  joinItemWithCategoryID?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Array<Maybe<Scalars['String']>>>;
  productOptionGroupID?: Maybe<Scalars['String']>;
};

export enum E_Currency {
  Thb = 'THB'
}

export enum E_Is_Active {
  False = 'FALSE',
  True = 'TRUE'
}

export enum E_Payment_Type {
  OneTime = 'ONE_TIME'
}

export enum E_Pricing_Model {
  StandardPricing = 'STANDARD_PRICING',
  VolumePricing = 'VOLUME_PRICING'
}

export enum E_Province {
  AmnatCharoen = 'AMNAT_CHAROEN',
  AngThong = 'ANG_THONG',
  Bangkok = 'BANGKOK',
  Buogkan = 'BUOGKAN',
  BuriRam = 'BURI_RAM',
  Chachoengsao = 'CHACHOENGSAO',
  Chaiyaphum = 'CHAIYAPHUM',
  ChaiNat = 'CHAI_NAT',
  Chanthaburi = 'CHANTHABURI',
  ChiangMai = 'CHIANG_MAI',
  ChiangRai = 'CHIANG_RAI',
  ChonBuri = 'CHON_BURI',
  Chumphon = 'CHUMPHON',
  Kalasin = 'KALASIN',
  KamphaengPhet = 'KAMPHAENG_PHET',
  Kanchanaburi = 'KANCHANABURI',
  KhonKaen = 'KHON_KAEN',
  Krabi = 'KRABI',
  Lampang = 'LAMPANG',
  Lamphun = 'LAMPHUN',
  Loburi = 'LOBURI',
  Loei = 'LOEI',
  MaeHongSon = 'MAE_HONG_SON',
  MahaSarakham = 'MAHA_SARAKHAM',
  Mukdahan = 'MUKDAHAN',
  NakhonNayok = 'NAKHON_NAYOK',
  NakhonPathom = 'NAKHON_PATHOM',
  NakhonPhanom = 'NAKHON_PHANOM',
  NakhonRatchasima = 'NAKHON_RATCHASIMA',
  NakhonSawan = 'NAKHON_SAWAN',
  NakhonSiThammarat = 'NAKHON_SI_THAMMARAT',
  Nan = 'NAN',
  Narathiwat = 'NARATHIWAT',
  NongBuaLamPhu = 'NONG_BUA_LAM_PHU',
  NongKhai = 'NONG_KHAI',
  Nonthaburi = 'NONTHABURI',
  PathumThani = 'PATHUM_THANI',
  Pattani = 'PATTANI',
  Phangnga = 'PHANGNGA',
  Phatthalung = 'PHATTHALUNG',
  Phayao = 'PHAYAO',
  Phetchabun = 'PHETCHABUN',
  Phetchaburi = 'PHETCHABURI',
  Phichit = 'PHICHIT',
  Phitsanulok = 'PHITSANULOK',
  Phrae = 'PHRAE',
  PhraNakhonSiAyutthaya = 'PHRA_NAKHON_SI_AYUTTHAYA',
  Phuket = 'PHUKET',
  PrachinBuri = 'PRACHIN_BURI',
  PrachuapKhiriKhan = 'PRACHUAP_KHIRI_KHAN',
  Ranong = 'RANONG',
  Ratchaburi = 'RATCHABURI',
  Rayong = 'RAYONG',
  RoiEt = 'ROI_ET',
  SakonNakhon = 'SAKON_NAKHON',
  SamutPrakan = 'SAMUT_PRAKAN',
  SamutSakhon = 'SAMUT_SAKHON',
  SamutSongkhram = 'SAMUT_SONGKHRAM',
  Saraburi = 'SARABURI',
  Satun = 'SATUN',
  SaKaeo = 'SA_KAEO',
  SingBuri = 'SING_BURI',
  SiSaKet = 'SI_SA_KET',
  Songkhla = 'SONGKHLA',
  Sukhothai = 'SUKHOTHAI',
  SuphanBuri = 'SUPHAN_BURI',
  SuratThani = 'SURAT_THANI',
  Surin = 'SURIN',
  Tak = 'TAK',
  Trang = 'TRANG',
  Trat = 'TRAT',
  UbonRatchathani = 'UBON_RATCHATHANI',
  UdonThani = 'UDON_THANI',
  UthaiThani = 'UTHAI_THANI',
  Uttaradit = 'UTTARADIT',
  Yala = 'YALA',
  Yasothon = 'YASOTHON'
}

export enum E_Trigger_Type {
  Approved = 'APPROVED',
  GoodsIssue = 'GOODS_ISSUE',
  GoodsIssueVoid = 'GOODS_ISSUE_VOID',
  GoodsReceipt = 'GOODS_RECEIPT',
  GoodsReceiptVoid = 'GOODS_RECEIPT_VOID',
  InventoryAdjustment = 'INVENTORY_ADJUSTMENT',
  InventoryAdjustmentVoid = 'INVENTORY_ADJUSTMENT_VOID',
  Order = 'ORDER',
  OrderVoid = 'ORDER_VOID',
  PhysicalCount = 'PHYSICAL_COUNT',
  PhysicalCountVoid = 'PHYSICAL_COUNT_VOID'
}

export enum E_Type_Option {
  Checkbox = 'CHECKBOX',
  Tag = 'TAG'
}

export enum Freight_Model {
  FixFreight = 'FIX_FREIGHT',
  ProvinceFreight = 'PROVINCE_FREIGHT'
}

export type FreightModelFix = {
  __typename?: 'FreightModelFix';
  freight?: Maybe<Scalars['Float']>;
};

export type FreightModelProvince = {
  __typename?: 'FreightModelProvince';
  isFreightOnDelivery?: Maybe<Scalars['Boolean']>;
  province?: Maybe<E_Province>;
  tableFreightProvince?: Maybe<Array<Maybe<TableFreightProvince>>>;
};

export type Inventory = {
  __typename?: 'Inventory';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  isVoided?: Maybe<E_Is_Active>;
  orderID?: Maybe<Scalars['String']>;
  productID?: Maybe<Scalars['String']>;
  productSKUID?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Int']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  stockAccumulated?: Maybe<Scalars['Float']>;
  stockAvailable?: Maybe<Scalars['Float']>;
  stockCommitted?: Maybe<Scalars['Float']>;
  stockIncoming?: Maybe<Scalars['Float']>;
  transactionNo?: Maybe<Scalars['Int']>;
  triggerType?: Maybe<E_Trigger_Type>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type JoinProductCategoryProduct = {
  __typename?: 'JoinProductCategoryProduct';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
  joinItemWithCategoryID?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Pricing = {
  __typename?: 'Pricing';
  companyID?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  currency?: Maybe<E_Currency>;
  customAttribute?: Maybe<Scalars['JSON']>;
  priceModelStandard?: Maybe<PricingModelStandard>;
  priceModelVolumn?: Maybe<PricingModelVolumn>;
  pricingID?: Maybe<Scalars['String']>;
  pricingModel?: Maybe<E_Pricing_Model>;
  productID?: Maybe<Scalars['String']>;
  productSKUID?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type PricingModelStandard = {
  __typename?: 'PricingModelStandard';
  defaultPrice?: Maybe<Scalars['Float']>;
  paymentType?: Maybe<E_Payment_Type>;
  salePrice?: Maybe<Scalars['Float']>;
};

export type PricingModelVolumn = {
  __typename?: 'PricingModelVolumn';
  paymentType?: Maybe<E_Payment_Type>;
  tableVolumnPrice?: Maybe<Array<Maybe<TableVolumnPrice>>>;
};

export type Product = {
  __typename?: 'Product';
  brandID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['JSON']>;
  isActive?: Maybe<E_Is_Active>;
  isVisible?: Maybe<E_Is_Active>;
  minimumStock?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  productFileURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  productID?: Maybe<Scalars['String']>;
  productImgURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  productOptionGroup?: Maybe<ProductOptionGroup>;
  productOptionGroupID?: Maybe<Scalars['String']>;
  productSKU?: Maybe<Array<Maybe<ProductSku>>>;
  remark?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  specAttribute?: Maybe<Scalars['JSON']>;
  tagID?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitLabel?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Category>>>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductListNonCategoryFilter = {
  isActive?: InputMaybe<E_Is_Active>;
  isVisible?: InputMaybe<E_Is_Active>;
  name?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
};

export type ProductOption = {
  __typename?: 'ProductOption';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isActive?: Maybe<E_Is_Active>;
  isFilter?: Maybe<E_Is_Active>;
  isOption?: Maybe<E_Is_Active>;
  name?: Maybe<Scalars['String']>;
  productOptionGroupID?: Maybe<Scalars['String']>;
  productOptionID?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  type?: Maybe<E_Type_Option>;
  updatedAt?: Maybe<Scalars['String']>;
  variant?: Maybe<Array<Maybe<ProductOptionVariant>>>;
};

export type ProductOptionGroup = {
  __typename?: 'ProductOptionGroup';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<E_Is_Active>;
  name?: Maybe<Scalars['String']>;
  option?: Maybe<Array<Maybe<ProductOption>>>;
  productOptionGroupID?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductOptionGroupFilter = {
  companyID: Scalars['String'];
  isActive?: InputMaybe<E_Is_Active>;
  productOptionGroupID?: InputMaybe<Scalars['String']>;
};

export type ProductOptionGroupQuery = {
  __typename?: 'ProductOptionGroupQuery';
  items?: Maybe<Array<Maybe<ProductOptionGroup>>>;
  nextToken?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
};

export type ProductOptionVariant = {
  __typename?: 'ProductOptionVariant';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isActive?: Maybe<E_Is_Active>;
  isDefault?: Maybe<E_Is_Active>;
  name?: Maybe<Scalars['String']>;
  productOptionID?: Maybe<Scalars['String']>;
  productOptionVariantID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductQuery = {
  __typename?: 'ProductQuery';
  items?: Maybe<Array<Maybe<Product>>>;
  nextToken?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
};

export type ProductSku = {
  __typename?: 'ProductSKU';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  inventory?: Maybe<Inventory>;
  isActive?: Maybe<E_Is_Active>;
  isVisible?: Maybe<E_Is_Active>;
  optionAttribute?: Maybe<Scalars['JSON']>;
  pricing?: Maybe<Array<Maybe<Pricing>>>;
  productID?: Maybe<Scalars['String']>;
  productSKU?: Maybe<Scalars['String']>;
  productSKUID?: Maybe<Scalars['String']>;
  productSKUName?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  tagID?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductSkuFilter = {
  isActive?: InputMaybe<E_Is_Active>;
  isVisible?: InputMaybe<E_Is_Active>;
};

export type Query = {
  __typename?: 'Query';
  cmsCompanyInfo?: Maybe<CmsCompany>;
  productCategoryListInfo?: Maybe<ProductCategory>;
  productInfo?: Maybe<Product>;
  productList?: Maybe<ProductQuery>;
  productListNonCategory?: Maybe<ProductQuery>;
  productOptionGroupList?: Maybe<ProductOptionGroupQuery>;
  settingCompanyInfo?: Maybe<SettingCompany>;
};


export type QueryCmsCompanyInfoArgs = {
  companyID: Scalars['String'];
};


export type QueryProductCategoryListInfoArgs = {
  companyID: Scalars['String'];
};


export type QueryProductInfoArgs = {
  companyID: Scalars['String'];
  filterSKU?: InputMaybe<ProductSkuFilter>;
  productID: Scalars['String'];
};


export type QueryProductListArgs = {
  companyID: Scalars['String'];
  filter?: InputMaybe<Scalars['JSON']>;
  filterSKU?: InputMaybe<ProductSkuFilter>;
  isVisible?: InputMaybe<E_Is_Active>;
  joinItemWithCategoryID: Scalars['String'];
};


export type QueryProductListNonCategoryArgs = {
  companyID: Scalars['String'];
  filter: ProductListNonCategoryFilter;
  filterSKU?: InputMaybe<ProductSkuFilter>;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryProductOptionGroupListArgs = {
  filter: ProductOptionGroupFilter;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QuerySettingCompanyInfoArgs = {
  companyID: Scalars['String'];
};

export type SettingCompany = {
  __typename?: 'SettingCompany';
  companyID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAttribute?: Maybe<Scalars['JSON']>;
  domainName?: Maybe<Scalars['String']>;
  freightModel?: Maybe<Freight_Model>;
  freightModelFix?: Maybe<FreightModelFix>;
  freightModelProvince?: Maybe<Array<Maybe<FreightModelProvince>>>;
  isClose?: Maybe<E_Is_Active>;
  res_code?: Maybe<Scalars['String']>;
  res_desc?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['Float']>;
  timeoutCart?: Maybe<Scalars['Float']>;
  timeoutPaid?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type TableFreightProvince = {
  __typename?: 'TableFreightProvince';
  endPrice?: Maybe<Scalars['Float']>;
  freight?: Maybe<Scalars['Float']>;
  startPrice?: Maybe<Scalars['Float']>;
};

export type TableVolumnPrice = {
  __typename?: 'TableVolumnPrice';
  endUnit?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Float']>;
  startUnit?: Maybe<Scalars['Float']>;
};
