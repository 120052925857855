// Lib
import React from 'react'

// Include in project
import styles from './index.module.scss'
import { s3URL } from 'configs'
// import { useModal } from 'hooks'
// import { ModalUploadImageTable } from 'container'

interface Props {
  img: string
  name?: string
  readonly?: boolean
}

const ImageTable: React.FC<Props> = ({ img, name, readonly }: Props): JSX.Element => {
  // const { isModalOpen, handleClose, handleOpen } = useModal()

  const srcImage = () => {
    if (img.search('https') > -1) {
      return img
    } else {
      return `${s3URL}/${img}`
    }
  }

  return (
    <div className={styles.container}>
      <img src={srcImage()} alt="" />
      {/* onClick={() => (readonly ? '' : handleOpen())} /> */}
      {/* <ModalUploadImageTable open={isModalOpen} handleClose={handleClose} fileList={[img]} name={`${name}`} /> */}
    </div>
  )
}

export default ImageTable
