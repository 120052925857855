// Lib
import React, { useEffect } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { withFormik, useFormikContext, Form } from 'formik'

// Images
import imageLoginSVG from 'images/login/imageLogin.png'

// Include in project
import styles from './index.module.scss'
import yupLogin from 'validations/yupLogin.validate'
import { EResCode } from 'utils/other'
import { useLogin, useLoadingControl } from 'hooks'
import { Button, TextField, Checkbox, TextFieldPassword } from 'component'

export interface ILogin {
  checkbox: string[]
  companyID: string
  email: string
  password: string
  otp: string
}

const intitialValue: ILogin = {
  checkbox: [],
  companyID: '',
  email: '',
  password: '',
  otp: '',
}

const Login: React.FC = () => {
  const navigate = useNavigate()
  const { values, errors } = useFormikContext<ILogin>()
  const { login, data, isLoading } = useLogin()
  useLoadingControl(isLoading)

  const handleLogin = async () => {
    if (!_.isEmpty(errors)) return

    const packData = {
      companyID: values.companyID,
      email: values.email,
      password: values.password,
    }

    login(packData)
  }

  useEffect(() => {
    if (!data) return
    if (data.status !== 200) return
    if (data.data.res_code !== EResCode.Success) return

    localStorage.setItem('accessToken', data.data.accessToken)
    localStorage.setItem('refreshToken', data.data.refreshToken)
    navigate('/')
  }, [data])

  return (
    <Form className={styles.container}>
      <div className={styles.leftItem}>
        <img className={styles.photo} src={imageLoginSVG} alt="" />
      </div>
      <div className={styles.rightItem}>
        <h6>เข้าสู่ระบบ Admin</h6>
        <div className={styles.wrapperInput}>
          <TextField name="companyID" type="text" placeholder="Company Code" />
          <TextField name="email" type="text" placeholder="Email" />
          <TextFieldPassword name="password" placeholder="Password" />
          <Button fullWidth name="Login" typeButton="submit" type="info" functionOnClick={handleLogin} />
          <div className={styles.rememberAndForget}>
            <Checkbox
              label="Remember me"
              checked={values.checkbox.includes('Remember me')}
              value="Remember me"
              name="checkbox"
            />
            <p className={styles.textColor} onClick={() => navigate('/forgot-password')}>
              Forget password?
            </p>
          </div>
        </div>
      </div>
    </Form>
  )
}

const EnhancedLogin = withFormik({
  mapPropsToValues: () => ({ ...intitialValue }),
  validateOnMount: true,
  validationSchema: yupLogin,
  handleSubmit: () => {
    console.log('Skip')
  },
})(Login)

export default EnhancedLogin
