// Lib
import React from 'react'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { IForgotPassword } from 'pages/ForgotPassword'
import { Button, TextFieldPassword } from 'component'

//Images
import closeSVG from 'images/forgotPassword/RedCross.svg'
import checkSVG from 'images/forgotPassword/checkGreen.svg'

interface IRenderPasswordValidate {
  isCurrect: boolean
  title: string
}

interface Props {
  handleNextStep: () => void
}

const ForgotPasswordStepThree: React.FC<Props> = ({ handleNextStep }: Props) => {
  const { values } = useFormikContext<IForgotPassword>()
  const passwordTracker = {
    uppercase: values.password.match(/[A-Z]/g),
    lowercase: values.password.match(/[a-z]/g),
    specialCharacters: values.password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g),
    eightCharsOrGreater: values.password.match(/.{8,}/g),
  }

  const RenderPasswordValidate: React.FC<IRenderPasswordValidate> = ({ title, isCurrect }) => {
    return (
      <div className={styles.wrapperText}>
        <img src={isCurrect ? closeSVG : checkSVG} alt="icon" />
        <p aria-checked={!isCurrect} className={styles.text}>
          {title}
        </p>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapperText}>
        <h5>ตั้งค่ารหัสผ่านใหม่</h5>
        <h6>รหัสผ่านเก่าของคุณ จะถูกรีเซต และจะใช้รหัสผ่านใหม่นี้ในการเข้าสู่ระบบครั้งถัดไป </h6>
      </div>

      <div className={styles.wrapperInput}>
        <TextFieldPassword label="รหัสผ่านใหม่" placeholder="รหัสผ่านใหม่" name="password" />

        <div className={styles.groupText}>
          <RenderPasswordValidate
            isCurrect={!passwordTracker.eightCharsOrGreater}
            title="ต้องมีอักขระระหว่าง 8 ถึง 20 ตัว"
          />
          <RenderPasswordValidate isCurrect={!passwordTracker.lowercase} title="ต้องมีตัวพิมพ์เล็กอย่างน้อย 1 ตัว" />
          <RenderPasswordValidate isCurrect={!passwordTracker.uppercase} title="ต้องมีตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว" />
          <RenderPasswordValidate
            isCurrect={!passwordTracker.specialCharacters}
            title="ต้องมีอักษรพิเศษอย่างน้อย 1 ตัว"
          />
        </div>
        <TextFieldPassword label="กรอกรหัสผ่านอีกครั้ง" placeholder="รหัสผ่านใหม่" name="passwordConfirm" />
        <Button name="รีเซตรหัสผ่าน" typeButton="button" type="info" size="large" functionOnClick={handleNextStep} />
      </div>
    </div>
  )
}

export default ForgotPasswordStepThree
